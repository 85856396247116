import {
  BgCardModelType,
  GuaranteeController,
} from "@/services/api/controllers/bankGuarantee/GuaranteeController";
import { axiosInstanceBG } from "@/services/api/axiosInstance";
import { BgResponseType } from "@/types/BgTask";
import { AxiosError } from "axios";

export const getGuaranteePurchase = async (number: string) => {
  try {
    const { data } = await GuaranteeController.searchPurchase(number);
    return data[0] || [];
  } catch (error) {
    throw error;
  }
};

export const saveBgCard = async (model: BgCardModelType, orderId: string) => {
  try {
    await GuaranteeController.saveBgCard(model, orderId);
  } catch (error) {
    throw error;
  }
};

export const getBgCard = async (orderId: string) => {
  try {
    const { data } = await GuaranteeController.getBgCard(orderId);
    return data.components;
  } catch (error) {
    throw error;
  }
};

const TASK_LIST_SORT = { sort: "createdAt", sortOrder: "DESC" };
const TASK_LIST_LIMIT = 15;

export const getBGTasksList = async (filters = {}, pageNumber = 1) => {
  try {
    const { data } = await axiosInstanceBG.get<BgResponseType>(`/bg`, {
      params: {
        pageNum: pageNumber,
        pageSize: TASK_LIST_LIMIT,
        number: filters.numberTaskFilter,
        companyId: filters.company.id,
        createdDateFrom: filters.dateFilter ? filters.dateFilter[0] : undefined,
        createdDateTo: filters.dateFilter ? filters.dateFilter[1] : undefined,
        // processType: filters.orderTypeFilter,
        orderState: filters.orderStateFilter,
        sort: TASK_LIST_SORT.sort,
        sortOrder: TASK_LIST_SORT.sortOrder,
      },
    });

    return data;
  } catch (err) {
    console.log(err);
    throw new Error((err as AxiosError).message);
  }
};
