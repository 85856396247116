import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, FormInput } from "@farzoom/common-ui-components";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { Col, Row, Typography } from "antd";
import { useStyles } from "@/lib/modals/BankGuaranteeRequest/index";
import { useContext } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { BgFormType } from "@/types/BgForm";
import { LotsSubComponent } from "@/lib/modals/BankGuaranteeRequest/lots";

const { Text } = Typography;

export const IKZList = ({ lotIndex }: LotsSubComponent) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);

  const currentPrefix = prefix ? `${prefix}.purchase.lots` : `purchase.lots`;

  const {
    fields: list,
    prepend,
    remove,
  } = useFieldArray({
    control,
    name: `${currentPrefix}.${lotIndex}.ikzList`,
  });

  const onAddIKZ = () => {
    prepend("");
  };

  const onRemove = (idx: number) => remove(idx);

  return (
    <VerticalSpace>
      <Text>
        <div className={classes.actionTitle}>
          <span>Идентификационный код закупки</span>
          {!formDisabled && (
            <Button type="outlined" iconName="add" title="Добавить счет" onClick={onAddIKZ} />
          )}
        </div>
      </Text>
      <VerticalSpace size="small">
        {list.map((item, idx) => (
          <Row key={item.id} gutter={16} align="middle">
            <Col flex="auto">
              <FormInput
                name={`${currentPrefix}.${lotIndex}.ikzList.${idx}`}
                control={control}
                setValue={setValue}
                placeholder="Введите идентификационный код закупки"
              />
            </Col>
            {list.length > 1 && !formDisabled && (
              <Col flex="32px">
                <Button
                  type="outlined"
                  iconName="remove"
                  title="Удалить товар"
                  onClick={() => onRemove(idx)}
                />
              </Col>
            )}
          </Row>
        ))}
      </VerticalSpace>
    </VerticalSpace>
  );
};
