import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

const initialState = {
  open: false,
  type: "",
  config: {},
  closePath: "",
  isLoading: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.type = action.payload.type;
      state.config = action.payload.config;
      state.closePath = action.payload.closePath;
    },
    closeModal: (state) => {
      state.open = false;
      state.type = "";
      state.config = {};
      state.closePath = "";
    },
    setModalFetching: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { openModal, closeModal, setModalFetching } = modalSlice.actions;

export const modalState = (state: RootState) => state?.carrot?.modal;

export const selectModalOpen = (state: RootState) => !!state?.carrot?.modal?.open;
export const selectModalType = (state: RootState) => state.carrot?.modal.type;
export const selectModalConfig = (state: RootState) => state.carrot?.modal.config;
export const selectModalClosePath = (state: RootState) => state.carrot?.modal.closePath;
export const isModalLoading = (state: RootState) => state.carrot.modal.isLoading;

export default modalSlice.reducer;
