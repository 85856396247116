import React from "react";
import { Checkbox, Col, Row } from "antd";
import { FormInput } from "@farzoom/common-ui-components";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";
import { disabledDate, useStyles } from "../personInfoModal";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import useScreenSize from "@hooks/useScreenSize";

export const CEOBlock = ({ control, setValue, readonly, register, personW }) => {
  const classes = useStyles();
  const screenSize = useScreenSize();

  const onHeadChange = (e: CheckboxChangeEvent) => {
    setValue("personRole.isHead", e.target.checked);
    if (e.target.checked) {
      setValue("personRole.isSigner", true);
      setValue("personRole.employee.byProxy", false);
      setValue("personRole.employee.proxy.proxyName", null);
      setValue("personRole.employee.proxy.proxyDateStart", null);
      setValue("personRole.employee.proxy.proxyDateEnd", null);
    }
  };

  const onSignerChange = (e: CheckboxChangeEvent) => {
    setValue("personRole.isSigner", e.target.checked);

    if (!e.target.checked) {
      setValue("personRole.employee.byProxy", false);
      setValue("personRole.employee.proxy.proxyName", null);
      setValue("personRole.employee.proxy.proxyDateStart", null);
      setValue("personRole.employee.proxy.proxyDateEnd", null);
    }
  };

  return (
    <Row gutter={[16, 16]} className={classes.block}>
      <Col span={24} xl={6}>
        <VerticalSpace size="small">
          <Checkbox
            {...register("personRole.isHead")}
            checked={personW?.personRole?.isHead}
            onChange={onHeadChange}
          >
            Руководитель
          </Checkbox>
          <Checkbox
            {...register("personRole.isSigner")}
            checked={personW?.personRole?.isSigner}
            onChange={onSignerChange}
          >
            Подписант
          </Checkbox>
        </VerticalSpace>
      </Col>
      <Col span={24} xl={18}>
        <VerticalSpace>
          <Row gutter={8} align={screenSize.m ? "top" : "bottom"}>
            <Col span={10}>
              <FormInput
                name="personRole.employee.position"
                control={control}
                setValue={setValue}
                label="Должность"
                disabled={readonly}
              />
            </Col>
            <Col span={7}>
              <FormInputDateTime
                name="personRole.employee.positionDateStart"
                control={control}
                setValue={setValue}
                label="Дата назначения"
                controls={false}
                disabledDate={disabledDate}
                disabled={readonly}
              />
            </Col>
            <Col span={7}>
              <FormInputDateTime
                name="personRole.employee.positionDateEnd"
                control={control}
                setValue={setValue}
                label="Дата истечения полномочий"
                controls={false}
                disabled={readonly}
              />
            </Col>
          </Row>
          <Row gutter={8} align={screenSize.m ? "top" : "bottom"}>
            <Col span={10}>
              <FormInput
                name="personRole.employee.authorizingDoc.authorizingDocName"
                control={control}
                setValue={setValue}
                label="Документ о назначении на должность"
                disabled={readonly}
              />
            </Col>
            <Col span={7}>
              <FormInput
                name="personRole.employee.authorizingDoc.authorizingDocNumber"
                control={control}
                setValue={setValue}
                label="Номер документа"
                disabled={readonly}
              />
            </Col>
            <Col span={7}>
              <FormInputDateTime
                name="personRole.employee.authorizingDoc.authorizingDocDate"
                control={control}
                setValue={setValue}
                label="Дата"
                controls={false}
                disabledDate={disabledDate}
                disabled={readonly}
              />
            </Col>
          </Row>

          {personW?.personRole?.isSigner && (
            <VerticalSpace size="small">
              <Checkbox
                {...register("personRole.employee.byProxy")}
                checked={personW?.personRole?.employee?.byProxy}
                onChange={(e) => setValue("personRole.employee.byProxy", e.target.checked)}
              >
                Действует на основании доверенности
              </Checkbox>
              <Row gutter={8}>
                <Col span={10}>
                  <FormInput
                    name="personRole.employee.proxy.proxyName"
                    control={control}
                    setValue={setValue}
                    label="Наименование доверенности"
                    disabled={!personW?.personRole?.employee?.byProxy || readonly}
                  />
                </Col>
                <Col span={7}>
                  <FormInputDateTime
                    name="personRole.employee.proxy.proxyDateStart"
                    control={control}
                    setValue={setValue}
                    label="Дата выдачи"
                    controls={false}
                    disabledDate={disabledDate}
                    disabled={!personW?.personRole?.employee?.byProxy || readonly}
                  />
                </Col>
                <Col span={7}>
                  <FormInputDateTime
                    name="personRole.employee.proxy.proxyDateEnd"
                    control={control}
                    setValue={setValue}
                    label="Дата истечения"
                    controls={false}
                    disabled={!personW?.personRole?.employee?.byProxy || readonly}
                  />
                </Col>
              </Row>
            </VerticalSpace>
          )}
        </VerticalSpace>
      </Col>
    </Row>
  );
};
