import { SeldonProductType } from "@/types/BgForm";
import {
  beneficiariesKeys,
  benOrgKeys,
  keysMap,
  lotKeysMap,
  lotTargetTemplate,
  targetTemplate,
} from "./purchaseMapKeys";

export const purchaseMap = (purchaseSeldon) => {
  const target = structuredClone(targetTemplate);

  Object.entries(purchaseSeldon).forEach(([key, value]) => {
    if (keysMap[key]) target[keysMap[key]] = value;
    if (key === "purchaseTypeID") {
      if (value === 1) target.law = "commercial"; // Коммерческая
      if (value === 2) target.law = null; // Международная (не поддерживается)
      if (value === 3) target.law = null; // 94 ФЗ (не поддерживается)
      if (value === 4) target.law = "44"; // 44 ФЗ
      if (value === 5) target.law = "223"; // 223 ФЗ
      if (value === 6) target.law = "185-615"; // 615 ПП
      if (value === 7) target.law = null; // Малая (не поддерживается)
    }
  });

  purchaseSeldon.lotsList.forEach((lot, index) => {
    const currentLot = structuredClone(lotTargetTemplate);

    Object.entries(lot).forEach(([key, value]) => {
      if (lotKeysMap[key]) currentLot[lotKeysMap[key]] = value;
    });

    lot.productsList.forEach((product) => {
      currentLot.products = Object.entries(product.classifier)
        .filter(([key]) => key === "okpd2")
        .map(([_, value]) => ({
          okpdName: (value as SeldonProductType).name,
          okpdCode: (value as SeldonProductType).code,
        }));
    });

    lot.customersList.forEach((customer, bIndex) => {
      Object.entries(customer).forEach(([key, value]) => {
        if (beneficiariesKeys[key]) {
          currentLot.beneficiaries[bIndex][beneficiariesKeys[key]] = value;
        }
      });
      Object.entries(customer.organization).forEach(([cKey, cValue]) => {
        if (benOrgKeys[cKey]) currentLot.beneficiaries[bIndex][benOrgKeys[cKey]] = cValue;
      });
    });

    target.lots[index] = currentLot;
  });

  return target;
};
