import { getTokenParsed } from "@farzoom/common-ui-components";
import { useKeycloak } from "react-keycloak";

export const useAccessRoles = () => {
  const { keycloak } = useKeycloak();
  const tokenData = getTokenParsed();
  const realmRoles = tokenData?.realm_access?.roles;

  const isKeycloakToken = Boolean(keycloak.token);

  const hasRealmRole = (role: string) => realmRoles.includes(role);

  return {
    realmRoles: isKeycloakToken ? keycloak?.realmAccess?.roles : realmRoles,
    hasRealmRole: isKeycloakToken ? keycloak?.hasRealmRole : hasRealmRole,
  };
};
