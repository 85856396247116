import { Buttons, colors, ended, H3, MEDIA_QUERY, Spinner } from "@farzoom/common-ui-components";
import { Button, Form } from "antd";
import classnames from "classnames";
import { ReactNode } from "react";
import { createUseStyles } from "react-jss";

type StartFormWrapperProps = {
  title: string;
  formName: string;
  children: ReactNode;
  isFetching?: boolean;
  onSubmit?: () => void;
  onClose: () => void;
  submitBtnText?: string;
  className?: string;
  isSubmitDisabled?: boolean;
};

const useStyles = createUseStyles({
  startForm: {
    minWidth: 800,
    position: "relative",
    maxHeight: "85svh",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: 5,
    "& $buttons": {
      ...ended,
      position: "sticky",
      bottom: 1,
      backgroundColor: "white",
      [MEDIA_QUERY.mobile]: {
        flexDirection: "column-reverse",
        alignItems: "stretch",

        "& > button": {
          height: 46,

          "& > span": {
            fontSize: 14,
            lineHeight: 22 / 14,
            fontWeight: 500,
          },
        },
      },
    },
    [MEDIA_QUERY.mobile]: {
      minWidth: "unset",
    },
  },
  form: {
    marginTop: 24,
  },
  buttons: {
    paddingTop: 16,
  },
});

export const StartFormWrapper = ({
  title,
  formName,
  children,
  isFetching,
  onSubmit,
  onClose,
  submitBtnText = "Отправить заявку",
  className,
  isSubmitDisabled = false,
}: StartFormWrapperProps) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.startForm, className)}>
      <H3>{title}</H3>
      <div className={classes.form}>
        <Form layout="vertical" name={formName}>
          {children}
          <Buttons className={classes.buttons}>
            <Button onClick={onClose}>Отмена</Button>
            <Button onClick={onSubmit} type="primary" disabled={isSubmitDisabled || isFetching}>
              {submitBtnText}
            </Button>
          </Buttons>
        </Form>
      </div>
      <Spinner isFetching={isFetching} color={colors.primaryColor1} />
    </div>
  );
};
