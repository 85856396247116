import { VerticalSpace } from "@components/UI/VerticalSpace";
import { Typography } from "antd";
import React from "react";
import { useStyles } from "../personInfoModal";
import { CEOBlock } from "@components/BankGuarantee/personInfo/personRoles/CEOBlock";
import { FounderBlock } from "@components/BankGuarantee/personInfo/personRoles/founderBlock";
import { BeneficiaryBlock } from "@components/BankGuarantee/personInfo/personRoles/beneficiaryBlock";
import { MembersBlock } from "@components/BankGuarantee/personInfo/personRoles/membersBlock";

const { Title } = Typography;

export const PersonRoles = ({ control, setValue, readonly, register, personW }) => {
  const classes = useStyles();

  return (
    <div className={classes.rolesWrapper}>
      <Title level={4}>Роли физ.лица в компании</Title>
      <VerticalSpace>
        <CEOBlock
          control={control}
          setValue={setValue}
          readonly={readonly}
          register={register}
          personW={personW}
        />
        <FounderBlock
          control={control}
          setValue={setValue}
          readonly={readonly}
          register={register}
          personW={personW}
        />
        <BeneficiaryBlock
          control={control}
          setValue={setValue}
          readonly={readonly}
          register={register}
          personW={personW}
        />
        <MembersBlock register={register} personW={personW} setValue={setValue} />
      </VerticalSpace>
    </div>
  );
};
