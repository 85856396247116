import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { FormAutoComplete } from "./FormAutoComplete";
import { centered } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  addressSearch: {
    width: "100%",
    gap: 20,
    ...centered,
  },
});

export const AddressSearch = ({
  label,
  prefix,
  control,
  showHint,
  disabled,
  required,
  setValue,
  forceFetch,
  controlled,
  placeholder,
  addressDetails = {},
  hiddenFieldNames = [],
  getVisibleFieldName,
  getHiddenFieldNames,
  className,
  fullAddress,
  parentOnChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.addressSearch}>
      <FormAutoComplete
        type="address"
        label={label}
        prefix={prefix}
        className={className}
        classes={classes}
        control={control}
        setValue={setValue}
        required={required}
        disabled={disabled}
        showHint={showHint}
        controlled={controlled}
        forceFetch={forceFetch}
        placeholder={placeholder}
        hiddenFieldNames={hiddenFieldNames}
        addressDetails={addressDetails}
        getVisibleFieldName={getVisibleFieldName}
        getHiddenFieldNames={getHiddenFieldNames}
        fullAddress={fullAddress}
        parentOnChange={parentOnChange}
      />
    </div>
  );
};

AddressSearch.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hiddenFieldNames: PropTypes.array,
  getVisibleFieldName: PropTypes.func,
  prefix: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,

  showHint: PropTypes.bool,
  forceFetch: PropTypes.bool,
  controlled: PropTypes.bool,
  placeholder: PropTypes.string,
  getHiddenFieldNames: PropTypes.func,
  className: PropTypes.string,
  fullAddress: PropTypes.bool,
  parentOnChange: PropTypes.func,
};
