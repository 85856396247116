import { Button, colors, Modal } from "@farzoom/common-ui-components";
import { Checkbox, Col, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useAppSelector } from "@store/store";
import { isMultilot } from "@store/BankGuarantee/purchaseSlice";
import { PurchaseLotType } from "@/types/BgForm";

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  container: {
    position: "relative",
    maxWidth: 1000,
  },
  head: {
    marginTop: 34,
    marginBottom: 24,
    display: "block",
  },
  row: {
    padding: 16,
    backgroundColor: colors.cardBackground,
    borderRadius: 8,
    "&:not(:last-of-type)": {
      marginBottom: 20,
    },
  },

  buttons: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 16,
    backgroundColor: "white",
  },
});

export const LotsSelectModal = ({ purchase, onOk, onClose }) => {
  const classes = useStyles();
  const isMultilotPurchase = useAppSelector(isMultilot);
  const [selectedLots, setSelectedLots] = useState<PurchaseLotType[]>(() => []);

  const onChangeSelect = (lot) => (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      setSelectedLots(selectedLots.filter((l) => lot.id !== l.id));
      return;
    }

    if (!isMultilotPurchase) {
      setSelectedLots([lot]);
      return;
    }

    setSelectedLots([...selectedLots, lot]);
  };

  return (
    <Modal color={colors.ruby1} onClose={onClose} closePath={false}>
      <div className={classes.container}>
        <Title level={4}>Доступные лоты</Title>
        <Text strong className={classes.head}>
          <Row gutter={8}>
            <Col offset={1} span={1}>
              №
            </Col>
            <Col span={19}>Предмет контракта</Col>
            <Col span={3}>НМЦК</Col>
          </Row>
        </Text>
        {purchase.lotsList.map((lot) => (
          <Row key={lot.id} gutter={8} className={classes.row}>
            <Col span={1}>
              <Checkbox
                onChange={onChangeSelect(lot)}
                checked={!!selectedLots.find((l) => lot.id === l.id)}
              />
            </Col>
            <Col span={1}>{lot.lotNumber}</Col>
            <Col span={19}>{lot.subject}</Col>
            <Col span={3}>
              {lot.price.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })}
            </Col>
          </Row>
        ))}
      </div>
      <div className={classes.buttons}>
        <Button type="primary" text="Выбрать" onClick={() => onOk(selectedLots)} />
      </div>
    </Modal>
  );
};
