import React from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  formAutoCompleteItem: {
    padding: [4, 0],
    "& mark": {
      background: "none",
      padding: 0,
      color: "#F5A631",
    },
  },
  formAutoCompleteItemTitle: {
    fontSize: 16,
    lineHeight: 16 / 16,
    fontWeight: 600,
  },
  formAutoCompleteItemSubtitle: {
    marginTop: 4,
    color: "#81888c",
    fontSize: 12,
    lineHeight: 12 / 12,
    fontWeight: 400,
    "& span": {
      display: "inline-block",
      verticalAlign: "top",
      "& + span": {
        marginLeft: 4,
      },
    },
  },
});

export const FormAutoCompleteItem = ({
  search = "",
  value = "",
  notValueText = "Наименование отсутствует",
  subValues = {},
  isPerson,
}) => {
  const classes = useStyles();
  const { inn, ogrn, kpp, address, name } = subValues || {};
  const hasSubValues = Object.keys(subValues || {})?.length > 0;
  const highlighterProps = {
    searchWords: search?.split(" "),
    autoEscape: true,
  };
  return (
    <div className={classes.formAutoCompleteItem}>
      <Highlighter
        className={classes.formAutoCompleteItemTitle}
        textToHighlight={
          /* value?.length > 28
          ? `${value?.slice(0, 28)}...`
          : */ value || notValueText
        }
        {...highlighterProps}
      />
      {hasSubValues && (
        <>
          <div className={classes.formAutoCompleteItemSubtitle}>
            {inn && (
              <span>
                <span>ИНН</span>
                <Highlighter textToHighlight={inn} {...highlighterProps} />
              </span>
            )}
            {ogrn && (
              <span>
                <span>, ОГРН</span>
                <Highlighter textToHighlight={ogrn} {...highlighterProps} />
              </span>
            )}
            {kpp && (
              <span>
                <span>, КПП</span>
                <Highlighter textToHighlight={kpp} {...highlighterProps} />
              </span>
            )}
            {!isPerson && inn && (
              <span>{kpp ? ", юридическое лицо" : ", индивидуальный предприниматель"}</span>
            )}
          </div>
          {address && (
            <div className={classes.formAutoCompleteItemSubtitle}>
              <span>{address}</span>
            </div>
          )}
          {name && (
            <div className={classes.formAutoCompleteItemSubtitle}>
              <span style={{ whiteSpace: "pre-wrap" }}>{name}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

FormAutoCompleteItem.propTypes = {
  search: PropTypes.string,
  value: PropTypes.string,
  notValueText: PropTypes.string,
  subValues: PropTypes.object,
  isPerson: PropTypes.bool,
};
