import { useLocation } from "react-router-dom";

export const useExternalTask = () => {
  const location = useLocation();

  const isExternalTask = location.pathname.includes("external-task");
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  return {
    isExternalTask,
    token,
  };
};
