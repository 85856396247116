export const THEME_COLOR = "#242232";

export const COLOR_KEYS = {
  purple: "purple",
  blue: "blue",
  yellow: "yellow",
  red: "red",
  green: "green",
};

export const COLOR_VALUES = {
  purple: "#722ED1",
  blue: "#538DD5",
  yellow: "#E98C0D",
  red: "#ff0000",
  green: "#41B807",
  gray: "#666",
};

export const COLORS_BY_ORDER_STATUS_CATEGORY = {
  total: "",
  assigned: COLOR_KEYS.purple,
  assignedAgent: COLOR_KEYS.purple,
  assignedClient: COLOR_KEYS.blue,
  inprogress: COLOR_KEYS.yellow,
  sold: COLOR_KEYS.green,
  lost: COLOR_KEYS.red,
};

const WIDTH_1024 = 1200;

export const adaptive_1024 = `@media (max-width: ${WIDTH_1024 - 1}px)`;
export const adaptive_1200 = "@media (max-width: 1199.98px)";
