import { useForm } from "react-hook-form";
import { useStartForm } from "../hooks/useStartForm";
import { completeStartForm } from "@store/formsSlice";
import { StartFormWrapper } from "../StartFormWrapper";
import { FormInput, FormInputMoney, FormSelect } from "@farzoom/common-ui-components";
import { DocumentsBlock } from "@/_custom/components/Blocks/DocumentsBlock";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { createUseStyles } from "react-jss";
import { useDebtorsList } from "../hooks/useDebtorsList";
import { Typography } from "antd";
import classnames from "classnames";
import { suppliesState } from "@store/supplies/suppliesSlice";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";

const { Title } = Typography;

const useStyles = createUseStyles({
  inputsWrapper: {
    marginBottom: 16,
    display: "flex",
    gap: 16,
    "& .ant-form-item": {
      marginBottom: 0,
    },
  },
  input: {
    flexBasis: "100%",
    "& input": {
      borderRadius: "8px !important",
    },
    "& .ant-input-number-handler-wrap": {
      display: "none",
    },
  },
  moneyInput: {
    "& .ant-input-number-status-error input": {
      borderColor: "var(--ant-error-color) !important",
    },
  },
  select: {
    margin: "0 0 16px",
    "& .ant-select input": {
      borderRadius: "8px !important",
    },
    "& .ant-form-item-control .ant-select-selector": {
      borderRadius: "8px !important",
    },
  },
});
const formatDate = (date) => moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");

export const SupplyVerificationStartForm = ({ id }) => {
  const dispatch = useDispatch();
  const { control, setValue, register, handleSubmit } = useForm();
  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Заявка отправлена",
  });
  const { supplyConfig } = useSelector(suppliesState);
  const { debtorsList } = useDebtorsList(supplyConfig);
  const classes = useStyles();

  const onSubmit = handleSubmit((data) => {
    setIsFetching(true);

    const payload = {
      supplyContractNumber: data.supplyContractNumber,
      supplyContractDate: formatDate(data.supplyContractDate),
      docDate: formatDate(data.docDate),
      supplyAmount: data.supplyAmount,
      supplier: {
        inn: data.supplier?.inn,
      },
      debtor: {
        inn: data.debtor || supplyConfig.debtors[0].inn,
      },
      files: data.docs[0].files,
    };

    dispatch(
      completeStartForm({
        id,
        payload,
        onSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      id={id}
      title="Запрос досрочной оплаты"
      formName="supplyVerificationForm"
      isFetching={isFetching}
      onSubmit={onSubmit}
      onClose={onClose}
      isSubmitDisabled={!debtorsList.length}
    >
      {debtorsList.length === 0 && (
        <Title level={4} type="danger" style={{ marginBottom: "16px" }}>
          Необходимо подписать заявление о присоединении
        </Title>
      )}

      <FormSelect
        className={classes.select}
        name="debtor"
        label="Дебитор:"
        placeholder="Выберите из списка"
        setValue={setValue}
        register={register}
        control={control}
        defaultValue={supplyConfig.debtors.length === 1 ? supplyConfig.debtors[0].inn : null}
        options={debtorsList}
        required
      />

      {supplyConfig?.showSupplier && (
        <div className={classes.select}>
          <CompanySuggestion
            control={control}
            register={register}
            setValue={setValue}
            prefix="supplier"
            label="Поставщик"
            placeholder="Введите название или ИНН"
            required
            controlled
          />
        </div>
      )}

      <div className={classes.inputsWrapper}>
        <FormInput
          className={classes.input}
          control={control}
          setValue={setValue}
          register={register}
          name="supplyContractNumber"
          label="№ договора поставки"
          placeholder="Введите номер договора"
          required
        />

        <FormInputDateTime
          className={classes.input}
          name="supplyContractDate"
          type="date"
          label="Дата договора поставки"
          placeholder="Выберите дату договора"
          register={register}
          setValue={setValue}
          control={control}
          format="DD.MM.YYYY"
          required
        />
      </div>

      <div className={classes.inputsWrapper}>
        <FormInputDateTime
          className={classes.input}
          name="docDate"
          type="date"
          label="Дата закрывающего документа"
          placeholder="Выберите дату закрывающего документа"
          register={register}
          setValue={setValue}
          control={control}
          format="DD.MM.YYYY"
          required
        />
        <div className={classnames(classes.moneyInput, classes.input)}>
          <FormInputMoney
            control={control}
            setValue={setValue}
            register={register}
            name="supplyAmount"
            label="Сумма поставки"
            placeholder="Введите сумму"
            required
            maxLength={25}
          />
        </div>
      </div>

      <DocumentsBlock
        config={{
          types: [
            {
              load: true,
              sign: false,
              title: "Подтверждающие документы",
              remove: true,
              docType: "documentRegisterDeliveries",
              multiple: true,
              required: true,
              entityType: "expertise",
            },
          ],
          visible: true,
        }}
        setValue={setValue}
        prefix="docs"
        control={control}
      />
    </StartFormWrapper>
  );
};
