import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { OrderInfoWrapper } from "./OrderInfoWrapper";
import { LoadingOverlay } from "@farzoom/common-ui-components";
import { OrderController } from "@/services/api/controllers/OrderController";

const useStyles = createUseStyles({
  loading: {
    width: "calc(100vw - 220px)",
    maxWidth: "1300px",
    minHeight: "calc(100vh - 40px)",
  },
});

export const OrderInfoForm = ({ orderId, userInfo }) => {
  const [config, setConfig] = useState();
  const [model, setModel] = useState();

  const _userInfo =
    Object.keys(userInfo || {})?.length > 0 ? userInfo : window?.shellSharedContext?.userInfo;

  const getFormData = async () => {
    if (orderId) {
      const config = await OrderController?.getConfig(orderId).then((response) => response?.data);
      const model = await OrderController?.getModel(orderId).then((response) => response?.data);
      setConfig(config);
      setModel(model);
    }
  };

  useEffect(async () => {
    const init = async () => await getFormData();
    init();
  }, [orderId]);

  const classes = useStyles();

  return config && model ? (
    <OrderInfoWrapper
      orderId={orderId}
      config={config}
      model={model}
      userInfo={_userInfo}
      onSaveCallback={getFormData}
    />
  ) : (
    <div className={classes.loading}>
      <LoadingOverlay />
    </div>
  );
};
