import { createUseStyles } from "react-jss";
import { adaptive_1024 } from "@/config";
import { colors } from "@farzoom/common-ui-components";

export const useStyles = createUseStyles({
  startFormButton: {
    right: 20,
    bottom: 20,
    position: "fixed",
    zIndex: 500,
    [adaptive_1024]: {
      bottom: 8,
    },
  },
  overlay: {
    zIndex: 200,
  },
  button: {
    background: ["var(--ant-primary-color)", "!important"],
    width: 64,
    height: 64,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: [0, 2, 8, "gray"],
    cursor: "pointer",
    transition: [
      ["transform", "300ms", "ease"],
      ["filter", "300ms", "ease"],
    ],
    position: "relative",
    [adaptive_1024]: {
      width: 50,
      height: 50,
      borderRadius: 12,
      background: colors.ruby2,
      boxShadow: [0, 4, 10, "rgba(102, 102, 102, 0.32)"],
    },
    "&.open": {
      background: "#fff",
      "&:before, &:after": {
        transform: "translate(-50%, -50%) rotateZ(135deg)",
      },
    },
    "&:before, &:after": {
      content: '""',
      background: "#fff",
      borderRadius: 8,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: ["transform", "300ms", "ease"],
    },
    "&:before": {
      width: 32,
      height: 4,
      [adaptive_1024]: {
        width: 19,
        height: 2,
      },
    },
    "&:after": {
      width: 4,
      height: 32,
      [adaptive_1024]: {
        width: 2,
        height: 19,
      },
    },
    "&:hover": {
      transform: "scale(1.1)",
      filter: "brightness(1.1)",
    },
  },
  list: {
    position: "absolute",
    bottom: "100%",
    right: 0,
    borderRadius: 16,
    transform: "translateY(-10px)",
  },
  item: {
    background: "#fff",
    width: "100%",
    minHeight: 48,
    padding: [0, 24],
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: 20,
    fontFamily: "Montserrat",
    fontWeight: 500,
    transition: ["background", "300ms", "ease"],
    "& + &": {
      marginTop: 16,
    },
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
    display: "block",
  },
});
