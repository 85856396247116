import { createUseStyles } from "react-jss";
import { colors } from "@farzoom/common-ui-components";

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: colors.cardBackground,
    padding: 16,
    borderRadius: 8,
    "& [class*=formRadioSingle]": {
      padding: "0 !important",
      background: "none",
      gap: 8,
    },
    "& .ant-row:not(:last-of-type)": {
      marginBottom: 22,
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 16,
    "& h4": {
      margin: 0,
    },
  },
  titleLink: {
    fontSize: 16,
    fontWeight: 500,
    display: "inline-flex",
    gap: 8,
    transition: "color .3s ease !important",
    "&:hover": {
      color: "var(--ant-primary-color-active) !important",
      "& $linkIcon": {
        rotate: "90deg",
      },
    },
  },
  linkIcon: {
    display: "inline-block",
    rotate: "45deg",
    transformOrigin: "center",
    transition: "rotate .2s ease",
    marginTop: 2,
  },

  label: {
    display: "block",
    fontSize: 14,
    fontWeight: 400,
  },
  btnWrapper: {
    paddingTop: 16,
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",

    "& button": {
      marginLeft: "auto",
    },
  },
  bgModalContainer: {
    width: 1100,
  },
});
