import React from "react";
import { Action, Icons } from "..";

export const Edit = (props) => (
  <Action {...props}>
    <Icons.Edit />
  </Action>
);

export const Confirm = (props) => (
  <Action {...props}>
    <Icons.Confirm />
  </Action>
);

export const Cancel = (props) => (
  <Action {...props}>
    <Icons.Cancel />
  </Action>
);

export const Success = (props) => (
  <Action {...props}>
    <Icons.Success />
  </Action>
);
