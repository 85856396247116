import { FormRadioSingle } from "@farzoom/common-ui-components";
import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { Checkbox, Typography } from "antd";
import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";
import { AddressSearch } from "@/_custom/components/Common/AddressSearch";
import { OwnershipPropertyType } from "@/types/Customer";
import { useAddresses } from "@components/BankGuarantee/customerInfo/customerAddresses/useAddresses";
import { useWatch } from "react-hook-form";

type CustomerAddressesProps = CustomerFormBaseProps;

const { Text } = Typography;

type OwnershipOption = {
  code: OwnershipPropertyType;
  name: "Собственность" | "Аренда";
};

const ownershipOptions: OwnershipOption[] = [
  { code: "property", name: "Собственность" },
  { code: "rent", name: "Аренда" },
];

export const CustomerAddresses = ({
  config,
  prefix,
  control,
  setValue,
  getValues,
}: CustomerAddressesProps) => {
  const classes = useStyles();
  const addressesValue = getValues(`${prefix}.addresses` as "customerAddresses.addresses");
  const legalAddress = useWatch({
    control,
    name: `${prefix}.addresses.legal` as "customerAddresses.addresses.legal",
  });

  const {
    actualAsLegal,
    postalAsLegal,
    onChangeLegalOwnership,
    onChangeActualAsLegal,
    onChangePostalAsLegal,
    onChangeLegalAddress,
    onChangeSubAddress,
  } = useAddresses({ addressesValue, legalAddress, setValue, prefix });

  return (
    <fieldset disabled={config.readonly}>
      <div className={classes.title}>{config.title}</div>
      <div className={classes.wrapper}>
        <VerticalSpace>
          <VerticalSpace size="small">
            <Text>Юридический адрес:</Text>
            <VerticalSpace size={4}>
              <AddressSearch
                prefix={`${prefix}.addresses.legal.address`}
                control={control}
                setValue={setValue}
                controlled
                fullAddress
                parentOnChange={onChangeLegalAddress}
              />
              <FormRadioSingle
                name={`${prefix}.addresses.legal.ownType`}
                control={control}
                options={ownershipOptions}
                parentOnChange={onChangeLegalOwnership}
              />
            </VerticalSpace>
          </VerticalSpace>

          <VerticalSpace size="small">
            <VerticalSpace size="small">
              <Text>Фактический адрес:</Text>
              <Checkbox checked={actualAsLegal} onChange={onChangeActualAsLegal}>
                Совпадает с юридическим
              </Checkbox>
            </VerticalSpace>
            {!actualAsLegal && (
              <VerticalSpace size={4}>
                <AddressSearch
                  prefix={`${prefix}.addresses.actual.address`}
                  control={control}
                  setValue={setValue}
                  fullAddress
                  controlled
                  parentOnChange={onChangeSubAddress("actual")}
                />
                <FormRadioSingle
                  name={`${prefix}.addresses.actual.ownType`}
                  control={control}
                  options={ownershipOptions}
                />
              </VerticalSpace>
            )}
          </VerticalSpace>

          <VerticalSpace size="small">
            <VerticalSpace size="small">
              <Text>Почтовый адрес:</Text>
              <Checkbox checked={postalAsLegal} onChange={onChangePostalAsLegal}>
                Совпадает с юридическим
              </Checkbox>
            </VerticalSpace>
            {!postalAsLegal && (
              <VerticalSpace size={4}>
                <AddressSearch
                  prefix={`${prefix}.addresses.postal.address`}
                  control={control}
                  setValue={setValue}
                  fullAddress
                  controlled
                  parentOnChange={onChangeSubAddress("postal")}
                />
                <FormRadioSingle
                  name={`${prefix}.addresses.postal.ownType`}
                  control={control}
                  options={ownershipOptions}
                />
              </VerticalSpace>
            )}
          </VerticalSpace>
        </VerticalSpace>
      </div>
    </fieldset>
  );
};
