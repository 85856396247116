import { AppDispatch, useAppSelector } from "@store/store";
import { isCooperative, setShowPurchaseFields } from "@store/BankGuarantee/purchaseSlice";
import { useFormContext } from "react-hook-form";
import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import { setModalFetching } from "@store/modalSlice";
import { getGuaranteePurchase } from "@/services/api/bankGuarantee/guarantee";
import { BgFormType } from "@/types/BgForm";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { purchaseMap } from "@/lib/modals/BankGuaranteeRequest/utils/mapPurchaseFromSeldon";
import {
  PURCHASE_MAP_ERROR_TYPE,
  PURCHASE_MAP_ERRORS,
} from "@/lib/modals/BankGuaranteeRequest/utils/purchaseErrors";
import { LOT_MODEL } from "@/lib/modals/BankGuaranteeRequest/utils/purchaseLotModels";
import { debounce } from "lodash";

type UseSearchProps = {
  dispatch: AppDispatch;
};

export const usePurchaseSearch = ({ dispatch }: UseSearchProps) => {
  const {
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    reset,
  } = useFormContext<BgFormType>();
  const { prefix, isStartForm } = useContext(GuaranteeFormContext);

  const isCooperativePurchaseAllowed = useAppSelector(isCooperative);

  const [canSelectLot, setCanSelectLot] = useState(false);
  const [tempPurchase, setTempPurchase] = useState<Record<string, unknown> | null>(null);

  const [foundedPurchase, setFoundedPurchase] = useState<Record<string, unknown> | null>(null);

  const [purchaseSearchErr, setPurchaseSearchErr] = useState(false);

  const onPurchaseNumberChange = debounce(async (e: BaseSyntheticEvent) => {
    if (!reset) return;

    dispatch(setShowPurchaseFields(false));
    setPurchaseSearchErr(false);
    setFoundedPurchase(null);
    setTempPurchase(null);

    reset({
      ...getValues(),
      purchase: {
        purchaseNumber: e.target.value,
        lots: [LOT_MODEL],
      },
    });

    if (errors[PURCHASE_MAP_ERROR_TYPE.purchaseMapError]) {
      clearErrors(PURCHASE_MAP_ERROR_TYPE.purchaseMapError as "purchase");
    }

    await onSearchPurchase(e.target.value);
  }, 500);

  const getIsCooperative = (lots) => {
    const uniqBens = new Set<number>();
    if (!isCooperativePurchaseAllowed) {
      lots.forEach((lot) => {
        lot.customersList.forEach((customer) => {
          uniqBens.add(customer.organization.id);
        });
      });

      if (uniqBens.size > 1) {
        setError(PURCHASE_MAP_ERROR_TYPE.purchaseMapError as "purchase", {
          type: "custom",
          message: PURCHASE_MAP_ERRORS[PURCHASE_MAP_ERROR_TYPE["cooperative"]],
        });

        return false;
      }
    }

    return true;
  };

  const mapPurchaseToForm = (purchase: Record<string, unknown>) => {
    const mappedPurchase = purchaseMap(purchase);
    setFoundedPurchase(mappedPurchase);
    setTempPurchase(null);
    dispatch(setModalFetching(false));
    dispatch(setShowPurchaseFields(true));
  };

  const onSearchPurchase = async (number: string) => {
    if (!getValues) return;
    dispatch(setModalFetching(true));

    try {
      const purchase = await getGuaranteePurchase(number);

      if (purchase.length === 0) {
        dispatch(setModalFetching(false));
        setPurchaseSearchErr(true);
        return;
      }

      setTempPurchase(purchase);

      if (purchase.lotsList.length > 1) {
        if (!getIsCooperative(purchase.lotsList)) {
          dispatch(setModalFetching(false));
          return;
        }

        setCanSelectLot(true);
        return;
      }

      if (!getIsCooperative(purchase.lotsList)) {
        dispatch(setModalFetching(false));
        return;
      }

      mapPurchaseToForm(purchase);
    } catch (_) {
      dispatch(setModalFetching(false));
      setPurchaseSearchErr(true);
      return;
    }
  };

  const onCompleteSelectLots = (lots) => {
    if (!tempPurchase) return;

    tempPurchase.lotsList = lots;
    mapPurchaseToForm(tempPurchase);

    setCanSelectLot(false);
  };

  const onCloseSelectLots = () => {
    setCanSelectLot(false);
    setTempPurchase(null);
    dispatch(setModalFetching(false));
    dispatch(setShowPurchaseFields(false));

    if (!reset) return;

    reset({
      ...getValues(),
      purchase: {
        lots: [LOT_MODEL],
      },
    });
  };

  const handleManualInput = () => {
    dispatch(setShowPurchaseFields(true));
    setPurchaseSearchErr(false);
  };

  useEffect(() => {
    if (!isStartForm) return;
    if (!foundedPurchase) return;
    if (!reset || !getValues) return;

    const purchaseObj = prefix
      ? { [prefix]: { purchase: { ...foundedPurchase } } }
      : { purchase: { ...foundedPurchase } };

    reset({
      ...getValues(),
      ...purchaseObj,
    });
  }, [foundedPurchase]);

  useEffect(() => {
    if (!isStartForm) dispatch(setShowPurchaseFields(true));

    return () => {
      dispatch(setShowPurchaseFields(false));
    };
  }, []);

  return {
    tempPurchase,
    canSelectLot,
    onPurchaseNumberChange,
    onCompleteSelectLots,
    onCloseSelectLots,
    purchaseSearchErr,
    handleManualInput,
  };
};
