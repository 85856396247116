import React, { lazy, Suspense, useEffect, useState } from "react";
import { useKeycloak } from "react-keycloak";
import { Redirect, Switch } from "react-router-dom";
import { Layout } from "@/layout/MainLayout";
import { getUserInfo } from "@store/userSlice";
import Overlay from "@components/Overlay";
import { getToken, setToken } from "@farzoom/common-ui-components";
import { AppDispatch, useAppDispatch } from "@store/store";
import { ScrollToTop } from "@utils/ScrollToTop";
import { useTestSign } from "@hooks/useTestSign";
import { SimpleLayout } from "@/layout/SimpleLayout";
import { useAppJivoSite } from "@hooks/useJivoSite";
import { getSupplyConfig, onOpenSupplies } from "@store/supplies/suppliesThunks";
import { BGLayout } from "@/layout/BGLayout";
import { onOpenWidget } from "@store/widgetsSlice";
import { onOpenTasks } from "@store/tasksSlice";
import { fetchCustomers } from "@store/BankGuarantee/customersSlice";
import { useAccessRoles } from "@hooks/useAccessRoles";
import { onOpenBgTasks } from "@store/BankGuarantee/bgSlice";

export type RouterParams = {
  id?: string;
};
type AppLink = {
  name: string;
  path: string;
  icon: string;
  onClick: (dispatch: AppDispatch) => void;
  role: string;
};

const Tasks = lazy(() => import("./pages/Tasks"));
const Main = lazy(() => import("./pages/Main"));
const Supplies = lazy(() => import("./pages/Supplies"));
const Customers = lazy(() => import("./pages/Multibank/Customers"));
const Guarantees = lazy(() => import("./pages/Multibank/Guarantees"));
const Reports = lazy(() => import("./pages/Reports"));
const NotFoundPage = lazy(() => import("./pages/NotFound"));

const PAGE_ACCESS_ROLES = {
  customer: "CUSTOMERS_VIEWER",
  order: "ORDERS_VIEWER",
  main: "MAIN_VIEWER",
  supplies: "SUPPLIES_VIEWER",
  bg: "BG_VIEWER",
  reports: "REPORTS_VIEWER",
};

export const APP_PAGE_PATH_NAMES = {
  main: "main",
  tasks: "tasks",
  supplies: "supplies",
  guarantees: "guarantees",
  customers: "customers",
  reports: "reports",
};

export const APP_LINKS: AppLink[] = [
  {
    name: "Главная",
    path: `/${APP_PAGE_PATH_NAMES.main}`,
    icon: "icon-profile",
    onClick: (dispatch: AppDispatch) => dispatch(onOpenWidget()),
    role: PAGE_ACCESS_ROLES.main,
  },
  {
    name: "Заявки",
    path: `/${APP_PAGE_PATH_NAMES.tasks}`,
    icon: "icon-ok",
    onClick: (dispatch: AppDispatch) => dispatch(onOpenTasks()),
    role: PAGE_ACCESS_ROLES.order,
  },
  {
    name: "Поставки",
    path: `/${APP_PAGE_PATH_NAMES.supplies}`,
    icon: "icon-doc-2",
    onClick: (dispatch: AppDispatch) => dispatch(onOpenSupplies()),
    role: PAGE_ACCESS_ROLES.supplies,
  },
  {
    name: "Гарантии",
    path: `/${APP_PAGE_PATH_NAMES.guarantees}`,
    icon: "icon-file",
    onClick: (dispatch: AppDispatch) => dispatch(onOpenBgTasks()),
    role: PAGE_ACCESS_ROLES.bg,
  },
  {
    name: "Клиенты",
    path: `/${APP_PAGE_PATH_NAMES.customers}`,
    icon: "icon-user",
    onClick: (dispatch: AppDispatch) => dispatch(fetchCustomers()),
    role: PAGE_ACCESS_ROLES.customer,
  },
  {
    name: "Отчёты",
    path: `/${APP_PAGE_PATH_NAMES.reports}`,
    icon: "icon-doc-2",
    onClick: () => false,
    role: PAGE_ACCESS_ROLES.reports,
  },
];

export const Routes = () => {
  const { initialized, keycloak } = useKeycloak();
  const { hasRealmRole } = useAccessRoles();
  const dispatch = useAppDispatch();
  const [routeToRedirect, setRouteToRedirect] = useState<AppLink | null>(null);

  useTestSign();
  useAppJivoSite();

  if (!getToken() && keycloak.token) setToken(keycloak.token);

  useEffect(() => {
    if (initialized) {
      dispatch(getUserInfo());
      if (hasRealmRole(PAGE_ACCESS_ROLES.supplies)) dispatch(getSupplyConfig());

      setRouteToRedirect(APP_LINKS.find((l) => hasRealmRole(l.role)) || null);
    }
  }, [initialized]);

  if (!initialized) return <Overlay />;

  return (
    <div className="fr-app-routes">
      <Suspense fallback={<Overlay />}>
        <Switch>
          <Redirect from="/" to={routeToRedirect?.path || ""} exact />

          <Layout role={PAGE_ACCESS_ROLES.main} path="/main" component={Main} />
          <Layout role={PAGE_ACCESS_ROLES.order} exact path="/tasks" component={Tasks} />
          <Layout role={PAGE_ACCESS_ROLES.order} path="/tasks/:id" component={Tasks} />
          <Layout role={PAGE_ACCESS_ROLES.supplies} path="/supplies" component={Supplies} />
          <BGLayout
            role={PAGE_ACCESS_ROLES.customer}
            exact
            path="/customers"
            component={Customers}
          />
          <BGLayout role={PAGE_ACCESS_ROLES.customer} path="/customers/:id" component={Customers} />
          <Layout exact role={PAGE_ACCESS_ROLES.bg} path="/guarantees" component={Guarantees} />
          <Layout role={PAGE_ACCESS_ROLES.bg} path="/guarantees/:id" component={Guarantees} />
          <Layout role={PAGE_ACCESS_ROLES.reports} path="/reports" component={Reports} />

          <SimpleLayout component={NotFoundPage} />
        </Switch>
      </Suspense>
      <ScrollToTop />
    </div>
  );
};
