/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { FormAutoComplete } from "./FormAutoComplete";

const useStyles = createUseStyles({
  bankSearch: {
    width: "100%",
    display: "flex",
  },
});

export const BankSearch = ({
  type,
  label,
  prefix,
  control,
  setValue,
  required,
  disabled,
  className,
  placeholder,
  hiddenFieldNames = [],
  getHiddenFieldNames,
  getVisibleFieldName,
  controlled,
}) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.bankSearch, className)}>
      <FormAutoComplete
        type="bank"
        label={label}
        prefix={prefix}
        control={control}
        setValue={setValue}
        required={required}
        disabled={disabled}
        payloadFieldName={type}
        placeholder={placeholder}
        hiddenFieldNames={hiddenFieldNames}
        getHiddenFieldNames={getHiddenFieldNames}
        getVisibleFieldName={getVisibleFieldName}
        controlled={controlled}
      />
    </div>
  );
};

BankSearch.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hiddenFieldNames: PropTypes.array,
  getVisibleFieldName: PropTypes.func,
  prefix: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  controlled: PropTypes.bool,
};
