import { axiosFrontAgent } from "../axiosInstance";

export const SearchController = Object.freeze({
  suggestion: {
    company: (q) =>
      axiosFrontAgent.get(`/suggest/company`, { params: { q } }).then(({ data }) => data),
    address: (q) =>
      axiosFrontAgent.get(`/suggest/address`, { params: { q } }).then(({ data }) => data),
    bank: (q) => axiosFrontAgent.get(`/suggest/bank`, { params: { q } }).then(({ data }) => data),
    fms: (q) =>
      axiosFrontAgent.get(`/suggest/fms_unit`, { params: { q } }).then(({ data }) => data),
  },
  company: (q) =>
    axiosFrontAgent.get("/company/search", { params: { q } }).then(({ data }) => data?.company),
  searchByParams: (params) => axiosFrontAgent.get("/person/searchByParams", { params }),
});
