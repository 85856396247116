import React from "react";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { SmallCollapseIcon } from "../Icons";
import { CollapseIcon, getIsMobile } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  icon: {
    width: 24,
    height: 24,
    marginLeft: 8,
    transform: "rotateZ(-180deg)",
    transition: ["transform", "300ms", "ease"],
    "&.collapsed": {
      transform: "rotateZ(0)",
    },
  },
});

export const CollapseIconWrapper = ({ collapse, collapsed }) => {
  const classes = useStyles();
  const isMobile = getIsMobile();
  return collapse ? (
    <div className={classnames(classes.icon, { collapsed })}>
      {isMobile ? <SmallCollapseIcon /> : <CollapseIcon />}
    </div>
  ) : null;
};
