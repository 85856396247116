import classnames from "classnames";
import ruRU from "antd/lib/locale/ru_RU";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CarrotProvider } from "./index";
import { createUseStyles } from "react-jss";
import { ReactNode } from "react";
import { store } from "@/store/store";
// import { carrot } from "@/carrotTheme";
import { persimmon } from "@/persimmonTheme";

type ProvidersProps = {
  children: ReactNode;
};

const useStyles = createUseStyles({
  app: {
    // ...carrot,
    ...persimmon,
  },
});

export const AppProvider = ({ children }: ProvidersProps) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.app, "persimmon")}>
      <AntdConfigProvider
        locale={ruRU}
        getPopupContainer={(trigger) =>
          (document.querySelector("[class*=app]") as HTMLElement) ||
          trigger?.parentElement ||
          document.body
        }
      >
        <BrowserRouter>
          <ReduxProvider store={store}>
            <CarrotProvider>{children}</CarrotProvider>
          </ReduxProvider>
        </BrowserRouter>
      </AntdConfigProvider>
    </div>
  );
};
