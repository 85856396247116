import { useForm } from "react-hook-form";
import { Col, Row } from "antd";
import { createUseStyles } from "react-jss";
import { Button, FormSelect, Modal } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  Row: {
    width: 700,
    minHeight: 100,
  },
});

export const AddModal = ({ onClose, onSubmit, mode = "add", expendituresRef = {} }) => {
  const classes = useStyles();
  const { control, handleSubmit } = useForm();

  return (
    <Modal onClose={onClose}>
      <Row className={classes.Row}>
        <Col span={24}>
          <FormSelect
            control={control}
            placeholder="Выберите статью"
            name="key"
            label="Статья"
            required
            options={Object.entries(expendituresRef).map(([key, value]) => ({
              key,
              value: `${key.slice(1)} ${value}`,
            }))}
          />
        </Col>
      </Row>
      <div>
        <Button
          type="primary"
          onClick={handleSubmit((form) => onSubmit && onSubmit(form))}
          text={mode === "add" ? "Добавить" : "Сохранить"}
        />
      </div>
    </Modal>
  );
};
