import { Col, Form, Input, Row, Space, Typography } from "antd";
import { Button, FormInput, FormSelect, FormTextArea } from "@farzoom/common-ui-components";
import { Section } from "@/lib/modals/BankGuaranteeRequest/section";
import React, { useContext } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { LotsSelectModal } from "@/lib/modals/BankGuaranteeRequest/lotsSelectModal";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { BgFormType } from "@/types/BgForm";
import { useAppDispatch, useAppSelector } from "@store/store";
import { isShowPurchase } from "@store/BankGuarantee/purchaseSlice";
import { useBGPurchaseShortCuts } from "@hooks/useBGPurchaseShortCuts";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";
import { usePurchaseSearch } from "@/lib/modals/BankGuaranteeRequest/hooks/usePurchaseSearch";
import { PURCHASE_MAP_ERROR_TYPE } from "@/lib/modals/BankGuaranteeRequest/utils/purchaseErrors";

const { Text } = Typography;

const LAW_OPTIONS = [
  { key: "44", value: "44-ФЗ" },
  { key: "223", value: "223-ФЗ" },
  { key: "185-615", value: "185-ФЗ/615ПП" },
  { key: "commercial", value: "Коммерческая гарантия" },
];

export const PurchaseInfo = () => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext<BgFormType>();

  const dispatch = useAppDispatch();

  const { formDisabled, prefix, canEditPurchase } = useContext(GuaranteeFormContext);

  const {
    tempPurchase,
    canSelectLot,
    onPurchaseNumberChange,
    onCompleteSelectLots,
    onCloseSelectLots,
    purchaseSearchErr,
    handleManualInput,
  } = usePurchaseSearch({ dispatch });

  const isShowPurchaseFields = useAppSelector(isShowPurchase);

  useBGPurchaseShortCuts();

  const currentPrefix = (prefix ? `${prefix}.purchase` : "purchase") as "purchase";
  const purchaseW = useWatch({ name: currentPrefix });

  return (
    <Section title="Сведения о закупке">
      <Row gutter={16} align="bottom">
        <Col span={8}>
          <Controller
            control={control}
            name={`${currentPrefix}.purchaseNumber`}
            rules={{
              required: "Поле обязательно к заполнению",
            }}
            render={({ fieldState: { invalid } }) => (
              <Form.Item
                label="Реестровый № торгов"
                validateStatus={invalid ? "error" : undefined}
                required
                help={errors.purchase?.purchaseNumber?.message}
              >
                <Input
                  size="large"
                  onInput={onPurchaseNumberChange}
                  placeholder="Введите № торгов"
                  disabled={formDisabled || !canEditPurchase}
                  defaultValue={getValues(`${currentPrefix}.purchaseNumber`)!}
                />
                {purchaseSearchErr && (
                  <Space align="baseline">
                    <Text strong>Закупка не найдена.</Text>
                    <Button type="small" text="Заполните вручную" onClick={handleManualInput} />
                  </Space>
                )}
              </Form.Item>
            )}
          />
        </Col>

        {isShowPurchaseFields && (
          <>
            <Col span={8}>
              <FormInput
                name={`${currentPrefix}.marketPlace`}
                control={control}
                label="Площадка"
                placeholder="Название площадки"
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={`${currentPrefix}.auctionType`}
                label="Тип аукциона"
                setValue={setValue}
                control={control}
                defaultValue={purchaseW?.auctionType || null}
                options={[
                  { key: "public", value: "Открытый" },
                  { key: "private", value: "Закрытый" },
                ]}
                disabled={formDisabled}
              />
            </Col>
          </>
        )}
      </Row>

      {isShowPurchaseFields && (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <FormInput
                name={`${currentPrefix}.tenderType`}
                control={control}
                label="Способ размещения"
              />
            </Col>
            <Col span={8}>
              <FormInputDateTime
                name={`${currentPrefix}.tenderDate`}
                control={control}
                setValue={setValue}
                label="Дата размещения тендера"
                disabled={formDisabled}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={`${currentPrefix}.law`}
                label="Закон"
                setValue={setValue}
                control={control}
                defaultValue={null}
                options={LAW_OPTIONS}
                disabled={formDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <FormInput
                name={`${currentPrefix}.purchaseLink`}
                control={control}
                label="Ссылка на тендер в Интернете"
                placeholder="Укажите ссылку"
              />
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <FormTextArea
                name={`${currentPrefix}.purchaseSubject`}
                control={control}
                label="Предмет закупки"
              />
            </Col>
          </Row>
        </>
      )}

      {errors && errors[PURCHASE_MAP_ERROR_TYPE.purchaseMapError] && (
        <Text type="danger">{errors[PURCHASE_MAP_ERROR_TYPE.purchaseMapError].message}</Text>
      )}

      {canSelectLot && (
        <LotsSelectModal
          purchase={tempPurchase}
          onOk={onCompleteSelectLots}
          onClose={onCloseSelectLots}
        />
      )}
    </Section>
  );
};
