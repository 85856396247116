import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { debounce } from "lodash";
import { Col, Empty, Row } from "antd";
import { useFieldArray } from "react-hook-form";
import { Collapsed } from "../../../_components/Collapsed";
import { CollapseIconWrapper } from "../../../_components/CollapsedBlock/CollapseIconWrapper";
import { Document } from "./index";
import { H5, LoadingOverlay, showNotification } from "@farzoom/common-ui-components";
import { useSelector } from "react-redux";
import { ALLOWED_FILE_TYPES } from "@/services/constants";
import { isTestDownload, isTestSign } from "@store/signSlice";

const useStyles = createUseStyles({
  documentsBlock: {
    marginBottom: 20,
  },
  title: {
    "&.collapse": {
      cursor: "pointer",
    },
  },
  documents: {
    marginTop: 16,
  },
});

export const DocumentsBlock = ({
  config,
  prefix,
  smallTitle,
  control,
  noTitle,
  isOpen = true,
  userInfo,
  ...rest
}) => {
  const { onAddDocument, isSingleSign, taskFormSignUpdate } = rest;
  const classes = useStyles();

  const { fields, replace } = useFieldArray({
    control,
    name: prefix,
    keyName: "rhf-id",
  });

  // Хэш-мап, в которую сохраняется значение полей и из которой при обновлении он генерируется
  const [documentTypesDictionary, setDocumentTypesDictionary] = useState({});
  const [loading, setLoading] = useState(true);
  const [noDocs, setNoDocs] = useState(false);
  const [collapsed, setCollapsed] = useState(isOpen);

  const isTest = useSelector(isTestSign);
  const isDownload = useSelector(isTestDownload);

  const title = config?.title;
  const documentTypes = config?.types || [];

  const onCollapsed = () => config?.collapse && setCollapsed(!collapsed);

  // Инициализация хэш-мапы
  useEffect(() => {
    const initialDictionary = documentTypes.reduce((result, type) => {
      if (type?.docType) {
        const foundInModel = fields.find((field) => type.docType === field?.docType);
        if (foundInModel || type?.load) {
          result[type.docType] = foundInModel;
        }
      }
      return result;
    }, {});

    setNoDocs(
      !documentTypes.filter(
        (docType) => docType?.load || initialDictionary[docType.docType]?.files?.length
      ).length
    );
    setDocumentTypesDictionary(initialDictionary);
    setLoading(false);
  }, [fields]);

  // Обновление полей в RHF и в хэш-мапе
  const update = (docType) => (value) => {
    onAddDocument && onAddDocument(docType, value);
    const updatedDictionary = {
      ...documentTypesDictionary,
      [docType]: value,
    };

    replace(Object.values(updatedDictionary).filter(Boolean));
    // console.log('Документы после обновления', Object.values(updatedDictionary).filter(Boolean))
    setDocumentTypesDictionary(updatedDictionary);
  };

  const collapseProps = {
    className: classnames(classes.title, {
      collapse: config?.collapse,
    }),
    onClick: onCollapsed,
  };

  return config?.visible ? (
    <div className={classes.documentsBlock}>
      <Row gutter={16} justify="space-between">
        <Col>
          {noTitle ? null : smallTitle ? (
            <p {...collapseProps}>
              <b>{title}</b>
              <CollapseIconWrapper collapse={config?.collapse} collapsed={collapsed} />
            </p>
          ) : (
            <H5 {...collapseProps}>
              <span>{title}</span>
              <CollapseIconWrapper collapse={config?.collapse} collapsed={collapsed} />
            </H5>
          )}
        </Col>
      </Row>
      <Collapsed isOpened={collapsed}>
        {loading ? (
          <LoadingOverlay />
        ) : Object.values(documentTypes)?.length > 0 ? (
          <div className={classes.documents}>
            {Object.values(documentTypes)
              .filter((type) => type?.load || !!documentTypesDictionary[type.docType]?.files.length)
              .map((type, idx) => (
                <Document
                  control={control}
                  prefix={`${prefix}.${idx}`}
                  readonly={config?.readonly}
                  required={type?.required}
                  key={type?.docType}
                  config={type}
                  value={documentTypesDictionary[type?.docType]}
                  isTest={isTest}
                  isDownload={isDownload}
                  update={update(type?.docType)}
                  allowedTypes={ALLOWED_FILE_TYPES}
                  userInfo={userInfo}
                  isSingleSign={isSingleSign}
                  taskFormSignUpdate={taskFormSignUpdate}
                />
              ))}
          </div>
        ) : null}
        {noDocs && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Загруженные документы отсутствуют"
          />
        )}
      </Collapsed>
    </div>
  ) : null;
};

DocumentsBlock.notify = debounce((message) => {
  showNotification({
    type: "success",
    message,
    duration: 10,
  });
}, 100);

DocumentsBlock.propTypes = {
  config: PropTypes.object,
  prefix: PropTypes.string,
  title: PropTypes.string,
  smallTitle: PropTypes.bool,
  noTitle: PropTypes.bool,
  control: PropTypes.object,
};

DocumentsBlock.defaultProps = {
  smallTitle: false,
  noTitle: false,
};
