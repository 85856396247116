import { ActionReducerMapBuilder, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { notification } from "antd";
import {
  getSearchCompaniesDebtor,
  getSearchCompaniesSupplier,
  getSupplies,
  getSupplyConfig,
  postRequestFinancing,
  postRevokeSupplies,
  postSupplyDiscountOffer,
  postSupplyOffer,
} from "./suppliesThunks";
import { RootState } from "@store/store";

/*type StateType = {
  supplies: {
    items: unknown[];
    pageCount: number;
    pageNumber: number;
    pageSize: number;
    sort: string;
    sortOrder: string;
    totalCount: number;
  };
  supplyConfig: {
    canMakeDiscountOffer: boolean;
    canMakeOffer: boolean;
    canRequestFinancing: boolean;
    canRevokeSupply: boolean;
    minDiscountAmount: number;
    showDaysTillPayment: boolean;
    showDebtor: boolean;
    showDeferDays: boolean;
    showRemainingDaysPercent: boolean;
    showSupplier: boolean;
    supplyStatuses: { statusCode: string; statusName: string }[];
    debtors: { companyId: string; inn: string; name: string }[];
    factors: {
      factorCompanyId: string;
      factorInn: string;
      factorName: string;
      factorOgrn: string;
      finType: string;
      iconFileName: string;
    }[];
  };
  isSuppliesLoading: boolean;
  foundCompaniesDebtor: unknown[];
  foundCompaniesSupplier: unknown[];
  paginationSupplies: {
    debtorCompanyId: string;
    pageNum: number;
    pageSize: number;
    sort: string;
    sortOrder: string;
    statuses: unknown;
    supplierCompanyId: string;
  };
  isOfferLoading: boolean;
  isOfferSuccessMessage: boolean;
  offerType: (typeof offerTypes)[number] | "";
};*/

const initialState = {
  supplies: {},
  supplyConfig: {},
  isSuppliesLoading: false,
  filterSuggestions: {
    error_code: 0,
    error_message: null,
    company: [],
  },
  isOfferLoading: false,
  isOfferSuccessMessage: false,
  offerType: "",
  isSearchFilter: false,
  suppliesFilters: {},
};

const showErrorNotification = () =>
  notification.error({
    message: "Что-то пошло не так...",
    description: "Попробуйте ещё раз",
  });

const commonRejectedHandler = (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder.addMatcher(
    isAnyOf(
      postSupplyOffer.rejected,
      postRequestFinancing.rejected,
      postSupplyDiscountOffer.rejected
    ),
    (state) => {
      console.log("rejected");
      state.offerType = "";
      state.isOfferSuccessMessage = false;
      state.isOfferLoading = false;

      showErrorNotification();
    }
  );
};
const handleGetSupplies = (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(getSupplies.pending, (state) => {
      state.isSuppliesLoading = true;
    })
    .addCase(getSupplies.fulfilled, (state, action) => {
      state.supplies = action.payload;
      state.isSuppliesLoading = false;
    })
    .addCase(getSupplies.rejected, (state) => {
      state.supplies = initialState.supplies;
      state.isSuppliesLoading = false;
    })
    .addCase(getSupplyConfig.fulfilled, (state, action) => {
      state.supplyConfig = action.payload;
    });
};
const handlePostOffers = (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(postSupplyOffer.fulfilled, (state) => {
      state.offerType = "postOffer";
      state.isOfferSuccessMessage = true;
    })
    .addCase(postRequestFinancing.fulfilled, (state) => {
      state.offerType = "requestFinancing";
      state.isOfferSuccessMessage = true;
    })
    .addCase(postSupplyDiscountOffer.fulfilled, (state) => {
      state.offerType = "discountOffer";
      state.isOfferSuccessMessage = true;
    })
    .addCase(postRevokeSupplies.rejected, (state) => {
      state.isOfferLoading = false;
      notification.error({
        message: "Не удалось отозвать поставки",
      });
    });
};
const handleFilter = (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(getSearchCompaniesDebtor.fulfilled, (state, action) => {
      state.filterSuggestions = action.payload;
      state.isSearchFilter = false;
    })
    .addCase(getSearchCompaniesSupplier.fulfilled, (state, action) => {
      state.filterSuggestions = action.payload;
      state.isSearchFilter = false;
    })
    .addMatcher(
      isAnyOf(getSearchCompaniesDebtor.pending, getSearchCompaniesSupplier.pending),
      (state) => {
        state.isSearchFilter = true;
      }
    )
    .addMatcher(
      isAnyOf(getSearchCompaniesDebtor.rejected, getSearchCompaniesSupplier.rejected),
      (state) => {
        state.isSearchFilter = false;
        state.filterSuggestions = initialState.filterSuggestions;
      }
    );
};

export const suppliesSlice = createSlice({
  name: "supplies",
  initialState,
  reducers: {
    setIsOfferMessage(state, action) {
      state.isOfferSuccessMessage = action.payload;
    },
    setIsOfferLoading(state, action) {
      state.isOfferLoading = action.payload;
    },
    clearSuggestions(state) {
      state.filterSuggestions = initialState.filterSuggestions;
    },
    setSuppliesFilters(state, action) {
      state.suppliesFilters = action.payload;
    },
    resetSuppliesState(state) {
      state.supplies = initialState.supplies;
      state.suppliesFilters = initialState.suppliesFilters;
    },
  },
  extraReducers: (builder) => {
    handleGetSupplies(builder);
    handlePostOffers(builder);
    handleFilter(builder);
    commonRejectedHandler(builder);
  },
});

export const {
  setIsOfferMessage,
  clearSuggestions,
  resetSuppliesState,
  setSuppliesFilters,
  setIsOfferLoading,
} = suppliesSlice.actions;

export const suppliesState = (state: RootState) => state?.carrot?.supplies;
export const suppliesConfig = (state: RootState) => state?.carrot?.supplies.supplyConfig;
export const suppliesList = (state: RootState) => state?.carrot?.supplies.supplies;
export const isOfferCreated = (state: RootState) => state?.carrot?.supplies.isOfferLoading;

export default suppliesSlice.reducer;
