import { Checkbox, Col, Radio, Row, Space } from "antd";
import {
  ERROR_MESSAGES,
  FormInput,
  MASKS,
  PATTERNS,
  PLACEHOLDERS,
} from "@farzoom/common-ui-components";
import React, { useState } from "react";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import { disabledDate, useStyles } from "./personInfoModal";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";
import { AddressSearch } from "@/_custom/components/Common/AddressSearch";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Nullish } from "@/types/helpers";
import { notNullish } from "@utils/notNullish";
import { EmailInput } from "@components/UI/emailInput";

export const PersonInfoBlock = ({ control, setValue, personW, errors, getValues, readonly }) => {
  const classes = useStyles();

  const [actualAddress, registrationAddress] = getValues([
    "person.actualAddress",
    "person.registrationAddress",
  ]);
  const [actualAsRegistration, setActualAsRegistration] = useState(
    actualAddress === registrationAddress && notNullish(registrationAddress)
  );

  const onChangeAsRegistration = (e: CheckboxChangeEvent) => {
    setActualAsRegistration(e.target.checked);

    if (e.target.checked) {
      setValue("person.actualAddress", personW?.person?.registrationAddress);
    }
  };

  const onRegistrationAddressChange = (value: Nullish<string>) => {
    if (actualAsRegistration) {
      setValue("person.actualAddress", value);
    }
  };

  return (
    <VerticalSpace className={classes.block}>
      <Space align="start">
        <FormInput
          name="person.lastName"
          control={control}
          setValue={setValue}
          label="Фамилия"
          required
        />
        <FormInput
          name="person.firstName"
          control={control}
          setValue={setValue}
          label="Имя"
          required
        />
        <FormInput
          name="person.secondName"
          control={control}
          setValue={setValue}
          label="Отчество"
        />
        <Controller
          name="person.gender"
          control={control}
          rules={{ required: true }}
          render={() => (
            <Radio.Group
              value={personW?.person?.gender}
              onChange={(e) => setValue("person.gender", e.target.value)}
              className={classnames(
                classes.gender,
                errors.person?.gender ? classes.genderError : null
              )}
            >
              <Radio value="male">М</Radio>
              <Radio value="female">Ж</Radio>
            </Radio.Group>
          )}
        />
      </Space>
      <Row gutter={8}>
        <Col span={12}>
          <FormInput
            name="person.inn"
            control={control}
            setValue={setValue}
            label="ИНН"
            mask={MASKS.inn}
            pattern={{
              value: PATTERNS.inn,
              message: ERROR_MESSAGES.inn,
            }}
          />
        </Col>
        <Col span={12}>
          <FormInput name="person.snils" control={control} setValue={setValue} label="СНИЛС" />
        </Col>
      </Row>
      <Space>
        <FormInputDateTime
          name="person.birthDate"
          control={control}
          setValue={setValue}
          label="Дата рождения"
          disabledDate={disabledDate}
          disabled={readonly}
        />
        <FormInput
          name="person.birthPlace"
          control={control}
          setValue={setValue}
          label="Место рождения"
        />
      </Space>
      <AddressSearch
        prefix="person.registrationAddress"
        control={control}
        setValue={setValue}
        label="Адрес регистрации"
        controlled
        parentOnChange={onRegistrationAddressChange}
      />
      <VerticalSpace size={4}>
        <div className={classes.label}>Адрес местонаходения:</div>
        <Checkbox checked={actualAsRegistration} onChange={onChangeAsRegistration}>
          Совпадает с адресом регистрации
        </Checkbox>
        {!actualAsRegistration && (
          <AddressSearch
            prefix="person.actualAddress"
            control={control}
            setValue={setValue}
            controlled
          />
        )}
      </VerticalSpace>
      <Row gutter={8}>
        <Col span={12}>
          <FormInput
            name="person.phone"
            control={control}
            setValue={setValue}
            label="Телефон"
            placeholder={PLACEHOLDERS.phone}
            mask={MASKS.phone}
            pattern={{
              value: PATTERNS.phone,
              message: ERROR_MESSAGES.phone,
            }}
          />
        </Col>
        <Col span={12}>
          <EmailInput name="person.email" control={control} setValue={setValue} />
        </Col>
      </Row>
    </VerticalSpace>
  );
};
