import { useAppSelector } from "@store/store";
import { fullName, userName } from "@store/userSlice";

export const useAppJivoSite = () => {
  const name = useAppSelector(fullName);
  const username = useAppSelector(userName);

  if (window.jivo_api) {
    window.jivo_api.setContactInfo({
      name: name,
      description: `Логин: ${username}`,
    });
    window.jivo_api.setCustomData([
      { title: "Имя пользователя", content: name },
      { title: "Логин пользователя", content: username },
    ]);
  }
};

// TODO: типизировать data
export const useExternalJivoSite = (data) => {
  if (!data) return;

  if (window.jivo_api) {
    window.jivo_api.setContactInfo({
      name: data?.assignee || "",
      description: `Обращение со страницы подписи`,
    });
    window.jivo_api.setCustomData([{ title: "Имя пользователя", content: data?.assignee || "" }]);
  }
};
