import { useFieldArray, useFormContext } from "react-hook-form";
import { LotsSubComponent } from "@/lib/modals/BankGuaranteeRequest/lots";
import { Col, Row } from "antd";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { useStyles } from "@/lib/modals/BankGuaranteeRequest/index";
import { Button, FormInput, FormInputMoney, PLACEHOLDERS } from "@farzoom/common-ui-components";
import React, { useContext } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { AddressSearch } from "@/_custom/components/Common/AddressSearch";
import { BgFormType } from "@/types/BgForm";
import { BeneficiaryContract } from "@/lib/modals/BankGuaranteeRequest/Beneficiaries/beneficiaryContract";
import { EmailInput } from "@components/UI/emailInput";

// const { Text } = Typography;

export const Beneficiaries = ({ lotIndex }: LotsSubComponent) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);

  const currentPrefix = (prefix ? `${prefix}.purchase.lots` : "purchase.lots") as "purchase.lots";

  const { fields: beneficiaries, remove } = useFieldArray({
    control,
    name: `${currentPrefix}.${lotIndex}.beneficiaries` as `purchase.lots.${number}.beneficiaries`,
  });

  // const onAddBeneficiary = () => prepend(BENEFICIARY_MODEL);

  const onRemove = (idx: number) => remove(idx);

  return (
    <>
      {/*TODO: до лучших времен*/}
      {/*<Text strong>
        <div className={classes.actionTitle}>
          <span>Бенефициары</span>
          {!formDisabled && (
            <Button
              type="outlined"
              iconName="add"
              title="Добавить счет"
              onClick={onAddBeneficiary}
            />
          )}
        </div>
      </Text>*/}
      {beneficiaries.map((beneficiary, bIdx) => (
        <VerticalSpace className={classes.beneficiaryWrapper} key={beneficiary.id}>
          <Row gutter={16}>
            <Col span={18}>
              <CompanySuggestion
                prefix={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}`}
                control={control}
                setValue={setValue}
                label="Бенефициар"
                placeholder="Введите название или ИНН"
                getVisibleFieldName={(prfx) => `${prfx}.displayName`}
                hiddenFieldNames={["inn", "kpp", "ogrn"]}
                controlled
                required
              />
            </Col>
            <Col span={6}>
              <FormInput
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.customerRegNum`}
                className={classes.input}
                control={control}
                setValue={setValue}
                label="Регномер"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormInput
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.deliveryPlace`}
                className={classes.input}
                control={control}
                setValue={setValue}
                label="Место доставки"
              />
            </Col>
            <Col span={6}>
              <FormInputMoney
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.purchaseAmount`}
                control={control}
                setValue={setValue}
                label="НМЦК"
                controls={false}
                disabled={formDisabled}
                maxLength={25}
              />
            </Col>
            <Col span={6}>
              <FormInputMoney
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.bgAmount`}
                control={control}
                setValue={setValue}
                label="Сумма гарантии"
                controls={false}
                disabled={formDisabled}
                required
                maxLength={25}
              />
            </Col>
          </Row>
          <AddressSearch
            prefix={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.actualAddress`}
            control={control}
            setValue={setValue}
            label="Место нахождения бенефициара"
            controlled
          />
          <Row gutter={16}>
            <Col span={12}>
              <FormInput
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.phone`}
                control={control}
                setValue={setValue}
                label="Телефон"
                placeholder={PLACEHOLDERS.phone}
              />
            </Col>
            <Col span={12}>
              <EmailInput
                name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.email`}
                control={control}
                setValue={setValue}
              />
            </Col>
          </Row>

          <BeneficiaryContract lotIndex={lotIndex} bIdx={bIdx} />

          {beneficiaries.length > 1 && !formDisabled && (
            <Button
              className={classes.beneficiaryRemove}
              type="outlined"
              iconName="remove"
              title="Удалить бенефициара"
              onClick={() => onRemove(bIdx)}
            />
          )}
        </VerticalSpace>
      ))}
    </>
  );
};
