import { ButtonRemove } from "@farzoom/common-ui-components";

const ROWS = [
  { title: "БУХГАЛТЕРСКИЙ БАЛАНС (ТЫС. РУБ.)", merge: true, bold: true },
  { title: "АКТИВ", merge: true, bold: true },
  { title: "I. ВНЕОБОРОТНЫЕ АКТИВЫ", merge: true, bold: true },
  { title: "Нематериальные активы", dataKey: "b1110" },
  { title: "Результаты исслед. и разработок", dataKey: "b1120" },
  { title: "Нематериальные поисковые активы", dataKey: "b1130" },
  { title: "Материальные поисковые активы", dataKey: "b1140" },
  { title: "Основные средства", dataKey: "b1150" },
  { title: "Доходные вложения в материальные ценности", dataKey: "b1160" },
  { title: "Долгосрочные финансовые вложения", dataKey: "b1170" },
  { title: "Отложенные налоговые активы", dataKey: "b1180" },
  { title: "Прочие внеоборотные активы", dataKey: "b1190" },
  {
    title: "ИТОГО по разделу I",
    dataKey: "b1100",
    summary: true,
    bold: true,
    sum: ["b1110", "b1120", "b1130", "b1140", "b1150", "b1160", "b1170", "b1180", "b1190"],
  },
  { title: "II. ОБОРОТНЫЕ АКТИВЫ", merge: true, bold: true },
  { title: "Запасы", dataKey: "b1210" },
  { title: "НДС по приобретенным ценностям", dataKey: "b1220" },
  { title: "Дебиторская задолженность", dataKey: "b1230" },
  { title: "Краткосрочные фин. Вложения", dataKey: "b1240" },
  { title: "Денежные средства", dataKey: "b1250" },
  { title: "Прочие оборотные активы", dataKey: "b1260" },
  {
    title: "ИТОГО по разделу II",
    dataKey: "b1200",
    summary: true,
    bold: true,
    sum: ["b1210", "b1220", "b1230", "b1240", "b1250", "b1260"],
  },
  {
    title: "БАЛАНС",
    dataKey: "b1600",
    summary: true,
    bold: true,
    sum: ["b1100", "b1200"],
  },
  { title: "ПАССИВ", merge: true, bold: true },
  { title: "III. КАПИТАЛ И РЕЗЕРВЫ", merge: true, bold: true },
  { title: "Уставный капитал", dataKey: "b1310" },
  { title: "Собственные акции, выкупленные у акционеров", dataKey: "b1320" },
  { title: "Переоценка внеоборотных активов", dataKey: "b1340" },
  { title: "Добавочный капитал", dataKey: "b1350" },
  { title: "Резервный капитал", dataKey: "b1360" },
  { title: "Нераспределенная прибыль", dataKey: "b1370" },
  {
    title: "ИТОГО по разделу III",
    dataKey: "b1300",
    summary: true,
    bold: true,
    sum: ["b1310", "b1320", "b1340", "b1350", "b1360", "b1370"],
  },
  { title: "IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА", merge: true, bold: true },
  { title: "Заемные средства", dataKey: "b1410" },
  { title: "Отложенные налоговые обязательства", dataKey: "b1420" },
  { title: "Оценочные обязательства долгосроч.", dataKey: "b1430" },
  { title: "Прочие долгосрочные обязательства", dataKey: "b1450" },
  {
    title: "ИТОГО по разделу IV",
    dataKey: "b1400",
    summary: true,
    bold: true,
    sum: ["b1410", "b1420", "b1430", "b1450"],
  },
  { title: "V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА", merge: true, bold: true },
  { title: "Заемные средства", dataKey: "b1510" },
  { title: "Кредиторская задолженность", dataKey: "b1520" },
  { title: "Доходы будущих периодов", dataKey: "b1530" },
  { title: "Оценочные обязательства", dataKey: "b1540" },
  { title: "Прочие краткосрочные обязательства", dataKey: "b1550" },
  {
    title: "ИТОГО по разделу V",
    dataKey: "b1500",
    summary: true,
    bold: true,
    sum: ["b1510", "b1520", "b1530", "b1540", "b1550"],
  },
  {
    title: "БАЛАНС",
    dataKey: "b1700",
    summary: true,
    bold: true,
    sum: ["b1300", "b1400", "b1500"],
  },
  {
    title: "ОТЧЕТ О ФИНАНСОВЫХ РЕЗУЛЬТАТАХ (ТЫС. РУБ.)",
    merge: true,
    bold: true,
  },
  {
    title: "Выручка от реализации (нетто, без НДС, акцизов)",
    dataKey: "b2110",
    positive: true,
  },
  { title: "Себестоимость", dataKey: "b2120", negative: true },
  {
    title: "Валовая прибыль",
    dataKey: "b2100",
    summary: true,
    sub: ["b2110", "b2120"],
  },
  { title: "Коммерческие расходы", dataKey: "b2210", negative: true },
  { title: "Управленческие расходы", dataKey: "b2220", negative: true },
  {
    title: "Прибыль (убыток) от продаж",
    dataKey: "b2200",
    summary: true,
    sub: ["b2100", "b2210", "b2220"],
  },
  {
    title: "Доходы от участия в других организациях",
    dataKey: "b2310",
    positive: true,
  },
  { title: "Проценты к получению", dataKey: "b2320", positive: true },
  { title: "Проценты к уплате", dataKey: "b2330", negative: true },
  { title: "Прочие доходы (операционные)", dataKey: "b2340" },
  { title: "Прочие расходы (операционные)", dataKey: "b2350", negative: true },
  {
    title: "Прибыль (убыток) до налогообложения",
    dataKey: "b2300",
    summary: true,
    calc: (p) =>
      (p.b2200 || 0) +
      (p.b2310 || 0) +
      (p.b2320 || 0) -
      Math.abs(p.b2330 || 0) +
      (p.b2340 || 0) -
      Math.abs(p.b2350 || 0),
  },
  { title: "Налог на прибыль", dataKey: "b2410" },
  { title: "Отложенные налоговые обязательства", dataKey: "b2430" },
  { title: "Отложенные налоговые активы", dataKey: "b2450" },
  { title: "Налоговые санкции", dataKey: "b2460" },
  {
    title: "Чистая прибыль (убыток) отчетного периода",
    dataKey: "b2400",
    summary: true,
    calc: (p) =>
      (p.b2300 || 0) +
      ((p.b2300 || 0) > 0 ? p.b2410 || 0 : -(p.b2410 || 0)) +
      (p.b2430 || 0) +
      (p.b2450 || 0) +
      (p.b2460 || 0),
  },
  {
    title: "Значение амортизационных отчислений на последнюю отчетную годовую дату",
    dataKey: "b5640",
  },
  // TODO: For Rosbank
  {
    title:
      "Результат от переоценки внеоборотных активов, не включаемый в чистую прибыль (убыток) периода",
    dataKey: "b2510",
  },
  {
    title: "Результат от прочих операций, не включаемый в чистую прибыль (убыток) периода",
    dataKey: "b2520",
  },
  {
    title:
      "Налог на прибыль от операций, результат которых не включается в чистую прибыль (убыток) периода",
    dataKey: "b2530",
  },
  {
    title: "Совокупный финансовый результат периода",
    dataKey: "b2500",
  },
  {
    title: "Базовая прибыль (убыток) на акцию",
    dataKey: "b2900",
  },
  {
    title: "Разводненная прибыль (убыток) на акцию",
    dataKey: "b2910",
  },
  // Rosbank ends
];

const QUARTERS = {
  1: "1 кв.",
  2: "1 кв.",
  3: "1 кв.",
  4: "2 кв.",
  5: "2 кв.",
  6: "2 кв.",
  7: "3 кв.",
  8: "3 кв.",
  9: "3 кв",
  10: "4 кв.",
  11: "4 кв.",
  12: "4 кв.",
};

export const TAX_OPTIONS = [
  {
    name: "УСН",
    value: "usn",
    title: "Упрощённая система налогообложения",
  },
  {
    name: "ОСН",
    value: "osn",
    title: "Общая система налогообложения",
  },
];

export const formatPeriod = (period) =>
  period?.split &&
  period
    ?.split("-")
    ?.reverse()
    ?.map((str, index) => (index ? str : QUARTERS[+str]))
    ?.join(" ");

const formatCode = (dataKey) => (dataKey ? `Код ${dataKey.slice(1, 5)}` : "");

export const calcPeriod = (period, customROWS, config) => {
  const newPeriod = {};
  (config?.reportTable ?? customROWS ?? ROWS).forEach((row) => {
    if (row?.dataKey) {
      if (row?.sum) {
        newPeriod[row.dataKey] = row?.sum.reduce(
          (result, current) =>
            result + ((newPeriod && newPeriod[current]) || (period && period[current]) || 0),
          0
        );
      } else if (row?.sub) {
        newPeriod[row.dataKey] = row?.sub.reduce(
          (result, current) =>
            result
              ? result -
                Math.abs((newPeriod && newPeriod[current]) || (period && period[current]) || 0)
              : (newPeriod && newPeriod[current]) || (period && period[current]) || 0,
          0
        );
      } else if (row?.calc) {
        newPeriod[row.dataKey] = row.calc(newPeriod);
      } else {
        newPeriod[row.dataKey] = period && period[row.dataKey];
      }
    }
  });
  return newPeriod;
};
/*export const calcPeriod = (period, customROWS, config) => {
  const newPeriod = {};
  const rows = config?.reportTable || customROWS || ROWS;

  rows.forEach((row) => {
    const { dataKey, sum, sub, calc } = row || {};

    if (!dataKey) return;

    if (sum) {
      newPeriod[dataKey] = sum.reduce(
        (result, key) => result + (newPeriod[key] || period?.[key] || 0),
        0
      );
    } else if (sub) {
      newPeriod[dataKey] = sub.reduce((result, key) => {
        const value = newPeriod[key] || period?.[key] || 0;
        return result !== undefined ? result - Math.abs(value) : value;
      }, undefined);
    } else if (calc) {
      newPeriod[dataKey] = calc(newPeriod);
    } else {
      newPeriod[dataKey] = period?.[dataKey];
    }
  });

  return newPeriod;
};*/

export const getColumns = ({ config, periods, onSave }) =>
  ["", "", ...[...(periods || [])].reverse()]
    .map((period, index) => {
      const WIDTHS = {
        0: "30%",
        1: "10%",
      };
      // const TITLES = {
      //   0: "Структура баланса",
      //   1: "",
      // };
      const DATA_INDEXES = {
        0: "title",
        1: "code",
      };
      return {
        width: WIDTHS[index] || "12%",
        // title:
        //   (TITLES[index] || !config?.hideChoiceQuarter) && config?.reportType !== "F5"
        //     ? formatPeriod(period)
        //     : period?.split("-")?.reverse().join("-"),
        title: formatPeriod(period),
        dataIndex: DATA_INDEXES[index] || period,
        ...(index > 1 ? { editable: !config?.readonly } : {}),
      };
    })
    .map((col, index) => ({
      ...col,
      onCell: (record) => ({
        key: index,
        record,
        editable: !config.readonly && col?.editable && !record?.summary,
        dataIndex: col?.dataIndex,
        title: col?.title,
        colSpan: record?.merge ? (index === 0 ? periods?.length + 2 : 0) : 1,
        style: {
          fontWeight: record?.bold ? 600 : 400,
        },
        positive: !!record?.positive,
        negative: !!record?.negative,
        handleSave: onSave,
      }),
    }));

export const buildGridState = (periods = [], reports, customROWS, config, setDynamicCustomRows) => {
  periods.forEach((period) => {
    if (reports) {
      reports[period] = calcPeriod(reports[period], customROWS, config);
    }
  });

  return (config?.reportTable ?? customROWS ?? ROWS).map((layoutRow, index) => {
    const row = {
      ...layoutRow,
      title:
        config?.canChangeCount && !layoutRow.summary ? (
          <div style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
            <ButtonRemove
              onClick={() => {
                const newRows = customROWS.filter((_row, indexRow) => indexRow !== index);
                setDynamicCustomRows(newRows);
              }}
            />{" "}
            {layoutRow.title}
          </div>
        ) : (
          layoutRow.title
        ),
    };
    row.key = index;
    if (layoutRow.dataKey) {
      row.code = formatCode(layoutRow.dataKey);
      periods.forEach((period) => {
        if (reports) {
          row[period] = reports[period][layoutRow.dataKey];
        }
      });
    }
    return row;
  });
};

export const validateBuhReport = (value) =>
  ![value?.b1600, value?.b1700, value?.b2400, value?.b1370].every((v) => v !== undefined)
    ? "Проверьте заполнение полей с кодами 1600, 1700, 2400, 1370."
    : value?.b1600 !== value?.b1700
    ? "Значения в строках с кодами 1600 и 1700 отличаются."
    : true;
