import { combineReducers } from "redux";
import widgetsReducer from "./widgetsSlice";
import userReducer from "./userSlice";
import suppliesReducer from "./supplies/suppliesSlice";
import orderFilterReducer from "./orderFilterSlice";
import modalReducer from "./modalSlice";
import taskReducer from "./tasksSlice";
import signReducer from "./signSlice";
import startProcessFormReducer from "./formsSlice";
import bgCustomersReducer from "./BankGuarantee/customersSlice";
import purchaseReducer from "./BankGuarantee/purchaseSlice";
import bgReducer from "./BankGuarantee/bgSlice";

export const Reducers = () => ({
  carrot: combineReducers({
    widgets: widgetsReducer,
    user: userReducer,
    supplies: suppliesReducer,
    orderFilter: orderFilterReducer,
    modal: modalReducer,
    tasks: taskReducer,
    sign: signReducer,
    form: startProcessFormReducer,
    customers: bgCustomersReducer,
    purchase: purchaseReducer,
    guarantees: bgReducer,
  }),
});
