import { Header } from "./Header";
import { Result } from "antd";
import { FrownTwoTone, SmileTwoTone } from "@ant-design/icons";

export const TaskComplete = ({ title, status = "success" }) => {
  const icon =
    status === "success" ? (
      <SmileTwoTone twoToneColor="#91D4BB" />
    ) : (
      <FrownTwoTone twoToneColor="#AC284C" />
    );

  return (
    <>
      <Header />
      <div className="container bg-white complete-container">
        <Result status={status} icon={icon} title={title} />
      </div>
    </>
  );
};
