import { BeneficiariesType, PurchaseLotType } from "@/types/BgForm";

export const PRODUCT_MODEL = {
  okpdCode: "",
  okpdName: "",
};

export const BENEFICIARY_MODEL: BeneficiariesType = {
  inn: null,
  kpp: null,
  ogrn: null,
  displayName: null,
  customerRegNum: null,
  deliveryPlace: null,
  appAmount: null,
  conAmount: null,
  bgAmount: null,
  purchaseAmount: null,
  actualAddress: null,
  phone: null,
  email: null,
  contract: {
    concluded: false,
    contractNumber: null,
    contractDate: null,
    regNumber: null,
    contractAmount: null,
    contractLink: null,
  },
};

export const LOT_MODEL: PurchaseLotType = {
  lotNumber: null,
  lotSubject: null,
  startAmount: null,
  principalOfferAmount: null,
  ikzList: [""],
  products: [PRODUCT_MODEL],
  contractConditions: {
    prepaymentExists: false,
    prepaymentAmount: null,
    requiredIndisputableDebiting: false,
    includedForfeit: false,
    requiredSecurityForGuaranteePeriod: false,
    securityForGuaranteePeriodDays: null,
    securityForGuaranteePeriodAmount: null,
  },
  beneficiaries: [BENEFICIARY_MODEL],
};
