import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  fixConfigsReadonly,
  REQUEST_STATUSES,
  showNotification,
} from "@farzoom/common-ui-components";
import { axiosFrontAgent } from "@/services/api/axiosInstance";
import { TaskController } from "@/services/api/controllers/TaskController";
import { TaskForm } from "@pages/Tasks/components/TaskForm";

// import { useDispatch } from "react-redux";
import { onOpenTasks } from "@store/tasksSlice";
import { useDispatch } from "react-redux";
import { useExternalTask } from "@hooks/useExternalTask";

export const TaskFormWrapper = ({
  id,
  onClose,
  onShowSpinner,
  onHideSpinner,
  userInfo,

  model,
  config,
  isError,
  onCompleteCallback,
  token,
}) => {
  const dispatch = useDispatch();
  const { isExternalTask } = useExternalTask();
  const [status, setStatus] = useState(REQUEST_STATUSES.idle);
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStatus(REQUEST_STATUSES.pending);
    if (isExternalTask) return null;

    if (id) {
      Promise.all([axiosFrontAgent.get(`/task/${id}`), axiosFrontAgent.get(`/task/${id}/config`)])
        .then(([{ data: model }, { data: config }]) => {
          setTask({ model, config });
          fixConfigsReadonly(config);
          setStatus(REQUEST_STATUSES.fulfilled);
        })
        .catch(() => {
          setStatus(REQUEST_STATUSES.rejected);
        });
    }
  }, [id]);

  useEffect(() => {
    if (model && config) {
      setTask({ model, config });
      fixConfigsReadonly(config);
      setStatus(REQUEST_STATUSES.fulfilled);
    }

    if (isError) {
      console.log("err000");
      setStatus(REQUEST_STATUSES.rejected);
    }
  }, [model, config, isError]);

  const onCompleteTask = async ({ model, title = "Завершить задачу", taskId, decision }) => {
    onShowSpinner && onShowSpinner();
    setIsLoading(true);
    try {
      await TaskController?.completeTask(taskId, model, decision, token);

      !isExternalTask && showNotification({ message: `"${title}" выполнено`, type: "success" });
      onCompleteCallback && onCompleteCallback();
      onClose && onClose();
      dispatch(onOpenTasks());
    } catch (error) {
      console.log("error:", error);
      !isExternalTask &&
        showNotification({
          type: "error",
          message:
            error?.response?.data?.errors?.length > 0
              ? error?.response?.data?.errors?.join(", ")
              : `"${title}" не выполнено`,
        });

      onCompleteCallback && onCompleteCallback(false);
    } finally {
      onHideSpinner && onHideSpinner();
      setIsLoading(false);
    }
  };

  const onSaveDraftTask = async ({ taskId, model }) => {
    onShowSpinner && onShowSpinner();
    setIsLoading(true);
    try {
      await TaskController?.saveDraftTask(taskId, model, token);

      showNotification({
        message: "Черновик задачи сохранён",
        type: "success",
      });
      onClose && onClose();
    } catch (error) {
      console.log("error:", error);

      showNotification({
        message: "Черновик задачи не сохранён",
        type: "error",
      });
    } finally {
      onHideSpinner && onHideSpinner();
      setIsLoading(false);
    }
  };

  return (
    <TaskForm
      taskId={id}
      isExternalTask={isExternalTask}
      openedTaskStatus={status}
      openedTaskModel={task?.model}
      openedTaskConfig={task?.config}
      onCompleteTask={onCompleteTask}
      onSaveDraftTask={onSaveDraftTask}
      onClose={onClose}
      userInfo={userInfo}
      isLoading={isLoading}
    />
  );
};

TaskFormWrapper.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  onShowSpinner: PropTypes.func,
  onHideSpinner: PropTypes.func,

  model: PropTypes.object,
  config: PropTypes.object,
  isError: PropTypes.bool,
  onCompleteCallback: PropTypes.func,
  token: PropTypes.string,
};
