import { PurchaseLotType, PurchaseType } from "@/types/BgForm";
import {
  BENEFICIARY_MODEL,
  LOT_MODEL,
  PRODUCT_MODEL,
} from "@/lib/modals/BankGuaranteeRequest/utils/purchaseLotModels";

export const keysMap = {
  firstPublishDate: "tenderDate",
  purchaseLink: "purchaseLink",
  subject: "purchaseSubject",
  epName: "marketPlace",
  sourceContractType: "tenderType",
  notificationNumber: "purchaseNumber",
};

export const lotKeysMap = {
  lotNumber: "lotNumber",
  subject: "lotSubject",
  IKZList: "ikzList",
  price: "startAmount",
};

export const beneficiariesKeys = {
  identificationCodeCustomer: "customerRegNum",
  deliveryPlace: "deliveryPlace",
  amount: "appAmount",
  conAmount: "conAmount",
  price: "purchaseAmount",
};

export const benOrgKeys = {
  name: "displayName",
  inn: "inn",
  kpp: "kpp",
  ogrnSource: "ogrn",
  phone: "phone",
  email: "email",
  factAddress: "actualAddress",
};

export const targetTemplate: PurchaseType = {
  auctionType: "public",
  lots: [LOT_MODEL],
  purchaseNumber: null,
  marketPlace: null,
  tenderType: null,
  law: null,
  tenderDate: null,
  purchaseLink: null,
  purchaseSubject: null,
};

export const lotTargetTemplate: PurchaseLotType = {
  products: [PRODUCT_MODEL],
  beneficiaries: [BENEFICIARY_MODEL],
  lotNumber: null,
  lotSubject: null,
  startAmount: null,
  principalOfferAmount: null,
  ikzList: [""],
  contractConditions: {
    requiredSecurityForGuaranteePeriod: false,
    requiredIndisputableDebiting: false,
    prepaymentExists: false,
    includedForfeit: false,
    prepaymentAmount: null,
    securityForGuaranteePeriodDays: null,
    securityForGuaranteePeriodAmount: null,
  },
};
