import { NavLink } from "react-router-dom";
import { UserMenu } from "@components/UserMenu";
import { useAppDispatch } from "@store/store";
import { APP_LINKS } from "@/routes";
import { useAccessRoles } from "@hooks/useAccessRoles";

export const Sidebar = () => {
  const { hasRealmRole } = useAccessRoles();
  const dispatch = useAppDispatch();

  return (
    <section className="fr-sidebar">
      <div className="fr-sidebar__menu">
        {APP_LINKS.filter((l) => hasRealmRole(l.role)).map((link) => (
          <NavLink
            key={link.path}
            to={link.path}
            activeClassName="active"
            onClick={() => link.onClick(dispatch)}
          >
            <span className={`icon ${link.icon}`} />
            <span>{link.name}</span>
          </NavLink>
        ))}
      </div>
      <UserMenu />
    </section>
  );
};
