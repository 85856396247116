export const ModalType = Object.freeze({
  orderForm: "orderForm",
  taskForm: "taskForm",
  bgTaskForm: "bgTaskForm",
  customerInfoForm: "customerInfoForm",
  createCustomerForm: "createCustomerForm",
  wizzard: "wizzard",
  suppliesImportStartForm: "suppliesImportStartForm",
  supplierAccessionStartForm: "supplierAccessionStartForm",
  supplyVerificationStartForm: "supplyVerificationStartForm",
  bankGuaranteeRequest: "bankGuaranteeRequest",
  bankGuaranteeRequestAgent: "bankGuaranteeRequestAgent",
  commissionChangeRequest: "commissionChangeRequest",
});
