import { useForm } from "react-hook-form";
import { StartFormWrapper } from "@/lib/modals/StartFormWrapper";
import { useStartForm } from "@/lib/modals/hooks/useStartForm";
import { useAppDispatch } from "@store/store";
import { createCustomer } from "@store/BankGuarantee/customersSlice";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { isIndividualEnabled, SOLE_ERROR_MESSAGE } from "@/lib/modals/BankGuaranteeRequest/principalInfo";

export const CreateCustomerForm = () => {
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Клиент успешно добавлен",
  });

  const isSubmitDisabled = !!Object.keys(errors).length;

  const onSubmit = handleSubmit((data) => {
    if (isSubmitDisabled) return;
    setIsFetching(true);

    const payload = {
      inn: data.customer?.inn,
    };

    dispatch(
      createCustomer({
        payload,
        onSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      title="Добавить клиента"
      formName="addCustomer"
      isFetching={isFetching}
      onSubmit={onSubmit}
      onClose={onClose}
      submitBtnText="Добавить"
      isSubmitDisabled={isSubmitDisabled}
    >
      <CompanySuggestion
        control={control}
        register={register}
        setValue={setValue}
        prefix="customer"
        label="Компания"
        placeholder="Введите название или ИНН"
        required
        hiddenFieldNames={[
          "inn",
          "ogrn",
          "kpp",
          { target: "name", source: (opt) => opt?.shortname },
        ]}
        controlled
        rules={{
          validate: () => isIndividualEnabled || getValues("customer.kpp") !== null || SOLE_ERROR_MESSAGE,
        }}
        parentOnChange={() => setTimeout(() => trigger("customer"))}
      />
    </StartFormWrapper>
  );
};
