import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from "@store/store";
import { openModal } from "@store/modalSlice";
import { ModalType } from "@/services/constants/ModalType";
import { useState } from "react";

type Props = {
  customerId: string;
  isOnForm?: boolean;
};

export const useCustomerInfo = ({ customerId, isOnForm }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isModal, setIsModal] = useState(false);

  const urlSearchParams = new URLSearchParams(location.search);

  const currentPath =
    location.pathname + (urlSearchParams.size ? `?${urlSearchParams.toString()}` : "");
  const currentQueryType = urlSearchParams.size ? "&" : "?";

  const handleOpenCustomerInfo = () => {
    if (isOnForm) {
      setIsModal(true);
      return;
    }

    history.push(`${currentPath}${currentQueryType}customerInfo=${customerId}`);
    urlSearchParams.delete("customerInfo");

    dispatch(
      openModal({
        type: ModalType.customerInfoForm,
        config: { customerId },
        closePath: currentPath,
      })
    );
  };

  return {
    handleOpenCustomerInfo,
    isModalOpen: isModal,
    setIsModalOpen: setIsModal,
  };
};
