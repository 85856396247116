import { Checkbox, Col, Row } from "antd";
import React, { useContext, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { BgFormType } from "@/types/BgForm";
import { LotsSubComponent } from "@/lib/modals/BankGuaranteeRequest/lots";
import { useStyles } from "@/lib/modals/BankGuaranteeRequest";
import { FormInput, FormInputMoney } from "@farzoom/common-ui-components";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";

type Props = LotsSubComponent & {
  bIdx: number;
};

export const BeneficiaryContract = ({ lotIndex, bIdx }: Props) => {
  const classes = useStyles();
  const { control, setValue, register, getValues } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);

  const currentPrefix = (prefix ? `${prefix}.purchase.lots` : "purchase.lots") as "purchase.lots";
  const guarantyTypePrefix = prefix ? `${prefix}.guarantee.type` : "guarantee.type";

  const [isContract, setIsContract] = useState(
    getValues(`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.concluded`) || false
  );

  const guaranteeType = useWatch({
    name: guarantyTypePrefix,
  });

  const onContractChange = (idx: number) => (e: CheckboxChangeEvent) => {
    setValue(
      `${currentPrefix}.${lotIndex}.beneficiaries.${idx}.contract.concluded`,
      e.target.checked
    );
    setIsContract(e.target.checked);

    if (!e.target.checked) {
      setValue(`${currentPrefix}.${lotIndex}.beneficiaries.${idx}.contract.contractNumber`, null);
      setValue(`${currentPrefix}.${lotIndex}.beneficiaries.${idx}.contract.contractDate`, null);
      setValue(`${currentPrefix}.${lotIndex}.beneficiaries.${idx}.contract.regNumber`, null);
      setValue(`${currentPrefix}.${lotIndex}.beneficiaries.${idx}.contract.contractAmount`, null);
    }
  };

  if (guaranteeType !== "execution") return null;

  return (
    <div>
      <Checkbox
        {...register(`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.concluded`)}
        onChange={onContractChange(bIdx)}
        checked={isContract}
      >
        Контракт заключен
      </Checkbox>
      {isContract && (
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.contractNumber`}
              className={classes.input}
              control={control}
              setValue={setValue}
              label="№ контракта"
            />
          </Col>
          <Col span={6}>
            <FormInputDateTime
              name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.contractDate`}
              control={control}
              setValue={setValue}
              label="Дата контракта"
              disabled={formDisabled}
            />
          </Col>
          <Col span={6}>
            <FormInput
              name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.regNumber`}
              className={classes.input}
              control={control}
              setValue={setValue}
              label="Реестровый №"
            />
          </Col>
          <Col span={6}>
            <FormInputMoney
              name={`${currentPrefix}.${lotIndex}.beneficiaries.${bIdx}.contract.contractAmount`}
              control={control}
              setValue={setValue}
              label="Сумма контракта"
              controls={false}
              disabled={formDisabled}
              maxLength={25}
            />
          </Col>

          {/*<FormInput
                  name={`purchase.lots.${lotIndex}.beneficiaries.${bIdx}.contract.contractLink`}
                  className={classes.input}
                  control={control}
                  setValue={setValue}
                  label="Ссылка на контракт"
                  placeholder="Укажите ссылку"
                />*/}
        </Row>
      )}
    </div>
  );
};
