import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { axiosFrontAgent } from "@/services/api/axiosInstance";
import { TaskController } from "@/services/api/controllers";
import { TaskForm } from "@pages/Tasks/components/TaskForm";
import {
  fixConfigsReadonly,
  REQUEST_STATUSES,
  showNotification,
} from "@farzoom/common-ui-components";
import { getBgTasks } from "@store/BankGuarantee/bgSlice";

export const BgTaskFormWrapper = ({ id, onClose, onShowSpinner, onHideSpinner, userInfo }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(REQUEST_STATUSES.idle);
  const [task, setTask] = useState({});

  useEffect(() => {
    setStatus(REQUEST_STATUSES.pending);

    if (id) {
      Promise.all([axiosFrontAgent.get(`/task/${id}`), axiosFrontAgent.get(`/task/${id}/config`)])
        .then(([{ data: model }, { data: config }]) => {
          setTask({ model, config });
          fixConfigsReadonly(config);
          setStatus(REQUEST_STATUSES.fulfilled);
        })
        .catch(() => {
          setStatus(REQUEST_STATUSES.rejected);
        });
    }
  }, [id]);

  const onCompleteTask = async ({ model, title = "Завершить задачу", taskId, decision }) => {
    onShowSpinner && onShowSpinner();
    try {
      await TaskController?.completeTask(taskId, model, decision);

      showNotification({ message: `"${title}" выполнено`, type: "success" });
      onClose && onClose();
      dispatch(getBgTasks());
    } catch (error) {
      console.log("error:", error);
      showNotification({
        type: "error",
        message:
          error?.response?.data?.errors?.length > 0
            ? error?.response?.data?.errors?.join(", ")
            : `"${title}" не выполнено`,
      });
    } finally {
      onHideSpinner && onHideSpinner();
    }
  };

  return (
    <TaskForm
      taskId={id}
      openedTaskStatus={status}
      openedTaskModel={task?.model}
      openedTaskConfig={task?.config}
      onCompleteTask={onCompleteTask}
      onClose={onClose}
      userInfo={userInfo}
    />
  );
};
