import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { Section } from "@/lib/modals/BankGuaranteeRequest/section";
import { useFormContext } from "react-hook-form";
import { BgFormType } from "@/types/BgForm";

export const isIndividualEnabled = true;
export const SOLE_ERROR_MESSAGE = "Подача заявок по ИП не поддерживается";

export const PrincipalInfo = () => {
  const { control, setValue, getValues, trigger } = useFormContext<BgFormType>();

  return (
    <Section title="Сведения о принципале">
      <div>
        <CompanySuggestion
          prefix="principal"
          control={control}
          setValue={setValue}
          placeholder="Введите название или ИНН"
          hiddenFieldNames={[
            "inn",
            "ogrn",
            "kpp",
            { target: "name", source: (opt) => opt?.shortname },
          ]}
          required
          controlled
          rules={{
            validate: () => isIndividualEnabled || getValues("principal.kpp") !== null || SOLE_ERROR_MESSAGE,
          }}
          parentOnChange={() => setTimeout(() => trigger("principal"))}
        />
      </div>
    </Section>
  );
};
