import React from "react";
import { createUseStyles } from "react-jss";
import { CheckCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { colors } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  parent: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    marginTop: "15px",
  },
  goodSignature: {
    fontSize: "30px",
    color: colors.green,
  },
  badSignature: {
    fontSize: "30px",
    color: colors.error,
  },
  unknownSignature: {
    fontSize: "30px",
    color: colors.orange2,
  },
  signInfoCnt: {
    display: "flex",
    "& + &": {
      marginTop: "10px",
    },
  },
  signInfoLeft: {
    paddingRight: "15px",
    flexGrow: 0,
    flexShrink: 0,
  },
  unknownMessage: {
    fontSize: "16px",
    lineHeight: "45px",
    color: colors.orange2,
  },
  lbl: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingRight: "10px",
    opacity: 0.6,
  },
  val: {
    fontSize: "16px",
    fontWeight: "600",
  },
  textGood: {
    color: colors.green,
  },
  textBad: {
    color: colors.error,
  },
  textUnknown: {
    color: colors.orange2,
  },
});

const sortFnc = (a, b) => (a.localSigningTime < b.localSigningTime ? -1 : 1);

export const SignInfo = ({ signInfo }) => {
  const classes = useStyles();

  const getLblClass = (sign) =>
    classes.lbl + " " + (sign.result ? classes.textGood : classes.textBad);
  const getValClass = (sign) =>
    classes.val + " " + (sign.result ? classes.textGood : classes.textBad);

  return (
    <div className={classes.parent}>
      {!signInfo && (
        <div className={classes.signInfoCnt}>
          <div className={classes.signInfoLeft}>
            <QuestionCircleOutlined className={classes.unknownSignature} />
          </div>
          <div>
            <span className={classes.unknownMessage}>
              Информация об электронной подписи недоступна
            </span>
          </div>
        </div>
      )}
      {signInfo && <div>Этот файл подписан {signInfo.signs.length} раз(а):</div>}
      {signInfo &&
        signInfo.signs?.sort(sortFnc).map((sign) => (
          <div key={sign.serialNumber} className={classes.signInfoCnt}>
            <div className={classes.signInfoLeft}>
              {sign.result && (
                <CheckCircleOutlined className={classes.goodSignature} title="Подпись верна" />
              )}
              {!sign.result && (
                <WarningOutlined className={classes.badSignature} title="Подпись НЕ верна" />
              )}
            </div>
            <div>
              {!sign.result && (
                <div>
                  <span className={getLblClass(sign)}>Сообщение об ошибке:</span>
                  <span className={getValClass(sign)}>{sign.message}</span>
                </div>
              )}
              <div>
                <span className={getLblClass(sign)}>Дата подписания:</span>
                <span className={getValClass(sign)}>{sign.localSigningTime}</span>
              </div>
              <div>
                <span className={getLblClass(sign)}>Сертификат №:</span>
                <span className={getValClass(sign)}>{sign.serialNumber}</span>
              </div>
              <div>
                <span className={getLblClass(sign)}>Владелец сертификата:</span>
                <span className={getValClass(sign)}>{sign.subjectName}</span>
              </div>
              <div>
                <span className={getLblClass(sign)}>Сертификат выдан:</span>
                <span className={getValClass(sign)}>{sign.issuerName}</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
