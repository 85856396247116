import React from "react";
import { Checkbox, Col, Row } from "antd";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { useStyles } from "../personInfoModal";

export const MembersBlock = ({ register, personW, setValue }) => {
  const classes = useStyles();

  return (
    <Row gutter={16} className={classes.block}>
      <Col>
        <VerticalSpace>
          <Checkbox
            {...register("personRole.isSupervisoryBoardMember")}
            checked={personW?.personRole?.isSupervisoryBoardMember}
            onChange={(e) => setValue("personRole.isSupervisoryBoardMember", e.target.checked)}
          >
            Член наблюдательного совета
          </Checkbox>
          <Checkbox
            {...register("personRole.isCollegialExecutiveBodyMember")}
            checked={personW?.personRole?.isCollegialExecutiveBodyMember}
            onChange={(e) =>
              setValue("personRole.isCollegialExecutiveBodyMember", e.target.checked)
            }
          >
            Член коллегиального исполнительного органа
          </Checkbox>
        </VerticalSpace>
      </Col>
    </Row>
  );
};
