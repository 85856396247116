import React from "react";
import {
  Block,
  CommentsBlock,
  CompanyBlock,
  DecisionTaskBlock,
  GenparamsBlock,
  OrderActivityBlock,
  OrderInfoBlock,
  ScoringBlock,
  TaskDescriptionBlock,
  UniversalBlock,
  UniversalTable,
} from "@farzoom/common-ui-components";
import { DocumentsBlock } from "@/_custom/components/Blocks/DocumentsBlock";
import { CustomTaskDescriptionBlock } from "@/components/CustomTaskDescriptionBlock";
import { CustomerShortCardFormWrapper } from "@components/BankGuarantee/customerShortCard/customerShortCardFormWrapper";
import { CustomerAddresses } from "@components/BankGuarantee/customerInfo/customerAddresses";
import { CustomerStaff } from "@components/BankGuarantee/customerInfo/customerStaff";
import { CustomerManagement } from "@components/BankGuarantee/customerInfo/customerManagement";
import { CustomerBankAccounts } from "@components/BankGuarantee/customerInfo/customerBankAccounts";
import { CustomerFoundersOrgs } from "@components/BankGuarantee/customerInfo/customerFoundersOrgs";
import { CustomerPersons } from "@components/BankGuarantee/customerInfo/customerPersons";
import { BgInfoCard } from "@components/BankGuarantee/BgInfoCard";
import { GuaranteeFormWrapper } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { BuhReportBlock } from "@/_custom/Blocks/BuhReportBlock";
import { CommissionChangeRequest } from "@components/BankGuarantee/commissionChangeRequest";

export const getDynamicBlock = ({
  model,
  config,
  type,
  prefix,
  control,
  watch,
  resetField,
  getValues,
  setValue,
  userInfo,
  companyId,
  canEdit,
  onSaveModel,
  decision,
  decisions,
  color,
  isCompanyLinkable,
  onGoToOrder,
  onGoToCompany,
  register,
  ...rest
}) => {
  const props = {
    register,
    prefix,
    control,
    watch,
    resetField,
    getValues,
    setValue,
    model,
    config,
    canEdit,
    onSaveModel,
    modalable: true,
    userInfo,
    color,
    ...rest,
  };

  const DYNAMIC_BLOCKS = {
    unitable: <UniversalTable {...props} />,
    comments: <CommentsBlock {...props} />,
    decision: <DecisionTaskBlock decision={decision} decisions={decisions} {...props} />,
    documents: <DocumentsBlock {...props} />,
    genparams: <GenparamsBlock {...props} />,
    scoringList: <ScoringBlock scoring={model} {...props} />,
    orderActivity: <OrderActivityBlock {...props} />,
    company: <CompanyBlock {...props} />,
    taskDescription: <TaskDescriptionBlock {...props} />,
    description: <CustomTaskDescriptionBlock {...props} />,
    orderInfo: (
      <OrderInfoBlock
        name="persimmon"
        orderInfo={model}
        companyId={companyId}
        isCompanyLinkable={isCompanyLinkable}
        onGoToOrder={onGoToOrder}
        onGoToCompany={onGoToCompany}
        {...props}
      />
    ),
    customerCard: <CustomerShortCardFormWrapper {...props} />,
    customerAddresses: <CustomerAddresses {...props} />,
    customerStaff: <CustomerStaff {...props} />,
    customerManagement: <CustomerManagement {...props} />,
    customerBankAccounts: <CustomerBankAccounts {...props} />,
    customerFounderOrgs: <CustomerFoundersOrgs {...props} />,
    productBg: <GuaranteeFormWrapper {...props} />,
    productBgCard: <BgInfoCard {...props} />,
    customerPersons: <CustomerPersons {...props} />,
    universalBuhReport: <BuhReportBlock {...props} customValidateFun={() => true} />,
    commissionChangeRequest: <CommissionChangeRequest {...props} />,
    // address: <AddressBlock {...props} />
    // bankAccounts: <BankAccountsBlock {...props} />,
    // universalBuhReport: <BuhReportSimple {...props} customValidateFun={() => true} />,
    // management: <ManagementSystemBlock {...props} />,
    // founderCompanies: <FounderCompaniesBlock {...props} />,
    // persons: <IndividualBlock title="Сведения о физических лицах организации" {...props} />,
    // licenses: <LicensesBlock className={classes?.licenses} {...props} />,
    // liabilities: <LiabilitiesBlock {...props} />,
    // requisitesCompany: <RequisitesCompanyBlock {...props} />,
  };

  const style = {
    order: config?.pos,
  };

  return (
    <Block style={style} key={prefix}>
      {DYNAMIC_BLOCKS[config.uiType || type] || <UniversalBlock {...props} />}
    </Block>
  );
};
