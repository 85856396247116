import { useState } from "react";
import { closeModal } from "@/store/modalSlice";
import { showNotification } from "@farzoom/common-ui-components";

export const useStartForm = ({ dispatch, successTitle }) => {
  const [isFetching, setIsFetching] = useState(false);
  const onClose = () => dispatch(closeModal());

  const onSuccess = () => {
    showNotification({ type: "success", message: successTitle });
    setIsFetching(false);
    onClose();
  };

  const onError = (error) => {
    console.error("error:", error);
    setIsFetching(false);
    showNotification({
      type: "error",
      message: (
        <>
          Что-то пошло не так...
          <br /> Попробуйте ещё раз позже
        </>
      ),
    });
  };

  return {
    setIsFetching,
    isFetching,
    onClose,
    onError,
    onSuccess,
  };
};
