import { useEffect } from "react";
import { setCooperative, setMultilot } from "@store/BankGuarantee/purchaseSlice";
import { useAppDispatch } from "@store/store";

export const useBGPurchaseShortCuts = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const shortCutHandler = (e) => {
      if (e.ctrlKey && (e.altKey || e.metaKey) && (e.code === "KeyL" || e.code === "KeyB")) {
        e.Handled = true;
        e.preventDefault();
        e.stopPropagation();
        if (e.code === "KeyL") {
          dispatch(setMultilot());
        }
        if (e.code === "KeyB") {
          dispatch(setCooperative());
        }
      }
    };

    document.addEventListener("keyup", shortCutHandler);

    return () => {
      document.removeEventListener("keyup", shortCutHandler);
    };
  }, []);
};
