import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Modal, Table } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  infoIcon: {
    cursor: "pointer",
  },
});

const LABELS = {
  source: "Исходный адрес одной строкой",
  result: "Стандартизованный адрес одной строкой",
  postal_code: "Индекс",
  country: "Страна",
  country_iso_code: "ISO-код страны",
  federal_district: "Федеральный округ",
  region_fias_id: "ФИАС-код региона",
  region_kladr_id: "КЛАДР-код региона",
  region_iso_code: "ISO-код региона",
  region_with_type: "Регион с типом",
  region_type: "Тип региона (сокращенный)",
  region_type_full: "Тип региона",
  region: "Регион",
  area_fias_id: "ФИАС-код района",
  area_kladr_id: "КЛАДР-код района",
  area_with_type: "Район в регионе с типом",
  area_type: "Тип района в регионе (сокращенный)",
  area_type_full: "Тип района в регионе",
  area: "Район в регионе",
  city_fias_id: "ФИАС-код города",
  city_kladr_id: "КЛАДР-код города",
  city_with_type: "Город с типом",
  city_type: "Тип города (сокращенный)",
  city_type_full: "Тип города",
  city: "Город",
  city_area: "Административный округ (только для Москвы)",
  city_district_fias_id: "ФИАС-код района города (заполняется, только если район есть в ФИАС)",
  city_district_kladr_id: "КЛАДР-код района города (не заполняется)",
  city_district_with_type: "Район города с типом",
  city_district_type: "Тип района города (сокращенный)",
  city_district_type_full: "Тип района города",
  city_district: "Район города",
  settlement_fias_id: "ФИАС-код населенного пункта",
  settlement_kladr_id: "КЛАДР-код населенного пункта",
  settlement_with_type: "Населенный пункт с типом",
  settlement_type: "Тип населенного пункта (сокращенный)",
  settlement_type_full: "Тип населенного пункта",
  settlement: "Населенный пункт",
  street_fias_id: "ФИАС-код улицы",
  street_kladr_id: "КЛАДР-код улицы",
  street_with_type: "Улица с типом",
  street_type: "Тип улицы (сокращенный)",
  street_type_full: "Тип улицы",
  street: "Улица",
  house_fias_id: "ФИАС-код дома",
  house_kladr_id: "КЛАДР-код дома",
  house_cadnum: "Кадастровый номер дома",
  house_type: "Тип дома (сокращенный)",
  house_type_full: "Тип дома",
  house: "Дом",
  block_type: "Тип корпуса/строения (сокращенный)",
  block_type_full: "Тип корпуса/строения",
  block: "Корпус/строение",
  entrance: "Подъезд",
  floor: "Этаж",
  flat_fias_id: "ФИАС-код квартиры",
  flat_cadnum: "Кадастровый номер квартиры",
  flat_type: "Тип квартиры (сокращенный)",
  flat_type_full: "Тип квартиры",
  flat: "Квартира",
  flat_area: "Площадь квартиры",
  square_meter_price: "Рыночная стоимость м²",
  flat_price: "Рыночная стоимость квартиры",
  postal_box: "Абонентский ящик",
  fias_id: "ФИАС-код адреса (идентификатор ФИАС)",
  // 'ROOM.ROOMGUID — если квартира найдена в ФИАС
  // 'HOUSE.HOUSEGUID — если дом найден в ФИАС
  // 'ADDROBJ.AOGUID — в противном случае
  fias_code: "Не заполняется, используйте fias_id",
  fias_level: "Уровень детализации, до которого адрес найден в ФИАС",
  fias_actuality_state: "Признак актуальности адреса в ФИАС",
  // 0 — актуальный
  // 1-50 — переименован
  // 51 — переподчинен
  // 99 — удален
  kladr_id: "КЛАДР-код адреса",
  capital_marker: "Признак центра района или региона",
  okato: "Код ОКАТО",
  oktmo: "Код ОКТМО",
  tax_office: "Код ИФНС для физических лиц",
  tax_office_legal: "Код ИФНС для организаций",
  timezone: "Часовой пояс",
  geo_lat: "Координаты: широта",
  geo_lon: "Координаты: долгота",
  beltway_hit: "Внутри кольцевой",
  beltway_distance: "Расстояние от кольцевой в км.",
  qc_geo: "Код точности координат",
  qc_complete: "Код пригодности к рассылке",
  qc_house: "Признак наличия дома в ФИАС",
  qc: "Код проверки адреса",
  unparsed_parts: "Нераспознанная часть адреса.",
  // eslint-disable-next-line max-len
  // metro: 'Список ближайших станций метро (до трёх штук)', // Построение таблицы падает на этом поле, т.к. в нём лежит массив объектов {name, line, distance}.
};

export const FormAutoCompleteInfo = ({ showHint, addressDetails }) => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);

  const onModal = () => setShowModal(!showModal);

  const data = Object.keys(addressDetails || {})?.map((key) => ({
    key,
    value: addressDetails?.[key],
  }));

  const items = data?.reduce((result, item) => {
    const { key, value } = item || {};
    if (LABELS[key]) {
      result.push([
        { type: "text", value: LABELS?.[key] || key },
        { type: "text", value },
      ]);
    }
    return result;
  }, []);

  return (
    <>
      {showHint && items?.length > 0 && (
        <div className={classes.iconWrapper}>
          <div className="ant-form-item-label">
            <label>&nbsp;</label>
          </div>
          {/*<InfoIcon className={classes.infoIcon} onClick={onModal} />*/}
        </div>
      )}
      {showModal && (
        <Modal onClose={onModal}>
          <Table headers={["Ключ", "Значение"]} items={items} sticky />
        </Modal>
      )}
    </>
  );
};

FormAutoCompleteInfo.propTypes = {
  showHint: PropTypes.bool,
  addressDetails: PropTypes.object,
};
