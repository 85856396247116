import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProcessController } from "@/services/api/controllers/ProcessController";

type CompleteStartFormProps = {
  id: string;
  payload: Record<string, unknown>;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

export const completeStartForm = createAsyncThunk(
  "forms/completeStartForm",
  async ({ id, payload, onSuccess, onError }: CompleteStartFormProps) => {
    try {
      await ProcessController?.startProcess(id, payload);
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
    }
  }
);

const startProcessFormSlice = createSlice({
  name: "formSlice",
  initialState: {},
  reducers: {},
});

export default startProcessFormSlice.reducer;
