import { StartFormWrapper } from "@/lib/modals/StartFormWrapper";
import { useForm } from "react-hook-form";
import { useStartForm } from "@/lib/modals/hooks/useStartForm";
import { completeStartForm } from "@store/formsSlice";
import { FormInput, FormSelect } from "@farzoom/common-ui-components";
import { createUseStyles } from "react-jss";
import { useAppDispatch } from "@store/store";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { useUpdateBgTasks } from "@/lib/modals/BankGuaranteeRequest/hooks/useUpdateBgTasks";
import { EmailInput } from "@components/UI/emailInput";

export const GUARANTEE_OPTIONS = [
  { key: "На участие в торгах", value: "На участие в торгах" },
  {
    key: "На исполнение обязательств по контракту",
    value: "На исполнение обязательств по контракту",
  },
  { key: "На возврат аванса", value: "На возврат аванса" },
  {
    key: "На обеспечение гарантийных обязательств",
    value: "На обеспечение гарантийных обязательств",
  },
];

export const useStyles = createUseStyles({
  inputsWrapper: {
    marginBottom: 16,
    display: "flex",
    gap: 16,
    "& .ant-form-item": {
      marginBottom: 0,
    },
  },
  input: {
    flexBasis: "100%",
    "& input": {
      borderRadius: "8px !important",
    },
    "& .ant-input-number-handler-wrap": {
      display: "none",
    },
  },
  actionTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  beneficiaryWrapper: {
    position: "relative",
    marginTop: 16,
    borderRadius: 8,
    padding: 16,
    border: "1px solid rgb(220, 220, 220)",
  },
  beneficiaryRemove: {
    position: "absolute",
    right: 4,
    top: 4,
  },
});

export const BankGuaranteeRequest = ({ id }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { control, setValue, register, handleSubmit } = useForm();
  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Заявка отправлена",
  });

  const { updateBgTasks } = useUpdateBgTasks();

  const onSubmitSuccess = () => {
    onSuccess();
    updateBgTasks();
  };

  const onSubmit = handleSubmit((data) => {
    setIsFetching(true);

    const payload = {
      beneficiary: {
        inn: data.beneficiary?.inn,
      },
      guaranteeType: data.guaranteeType,
      purchaseNumber: data.purchaseNumber,
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
    };

    dispatch(
      completeStartForm({
        id,
        payload,
        onSuccess: onSubmitSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      title="Запрос банковской гарантии"
      formName="bankGuaranteeRequest"
      isFetching={isFetching}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <CompanySuggestion
        control={control}
        register={register}
        setValue={setValue}
        prefix="beneficiary"
        label="Бенефициар"
        placeholder="Введите название или ИНН"
        required
        controlled
      />
      <div className={classes.inputsWrapper}>
        <FormSelect
          className={classes.input}
          name="guaranteeType"
          label="Вид банковской гарантии"
          setValue={setValue}
          register={register}
          control={control}
          defaultValue={null}
          options={GUARANTEE_OPTIONS}
          required
        />
        <FormInput
          className={classes.input}
          control={control}
          setValue={setValue}
          register={register}
          name="purchaseNumber"
          label="Реестровый № торгов"
          placeholder="Введите № торгов"
          required
        />
      </div>

      <FormInput
        control={control}
        setValue={setValue}
        register={register}
        name="contactName"
        label="ФИО контактного лица"
        placeholder="Иванов Фёдор Анатольевич"
        required
      />
      <div className={classes.inputsWrapper}>
        <EmailInput
          className={classes.input}
          name="contactEmail"
          control={control}
          setValue={setValue}
          label="Контактный email"
          required
        />
        <FormInput
          className={classes.input}
          control={control}
          setValue={setValue}
          register={register}
          name="contactPhone"
          label="Контактный телефон"
          placeholder="8 (999) 999 99 99"
          required
        />
      </div>
    </StartFormWrapper>
  );
};
