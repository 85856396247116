import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  borderRadius,
  colors,
  fonts,
  FormBuilder,
  locked,
  parseExpression,
} from "@farzoom/common-ui-components";
import configTheme from "../../theme";
import { getDynamicBlock } from "@utils/getDynamicBlock";

const useStyles = createUseStyles({
  licenses: {
    "& .ant-row": {
      "& .ant-form-item": {
        background: "none",
        padding: 0,
        borderRadius: 0,
      },
    },
    "& .ant-form-item-control-input": {
      backgroundColor: colors.disabled,
      ...locked,
      width: "100%",
      minHeight: 56,
      padding: [16, 40, 16, 16],
      color: colors.coal1,
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: 500,
      border: [1, "solid", "#F0F2F5"],
      borderRadius,
      fontFamily: fonts.roboto,
      outline: "none",
      cursor: "text",
      boxSizing: "border-box",
    },
  },
});

export const Builder = ({
  config: CONFIG,
  model: MODEL,
  orderId,
  decision,
  decisions,
  control,
  watch,
  resetField,
  register,
  setValue,
  userInfo,
  companyId,
  isCompanyLinkable,
  onGoToOrder,
  onGoToCompany,
  currentDecisionId,
  ...rest
}) => {
  const classes = useStyles();

  const getBlock = (prefix, type) => {
    if (type === "consents") {
      return null;
    }

    const config = CONFIG && CONFIG[prefix];
    const model = MODEL && MODEL[prefix];

    // Check is visible
    const currentModel = { ...MODEL, decision: { id: currentDecisionId } };
    const visible = config?.visibleExp
      ? parseExpression({
          model: currentModel,
          expression: config?.visibleExp,
          defaultValue: true,
        })
      : config?.visible;
    // console.log(`BLOCK. prefix:[${prefix}] type:[${type}] visible:[${visible}]`)
    if (!visible) {
      return null;
    }

    const props = {
      register,
      prefix,
      control,
      config,
      model,
      setValue,
      watch,
      resetField,
      type,
      userInfo,
      orderId,
      companyId,
      decision,
      decisions,
      color: configTheme?.theme?.primaryColor,
      classes,
      isCompanyLinkable,
      onGoToOrder,
      onGoToCompany,
      ...rest,
    };

    return getDynamicBlock(props);
  };

  return <FormBuilder config={CONFIG} getBlock={getBlock} theme={configTheme} />;
};

Builder.propTypes = {
  config: PropTypes.object,
  model: PropTypes.object,
  control: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  formValue: PropTypes.object,
};
