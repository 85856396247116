import { axiosInstance } from "./axiosInstance";

export const getUserInfo = async () => {
  try {
    const { data } = await axiosInstance.get("/session/getUserInfo");
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
