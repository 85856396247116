import { axiosInstance, axiosInstanceSpaCarrot } from "@/services/api/axiosInstance";

export const getSupplies = async (sorts, params) => {
  try {
    const { data } = await axiosInstanceSpaCarrot.get("/supply", {
      params: {
        ...sorts,
        ...params,
      },
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const getSupplyConfig = async () => {
  try {
    const { data } = await axiosInstanceSpaCarrot.get("/supply/config");
    return data;
  } catch (err) {
    return err;
  }
};

export const postSupplyOffer = async (data) => {
  try {
    const { res } = await axiosInstanceSpaCarrot.post("/supply/offer", data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const postSupplyDiscountOffer = async (data) => {
  try {
    const { res } = await axiosInstanceSpaCarrot.post("/supply/discountOffer", data);
    return res;
  } catch (err) {
    throw err;
  }
};

let abortControllerDebtor = null;
let abortControllerSupplier = null;

export const getSearchCompaniesSupplier = async (query) => {
  if (abortControllerSupplier) {
    abortControllerSupplier.abort();
  }

  abortControllerSupplier = new AbortController();
  const { signal } = abortControllerSupplier;

  try {
    if (query.params.q) {
      const { data } = await axiosInstance.get("/company/search", {
        ...query,
        signal,
      });
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return { error: true };
  }
};
export const getSearchCompaniesDebtor = async (query) => {
  if (abortControllerDebtor) {
    abortControllerDebtor.abort();
  }

  abortControllerDebtor = new AbortController();
  const { signal } = abortControllerDebtor;

  try {
    if (query.params.q) {
      const { data } = await axiosInstance.get("/company/search", {
        ...query,
        signal,
      });
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return { error: true };
  }
};

export const postRequestFinancing = async (data) => {
  try {
    const { res } = await axiosInstanceSpaCarrot.post("/supply/finRegistry", data);
    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * @param {string[]} data
 * */
export const postRevokeSupplies = async (data) => {
  try {
    return await axiosInstanceSpaCarrot.post("/supply/revokeSupply", data);
  } catch (err) {
    throw err;
  }
};
