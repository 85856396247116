import { GuaranteeForm } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/index";
import { createContext } from "react";

type GuaranteeWrapperProps = {
  prefix: string;
  config: {
    title: string;
    readonly: boolean;
    canEditPurchase?: boolean;
    [key: string]: unknown;
  };
};

export const GuaranteeFormContext = createContext({
  formDisabled: false,
  prefix: "",
  isStartForm: true,
  canEditPurchase: true,
});

export const GuaranteeFormWrapper = ({ config, prefix }: GuaranteeWrapperProps) => {
  return (
    <GuaranteeFormContext.Provider
      value={{
        formDisabled: config.readonly,
        prefix,
        isStartForm: false,
        canEditPurchase: config.canEditPurchase ?? true,
      }}
    >
      <fieldset disabled={config.readonly}>
        <GuaranteeForm />
      </fieldset>
    </GuaranteeFormContext.Provider>
  );
};
