import { useForm } from "react-hook-form";
import { StartFormWrapper } from "../StartFormWrapper";
import { FormSelect } from "@farzoom/common-ui-components";
import React from "react";
import { completeStartForm } from "@store/formsSlice";
import { useStartForm } from "../hooks/useStartForm";
import { useDispatch, useSelector } from "react-redux";
import { useDebtorsList } from "../hooks/useDebtorsList";
import { suppliesState } from "@store/supplies/suppliesSlice";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { EmailInput } from "@components/UI/emailInput";

export const SupplierAccessionStartForm = ({ id }) => {
  const dispatch = useDispatch();
  const { control, setValue, register, handleSubmit } = useForm();
  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Заявка отправлена",
  });

  const { supplyConfig } = useSelector(suppliesState);
  const { debtorsList } = useDebtorsList(supplyConfig);

  const onSubmit = handleSubmit((data) => {
    setIsFetching(true);

    const payload = {
      supplierEmail: data.email,
      supplier: {
        inn: data.supplier?.inn,
      },
      debtor: {
        inn: data.debtor || supplyConfig.debtors[0].inn,
      },
    };

    dispatch(
      completeStartForm({
        id,
        payload,
        onSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      id={id}
      title="Присоединение поставщика"
      formName="supplierAccessionForm"
      isFetching={isFetching}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      {debtorsList.length > 1 && (
        <FormSelect
          name="debtor"
          label="Дебитор:"
          placeholder="Выберите из списка"
          setValue={setValue}
          register={register}
          control={control}
          defaultValue={null}
          options={debtorsList}
          required
        />
      )}
      <CompanySuggestion
        control={control}
        register={register}
        setValue={setValue}
        prefix="supplier"
        label="Компания или ИП"
        placeholder="Введите название или ИНН"
        required
        controlled
      />
      <EmailInput name="email" control={control} setValue={setValue} required />
    </StartFormWrapper>
  );
};
