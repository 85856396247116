import { createSlice } from "@reduxjs/toolkit";

// type CompanyFilterType = {
//   id: string;
//   inn: string;
//   ogrn: string;
//   shortName: string;
// };
// type DateFilter = [string, string];

const isKeyExist = (key: string) => Boolean(localStorage.getItem(key));

const persistedOrderTypeFilter = isKeyExist("orderTypeFilter")
  ? JSON.parse(localStorage.getItem("orderTypeFilter") as string)
  : null;
const persistedOrderStateFilter = isKeyExist("orderStateFilter")
  ? JSON.parse(localStorage.getItem("orderStateFilter") as string)
  : "ACTIVE";

const initialState = {
  numberTaskFilter: null,
  orderTypeFilter: persistedOrderTypeFilter,
  orderStateFilter: persistedOrderStateFilter,
  company: {
    id: "",
    shortName: "",
    inn: "",
    ogrn: "",
  },
  dateFilter: [],
};

export const orderFilterSlice = createSlice({
  name: "orderFilter",
  initialState,
  reducers: {
    setNumberTaskFilter(state, action) {
      state.numberTaskFilter = action.payload;
    },
    setOrderStateFilter(state, action) {
      localStorage.setItem("orderStateFilter", JSON.stringify(action.payload));
      state.orderStateFilter = action.payload;
    },
    setOrderTypeFilter(state, action) {
      localStorage.setItem("orderTypeFilter", JSON.stringify(action.payload));
      state.orderTypeFilter = action.payload;
    },
    setCompany(state, action) {
      console.log("state.company", state.company, action.payload);
      state.company = Object.assign(state.company as unknown as object, action.payload);
      console.log("state.company 1", state.company);
    },
    setDateFilter(state, action) {
      state.dateFilter = action.payload;
    },
    clearFilters(state) {
      state.numberTaskFilter = null;
      state.orderStateFilter = null;
      state.orderTypeFilter = null;
      state.company = initialState.company;
      state.dateFilter = [];

      localStorage.removeItem("orderTypeFilter");
      localStorage.removeItem("orderStateFilter");
    },
    resetFilters(state) {
      state.numberTaskFilter = null;
      state.company = initialState.company;
      state.dateFilter = [];
    },
  },
});

export const {
  setNumberTaskFilter,
  setOrderStateFilter,
  setOrderTypeFilter,
  clearFilters,
  setCompany,
  setDateFilter,
  resetFilters,
} = orderFilterSlice.actions;

export const orderFilterState = (state) => state?.carrot?.orderFilter;
export default orderFilterSlice.reducer;
