import React from "react";
import { Checkbox, Col, Row } from "antd";
import { FormInputMoney, FormInputNumber, FormSelect } from "@farzoom/common-ui-components";
import { BENEFICIARY_REASONS } from "@components/BankGuarantee/personInfo/utils/constants";
import { useStyles } from "@components/BankGuarantee/personInfo/personInfoModal";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

export const BeneficiaryBlock = ({ control, setValue, readonly, register, personW }) => {
  const classes = useStyles();

  const onBeneficiaryChange = (e: CheckboxChangeEvent) => {
    setValue("personRole.isBeneficiaryOwner", e.target.checked);

    if (!e.target.checked) {
      setValue("personRole.beneficiaryOwner.reason", null);
      setValue("personRole.beneficiaryOwner.sharePercent", null);
      setValue("personRole.beneficiaryOwner.shareAmount", null);
    }
  };

  return (
    <Row gutter={[16, 16]} className={classes.block}>
      <Col span={24} xl={6}>
        <Checkbox
          {...register("personRole.isBeneficiaryOwner")}
          checked={personW?.personRole?.isBeneficiaryOwner}
          onChange={onBeneficiaryChange}
        >
          Бенефициарный владелец
        </Checkbox>
      </Col>
      <Col span={24} xl={18}>
        <Row gutter={8}>
          <Col span={12} xl={10}>
            <FormSelect
              name="personRole.beneficiaryOwner.reason"
              label="Основание наличия статуса"
              setValue={setValue}
              control={control}
              defaultValue={personW.personRole?.beneficiaryOwner?.reason}
              options={BENEFICIARY_REASONS}
              disabled={!personW?.personRole?.isBeneficiaryOwner || readonly}
            />
          </Col>
          <Col span={6} xl={7}>
            <FormInputNumber
              name="personRole.beneficiaryOwner.sharePercent"
              control={control}
              setValue={setValue}
              label="Доля, %"
              controls={false}
              disabled={!personW?.personRole?.isBeneficiaryOwner || readonly}
            />
          </Col>
          <Col span={6} xl={7}>
            <FormInputMoney
              name="personRole.beneficiaryOwner.shareAmount"
              control={control}
              setValue={setValue}
              label="Доля, руб"
              controls={false}
              maxLength={25}
              disabled={!personW?.personRole?.isBeneficiaryOwner || readonly}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
