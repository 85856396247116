import React from "react";

const Edit = () => (
  <svg viewBox="0 0 24 24">
    <path d="M20.185 5.332a1.075 1.075 0 0 1 0 1.52l-1.54 1.54-3.037-3.038 1.541-1.54a1.074 1.074 0 0 1 1.518 0l1.518 1.518zm-5.637 1.083l3.037 3.036-8.61 8.61-3.59 1.393a.65.65 0 0 1-.84-.84l1.394-3.59 8.609-8.609z" />
  </svg>
);

const Confirm = () => (
  <svg viewBox="0 0 24 24">
    <path d="M17.459 9.955l-6.46 6.5a1 1 0 0 1-1.418 0l-3.04-3.06a.999.999 0 1 1 1.418-1.41l2.331 2.346 5.751-5.786a1 1 0 0 1 1.418 1.41M12 2C6.478 2 2 6.477 2 12s4.478 10 10 10 10-4.477 10-10S17.522 2 12 2" />
  </svg>
);

const Cancel = () => (
  <svg viewBox="0 0 24 24">
    <path d="M12.5 9.3H9.3l1.8-1.7c.3-.3.3-.7 0-.9-.3-.3-.7-.3-.9 0l-3 2.8c-.1.1-.2.3-.2.5s.1.4.2.5l3 2.8c.3.3.7.2.9 0 .3-.3.2-.7 0-.9l-1.8-1.7h3.2c1.6 0 2.8 1.3 2.8 2.8 0 1.5-1.3 2.8-2.8 2.8-.4 0-.7.3-.7.7 0 .4.3.7.7.7 2.3 0 4.2-1.9 4.2-4.2 0-2.3-1.9-4.2-4.2-4.2z" />
  </svg>
);

const Success = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#22c7b5" />
      <path
        fill="#fff"
        d="M8.275 13.958a.832.832 0 0 1-.577-.231l-3.067-2.942a.833.833 0 1 1 1.154-1.202l2.49 2.387 5.94-5.697a.833.833 0 0 1 1.154 1.204l-6.518 6.25a.832.832 0 0 1-.576.231"
      />
    </g>
  </svg>
);

export const Icons = {
  Edit,
  Confirm,
  Cancel,
  Success,
};
