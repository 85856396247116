import React from "react";
import { Checkbox, Col, Row } from "antd";
import { FormInputMoney, FormInputNumber } from "@farzoom/common-ui-components";
import { useStyles } from "@components/BankGuarantee/personInfo/personInfoModal";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import useScreenSize from "@hooks/useScreenSize";

export const FounderBlock = ({ control, setValue, readonly, register, personW }) => {
  const classes = useStyles();
  const screenSize = useScreenSize();

  const onFounderChange = (e: CheckboxChangeEvent) => {
    setValue("personRole.isFounder", e.target.checked);

    if (!e.target.checked) {
      setValue("personRole.founder.sharePercent", null);
      setValue("personRole.founder.shareAmount", null);
    }
  };

  return (
    <Row gutter={[16, 16]} className={classes.block}>
      <Col span={24} xl={6}>
        <Checkbox
          {...register("personRole.isFounder")}
          checked={personW?.personRole?.isFounder}
          onChange={onFounderChange}
        >
          Учредитель
        </Checkbox>
      </Col>
      <Col span={24} xl={18}>
        <Row gutter={8}>
          <Col span={12} xl={7} offset={screenSize.xl ? 10 : 0}>
            <FormInputNumber
              name="personRole.founder.sharePercent"
              control={control}
              setValue={setValue}
              label="Доля, %"
              controls={false}
              disabled={!personW?.personRole?.isFounder || readonly}
            />
          </Col>
          <Col span={12} xl={7}>
            <FormInputMoney
              name="personRole.founder.shareAmount"
              control={control}
              setValue={setValue}
              label="Доля, руб"
              controls={false}
              maxLength={25}
              disabled={!personW?.personRole?.isFounder || readonly}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
