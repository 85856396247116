import { Col, Row } from "antd";
import { GUARANTEE_OPTIONS_AGENT } from "@/lib/modals/BankGuaranteeRequest/bankGuaranteeRequestAgent";
import { FormInputMoney, FormSelect } from "@farzoom/common-ui-components";
import { useStyles } from "@/lib/modals/BankGuaranteeRequest/index";
import { Section } from "@/lib/modals/BankGuaranteeRequest/section";
import { useContext, useEffect } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { useFormContext, useWatch } from "react-hook-form";
import { BgFormType } from "@/types/BgForm";
import { notNullish } from "@utils/notNullish";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";

export const GuaranteeInfo = () => {
  const classes = useStyles();
  const { control, setValue, getValues } = useFormContext<BgFormType>();
  const { formDisabled, prefix, canEditPurchase } = useContext(GuaranteeFormContext);

  const currentPrefix = prefix ? `${prefix}.guarantee` : "guarantee";
  const lotsPrefix = prefix ? `${prefix}.purchase.lots` : "purchase.lots";

  const lotsW = useWatch({ name: lotsPrefix });

  useEffect(() => {
    const amount = getValues(lotsPrefix as "purchase.lots").reduce((total, lot) => {
      return (
        total +
        lot.beneficiaries.reduce((beneficiaryTotal, beneficiary) => {
          return beneficiaryTotal + (notNullish(beneficiary.bgAmount) ? beneficiary.bgAmount! : 0);
        }, 0)
      );
    }, 0);

    setValue(`${currentPrefix}.amount` as "guarantee.amount", amount);
  }, [lotsW]);

  return (
    <Section title="Сведения о банковской гарантии">
      <FormSelect
        name={`${currentPrefix}.type`}
        className={classes.input}
        label="Вид банковской гарантии"
        setValue={setValue}
        control={control}
        defaultValue={null}
        options={GUARANTEE_OPTIONS_AGENT}
        disabled={formDisabled || !canEditPurchase}
        required
      />
      <Row gutter={16}>
        <Col span={6}>
          <FormInputDateTime
            name={`${currentPrefix}.startDate`}
            control={control}
            setValue={setValue}
            label="Дата начала гарантии"
            disabled={formDisabled}
            required
          />
        </Col>
        <Col span={6}>
          <FormInputDateTime
            name={`${currentPrefix}.endDate`}
            control={control}
            setValue={setValue}
            label="Дата окончания"
            disabled={formDisabled}
            required
          />
        </Col>
        <Col span={12}>
          <FormInputMoney
            name={`${currentPrefix}.amount`}
            className={classes.input}
            control={control}
            setValue={setValue}
            label="Сумма гарантии"
            disabled
            maxLength={25}
          />
        </Col>
      </Row>
    </Section>
  );
};
