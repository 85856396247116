import { VerticalSpace } from "@components/UI/VerticalSpace";
import { Col, Row, Space, Typography } from "antd";
import { FormInputMoney, FormInputNumber, FormSwitch } from "@farzoom/common-ui-components";
import { useContext, useState } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { useFormContext, useWatch } from "react-hook-form";
import { BgFormType } from "@/types/BgForm";
import { LotsSubComponent } from "@/lib/modals/BankGuaranteeRequest/lots";
import { createUseStyles } from "react-jss";

const { Text } = Typography;

const useStyles = createUseStyles({
  switch: {
    "& [class*=baseInput] .ant-switch": {
      width: "60px",
    },
  },
});

export const ContractConditions = ({ lotIndex }: LotsSubComponent) => {
  const classes = useStyles();
  const { control, setValue, getValues } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);

  const currentPrefix = (prefix ? `${prefix}.purchase.lots` : "purchase.lots") as "purchase.lots";
  const guarantyTypePrefix = prefix ? `${prefix}.guarantee.type` : "guarantee.type";

  const [isPrepayment, setIsPrepayment] = useState(
    getValues(`${currentPrefix}.${lotIndex}.contractConditions.prepaymentExists`)
  );
  const [isGuarantee, setIsGuarantee] = useState(
    getValues(`${currentPrefix}.${lotIndex}.contractConditions.requiredSecurityForGuaranteePeriod`)
  );

  const guaranteeType = useWatch({
    name: guarantyTypePrefix,
  });

  const onPrepaymentChange = (value: boolean) => {
    setIsPrepayment(value);

    if (!value) {
      setValue(`${currentPrefix}.${lotIndex}.contractConditions.prepaymentAmount`, null);
    }
  };

  const onGuaranteeChange = (value: boolean) => {
    setIsGuarantee(value);

    if (!setIsGuarantee) {
      setValue(
        `${currentPrefix}.${lotIndex}.contractConditions.securityForGuaranteePeriodDays`,
        null
      );
      setValue(
        `${currentPrefix}.${lotIndex}.contractConditions.securityForGuaranteePeriodAmount`,
        null
      );
    }
  };

  return (
    <VerticalSpace>
      <Text strong>Условия контракта</Text>
      <VerticalSpace>
        <Space>
          <FormSwitch
            label="Бесспорное списание"
            className={classes.switch}
            name={`${currentPrefix}.${lotIndex}.contractConditions.requiredIndisputableDebiting`}
            control={control}
            checkedLabel=""
            uncheckedLabel=""
          />
        </Space>

        {guaranteeType === "execution" && (
          <VerticalSpace>
            <Space>
              <FormSwitch
                label="Неустойки"
                className={classes.switch}
                name={`${currentPrefix}.${lotIndex}.contractConditions.includedForfeit`}
                control={control}
                checkedLabel=""
                uncheckedLabel=""
              />
            </Space>
            <VerticalSpace size="small">
              <Space>
                <FormSwitch
                  label="Предусмотрен аванс"
                  className={classes.switch}
                  name={`${currentPrefix}.${lotIndex}.contractConditions.prepaymentExists`}
                  control={control}
                  checkedLabel=""
                  uncheckedLabel=""
                  parentOnChange={onPrepaymentChange}
                />
              </Space>
              {isPrepayment && (
                <FormInputMoney
                  name={`${currentPrefix}.${lotIndex}.contractConditions.prepaymentAmount`}
                  control={control}
                  setValue={setValue}
                  label="Сумма аванса"
                  controls={false}
                  disabled={formDisabled}
                  maxLength={25}
                  required
                />
              )}
            </VerticalSpace>

            <Row gutter={[16, 8]} align="middle">
              <Col span={24}>
                <Space>
                  <FormSwitch
                    label="Гарантийный период"
                    className={classes.switch}
                    name={`${currentPrefix}.${lotIndex}.contractConditions.requiredSecurityForGuaranteePeriod`}
                    control={control}
                    checkedLabel=""
                    uncheckedLabel=""
                    parentOnChange={onGuaranteeChange}
                  />
                </Space>
              </Col>
              {isGuarantee && (
                <>
                  <Col span={12}>
                    <FormInputNumber
                      name={`${currentPrefix}.${lotIndex}.contractConditions.securityForGuaranteePeriodDays`}
                      control={control}
                      setValue={setValue}
                      label="Срок гарантийного периода в днях"
                      controls={false}
                      disabled={formDisabled}
                    />
                  </Col>
                  <Col span={12}>
                    <FormInputMoney
                      name={`${currentPrefix}.${lotIndex}.contractConditions.securityForGuaranteePeriodAmount`}
                      control={control}
                      setValue={setValue}
                      label="Сумма гарантийного обслуживания"
                      controls={false}
                      disabled={formDisabled}
                      maxLength={25}
                    />
                  </Col>
                </>
              )}
            </Row>
          </VerticalSpace>
        )}
      </VerticalSpace>
    </VerticalSpace>
  );
};
