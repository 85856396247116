import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Main } from "../services/api";
import { currentPageLinkClickHandler } from "@utils/links";

const initialState = {
  widgets: [],
  isFetching: false,
};

export const fetchWidgets = createAsyncThunk("widgets/fetchWidgets", async (_, { dispatch }) => {
  dispatch(setIsFetching());
  return await Main.getWidgets();
});

export const onOpenWidget = createAsyncThunk("widgets/onOpenWidget", async (_, { dispatch }) => {
  currentPageLinkClickHandler({
    pathname: "/main",
    onSuccess: () => {
      dispatch(fetchWidgets());
    },
  });
});

const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    setIsFetching: (state) => {
      state.isFetching = true;
    },
    resetWidgetState: (state) => {
      state.widgets = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWidgets.fulfilled, (state, action) => {
        state.widgets = action.payload;
        state.isFetching = false;
      })
      .addCase(fetchWidgets.rejected, (state) => {
        state.widgets = [];
        state.isFetching = false;
      });
  },
});

export const widgetsState = (state) => state?.carrot?.widgets;

export const { setIsFetching, resetWidgetState } = widgetsSlice.actions;
export default widgetsSlice.reducer;
