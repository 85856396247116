import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Tasks } from "@/services/api";
import { RootState } from "@store/store";
import { currentPageLinkClickHandler } from "@utils/links";

const initialState = {
  isFetching: false,
  isFetchingMore: false,
  tasks: {
    order: [],
    page: 1,
    more: false,
  },
};

export const onOpenTasks = createAsyncThunk("tasks/onOpenTasks", async (_, { dispatch }) => {
  currentPageLinkClickHandler({
    pathname: "/tasks",
    onSuccess: () => {
      dispatch(getTasks());
    },
  });
});

export const getTasks = createAsyncThunk("tasks/getTasks", async (_, { dispatch, getState }) => {
  dispatch(setIsFetching(true));
  const state = getState();
  return await Tasks.getTasksList((state as RootState).carrot.orderFilter);
});

export const getMoreTasks = createAsyncThunk(
  "tasks/getMoreTasks",
  async (_, { dispatch, getState }) => {
    dispatch(setIsFetchingMore(true));
    const state = getState();
    return await Tasks.getTasksList(
      (state as RootState).carrot.orderFilter,
      (state as RootState).carrot.tasks.tasks.page + 1
    );
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsFetchingMore: (state, action) => {
      state.isFetchingMore = action.payload;
    },
    resetTasksState: (state) => {
      state.tasks = initialState.tasks;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.isFetching = false;
      })
      .addCase(getTasks.rejected, (state) => {
        state.tasks = initialState.tasks;
        state.isFetching = false;
      })
      .addCase(getMoreTasks.fulfilled, (state, action) => {
        state.tasks.order = state.tasks.order.concat(action.payload.order);
        state.tasks.more = action.payload.more;
        state.tasks.page = action.payload.page;
        state.isFetchingMore = false;
      })
      .addCase(getMoreTasks.rejected, (state) => {
        state.isFetchingMore = false;
      });
  },
});

export const taskState = (state) => state?.carrot?.tasks;
export const tasksList = (state) => state?.carrot?.tasks?.tasks?.order;
export const hasMore = (state) => state.carrot?.tasks?.tasks?.more;
export const isLoadingMore = (state) => state.carrot?.tasks?.isFetchingMore;

export const { setIsFetching, setIsFetchingMore, resetTasksState } = tasksSlice.actions;

export default tasksSlice.reducer;
