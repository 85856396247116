import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { currentPageLinkClickHandler } from "@utils/links";
import { getBGTasksList } from "@/services/api/bankGuarantee/guarantee";
import { BgResponseType } from "@/types/BgTask";

type StateType = {
  isFetching: boolean;
  isFetchingMore: boolean;
  tasks: BgResponseType;
  isMore: boolean;
};

const initialState: StateType = {
  isFetching: false,
  isFetchingMore: false,
  tasks: {
    items: [],
    pageNumber: 1,
    pageSize: 30,
    pageCount: 1,
    totalCount: 1,
    sort: "",
    sortOrder: "",
  },
  isMore: true,
};

export const onOpenBgTasks = createAsyncThunk("bg/onOpenBgTasks", async (_, { dispatch }) => {
  currentPageLinkClickHandler({
    pathname: "/guarantees",
    onSuccess: () => {
      dispatch(getBgTasks());
    },
  });
});

export const getBgTasks = createAsyncThunk(
  "tasks/getBgTasks",
  async (_, { dispatch, getState }) => {
    dispatch(setIsFetching(true));
    const state = getState();
    return await getBGTasksList((state as RootState).carrot.orderFilter);
  }
);

export const getMoreBgTasks = createAsyncThunk(
  "tasks/getMoreBgTasks",
  async (_, { dispatch, getState }) => {
    dispatch(setIsFetchingMore(true));
    const state = getState();
    return await getBGTasksList(
      (state as RootState).carrot.orderFilter,
      (state as RootState).carrot.guarantees.tasks.pageNumber + 1
    );
  }
);

const bgSlice = createSlice({
  name: "guarantees",
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsFetchingMore: (state, action) => {
      state.isFetchingMore = action.payload;
    },
    resetTasksState: (state) => {
      state.tasks = initialState.tasks;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBgTasks.fulfilled, (state, action) => {
        state.tasks = action.payload;

        state.isFetching = false;
        state.isMore = action.payload.pageNumber !== action.payload.pageCount;
      })
      .addCase(getBgTasks.rejected, (state) => {
        state.tasks = initialState.tasks;
        state.isFetching = false;
      })
      .addCase(getMoreBgTasks.fulfilled, (state, action) => {
        state.tasks.items = state.tasks.items.concat(action.payload.items);
        state.tasks.pageNumber = action.payload.pageNumber;
        state.isFetchingMore = false;
        state.isMore = action.payload.pageNumber !== action.payload.pageCount;
      })
      .addCase(getMoreBgTasks.rejected, (state) => {
        state.isFetchingMore = false;
      });
  },
});

export const taskState = (state: RootState) => state?.carrot?.guarantees;
export const tasksList = (state: RootState) => state?.carrot?.guarantees?.tasks.items;
export const hasMore = (state: RootState) => state.carrot?.guarantees?.isMore;
export const isLoadingMore = (state: RootState) => state.carrot?.guarantees?.isFetchingMore;

export const { setIsFetching, setIsFetchingMore, resetTasksState } = bgSlice.actions;

export default bgSlice.reducer;
