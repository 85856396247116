import { cadespluginInit } from "./3rdparty/cadesplugin_api";

const parseString = d => {
  const res = {};
  const pos = 0;
  while (d.length > 0) {
    let nameEndPos = d.indexOf("=", pos);
    if (nameEndPos < 0) nameEndPos = d.length - 1;

    const name = d.substr(0, nameEndPos);
    d = d.substr(nameEndPos + 1);
    let value = null;
    /* if string in quotes - search for closest single quote (not paired) */
    if (d[0] == '"') {
      let i = 1;
      while (i < d.length) {
        if (d[i] == '"') {
          if (i < d.length - 1 && d[i + 1] == '"') i += 2;
          else break;
        } else {
          ++i;
        }
      }
      value = d.substr(1, i - 1).replace(/""/g, '"');
      d = d.substr(i + 3);
    } else {
      const nextPos = d.indexOf("=");
      if (nextPos < 0) {
        value = d;
        d = "";
      } else {
        const s = d.substr(0, nextPos);
        const valueEndPos = s.lastIndexOf(", ");
        value = s.substr(0, valueEndPos);
        d = d.substr(value.length + 2);
      }
    }

    res[name] = value;
  }
  return res;
};

const q = (resolve, reject) => new Promise(resolve, reject);

const qify = promise =>
  q((resolve, reject) =>
    promise.then(res => resolve(res)).catch(err => reject(err))
  );

const loadPluginApi = () =>
  q((resolve, reject) => {
    try {
      cadespluginInit();
      resolve();
    } catch (e) {
      reject(e);
    }
  });

const setupPlugin = () => loadPluginApi().then(_ => qify(cadesplugin));

const getErrorMessage = e => {
  let err = e.message;
  if (!err) {
    err = e;
  } else if (e.number) {
    err += ` (${e.number})`;
  }
  return err;
};

const getCertList = () =>
  setupPlugin().then(() => {
    const canAsync = !!cadesplugin.CreateObjectAsync;
    if (canAsync) {
      return getCertList_Async();
    }
    return getCertList_Sync();
  });

const getCertList_Async = () =>
  q((resolve, reject) => {
    cadesplugin.async_spawn(function* () {
      let step = "";
      try {
        step = "Получение доступа к хранилищу";
        const oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
        if (!oStore) {
          throw new Error("Не удалось получить доступ");
        }

        step = "Открытие хранилища";
        yield oStore.Open();

        step = "Получение сертификатов";
        const certs = yield oStore.Certificates;
        const certCnt = yield certs.Count;

        const result = [];
        if (certCnt > 0) {
          const dateObj = new Date();
          for (let i = 1; i <= certCnt; i++) {
            step = `Чтение сертификата ${i}`;
            const cert = yield certs.Item(i);

            const ValidFromDate = new Date(yield cert.ValidFromDate);
            const ValidToDate = new Date(yield cert.ValidToDate);
            const Thumbprint = yield cert.Thumbprint;
            const subject = parseString(yield cert.SubjectName);
            const SubjectName = subject.CN;

            const Validator = yield cert.IsValid();
            const IsValid = yield Validator.Result;
            const HasPrivateKey = yield cert.HasPrivateKey();
            const PublicKey = yield cert.PublicKey();
            const Algorithm = yield PublicKey.Algorithm;
            const AlgOid = yield Algorithm.Value;
            const HashAlg = convertAlgOidToHashAlg(AlgOid);

            // if(dateObj < ValidToDate && HasPrivateKey && IsValid) {
            result.push({
              ValidFromDate,
              ValidToDate,
              SubjectName,
              Thumbprint,
              HashAlg
            });
            // }
          }
        }
        resolve(result);
      } catch (ex) {
        reject(new Error(`${step}: ${getErrorMessage(ex)}`));
      } finally {
        if (oStore && typeof oStore.Close === "function") yield oStore.Close();
      }
    });
  });

const getCertList_Sync = () =>
  q((resolve, reject) => {
    let step = "";
    try {
      step = "Получение доступа к хранилищу";
      const oStore = cadesplugin.CreateObject("CAdESCOM.Store");
      if (!oStore) throw new Error("Не удалось получить доступ");

      step = "Открытие хранилища";
      oStore.Open();

      step = "Получение сертификатов";
      const certs = oStore.Certificates;
      const certCnt = certs.Count;

      const result = [];
      if (certCnt > 0) {
        const dateObj = new Date();
        for (let i = 1; i <= certCnt; i++) {
          step = `Чтение сертификата ${i}`;
          const cert = certs.Item(i);

          const ValidFromDate = new Date(cert.ValidFromDate);
          const ValidToDate = new Date(cert.ValidToDate);
          const { Thumbprint } = cert;
          const subject = parseString(cert.SubjectName);
          const SubjectName = subject.CN;

          const Validator = cert.IsValid();
          const IsValid = Validator.Result;
          const HasPrivateKey = cert.HasPrivateKey();
          const PublicKey = cert.PublicKey();
          const { Algorithm } = PublicKey;
          const AlgOid = Algorithm.Value;
          const HashAlg = convertAlgOidToHashAlg(AlgOid);

          // if(dateObj < ValidToDate && HasPrivateKey && IsValid) {
          result.push({
            ValidFromDate,
            ValidToDate,
            SubjectName,
            Thumbprint,
            HashAlg
          });
          // }
        }
      }
      resolve(result);
    } catch (ex) {
      reject(new Error(`${step}: ${getErrorMessage(ex)}`));
    } finally {
      if (oStore) oStore.Close();
    }
  });

const convertAlgOidToHashAlg = AlgOid => {
  // console.log('AlgOid = ', AlgOid)
  switch (AlgOid) {
    case "1.2.643.2.2.9":
    case "1.2.643.2.2.19":
      return "GOST_3411";
    case "1.2.643.7.1.1.2.2":
    case "1.2.643.7.1.1.1.1":
      return "GOST_3411_2012_256";
    case "1.2.643.7.1.1.2.3":
    case "1.2.643.7.1.1.1.2":
      return "GOST_3411_2012_512";
    default:
      return AlgOid;
  }
};

const sign = (thumbprint, dataToSign, isDetached) =>
  setupPlugin().then(() =>
    cadesplugin.CreateObjectAsync
      ? sign_Async(thumbprint, dataToSign, isDetached)
      : sign_Sync(thumbprint, dataToSign, isDetached)
  );

const sign_Async = (thumbprint, dataToSign, isDetached) =>
  q((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      try {
        const oStore = yield cadesplugin.CreateObjectAsync("CAPICOM.Store");
        yield oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
        );

        const CertificatesObj = yield oStore.Certificates;
        const oCertificates = yield CertificatesObj.Find(
          cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
          thumbprint
        );

        const Count = yield oCertificates.Count;
        if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

        const oCertificate = yield oCertificates.Item(1);
        const oSigner =
          yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        yield oSigner.propset_Certificate(oCertificate);

        const oSignedData = yield cadesplugin.CreateObjectAsync(
          "CAdESCOM.CadesSignedData"
        );
        yield oSignedData.propset_ContentEncoding(
          cadesplugin.CADESCOM_BASE64_TO_BINARY
        );
        // dataToSign = dataToSign.replace(/=/gi, ''); // Not required for new version of CryptoPro Browser Plugin
        yield oSignedData.propset_Content(dataToSign);

        const sSignedMessage = yield oSignedData.SignCades(
          oSigner,
          cadesplugin.CADESCOM_CADES_BES,
          isDetached
        );

        yield oStore.Close();

        resolve(sSignedMessage);
      } catch (ex) {
        reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
      } finally {
        if (oStore && typeof oStore.Close === "function") yield oStore.Close();
      }
    });
  });

const sign_Sync = (thumbprint, dataToSign, isDetached) =>
  q((resolve, reject) => {
    try {
      const oStore = cadesplugin.CreateObject("CAPICOM.Store");
      oStore.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const CertificatesObj = oStore.Certificates;
      const oCertificates = CertificatesObj.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );

      const { Count } = oCertificates;
      if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

      const oCertificate = oCertificates.Item(1);
      const oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      oSigner.Certificate = oCertificate;

      const oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      oSignedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
      // dataToSign = dataToSign.replace(/=/gi, ''); // Not required for new version of CryptoPro Browser Plugin
      oSignedData.Content = dataToSign;

      const sSignedMessage = oSignedData.SignCades(
        oSigner,
        cadesplugin.CADESCOM_CADES_BES,
        isDetached
      ); // CADESCOM_CADES_X_LONG_TYPE_1

      oStore.Close();

      resolve(sSignedMessage);
    } catch (ex) {
      reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
    } finally {
      if (oStore) oStore.Close();
    }
  });

const signHash = (thumbprint, hash, hashAlg) =>
  setupPlugin().then(() =>
    cadesplugin.CreateObjectAsync
      ? signHash_Async(thumbprint, hash, hashAlg)
      : signHash_Sync(thumbprint, hash, hashAlg)
  );

const signHash_Async = (thumbprint, hash, hashAlg) =>
  q((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      try {
        const oStore = yield cadesplugin.CreateObjectAsync("CAPICOM.Store");
        yield oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
        );

        const CertificatesObj = yield oStore.Certificates;
        const oCertificates = yield CertificatesObj.Find(
          cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
          thumbprint
        );

        const Count = yield oCertificates.Count;
        if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

        const oCertificate = yield oCertificates.Item(1);
        const oSigner =
          yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        yield oSigner.propset_Certificate(oCertificate);
        yield oSigner.propset_Options(
          cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
        );

        // определение алгоритма хэширования
        let hashAlgCode;
        switch (hashAlg) {
          case "GOST_3411":
            hashAlgCode = cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411;
            break;
          case "GOST_3411_2012_256":
            hashAlgCode =
              cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256;
            break;
          case "GOST_3411_2012_512":
            hashAlgCode =
              cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512;
            break;
          default:
            throw new Error(
              `Неподдерживаемый алгоритм хэширования - ${hashAlg}`
            );
        }

        // хэш подписываемых данных
        const oHashedData = yield cadesplugin.CreateObjectAsync(
          "CAdESCOM.HashedData"
        );
        yield oHashedData.propset_Algorithm(hashAlgCode);
        yield oHashedData.SetHashValue(hash);

        const oSignedData = yield cadesplugin.CreateObjectAsync(
          "CAdESCOM.CadesSignedData"
        );
        const sSignedMessage = yield oSignedData.SignHash(
          oHashedData,
          oSigner,
          cadesplugin.CADESCOM_CADES_BES
        );

        yield oStore.Close();

        resolve(sSignedMessage);
      } catch (ex) {
        reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
      } finally {
        if (oStore) yield oStore.Close();
      }
    });
  });

const signHash_Sync = (thumbprint, hash, hashAlg) =>
  q((resolve, reject) => {
    try {
      const oStore = cadesplugin.CreateObject("CAPICOM.Store");
      oStore.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const CertificatesObj = oStore.Certificates;
      const oCertificates = CertificatesObj.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );

      const { Count } = oCertificates;
      if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

      const oCertificate = oCertificates.Item(1);
      const oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      oSigner.Certificate = oCertificate;
      oSigner.Options = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN;

      // определение алгоритма хэширования
      let hashAlgCode;
      switch (hashAlg) {
        case "GOST_3411":
          hashAlgCode = cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411;
          break;
        case "GOST_3411_2012_256":
          hashAlgCode =
            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256;
          break;
        case "GOST_3411_2012_512":
          hashAlgCode =
            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512;
          break;
        default:
          throw new Error(`Неподдерживаемый алгоритм хэширования - ${hashAlg}`);
      }

      // хэш подписываемых данных
      const oHashedData = cadesplugin.CreateObject("CAdESCOM.HashedData");
      oHashedData.Algorithm = hashAlgCode;
      oHashedData.SetHashValue(hash);

      const oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      const sSignedMessage = oSignedData.SignHash(
        oHashedData,
        oSigner,
        cadesplugin.CADESCOM_CADES_BES
      );

      oStore.Close();

      resolve(sSignedMessage);
    } catch (ex) {
      reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
    } finally {
      if (oStore) oStore.Close();
    }
  });

const coSign = (thumbprint, signature) =>
  setupPlugin().then(() =>
    cadesplugin.CreateObjectAsync
      ? coSign_Async(thumbprint, signature)
      : coSign_Sync(thumbprint, signature)
  );

const coSign_Async = (thumbprint, signature) => {
  const decoded = atob(signature);
  if (decoded.substr(0, 5) === "-----") {
    signature = decoded;
  }
  return q((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      try {
        const oStore = yield cadesplugin.CreateObjectAsync("CAPICOM.Store");
        yield oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
        );

        const CertificatesObj = yield oStore.Certificates;
        const oCertificates = yield CertificatesObj.Find(
          cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
          thumbprint
        );

        const Count = yield oCertificates.Count;
        if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

        const oCertificate = yield oCertificates.Item(1);
        const oSigner =
          yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        yield oSigner.propset_Certificate(oCertificate);

        const oSignedData = yield cadesplugin.CreateObjectAsync(
          "CAdESCOM.CadesSignedData"
        );
        yield oSignedData.propset_ContentEncoding(
          cadesplugin.CADESCOM_BASE64_TO_BINARY
        );
        // Not required for new version of CryptoPro Browser Plugin
        // signature = signature.replace(/=/gi, '').replace(/-----.+-----/g, '').replace(/\r/g, '').replace(/\n/g, '');
        signature = signature
          .replace(/-----.+-----/g, "")
          .replace(/\r/g, "")
          .replace(/\n/g, "");
        yield oSignedData.propset_Content(signature);

        yield oSignedData.VerifyCades(
          signature,
          cadesplugin.CADESCOM_CADES_BES
        );
        const sSignedMessage = yield oSignedData.CoSignCades(
          oSigner,
          cadesplugin.CADESCOM_CADES_BES
        ); // CADESCOM_CADES_X_LONG_TYPE_1

        yield oStore.Close();

        resolve(sSignedMessage);
      } catch (ex) {
        reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
      } finally {
        if (oStore) yield oStore.Close();
      }
    });
  });
};

const coSign_Sync = (thumbprint, signature) => {
  const decoded = atob(signature);
  if (decoded.substr(0, 5) === "-----") {
    signature = decoded;
  }
  return q((resolve, reject) => {
    try {
      const oStore = cadesplugin.CreateObject("CAPICOM.Store");
      oStore.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const CertificatesObj = oStore.Certificates;
      const oCertificates = CertificatesObj.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );

      const { Count } = oCertificates;
      if (Count == 0) throw new Error(`Сертификат не найден: ${thumbprint}`);

      const oCertificate = oCertificates.Item(1);
      const oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      oSigner.Certificate = oCertificate;

      const oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      oSignedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
      // Not required for new version of CryptoPro Browser Plugin
      // signature = signature.replace(/=/gi, '').replace(/-----.+-----/g, '').replace(/\r/g, '').replace(/\n/g, '');
      signature = signature
        .replace(/-----.+-----/g, "")
        .replace(/\r/g, "")
        .replace(/\n/g, "");
      oSignedData.Content = signature;

      oSignedData.VerifyCades(signature, cadesplugin.CADESCOM_CADES_BES);
      const sSignedMessage = oSignedData.CoSignCades(
        oSigner,
        cadesplugin.CADESCOM_CADES_BES
      ); // CADESCOM_CADES_X_LONG_TYPE_1

      oStore.Close();

      resolve(sSignedMessage);
    } catch (ex) {
      reject(`Не удалось сформировать подпись. ${getErrorMessage(ex)}`);
    } finally {
      if (oStore) oStore.Close();
    }
  });
};

const checkSignature = (signature, dataToVerify, isDetached) =>
  setupPlugin().then(() =>
    cadesplugin.CreateObjectAsync
      ? checkSignature_Async(signature, dataToVerify, isDetached)
      : checkSignature_Sync(signature, dataToVerify, isDetached)
  );

const checkSignature_Async = (signature, dataToVerify, isDetached) => {
  const decoded = atob(signature);
  if (decoded.substr(0, 5) === "-----") {
    signature = decoded;
  }
  return q((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      try {
        let isValid;
        let error;
        try {
          const oSignedData = yield cadesplugin.CreateObjectAsync(
            "CAdESCOM.CadesSignedData"
          );
          if (isDetached) {
            yield oSignedData.propset_ContentEncoding(
              cadesplugin.CADESCOM_BASE64_TO_BINARY
            );
            // dataToVerify = dataToVerify.replace(/=/gi, ''); // Not required for new version of CryptoPro Browser Plugin
            yield oSignedData.propset_Content(dataToVerify);
          }
          yield oSignedData.VerifyCades(
            signature,
            cadesplugin.CADESCOM_CADES_BES,
            isDetached || false
          );
          isValid = true;
        } catch (ex) {
          isValid = false;
          error = ex;
        }

        if (oSignedData) {
          const signers = yield oSignedData.Signers;
          const cnt = yield signers.Count;
          const result = [];
          for (let i = 1; i <= cnt; ++i) {
            const oSigner = yield signers.Item(i);
            const res = {};
            const c = yield oSigner.Certificate;
            res.cert = {};
            res.cert.Thumbprint = yield c.Thumbprint;
            res.cert.SubjectName = yield c.SubjectName;
            res.cert.IssuerName = yield c.IssuerName;
            res.cert.SerialNumber = yield c.SerialNumber;
            res.cert.ValidFromDate = yield c.ValidFromDate;
            res.cert.ValidToDate = yield c.ValidToDate;

            res.isValid = yield (yield oSigner.SignatureStatus).IsValid;
            try {
              res.SigningTime = yield oSigner.SigningTime;
            } catch (ex) {
              console.log(
                "cert: ",
                res.cert.SerialNumber,
                ", SigningTime error: ",
                ex.message
              );
            }

            res.cert.SubjectNameObj = parseString(res.cert.SubjectName);

            res.cert.SubjectNameArr = [];
            for (const k in res.cert.SubjectNameObj)
              if (res.cert.SubjectNameObj.hasOwnProperty(k)) {
                res.cert.SubjectNameArr.push({
                  name: k,
                  value: res.cert.SubjectNameObj[k]
                });
              }

            result.push(res);
          }

          resolve({
            isValid,
            error,
            signers: result
          });
        } else {
          reject(getErrorMessage(error));
        }
      } catch (ex) {
        reject(getErrorMessage(ex));
      }
    });
  });
};

const checkSignature_Sync = (signature, dataToVerify, isDetached) => {
  const decoded = atob(signature);
  if (decoded.substr(0, 5) === "-----") {
    signature = decoded;
  }
  return q((resolve, reject) => {
    try {
      let isValid;
      let error;
      try {
        const oSignedData = cadesplugin.CreateObject(
          "CAdESCOM.CadesSignedData"
        );
        if (isDetached) {
          oSignedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
          // dataToVerify = dataToVerify.replace(/=/gi, ''); // Not required for new version of CryptoPro Browser Plugin
          oSignedData.Content = dataToVerify;
        }
        oSignedData.VerifyCades(
          signature,
          cadesplugin.CADESCOM_CADES_BES,
          isDetached || false
        );
        isValid = true;
      } catch (ex) {
        isValid = false;
        error = ex;
      }

      if (oSignedData) {
        const signers = oSignedData.Signers;
        const cnt = signers.Count;
        const result = [];
        for (let i = 1; i <= cnt; ++i) {
          const oSigner = signers.Item(i);
          const res = {};
          const c = oSigner.Certificate;
          res.cert = {};
          res.cert.Thumbprint = c.Thumbprint;
          res.cert.SubjectName = c.SubjectName;
          res.cert.IssuerName = c.IssuerName;
          res.cert.SerialNumber = c.SerialNumber;
          res.cert.ValidFromDate = c.ValidFromDate;
          res.cert.ValidToDate = c.ValidToDate;

          res.isValid = oSigner.SignatureStatus.IsValid;
          try {
            res.SigningTime = oSigner.SigningTime;
          } catch (ex) {
            console.log(
              "cert: ",
              res.cert.SerialNumber,
              ", SigningTime error: ",
              ex.message
            );
          }

          res.cert.SubjectNameObj = parseString(res.cert.SubjectName);

          res.cert.SubjectNameArr = [];
          for (const k in res.cert.SubjectNameObj)
            if (res.cert.SubjectNameObj.hasOwnProperty(k)) {
              res.cert.SubjectNameArr.push({
                name: k,
                value: res.cert.SubjectNameObj[k]
              });
            }

          result.push(res);
        }

        resolve({
          isValid,
          error,
          signers: result
        });
      } else {
        reject(getErrorMessage(error));
      }
    } catch (ex) {
      reject(getErrorMessage(ex));
    }
  });
};

const fzSvcCryptoPro = {};
fzSvcCryptoPro.parseString = d => parseString(d);
fzSvcCryptoPro.q = (resolve, reject) => q(resolve, reject);
fzSvcCryptoPro.qify = promise => qify(promise);
fzSvcCryptoPro.loadPluginApi = () => loadPluginApi();
fzSvcCryptoPro.setupPlugin = () => setupPlugin();
fzSvcCryptoPro.getErrorMessage = e => getErrorMessage(e);
fzSvcCryptoPro.getCertList = () => getCertList();
fzSvcCryptoPro.getCertList_Async = () => getCertList_Async();
fzSvcCryptoPro.getCertList_Sync = () => getCertList_Sync();
fzSvcCryptoPro.convertAlgOidToHashAlg = AlgOid =>
  convertAlgOidToHashAlg(AlgOid);
fzSvcCryptoPro.sign = (thumbprint, dataToSign, isDetached) =>
  sign(thumbprint, dataToSign, isDetached);
fzSvcCryptoPro.sign_Async = (thumbprint, dataToSign, isDetached) =>
  sign_Async(thumbprint, dataToSign, isDetached);
fzSvcCryptoPro.sign_Sync = (thumbprint, dataToSign, isDetached) =>
  sign_Sync(thumbprint, dataToSign, isDetached);
fzSvcCryptoPro.signHash = (thumbprint, hash, hashAlg) =>
  signHash(thumbprint, hash, hashAlg);
fzSvcCryptoPro.signHash_Async = (thumbprint, hash, hashAlg) =>
  signHash_Async(thumbprint, hash, hashAlg);
fzSvcCryptoPro.signHash_Sync = (thumbprint, hash, hashAlg) =>
  signHash_Sync(thumbprint, hash, hashAlg);
fzSvcCryptoPro.coSign = (thumbprint, signature) =>
  coSign(thumbprint, signature);
fzSvcCryptoPro.coSign_Async = (thumbprint, signature) =>
  coSign_Async(thumbprint, signature);
fzSvcCryptoPro.coSign_Sync = (thumbprint, signature) =>
  coSign_Sync(thumbprint, signature);
fzSvcCryptoPro.checkSignature = (signature, dataToVerify, isDetached) =>
  checkSignature(signature, dataToVerify, isDetached);
fzSvcCryptoPro.checkSignature_Async = (signature, dataToVerify, isDetached) =>
  checkSignature_Async(signature, dataToVerify, isDetached);
fzSvcCryptoPro.checkSignature_Sync = (signature, dataToVerify, isDetached) =>
  checkSignature_Sync(signature, dataToVerify, isDetached);

export default fzSvcCryptoPro;
