import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { RootState } from "@store/store";

const initialState = {
  isMultilot: false,
  isCooperative: false,
  isShowPurchaseFields: false,
};

const purchaseSlice = createSlice({
  name: "purchaseSlice",
  initialState,
  reducers: {
    setMultilot(state) {
      if (state.isMultilot) {
        notification.error({
          message: `${!state.isMultilot ? "Включены" : "Выключены"} мультилотовые закупки`,
        });
      } else {
        notification.success({
          message: `${!state.isMultilot ? "Включены" : "Выключены"} мультилотовые закупки`,
        });
      }

      state.isMultilot = !state.isMultilot;
    },
    setCooperative(state) {
      if (state.isCooperative) {
        notification.error({
          message: `${!state.isCooperative ? "Включены" : "Выключены"} многобенефициарные закупки`,
        });
      } else {
        notification.success({
          message: `${!state.isCooperative ? "Включены" : "Выключены"} многобенефициарные закупки`,
        });
      }

      state.isCooperative = !state.isCooperative;
    },
    setShowPurchaseFields(state, action) {
      state.isShowPurchaseFields = action.payload;
    },
  },
});

export const { setMultilot, setCooperative, setShowPurchaseFields } = purchaseSlice.actions;
export const isMultilot = (state: RootState) => state.carrot.purchase.isMultilot;
export const isCooperative = (state: RootState) => state.carrot.purchase.isCooperative;
export const isShowPurchase = (state: RootState) => state.carrot.purchase.isShowPurchaseFields;

export default purchaseSlice.reducer;
