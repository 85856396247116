import { Result } from "antd";
import React from "react";
import { Header } from "./Header";

export const TaskError = () => {
  return (
    <>
      <Header />
      <div className="container bg-white complete-container">
        <Result status={500} title="Что-то пошло не так, возможно ссылка устарела :(" />
      </div>
    </>
  );
};
