import React, { useEffect, useRef, useState } from "react";
import { Buffer } from "buffer";
import { Button, Modal, Select } from "antd";
import fzSvcCryptoPro from "./fzSvcCryptoPro";
import { Icons, Status, Success } from "./Layout";
import { cn } from "@/_custom/utils/bemConfig";
import "./Sign.scss";

import { showNotification } from "@farzoom/common-ui-components";
import { DocumentController } from "@/services/api/controllers";
import { checkSigned } from "../../../utils/fileSignUtils";
import { useExternalTask } from "@hooks/useExternalTask";

const bem = cn("sign");

const removeType = (file) => {
  delete file?.type;
  return file;
};

export const Sign = ({
  config,
  value,
  update,
  isMass = false, // если массовое подписание
  isTest = false, // если нужны мок сертификаты
  isDownload = false, // Формирование тестовых файлов при создании подписи
  isSuccess, // Все файлы подписаны
  needCoSign = false,
  userInfo,
  showSignBtn = true,
  btnText = "Подписать",
  taskFormSignUpdate,
}) => {
  const [list, setList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [thumbprint, setThumbprint] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [filesToSignQueue, setFilesToSignQueue] = useState([]);
  const signRef = useRef();

  const { token } = useExternalTask();

  const currentUserCompanyInn = userInfo?.company?.inn;

  const files = isMass
    ? Object.keys(value).reduce(
        (result, item) => [
          ...result,
          ...(value[item]?.files || []).map((file) => ({
            ...file,
            type: value[item]?.docType,
            needCoSign: value[item]?.needCoSign, //для массового подписания на задаче
          })),
        ],
        []
      )
    : value?.files || [];

  useEffect(() => {
    if (filesToSignQueue.length > 0) {
      const { fileId, fileName, type } = filesToSignQueue[0];
      const fileIndex = files.findIndex((item) => item?.fileId === fileId);
      const onSuccess = () => {
        const newQueue = [...filesToSignQueue];
        newQueue.shift();
        setFilesToSignQueue(newQueue);
      };
      onSign({ fileId, fileName, type, fileIndex, onSuccess });
    }
  }, [filesToSignQueue, filesToSignQueue.length]);

  const onHideList = () => setShowList(false);
  const onShowList = () => setShowList(true);

  const onShowLoading = () => setLoading(true);
  const onHideLoading = () => setLoading(false);

  const onChangeThumbprint = (value) => setThumbprint(value);

  const onError = (error) => {
    const notifySettings = {
      type: "error",
      message: error,
      duration: 10,
    };
    if (error === "Плагин недоступен") {
      notifySettings.duration += 10;
      notifySettings.message += `. Для перехода на страницу плагина, нажмите на уведомление.`;
      notifySettings.onClick = () =>
        window.open(
          "https://www.cryptopro.ru/products/cades/plugin",
          "_blank",
          "noopener,noreferrer"
        );
    }
    showNotification(notifySettings);
  };

  const onShowCertList = () => {
    onShowLoading();
    onChangeThumbprint("");
    if (isTest) {
      if (!fzSvcCryptoPro?.org) {
        fzSvcCryptoPro.org = {
          getCertList: fzSvcCryptoPro.getCertList,
          checkSignature: fzSvcCryptoPro.checkSignature,
          sign: fzSvcCryptoPro.sign,
          coSign: fzSvcCryptoPro.coSign,
        };
      }
      fzSvcCryptoPro.getCertList = () =>
        Promise.all([]).then(() => [
          {
            ValidFromDate: "2018-01-01T00:00:00.000Z",
            ValidToDate: "2028-01-01T00:00:00.000Z",
            SubjectName: "Иванов Иван Иванович",
            Thumbprint: "QWERTYUIOP",
          },
          {
            ValidFromDate: "2019-01-01T00:00:00.000Z",
            ValidToDate: "2029-01-01T00:00:00.000Z",
            SubjectName: "Петров Пётр Петрович",
            Thumbprint: "ASDFGHJKL",
          },
          {
            ValidFromDate: "2020-01-01T00:00:00.000Z",
            ValidToDate: "2030-01-01T00:00:00.000Z",
            SubjectName: "Матвеев Матвей Матвеевич",
            Thumbprint: "ZXCVBNM",
          },
        ]);
      fzSvcCryptoPro.checkSignature = () =>
        Promise.all([]).then(() => ({
          isValid: true,
          signers: [],
        }));
      fzSvcCryptoPro.sign = () => Promise.all([]).then(() => "c2FtcGxl==");
      fzSvcCryptoPro.coSign = () => Promise.all([]).then(() => "c2FtcGxl==");
    } else if (fzSvcCryptoPro?.org) {
      fzSvcCryptoPro.getCertList = fzSvcCryptoPro.org.getCertList;
      fzSvcCryptoPro.checkSignature = fzSvcCryptoPro.org.checkSignature;
      fzSvcCryptoPro.sign = fzSvcCryptoPro.org.sign;
      fzSvcCryptoPro.coSign = fzSvcCryptoPro.org.coSign;
    }
    fzSvcCryptoPro
      .getCertList()
      .then((res) => {
        onShowList();
        setList(res);
        onHideLoading();
      })
      .catch((error) => {
        onError(error);
        onHideLoading();
        onHideList();
      });
  };

  const base64ToFile = (base64, filename) => {
    const base64norm = base64.replace(/=/g, "").replace(/\n/g, "");
    const buffer = Buffer.from(base64norm, "base64");
    return new File([buffer], filename, {
      type: "application/octet-stream",
    });
  };

  const arrayBufferToString = (buffer) =>
    new Uint8Array(buffer).reduce((result, byte) => result + String.fromCharCode(byte), "");

  const onRunQueueToSign = () =>
    setFilesToSignQueue([
      ...files.filter(
        (file) => !checkSigned(file, needCoSign || file.needCoSign, currentUserCompanyInn)
      ),
    ]);

  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const onSign = ({ fileId, fileName, type, fileIndex, onSuccess }) => {
    console.log(`Signing file. fileId=[${fileId}] fileName=[${fileName}]`);
    if (!thumbprint) {
      showNotification({
        type: "warning",
        message: `Необходимо выбрать сертификат для подписания`,
        duration: 10,
      });
      return false;
    }
    onShowLoading();
    setStatus("PREPARING");

    const downloadPromise = DocumentController.downloadBuffer(fileId, token);

    downloadPromise
      .then((response) => {
        if (isDownload) {
          saveData(response.data, `Неподписанный_с_бэка_${fileName}`);
          saveData(
            window.btoa(arrayBufferToString(response.data)),
            `Неподписанный_с_бэка_в_base64_${fileName}`
          );
        }
        return window.btoa(arrayBufferToString(response.data));
      })
      .then(async (base64Data) => {
        setStatus("SIGNING");
        if (fileName.toLowerCase().endsWith(".sig")) {
          return fzSvcCryptoPro.coSign(thumbprint, base64Data);
        } else {
          return fzSvcCryptoPro.sign(thumbprint, base64Data, false);
        }
      })
      .then((base64) => {
        if (isDownload) {
          saveData(base64, `Что_вернулось_с_плагина_${fileName}.sig`);
          const b = document.createElement("a");
          document.body.appendChild(b);
          b.style = "display: none";
          const testUrl = window.URL.createObjectURL(
            base64ToFile(base64, `Декодировка_из_base64_${fileName}.sig`)
          );
          b.href = testUrl;
          b.download = `Подписанный_декодированный_из_base64_${fileName}.sig`;
          b.click();
          window.URL.revokeObjectURL(testUrl);
        }

        const file = new FormData();
        const uploadFilename = fileName.toLowerCase().endsWith(".sig")
          ? fileName
          : `${fileName}.sig`;
        file.append("file", base64ToFile(base64, uploadFilename));
        return file;
      })
      .then((file) => {
        setStatus("UPLOADING");

        return DocumentController.upload(file, token);
      })
      .then(({ data }) => {
        if (isMass) {
          update(type)({
            ...value[type],
            files: [
              ...files
                .filter((file, idx) => idx !== fileIndex && file.type === type)
                .map(removeType),
              {
                ...data,
                isSignedOnThisTask: true,
              },
            ],
          });
        } else {
          const updatedDocument = {
            ...value,
            files: [
              ...files.filter((_, idx) => idx !== fileIndex),
              {
                ...data,
                isSignedOnThisTask: true,
              },
            ],
          };
          update(updatedDocument);

          taskFormSignUpdate(updatedDocument.docType, { files: updatedDocument.files });
        }

        setStatus(null);
        onHideLoading();
        onHideList();
        onSuccess();
      })
      .catch((error) => {
        setStatus(null);
        onHideLoading();
        onHideList();
        onError(error);
      });
  };

  const canSign = config?.sign && files?.length > 0;
  const showEdit = !loading && !showList;
  const showMainAction = showList && !loading;
  const showStatus = loading;
  const showSuccess = isSuccess && !showList && !loading;

  return (
    <>
      <div className={bem()} ref={signRef}>
        {showSignBtn && (
          <>
            {!showSuccess && showEdit && (
              <Button
                className={bem("edit", { hidden: !canSign, isMass: isMass })}
                title="Подписать"
                onClick={() => canSign && onShowCertList()}
                loading={showStatus}
                type={isMass ? "primary" : "default"}
              >
                <Icons.Edit />
                {btnText}
              </Button>
            )}
            {showStatus && <Status status={status} />}
            {showMainAction && (
              <Modal
                visible={true}
                onOk={onRunQueueToSign}
                onCancel={onHideList}
                title={`Выберите сертификат для${isMass ? " массового" : ""} подписания`}
                okText="Подписать выбранным сертификатом"
                cancelText="Назад"
              >
                <Select
                  className={bem("select")}
                  size="large"
                  defaultValue=""
                  onChange={(value) => onChangeThumbprint(value)}
                  getPopupContainer={() => document.body}
                >
                  <Select.Option value="" disabled />
                  {list.map(({ Thumbprint, SubjectName, ValidFromDate, ValidToDate }, idx) => (
                    <Select.Option
                      value={Thumbprint}
                      key={idx}
                      title={`${SubjectName} действует c ${new Date(
                        ValidFromDate
                      ).toLocaleDateString()} по ${new Date(ValidToDate).toLocaleDateString()}`}
                    >
                      {SubjectName}, действует c {new Date(ValidFromDate).toLocaleDateString()} по{" "}
                      {new Date(ValidToDate).toLocaleDateString()}
                    </Select.Option>
                  ))}
                </Select>
              </Modal>
            )}
          </>
        )}
        {showSuccess && <Success className={bem("success")} title="Документ подписан" />}
      </div>
    </>
  );
};
