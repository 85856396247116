import { axiosFrontAgent } from "../axiosInstance";

export const OrderController = Object.freeze({
  getOrders: (params) => axiosFrontAgent.get(`/order`, { params }),
  getOrder: (orderId) => axiosFrontAgent.get(`/order/${orderId}`),
  getConfig: (orderId) => axiosFrontAgent.get(`/card/order/${orderId}/config`),
  getModel: (orderId) => axiosFrontAgent.get(`/card/order/${orderId}/model`),
  updateModel: (orderId, components) =>
    axiosFrontAgent.post(`/card/order/${orderId}/model`, { components }),
});
