import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormSelect } from "@farzoom/common-ui-components";
import { DocumentsBlock } from "../../../_custom/components/Blocks/DocumentsBlock";
import { StartFormWrapper } from "../StartFormWrapper";
import { completeStartForm } from "@store/formsSlice";
import { useStartForm } from "../hooks/useStartForm";
import { useDispatch, useSelector } from "react-redux";
import { suppliesState } from "@/store/supplies/suppliesSlice";

const SuppliesImportStartForm = ({ id }) => {
  const dispatch = useDispatch();
  const { control, setValue, register, handleSubmit } = useForm();
  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Заявка отправлена",
  });
  const { supplyConfig } = useSelector(suppliesState);
  const [factorsToSelect, setFactorsToSelect] = useState([]);

  useEffect(() => {
    const factors = supplyConfig.factors?.map((factor) => ({
      key: factor.factorCompanyId,
      value: factor.factorName,
    }));

    setFactorsToSelect(() => [{ key: null, value: "Все факторы" }, ...factors]);
  }, []);

  const onSubmit = handleSubmit((data) => {
    setIsFetching(true);
    const payload = {
      factorCompanyId: data.factorCompanyId || null,
      files: data.docs[0].files,
    };

    dispatch(
      completeStartForm({
        id,
        payload,
        onSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      id={id}
      title="Импорт реестра"
      formName="creditStartForm"
      onClose={onClose}
      onSubmit={onSubmit}
      isFetching={isFetching}
    >
      <DocumentsBlock
        config={{
          types: [
            {
              load: true,
              sign: false,
              title: "Реестр поставок",
              remove: true,
              docType: "documentRegisterDeliveries",
              multiple: false,
              required: true,
              entityType: "expertise",
            },
          ],
          visible: true,
        }}
        setValue={setValue}
        prefix="docs"
        control={control}
      />
      <FormSelect
        name="factorCompanyId"
        label="Кто может профинансировать поставки:"
        setValue={setValue}
        register={register}
        control={control}
        defaultValue={null}
        options={factorsToSelect}
      />
    </StartFormWrapper>
  );
};

export default SuppliesImportStartForm;
