import React, { createContext, ReactNode, useEffect } from "react";
import { CommonComponentsLibraryProvider, UserInfo } from "@farzoom/common-ui-components";
import {
  CompanyInfoController,
  DocumentController,
  OrderController,
  SearchController,
} from "@/services/api/controllers";
import { ConfigProvider as AntdConfigProvider } from "antd";
import themeConfig from "../theme";

type ProvidersProps = {
  children: ReactNode;
  userInfo?: UserInfo;
};

AntdConfigProvider.config(themeConfig);

export type ControllersContextType = typeof ControllersContext;
export const controllers = {
  DocumentController,
  SearchController,
  CompanyInfoController,
  OrderController,
  apiPrefix: "front-agent",
};
export const ControllersContext = createContext(controllers);
// @ts-expect-error - b
export const UserInfoContext = createContext(window?.shellSharedContext?.userInfo || {});

const CarrotProvider = ({ children, userInfo }: ProvidersProps) => {
  useEffect(() => {
    if (window.shellSharedContext) {
      window.shellSharedContext.ControllersContext = ControllersContext;
    } else {
      window.shellSharedContext = { ControllersContext: ControllersContext };
    }
  }, []);

  return (
    <CommonComponentsLibraryProvider value={controllers}>
      <UserInfoContext.Provider
        // @ts-expect-error - b
        value={window?.shellSharedContext?.userInfo || userInfo}
      >
        {children}
      </UserInfoContext.Provider>
    </CommonComponentsLibraryProvider>
  );
};

export default CarrotProvider;
