import { ReactNode } from "react";
import { Space } from "antd";

type Props = {
  children: ReactNode;
  size?: "small" | "middle" | "large" | number;
  className?: string;
};

export const VerticalSpace = ({ children, size = "middle", className = "" }: Props) => (
  <Space direction="vertical" size={size} style={{ display: "flex" }} className={className}>
    {children}
  </Space>
);
