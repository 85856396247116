import { Space, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { colors } from "@farzoom/common-ui-components";
import { useCustomerInfo } from "@components/BankGuarantee/customerShortCard/hooks/useCustomerInfo";
import { CustomerTitle } from "@components/BankGuarantee/customerShortCard/customerTitle";
import { CustomerInfoModal } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/customerInfoModal";

const { Text } = Typography;

export type CustomerShortCardProps = {
  customer: {
    customerId: string;
    displayName: string;
    inn: string;
    ogrn: string;
    kpp: string;
  };
  isOnForm?: boolean;
  showLink?: boolean;
};

type StylesPropsType = {
  isOnForm?: boolean;
};

export const useStyles = createUseStyles({
  customer: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: (props: StylesPropsType) => (props.isOnForm ? colors.cardBackground : "white"),
    transition: "box-shadow .35s ease",
    "&:hover": {
      boxShadow: (props: StylesPropsType) =>
        !props.isOnForm ? "0px 5px 10px 2px rgba(34, 60, 80, 0.2)" : "none",
      "& $titleLink": {
        opacity: 1,
      },
    },
  },
  label: {
    display: "block",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& h4": {
      margin: 0,
    },
  },
  titleLink: {
    fontSize: 16,
    fontWeight: 500,
    display: "inline-flex",
    gap: 8,
    transition: "opacity .35s ease, color .3s ease !important",
    opacity: (props: StylesPropsType) => (props.isOnForm ? 1 : 0),
    "&:hover": {
      color: "var(--ant-primary-color-active) !important",
      "& $linkIcon": {
        rotate: "90deg",
      },
    },
  },
  linkIcon: {
    display: "inline-block",
    rotate: "45deg",
    transformOrigin: "center",
    transition: "rotate .2s ease",
    marginTop: 2,
  },
});

const copyableConfig = {
  tooltips: false,
};

export const CustomerShortCard = ({
  customer,
  isOnForm,
  showLink = true,
}: CustomerShortCardProps) => {
  const classes = useStyles({ isOnForm });
  const { handleOpenCustomerInfo, isModalOpen, setIsModalOpen } = useCustomerInfo({
    customerId: customer.customerId,
    isOnForm,
  });

  const onCustomerInfoClose = () => setIsModalOpen(false);

  return (
    <>
      <div className={classes.customer}>
        <CustomerTitle
          title={customer.displayName}
          showLink={showLink}
          isOnForm={isOnForm}
          onClick={handleOpenCustomerInfo}
        />
        <Space>
          <span>
            <Text type="secondary" className={classes.label}>
              ИНН
            </Text>
            <Text copyable={copyableConfig}>{customer.inn}</Text>
          </span>

          <span>
            <Text type="secondary" className={classes.label}>
              {customer.kpp ? "ОГРН" : "ОГРНИП"}
            </Text>
            <Text copyable={copyableConfig}>{customer.ogrn}</Text>
          </span>
          {customer.kpp && (
            <span>
              <Text type="secondary" className={classes.label}>
                КПП
              </Text>
              <Text copyable={copyableConfig}>{customer.kpp}</Text>
            </span>
          )}
        </Space>
      </div>

      {isModalOpen && (
        <CustomerInfoModal customerId={customer.customerId} onClose={onCustomerInfoClose} />
      )}
    </>
  );
};
