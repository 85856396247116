import React, { useRef, useState } from "react";
import classnames from "classnames";
import { Overlay, useOutsideClick, UserInfo } from "@farzoom/common-ui-components";
import { openModal } from "@/store/modalSlice";
import Icon from "../../layout/Icons";
import { ModalType } from "@/services/constants/ModalType";
import { processes } from "@/store/userSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { useStyles } from "./styles";

const PROCESSES = {
  "carrot-cpa-import-supplies": ModalType.suppliesImportStartForm,
  "carrot-cpa-supplier-accession-factor": ModalType.supplierAccessionStartForm,
  "carrot-cpa-supply-verification": ModalType.supplyVerificationStartForm,
  "carrot-cpa-bg-request": ModalType.bankGuaranteeRequest,
  "carrot-cpa-bg-agent": ModalType.bankGuaranteeRequestAgent,
};

type StartFormButtonProps = {
  userInfo?: UserInfo;
};

export const StartFormButton = ({ userInfo }: StartFormButtonProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const startFormButtonRef = useRef(null);

  const [open, setOpen] = useState(false);

  const stateProcesses = useAppSelector(processes);
  const availableProcesses = stateProcesses.length ? stateProcesses : userInfo?.avaliableProcesses;

  const onToggleMenu = () => setOpen((open) => !open);

  const onClose = () => setOpen(false);

  useOutsideClick(startFormButtonRef, onClose);

  const onSelectProcess = ({ processKey }) => {
    dispatch(
      openModal({
        type: PROCESSES[processKey],
        config: processKey,
        closePath: "",
      })
    );
    onClose();
  };

  if (!availableProcesses?.length) return null;

  return (
    <>
      {open && <Overlay className={classes.overlay} />}

      <div className={classes.startFormButton} ref={startFormButtonRef}>
        <div className={classnames(classes.button, { open })} onClick={onToggleMenu} />
        {open && (
          <div className={classes.list}>
            {availableProcesses.map((process) => (
              <div
                key={process?.processKey}
                className={classes.item}
                onClick={() => onSelectProcess(process)}
              >
                <Icon className={classes.icon} iconName="process" />
                <span>{process?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
