import { Button, colors, Modal } from "@farzoom/common-ui-components";
import { useStyles } from "./styles";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { getBgCard, saveBgCard } from "@/services/api/bankGuarantee/guarantee";
import { GuaranteeFormWrapper } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { BgCardModelType } from "@/services/api/controllers/bankGuarantee/GuaranteeController";
import { notification } from "antd";
import { useAppSelector } from "@store/store";
import { isModalLoading } from "@store/modalSlice";

export const BgModal = ({ onClose, onSave, ...rest }) => {
  const classes = useStyles();
  const { prefix, getValues, config } = rest;
  const isModalFetching = useAppSelector(isModalLoading);

  const methods = useForm<BgCardModelType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prfx, setPrfx] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      const data = await getBgCard(getValues(prefix).id);

      methods.reset(data);
      setPrfx(Object.keys(data)[0]);
      setIsLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length) {
      notification.error({
        message: "Ошибка отправки формы.",
        description: "Проверьте заполненные поля",
      });
    }
  }, [methods.formState.isSubmitted]);

  const isSubmitDisabled = !!Object.keys(methods.formState.errors).length;

  const onSubmit = methods.handleSubmit(async (data) => {
    if (isSubmitDisabled) return;
    setIsLoading(true);

    try {
      await saveBgCard(data, getValues(prefix).id);
      onSave({
        purchaseNumber: data[prfx].purchase.purchaseNumber,
        guaranteeType: data[prfx].guarantee.type,
      });

      notification.success({ message: "Данные о гарантии успешно сохранены" });
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Что-то пошло не так :(",
        description: "Данные о гарантии не обновлены",
      });
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Modal
      open={true}
      color={colors.ruby1}
      onClose={onClose}
      closePath={false}
      isFetching={isLoading || isModalFetching}
      fullHeight
    >
      <div className={classes.bgModalContainer}>
        {!!prfx && (
          <>
            <FormProvider {...methods}>
              <GuaranteeFormWrapper
                config={{ readonly: false, title: "", canEditPurchase: false }}
                prefix={prfx}
              />
            </FormProvider>
            {(!config.readonly || config.modalEdit) && (
              <div className={classes.btnWrapper}>
                <Button
                  text="Сохранить"
                  type="primary"
                  onClick={onSubmit}
                  disabled={isSubmitDisabled}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
