import { axiosInstanceBG } from "@/services/api/axiosInstance";
import { CustomerFormModel, CustomerShort } from "@/types/Customer";
import { UpdateCustomer } from "@/services/api/bankGuarantee/customers";
import { FormConfig } from "@/types/FormConfig";

export const CustomerController = Object.freeze({
  getCustomers: () => axiosInstanceBG.get<CustomerShort[]>(`/customer`),
  createCustomer: (data: { inn: string }) => axiosInstanceBG.post<CustomerShort>("/customer", data),
  updateCustomer: ({ orderId, components }: UpdateCustomer) =>
    axiosInstanceBG.post(`/card/customer/${orderId}/model`, { components }),
  getConfig: (companyId: string) =>
    axiosInstanceBG.get<FormConfig>(`/card/customer/${companyId}/config`),
  getModel: (companyId: string) =>
    axiosInstanceBG.get<CustomerFormModel>(`/card/customer/${companyId}/model`),
  searchPerson: (q: string) => axiosInstanceBG.get(`/person?q=${q}`),
});
