import React from "react";
import { useStyles } from "@components/StartFormButton/styles";
import { useAppDispatch } from "@store/store";
import { openModal } from "@store/modalSlice";
import { ModalType } from "@/services/constants/ModalType";

export const CreateCustomerButton = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleOpenCreateCustomer = () => {
    dispatch(
      openModal({
        type: ModalType.createCustomerForm,
        config: {},
        closePath: "/customers",
      })
    );
  };

  return (
    <div className={classes.startFormButton} onClick={handleOpenCreateCustomer}>
      <div className={classes.button} />
    </div>
  );
};
