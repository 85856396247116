import React from "react";
import { useForm } from "react-hook-form";
import {
  FormInputMoney,
  FormTextArea,
  Modal,
  showNotification,
} from "@farzoom/common-ui-components";
import { DocumentsBlock } from "@/_custom/components/Blocks/DocumentsBlock";
import { completeStartForm } from "@store/formsSlice";
import { useDispatch } from "react-redux";
import { useStartForm } from "@/lib/modals/hooks/useStartForm";
import { StartFormWrapper } from "@/lib/modals/StartFormWrapper";
import { Col, Row } from "antd";

type ModalProps = {
  id: string;
  orderId: string;
  onClose: () => void;
};

const CommissionChangeModal = ({ id, orderId, onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();
  const { isFetching, setIsFetching, onError } = useStartForm({ dispatch });

  const onSuccess = () => {
    showNotification({ type: "success", message: "Заявка отправлена" });
    setIsFetching(false);
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    setIsFetching(true);
    const payload = {
      bankOrderId: orderId,
      requestedCommissionAmount: data.requestedCommissionAmount,
      commissionChangeReason: data.commissionChangeReason || null,
      files: data.docs[0]?.files || null,
    };

    dispatch(
      completeStartForm({
        id,
        payload,
        onSuccess,
        onError,
      })
    );
  });

  return (
    <Modal
      isFetching={isFetching}
      withoutScroll={true}
      fullHeight={true}
      onClose={onClose}
      closePath={false}
    >
      <StartFormWrapper
        title="Запрос изменения комиссии"
        formName="commissionChangeRequest"
        onClose={onClose}
        onSubmit={onSubmit}
        isFetching={isFetching}
      >
        <Row>
          <Col span={24}>
            <FormInputMoney
              name="requestedCommissionAmount"
              control={control}
              label="Запрашиваемая сумма комиссии, руб."
              controls={false}
              required
            />
          </Col>
          <Col span={24}>
            <FormTextArea
              name="commissionChangeReason"
              control={control}
              label="Обоснование изменения комиссии"
            />
          </Col>
          <Col span={24}>
            <DocumentsBlock
              prefix="docs"
              control={control}
              config={{
                types: [
                  {
                    load: true,
                    sign: false,
                    title: "Обоснование понижения комиссии",
                    remove: true,
                    docType: "documentRegisterDeliveries",
                    multiple: false,
                    required: false,
                    entityType: "expertise",
                  },
                ],
                visible: true,
              }}
            />
          </Col>
        </Row>
      </StartFormWrapper>
    </Modal>
  );
};

export default CommissionChangeModal;
