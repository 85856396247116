import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";
import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import { Checkbox, Col, Divider, Empty, Row, Space, Typography } from "antd";
import {
  Button,
  ERROR_MESSAGES,
  FormInput,
  FormInputMoney,
  MASKS,
  PATTERNS,
} from "@farzoom/common-ui-components";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { BankSearch } from "@/_custom/components/Common/BankSearch";
import useScreenSize from "@hooks/useScreenSize";

type CustomerBankAccounts = CustomerFormBaseProps;

const { Text } = Typography;

const SPACE_SIZE = 6;

const ACCOUNT_MODEL = {
  accountNumber: "",
  bank: { name: "", bic: "", corrNumber: "" },
  cardFile2: false,
  cardFile2Amount: 0,
};

export const CustomerBankAccounts = ({
  prefix,
  config,
  setValue,
  control,
  watch,
  register,
  getValues,
}: CustomerBankAccounts) => {
  const classes = useStyles();
  const {
    fields: accounts,
    prepend,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.bankAccounts` as "customerBankAccounts.bankAccounts",
  });
  const screenSize = useScreenSize();

  const initialValues = getValues(`${prefix}.bankAccounts` as "customerBankAccounts.bankAccounts");
  const bankAccounts = watch(`${prefix}.bankAccounts` as "customerBankAccounts.bankAccounts");

  const onAddAccount = () => {
    prepend(ACCOUNT_MODEL);
  };

  const onCardFile2Change = (idx: number) => {
    return (e: CheckboxChangeEvent) => {
      setValue(`${prefix}.bankAccounts.${idx}.cardFile2`, e.target.checked);
      if (!e.target.checked) {
        setValue(`${prefix}.bankAccounts.${idx}.cardFile2Amount`, 0);
      }
    };
  };

  return (
    <fieldset disabled={config.readonly}>
      <div className={classNames(classes.title, classes.titleWithAction)}>
        <span>{config.title}</span>
        {!config.readonly && (
          <Button type="outlined" iconName="add" title="Добавить счет" onClick={onAddAccount} />
        )}
      </div>
      <div className={classes.wrapper}>
        {accounts.length === 0 && (
          <Empty description="Банковские счета отсутствуют">
            <Button className={classes.emptyAddBtn} text="Добавить" onClick={onAddAccount} />
          </Empty>
        )}
        {accounts.map((account, idx) => (
          <>
            <Row key={account.id + idx} align="top" gutter={8}>
              <Col span={10}>
                <VerticalSpace size={SPACE_SIZE}>
                  <BankSearch
                    label="Банк"
                    className={classes.formItem}
                    prefix={`${prefix}.bankAccounts.${idx}.bank`}
                    setValue={setValue}
                    control={control}
                    getVisibleFieldName={(prfx) => `${prfx}.name`}
                    hiddenFieldNames={[
                      { target: "bic", source: (opt) => opt?.data?.bic },
                      { target: "corrNumber", source: (opt) => opt?.data?.correspondent_account },
                    ]}
                    type="name"
                    required
                    controlled
                  />
                  <Space direction={screenSize.l ? "horizontal" : "vertical"}>
                    <Text type="secondary" className={classes.formItemInfo}>
                      БИК: {bankAccounts[idx]?.bank?.bic}
                    </Text>
                    <Text type="secondary" className={classes.formItemInfo}>
                      к/с: {bankAccounts[idx]?.bank?.corrNumber}
                    </Text>
                  </Space>
                </VerticalSpace>
              </Col>
              <Col span={7}>
                <VerticalSpace size={SPACE_SIZE}>
                  <FormInput
                    label="Номер р/с"
                    className={classes.formItem}
                    name={`${prefix}.bankAccounts.${idx}.accountNumber`}
                    control={control}
                    setValue={setValue}
                    defaultValue={initialValues[idx]?.accountNumber}
                    mask={MASKS.bankAccount}
                    pattern={{
                      value: PATTERNS.bankAccount,
                      message: ERROR_MESSAGES.bankAccount,
                    }}
                    required
                    disabled={config.readonly}
                  />
                  <Checkbox
                    {...register(`${prefix}.bankAccounts.${idx}.cardFile2`)}
                    onChange={onCardFile2Change(idx)}
                    checked={getValues(`${prefix}.bankAccounts.${idx}.cardFile2`)}
                  >
                    Картотека №2
                  </Checkbox>
                </VerticalSpace>
              </Col>
              <Col span={screenSize.l ? 6 : 7}>
                <FormInputMoney
                  name={`${prefix}.bankAccounts.${idx}.cardFile2Amount`}
                  label="Сумма задолженности"
                  className={classes.formItem}
                  setValue={setValue}
                  control={control}
                  defaultValue={initialValues[idx]?.cardFile2Amount}
                  controls={false}
                  disabled={!bankAccounts[idx].cardFile2 || config.readonly}
                  maxLength={25}
                />
              </Col>
              {screenSize.l && (
                <Col span={1} className={classes.flexCenterCol}>
                  {!config.readonly && (
                    <Button
                      type="outlined"
                      iconName="remove"
                      onClick={() => remove(idx)}
                      title="Удалить счет"
                    />
                  )}
                </Col>
              )}
            </Row>
            {!screenSize.l && (
              <Button
                className={classes.removeBtn}
                type="primary"
                onClick={() => remove(idx)}
                text="Удалить счет"
              />
            )}
            {!screenSize.l && accounts.length > 1 && <Divider />}
          </>
        ))}
      </div>
    </fieldset>
  );
};
