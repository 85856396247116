import { useEffect, useState } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const breakpoints = {
        1366: 1366,
        xl: 1200,
        l: 992,
        m: 768,
        s: 576,
      };

      let size = "";

      switch (true) {
        case width >= breakpoints["1366"]:
          size = "1366";
          break;
        case width >= breakpoints.xl:
          size = "xl";
          break;
        case width >= breakpoints.l:
          size = "l";
          break;
        case width >= breakpoints.m:
          size = "m";
          break;
        case width >= breakpoints.s:
          size = "s";
          break;
        default:
          size = "xs";
      }
      // if (width >= breakpoints.xl) {
      //   size = "xl";
      // } else if (width >= breakpoints.l) {
      //   size = "l";
      // } else if (width >= breakpoints.m) {
      //   size = "m";
      // } else if (width >= breakpoints.s) {
      //   size = "s";
      // } else {
      //   size = "xs";
      // }

      setScreenSize({
        width,
        1366: size === "1366",
        xl: size === "1366" || size === "xl",
        l: size === "1366" || size === "xl" || size === "l",
        m: size === "1366" || size === "xl" || size === "l" || size === "m",
        s: size === "1366" || size === "xl" || size === "l" || size === "m" || size === "s",
        xs:
          size === "1366" ||
          size === "xl" ||
          size === "l" ||
          size === "m" ||
          size === "s" ||
          size === "xs",
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
};

export default useScreenSize;
