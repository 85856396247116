import axios from "axios";
import { SERVER } from "../../services/constants";
import { useEffect } from "react";
import { useState } from "react";

export const useExternalDataTaskConfig = (token) => {
  const [configData, setConfigData] = useState(null);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER.HOST}${SERVER.API_ENDPOINT}/ext-task/public/task/config?token=${token}`
        );
        console.log("response", response);
        setConfigData(response.data);
        setStatus(response.status);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [token]);

  return { configData, status, error, loading };
};
