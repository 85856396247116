import { CustomerShortCard } from "./index";
import { useStyles } from "./styles";
import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";

type Props = CustomerFormBaseProps;

export const CustomerShortCardFormWrapper = ({ config, getValues }: Props) => {
  const classes = useStyles();

  const customer = getValues("customerCard") || getValues("principal");

  return (
    <div>
      <div className={classes.title}>{config.title}</div>
      <CustomerShortCard customer={customer} isOnForm showLink={config.showLink as boolean} />
    </div>
  );
};
