import { CustomerPerson, CustomerPersonRole, PersonsType } from "@/types/Customer";

export const PERSON_INFO_MODEL: CustomerPerson = {
  inn: null,
  snils: null,
  displayName: null,
  lastName: null,
  firstName: null,
  secondName: null,
  birthDate: null,
  birthPlace: null,
  registrationAddress: null,
  actualAddress: null,
  gender: null,
  identityDocument: {
    type: null,
    series: null,
    number: null,
    issuedBy: null,
    issuedByCode: null,
    issuedDate: null,
    expiryDate: null,
  },
  citizenship: null,
  countryOfResidence: null,
  ipdl: {
    pdlPosition: null,
    pdlOrganization: null,
    pdlOrganizationAddress: null,
    pdlRelation: null,
    isPdl: false,
  },
  mpdl: {
    pdlPosition: null,
    pdlOrganization: null,
    pdlOrganizationAddress: null,
    pdlRelation: null,
    isPdl: false,
  },
  rpdl: {
    pdlPosition: null,
    pdlOrganization: null,
    pdlOrganizationAddress: null,
    pdlRelation: null,
    isPdl: false,
  },
};
export const PERSON_ROLE_MODEL: CustomerPersonRole = {
  isHead: false,
  isSigner: false,
  isFounder: false,
  isBeneficiaryOwner: false,
  isSupervisoryBoardMember: false,
  isCollegialExecutiveBodyMember: false,
  employee: {
    position: null,
    positionDateStart: null,
    positionDateEnd: null,
    authorizingDoc: {
      authorizingDocName: null,
      authorizingDocNumber: null,
      authorizingDocDate: null,
    },
    byProxy: false,
    proxy: {
      proxyName: null,
      proxyDateStart: null,
      proxyDateEnd: null,
    },
  },
  founder: {
    sharePercent: null,
    shareAmount: null,
  },
  beneficiaryOwner: {
    reason: null,
    sharePercent: null,
    shareAmount: null,
  },
};

export const PERSON_MODEL: PersonsType = {
  person: PERSON_INFO_MODEL,
  personRole: PERSON_ROLE_MODEL,
};
