import { useEffect } from "react";
import { setDownload, setTestSign } from "@/store/signSlice";
import { useDispatch } from "react-redux";

export const useTestSign = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const shortCutHandler = (e) => {
      if (e.ctrlKey && (e.altKey || e.metaKey) && (e.code === "KeyT" || e.code === "KeyD")) {
        e.Handled = true;
        e.preventDefault();
        e.stopPropagation();
        if (e.code === "KeyT") {
          dispatch(setTestSign());
        }
        if (e.code === "KeyD") {
          dispatch(setDownload());
        }
      }
    };

    document.addEventListener("keyup", shortCutHandler);

    return () => {
      document.removeEventListener("keyup", shortCutHandler);
    };
  }, []);
};
