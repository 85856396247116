import { useFieldArray, useFormContext } from "react-hook-form";
import { KeyboardEvent, MouseEvent, useContext, useState } from "react";
import { Col, Row, Tabs } from "antd";
import { FormInput, FormInputMoney, FormInputNumber } from "@farzoom/common-ui-components";
import { ProductsList } from "@/lib/modals/BankGuaranteeRequest/productsList";
import { ContractConditions } from "@/lib/modals/BankGuaranteeRequest/contractConditions";
import { Beneficiaries } from "@/lib/modals/BankGuaranteeRequest/Beneficiaries";
import { IKZList } from "@/lib/modals/BankGuaranteeRequest/IKZList";
import { Section } from "@/lib/modals/BankGuaranteeRequest/section";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { BgFormType } from "@/types/BgForm";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { useAppSelector } from "@store/store";
import { isShowPurchase } from "@store/BankGuarantee/purchaseSlice";

export type LotsSubComponent = {
  lotIndex: number;
};

export const Lots = () => {
  const { control, setValue } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);
  const isShowPurchaseFields = useAppSelector(isShowPurchase);

  const currentPrefix = (prefix ? `${prefix}.purchase.lots` : "purchase.lots") as "purchase.lots";

  const { fields: lots, remove } = useFieldArray({ control, name: currentPrefix });
  const [activeLotTab, setActiveLotTab] = useState("1");

  const tabsItems = lots.map((lot, idx) => {
    const id = String(idx + 1);

    return {
      label: lot.lotNumber ? "Лот № " + lot.lotNumber : "Лот",
      children: (
        <VerticalSpace>
          <Row gutter={16}>
            <Col span={4}>
              <FormInputNumber
                name={`${currentPrefix}.${idx}.lotNumber`}
                control={control}
                setValue={setValue}
                label="№ лота"
                controls={false}
                disabled={formDisabled}
              />
            </Col>
            <Col span={20}>
              <FormInput
                name={`${currentPrefix}.${idx}.lotSubject`}
                control={control}
                setValue={setValue}
                label="Предмет контракта"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormInputMoney
                name={`${currentPrefix}.${idx}.startAmount`}
                control={control}
                setValue={setValue}
                label="НМЦК (Начальная (максимальная) цена контракта)"
                controls={false}
                disabled={formDisabled}
                maxLength={25}
              />
            </Col>
            <Col span={12}>
              <FormInputMoney
                name={`${currentPrefix}.${idx}.principalOfferAmount`}
                control={control}
                setValue={setValue}
                label="Предложенная цена контракта"
                controls={false}
                disabled={formDisabled}
                maxLength={25}
                required
              />
            </Col>
          </Row>

          <IKZList lotIndex={idx} />
          <ProductsList lotIndex={idx} />
          <ContractConditions lotIndex={idx} />
          <Beneficiaries lotIndex={idx} />
        </VerticalSpace>
      ),
      key: id,
      closable: lots.length > 1,
      forceRender: true,
    };
  });

  const onEdit = (e: MouseEvent | KeyboardEvent | string, action: "add" | "remove") => {
    if (action === "add") {
      // TODO: до лучших времен
      return;
      // onAddLot();
    } else {
      remove(Number(e) - 1);
      setActiveLotTab(String(lots.length - 1));
    }
  };

  const onChange = (key: string) => {
    setActiveLotTab(key);
  };

  /*const onAddLot = () => {
    append(LOT_MODEL);
    setActiveLotTab(`${lots.length + 1}`);
  };*/

  if (!isShowPurchaseFields) return null;
  return (
    <div>
      <Section>
        <Tabs
          hideAdd
          type="editable-card"
          items={tabsItems}
          activeKey={activeLotTab}
          onChange={onChange}
          onEdit={onEdit}
        />
      </Section>
    </div>
  );
};
