import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { colors, Modal } from "@farzoom/common-ui-components";
import { closeModal, isModalLoading, modalState } from "@/store/modalSlice";
import { Wizzard } from "../modals/Wizzard";
import SuppliesImportStartForm from "../modals/SuppliesImportStartForm";

import { OrderInfoForm } from "@/_custom/components/Common/OrderInfoForm";
import { SupplierAccessionStartForm } from "../modals/SupplierAccessionStartForm";
import { SupplyVerificationStartForm } from "../modals/SupplyVerificationStartForm";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { userState } from "@/store/userSlice";
import CustomErrorBoundary from "../../utils/CustomErrorBoundary";
import { TaskFormWrapper } from "../modals/TaskFormWrapper";
import { BankGuaranteeRequest } from "@/lib/modals/BankGuaranteeRequest";
import { BankGuaranteeRequestAgent } from "@/lib/modals/BankGuaranteeRequest/bankGuaranteeRequestAgent";
import { CreateCustomerForm } from "@components/BankGuarantee/createCustomerForm";
import { BgTaskFormWrapper } from "@components/BankGuarantee/bgTaskFormWrapper";
import { CustomerInfoForm } from "@components/BankGuarantee/customerInfo/CustomerInfoForm";
import CommissionChangeModal from "@components/BankGuarantee/commissionChangeRequest/commissionChangeModal";

export const ModalContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);

  const { open, config, type, closePath } = useAppSelector(modalState);
  const isModalFetching = useAppSelector(isModalLoading);

  const userInfo = useAppSelector(userState);

  const onShowSpinner = () => setIsFetching(true);
  const onHideSpinner = () => setIsFetching(false);

  const onClose = () => {
    if (closePath) {
      history.push(closePath);
    }

    dispatch(closeModal());
    onHideSpinner();
  };

  const MODALS = {
    taskForm: (
      <TaskFormWrapper
        id={config.taskId}
        onShowSpinner={onShowSpinner}
        onHideSpinner={onHideSpinner}
        onClose={onClose}
        userInfo={userInfo}
      />
    ),
    bgTaskForm: (
      <BgTaskFormWrapper
        id={config.taskId}
        onShowSpinner={onShowSpinner}
        onHideSpinner={onHideSpinner}
        onClose={onClose}
        userInfo={userInfo}
      />
    ),
    wizzard: (
      <Wizzard
        orderId={config?.orderId}
        taskId={config?.taskId}
        waitingText={config?.waitingText}
        noTaskText={config?.noTaskText}
      />
    ),
    orderForm: <OrderInfoForm orderId={config.orderId} userInfo={userInfo} />,
    customerInfoForm: <CustomerInfoForm customerId={config.customerId} />,
    createCustomerForm: <CreateCustomerForm />,
    suppliesImportStartForm: <SuppliesImportStartForm id="carrot-cpa-import-supplies" />,
    supplierAccessionStartForm: (
      <SupplierAccessionStartForm id="carrot-cpa-supplier-accession-factor" />
    ),
    supplyVerificationStartForm: (
      <SupplyVerificationStartForm id="carrot-cpa-supply-verification" />
    ),
    bankGuaranteeRequest: <BankGuaranteeRequest id="carrot-cpa-bg-request" />,
    bankGuaranteeRequestAgent: <BankGuaranteeRequestAgent id="carrot-cpa-bg-agent" />,
    commissionChangeRequest: (
      <CommissionChangeModal id="carrot-cpa-bg-agent-commission" orderId={config.orderId} />
    ),
  };

  const withoutScroll = type?.toLowerCase()?.includes("start");
  const fullHeight = type?.toLowerCase()?.includes("taskform");

  return open ? (
    <Modal
      color={colors.ruby1}
      isFetching={isFetching || isModalFetching}
      withoutScroll={withoutScroll}
      fullHeight={fullHeight}
      onClose={onClose}
      closePath={false}
    >
      <CustomErrorBoundary>{MODALS[type]}</CustomErrorBoundary>
    </Modal>
  ) : null;
};
