import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { FormInput, FormSelect } from "@farzoom/common-ui-components";
import { useState } from "react";
import { Checkbox, Col, Row, Space, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import { notNullish } from "@utils/notNullish";

type CustomerManagement = CustomerFormBaseProps;

const { Text } = Typography;

export const SOLE_EXECUTIVE_BODY_REF_ID_KEYS = {
  companyHead: "companyHead",
  managementCompany: "managementCompany",
};

export const SUPREME_GOVERNING_BODY_OPTIONS = [
  {
    key: "Единственный участник",
    value: "Единственный участник",
  },
  {
    key: "Общее собрание участников (несколько участников)",
    value: "Общее собрание участников (несколько участников)",
  },
  {
    key: "Общее собрание акционеров (несколько акционеров)",
    value: "Общее собрание акционеров (несколько акционеров)",
  },
  {
    key: "Единственный акционер (один акционер с долей 100%)",
    value: "Единственный акционер (один акционер с долей 100%)",
  },
];

export const SOLE_EXECUTIVE_BODY_REF_ID_OPTIONS = [
  {
    key: SOLE_EXECUTIVE_BODY_REF_ID_KEYS.companyHead,
    value: "Руководитель",
  },
  {
    key: SOLE_EXECUTIVE_BODY_REF_ID_KEYS.managementCompany,
    value: "Управляющая компания",
  },
];

export const CustomerManagement = ({
  config,
  prefix,
  control,
  getValues,
  setValue,
  register,
  watch,
}: CustomerManagement) => {
  const classes = useStyles();

  const NAMES = {
    supremeGoverningBody: `${prefix}.management.supremeGoverningBody`,
    soleExecutiveBody: `${prefix}.management.soleExecutiveBody`,
    supervisoryBoarExist: `${prefix}.management.supervisoryBoard.exists`,
    collegialExecutiveBodyName: `${prefix}.management.collegialExecutiveBody.name`,
    collegialExecutiveBodyExists: `${prefix}.management.collegialExecutiveBody.exists`,
    managementCompany: `${prefix}.management.managementCompany`,
    supervisoryBoardName: `${prefix}.management.supervisoryBoard.name`,
  };

  const [soleExecutiveBody, setSoleExecutiveBody] = useState(getValues(NAMES.soleExecutiveBody));

  const [managementCompanyData, supervisoryExist, collegialExist] = watch([
    NAMES.managementCompany,
    NAMES.supervisoryBoarExist,
    NAMES.collegialExecutiveBodyExists,
  ]);

  const onChangeSoleExecutiveBody = (value) => {
    setSoleExecutiveBody(value);

    setValue(NAMES.soleExecutiveBody, value);
    if (value === SOLE_EXECUTIVE_BODY_REF_ID_KEYS.managementCompany) {
      setValue(NAMES.managementCompany, {});
    } else {
      setValue(NAMES.managementCompany, null);
    }
  };

  const onChangeSupervisoryExist = (e: CheckboxChangeEvent) => {
    setValue(NAMES.supervisoryBoarExist, e.target.checked);
    if (!e.target.checked) {
      setValue(NAMES.supervisoryBoardName, "");
    }
  };
  const onChangeCollegialExist = (e: CheckboxChangeEvent) => {
    setValue(NAMES.collegialExecutiveBodyExists, e.target.checked);
    if (!e.target.checked) {
      setValue(NAMES.collegialExecutiveBodyName, "");
    }
  };

  return (
    <fieldset disabled={config.readonly}>
      <div className={classes.title}>{config.title}</div>
      <div className={classes.wrapper}>
        <VerticalSpace size="small">
          <FormSelect
            className={classes.formItem}
            name={NAMES.supremeGoverningBody}
            control={control}
            disabled={config.readonly}
            options={SUPREME_GOVERNING_BODY_OPTIONS}
            required={false}
            label="Высший орган управления"
          />
          <FormSelect
            className={classes.formItem}
            name={NAMES.soleExecutiveBody}
            control={control}
            disabled={config.readonly}
            options={SOLE_EXECUTIVE_BODY_REF_ID_OPTIONS}
            required={false}
            label="Единоличный исполнительный орган"
            parentOnChange={onChangeSoleExecutiveBody}
          />
          {soleExecutiveBody === SOLE_EXECUTIVE_BODY_REF_ID_KEYS.managementCompany && (
            <VerticalSpace size={6}>
              <CompanySuggestion
                className={classes.formItem}
                setValue={setValue}
                control={control}
                getVisibleFieldName={(prfx) => `${prfx}.name`}
                hiddenFieldNames={[
                  "inn",
                  "kpp",
                  "ogrn",
                  { target: "value", source: (opt) => opt?.shortname },
                ]}
                prefix={NAMES.managementCompany}
                label="Управляющая компания"
                controlled
              />
              <Space>
                {notNullish(managementCompanyData?.inn) && (
                  <Text type="secondary" className={classes.formItemInfo}>
                    ИНН: {managementCompanyData?.inn}
                  </Text>
                )}
                {notNullish(managementCompanyData?.kpp) && (
                  <Text type="secondary" className={classes.formItemInfo}>
                    КПП: {managementCompanyData?.kpp}
                  </Text>
                )}
                {notNullish(managementCompanyData?.ogrn) && (
                  <Text type="secondary" className={classes.formItemInfo}>
                    ОГРН: {managementCompanyData?.ogrn}
                  </Text>
                )}
              </Space>
            </VerticalSpace>
          )}

          <Row gutter={16} align="middle">
            <Col span={10}>
              <Checkbox
                {...register(NAMES.supervisoryBoarExist)}
                checked={supervisoryExist}
                onChange={onChangeSupervisoryExist}
              >
                Наблюдательный совет
              </Checkbox>
            </Col>
            {supervisoryExist && (
              <Col span={14}>
                <FormInput
                  className={classes.formItem}
                  control={control}
                  setValue={setValue}
                  register={register}
                  name={NAMES.supervisoryBoardName}
                  placeholder="Наименование наблюдательного совета"
                  defaultValue={getValues(NAMES.supervisoryBoardName)}
                />
              </Col>
            )}
          </Row>

          <Row gutter={16} align="middle">
            <Col span={10}>
              <Checkbox
                {...register(NAMES.collegialExecutiveBodyExists)}
                checked={collegialExist}
                onChange={onChangeCollegialExist}
              >
                Коллегиальный исполнительный орган
              </Checkbox>
            </Col>
            {collegialExist && (
              <Col span={14}>
                <FormInput
                  className={classes.formItem}
                  control={control}
                  setValue={setValue}
                  register={register}
                  name={NAMES.collegialExecutiveBodyName}
                  placeholder="Наименование исполнительного органа"
                  defaultValue={getValues(NAMES.collegialExecutiveBodyName)}
                />
              </Col>
            )}
          </Row>
        </VerticalSpace>
      </div>
    </fieldset>
  );
};
