import axios from "axios";
import { BASE_URLS, getToken } from "@farzoom/common-ui-components";
import { SERVER } from "../constants";

export const useConfig = (config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
};

export const axiosInstance = axios.create({
  baseURL: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}`,
});

export const axiosInstanceSpaCarrot = axios.create({
  baseURL: `${SERVER.HOST}${SERVER.SPA_CARROT}`,
});

export const axiosFrontAgent = axios.create({
  baseURL: BASE_URLS.frontAgent,
});

export const axiosExternalTask = axios.create({
  baseURL: "/api/ext-task/public",
});

export const axiosInstanceBG = axios.create({
  baseURL: `${SERVER.HOST}${SERVER.BG_API}`,
});

const axiosFrontBank = axios.create({
  baseURL: BASE_URLS.frontBank,
});

axiosInstance.interceptors.request.use(useConfig);
axiosInstanceSpaCarrot.interceptors.request.use(useConfig);
axiosFrontAgent.interceptors.request.use(useConfig);
axiosInstanceBG.interceptors.request.use(useConfig);
axiosFrontBank.interceptors.request.use(useConfig);
