import { ArrowUpOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useStyles } from "./";
import { useExternalTask } from "@hooks/useExternalTask";

const { Title, Link } = Typography;

type Props = {
  title: string;
  showLink?: boolean;
  isOnForm?: boolean;
  onClick: () => void;
};

export const CustomerTitle = ({ title, showLink, isOnForm, onClick }: Props) => {
  const classes = useStyles({ isOnForm });
  const { isExternalTask } = useExternalTask();
  const isLink = showLink && !isExternalTask;

  return (
    <div className={classes.title}>
      <Title level={4}>{title}</Title>
      {isLink && (
        <Link className={classes.titleLink} onClick={onClick}>
          <span>Открыть анкету</span>
          <span className={classes.linkIcon}>
            <ArrowUpOutlined />
          </span>
        </Link>
      )}
    </div>
  );
};
