import { Typography } from "antd";
import { ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { VerticalSpace } from "@components/UI/VerticalSpace";

const { Title } = Typography;

type SectionProps = {
  title?: ReactNode;
  children: ReactNode;
};

const useStyles = createUseStyles({
  section: {
    marginBottom: 20,
    borderRadius: 8,
    backgroundColor: "rgb(250, 250, 250)",
    padding: 16,
    "& [class*=autoComplete], & .ant-form-item": {
      marginBottom: 0,
    },
  },
});

export const Section = ({ title, children }: SectionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      {title && <Title level={4}>{title}</Title>}
      <VerticalSpace>{children}</VerticalSpace>
    </div>
  );
};
