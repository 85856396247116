import { axiosInstanceBG } from "@/services/api/axiosInstance";
import { BgFormType } from "@/types/BgForm";

export type BgCardModelType = {
  productBg: {
    guarantee: Pick<BgFormType, "guarantee">;
    purchase: Pick<BgFormType, "purchase">;
  };
};

export const GuaranteeController = Object.freeze({
  // getBgTasks: () =>
  searchPurchase: (number: string) => axiosInstanceBG.get(`/purchase?purchaseNumber=${number}`),
  saveBgCard: (model: BgCardModelType, orderId: string) =>
    axiosInstanceBG.post(`/card/bg/${orderId}/model`, {
      components: model,
    }),
  getBgCard: (orderId: string) => axiosInstanceBG.get(`/card/bg/${orderId}/model`),
});
