import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  infoFormBlock: {
    position: "relative",
    "& + &": {
      marginTop: 40,
    },
  },
});

export const InfoFormBlock = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.infoFormBlock}>{children}</div>;
};
