import { axiosInstance } from "./axiosInstance";
import { OrderController } from "@/services/api/controllers";

export type UpdateOrder = {
  orderId: string;
  components: Record<string, unknown>;
};

const TASK_LIST_SORT = { sort: "createdAt", sortOrder: "DESC" };
const TASK_LIST_LIMIT = 20;

export const getTasksList = async (filters = {}, pageNumber = 1) => {
  try {
    const {
      data: { error_code, ...rest },
    } = await axiosInstance.get(`/order/list`, {
      params: {
        pageNum: pageNumber,
        pageSize: TASK_LIST_LIMIT,
        number: filters.numberTaskFilter,
        companyId: filters.company.id,
        createdDateFrom: filters.dateFilter ? filters.dateFilter[0] : undefined,
        createdDateTo: filters.dateFilter ? filters.dateFilter[1] : undefined,
        processType: filters.orderTypeFilter,
        orderState: filters.orderStateFilter,
        sort: TASK_LIST_SORT.sort,
        sortOrder: TASK_LIST_SORT.sortOrder,
      },
    });

    if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      };
    }
    return {
      isSuccess: false,
      message: rest.error,
    };
  } catch (err) {
    console.log(err);
    return {
      isSuccess: false,
      message: err.message,
    };
  }
};

export const updateOrder = async ({ orderId, components }: UpdateOrder) => {
  try {
    return await OrderController.updateModel(orderId, components);
  } catch (err) {
    throw err;
  }
};
