import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Customers } from "@/services/api";
import { CustomerController } from "@/services/api/controllers/bankGuarantee/CustomerController";
import { openModal } from "@store/modalSlice";
import { ModalType } from "@/services/constants/ModalType";
import { CustomerShort } from "@/types/Customer";
import { RootState } from "@store/store";

type State = {
  customers: CustomerShort[];
  isCustomersFetching: boolean;
};
const initialState: State = {
  customers: [],
  isCustomersFetching: false,
};

type CreateCustomerFormProps = {
  payload: { inn: string };
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

export const createCustomer = createAsyncThunk(
  "customers/createCustomer",
  async ({ payload, onSuccess, onError }: CreateCustomerFormProps, { dispatch }) => {
    try {
      const { data } = await CustomerController.createCustomer(payload);
      onSuccess && onSuccess();

      dispatch(
        openModal({
          type: ModalType.customerInfoForm,
          config: { customerId: data.customerId },
          closePath: "/customers",
        })
      );
      dispatch(fetchCustomers());
    } catch (error) {
      onError && onError(error);
    }
  }
);

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (_, { dispatch }) => {
    dispatch(setIsCustomerFetching(true));
    const data = await Customers.getCustomers();
    dispatch(setIsCustomerFetching(false));

    return data;
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setIsCustomerFetching: (state, action) => {
      state.isCustomersFetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.customers = action.payload;
    });
  },
});

export const customersList = (state: RootState) => state?.carrot?.customers.customers;

export const { setIsCustomerFetching } = customersSlice.actions;
export default customersSlice.reducer;
