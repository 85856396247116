import { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Form, InputNumber } from "antd";
import { borderRadius, colors } from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  formItem: {
    margin: [[-5, -1], "!important"],
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
    "& .ant-input-number": {
      width: "100%",
      backgroundColor: "unset !important",
      border: "none !important",
      color: colors.transparent,
    },
    "& .ant-input-number-input": {
      userSelect: "none !important",
      transitionDuration: "0s !important",
      "&::selection": {
        color: ["transparent", "!important"],
      },
      "&:focus": {
        color: [colors.black, "!important"],
        "&::selection": {
          color: [colors.black, "!important"],
        },
      },
    },
  },
  viewCell: {
    // left: 10,
    position: "relative",
  },
  editCell: {
    position: "absolute",
    top: 13,
    left: 18,
    paddingRight: 24,
    width: "100%",
    height: "100%",
    transitionDelay: 0,
    pointerEvents: "none",
  },
  unselectable: {
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
  },
  input: {
    borderRadius,
  },
});

export const EditableCell = ({
  // title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  positive,
  negative,
  // control,
  // prefix,
  // getValues,
  ...rest
}) => {
  const classes = useStyles();

  const [editing, setEditing] = useState("init");

  const onFocus = (e) => {
    e.target.select();
    setEditing(true);
  };
  // const inputRef = useRef(0);

  /*const toNext = (e) => {
    console.log(e);
    console.log(e.target.tabindex);
    const { form } = e.nativeEvent.target;
    const index = [...form].indexOf(e.target);
    const nextIndex = index + 1;
    const lastIndex = (form?.elements?.length || 1) - 1;
    form.elements[nextIndex <= lastIndex ? nextIndex : lastIndex].focus();

    console.log({ form });
    console.log({ nextIndex });
    console.log(form.elements[nextIndex]);
    console.log(form.elements[nextIndex <= lastIndex ? nextIndex : lastIndex]);
    console.log(form.elements[nextIndex].focus());
    e.preventDefault();
  };*/

  const toNext = (e) => {
    const { form } = e.nativeEvent.target;
    console.log(e);
    const elements = Array.from(form.elements); // Преобразуем коллекцию в массив
    const index = elements.indexOf(e.target);

    console.log({ index });
    if (index !== -1) {
      const nextElement = elements[(index + 1) % elements.length];
      console.log(nextElement);
      nextElement.focus();
    }

    e.preventDefault();
  };

  const getValue = (value) =>
    value === undefined || value === null || Number.isNaN(`${value}`.replace(/ /g, ""))
      ? ""
      : value;

  const fixValue = (value) =>
    (negative && value > 0) || (positive && value < 0) ? value * -1 : getValue(value);

  const parser = (value) =>
    `${fixValue(value)}`
      .replace(/,/g, ".")
      .replace(/\$\s?|( *)/g, "")
      .replace(/[^-0-9,.]/g, "");

  const onSave = (event) => {
    try {
      // window?.getSelection()?.empty();
      const inputValue = parser(event?.target?.value?.replace(/,/g, "."));
      const value = +fixValue(inputValue);
      handleSave({ period: dataIndex, value, key: record?.dataKey });
      setEditing(false);
      if (event?.key === "Enter") {
        toNext(event);
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const formatter = (value) =>
    `${getValue(value)}`
      .replace(/[^-0-9. ]/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .replace(/(\.\d{2})(.+)$/, "$1");

  const isDataCell = dataIndex !== "code" && dataIndex !== "title";

  const strValue = record ? `${record[dataIndex] || "__"}` : "";
  const viewValue = strValue.replace("-", "");

  let childNode = (
    <div className={classes.viewCell}>
      {!isDataCell && children[1]}
      {isDataCell && (strValue < 0 ? `(${formatter(viewValue)})` : formatter(children[1]))} &nbsp;
    </div>
  );

  if (editable) {
    childNode = (
      <>
        <Form.Item className={classes.formItem} name={dataIndex}>
          <InputNumber
            // ref={inputRef}
            defaultValue={fixValue(record[dataIndex])}
            onPressEnter={onSave}
            onBlur={onSave}
            onFocus={onFocus}
            formatter={formatter}
            parser={parser}
            controls={false}
            keyboard={true}
            className={classes.input}
            stringMode
          />
        </Form.Item>
        {(editing === "init" || !editing) && (
          <div className={classes.editCell}>
            <div className={classes.unselectable}>
              {strValue < 0 || negative
                ? `(${formatter(viewValue)})`
                : formatter(children[1]) || ""}{" "}
              &nbsp;
            </div>
          </div>
        )}
      </>
    );
  }

  return <td {...rest}>{childNode}</td>;
};

EditableCell.propTypes = {
  title: PropTypes.any,
  editable: PropTypes.any,
  children: PropTypes.any,
  dataIndex: PropTypes.any,
  record: PropTypes.any,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  handleSave: PropTypes.func,
};
