import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { CustomerInfoWrapper } from "./CustomerInfoWrapper";
import { LoadingOverlay } from "@farzoom/common-ui-components";
import { CustomerController } from "@/services/api/controllers/bankGuarantee/CustomerController";
import { userState } from "@store/userSlice";
import { useSelector } from "react-redux";
import { FormConfig } from "@/types/FormConfig";
import { CustomerFormModel } from "@/types/Customer";

const useStyles = createUseStyles({
  loading: {
    width: "calc(100vw - 220px)",
    maxWidth: "1300px",
    minHeight: "calc(100vh - 40px)",
  },
});

export const CustomerInfoForm = ({ customerId, handleModalClose }) => {
  const userInfo = useSelector(userState);

  const [config, setConfig] = useState<FormConfig>();
  const [model, setModel] = useState<CustomerFormModel>();

  const _userInfo =
    Object.keys(userInfo || {})?.length > 0 ? userInfo : window?.shellSharedContext?.userInfo;

  const getFormData = async () => {
    if (customerId) {
      const config = await CustomerController?.getConfig(customerId).then(
        (response) => response?.data
      );
      const model = await CustomerController?.getModel(customerId).then(
        (response) => response?.data
      );
      setConfig(config);
      setModel(model);
    }
  };

  useEffect(() => {
    const init = async () => await getFormData();
    init();
  }, [customerId]);

  const classes = useStyles();

  return config && model ? (
    <CustomerInfoWrapper
      customerId={customerId}
      config={config}
      model={model}
      CustomerController={CustomerController}
      userInfo={_userInfo}
      onSaveCallback={getFormData}
      handleModalClose={handleModalClose}
    />
  ) : (
    <div className={classes.loading}>
      <LoadingOverlay />
    </div>
  );
};
