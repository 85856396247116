import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Form, Row, Select, Table } from "antd";
import { CalendarOutlined, DiffOutlined } from "@ant-design/icons";
import { useController, useForm } from "react-hook-form";
import {
  Alert,
  Button,
  CollapsedWrappedBlock,
  Document,
  ERROR_CLASSNAME,
  Spinner,
} from "@farzoom/common-ui-components";
import { EditableCell } from "./EditableCell";
import { EditableRow } from "./EditableRow";
import { useStyles } from "./BuhReportBlockStyles";
import { AddModal } from "./Modals/AddModal";
import {
  buildGridState,
  calcPeriod,
  formatPeriod,
  getColumns,
  TAX_OPTIONS,
  validateBuhReport,
} from "./utils";
import { CompanyInfoController } from "@/services/api/controllers";
import useScreenSize from "@hooks/useScreenSize";

export const BuhReportBlock = ({
  control,
  prefix,
  config,
  noCollapse,
  setValue,
  getValues,
  customRows,
  customValidateFun,
  headerComponent: HeaderComponent,
  dependentLatestPeriod,
}) => {
  const classes = useStyles();
  const screenSize = useScreenSize();

  const tableScroll = screenSize["1366"] ? "calc(100svh - 225px)" : "calc(100svh - 400px)";

  const {
    field: { value: buhData, onChange },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: prefix,
    rules: {
      validate: (value) => {
        if (!latestPeriod || config?.readonly) {
          return true;
        }
        const checkingPeriod = value?.reports && value.reports[latestPeriod];
        if (checkingPeriod) {
          return customValidateFun ? customValidateFun() : validateBuhReport(checkingPeriod);
        }
        return `Заполните ${formatPeriod(latestPeriod)} в отчётности.`;
      },
    },
  });

  const isError = !!invalid;

  const initialLatestPeriod = config.requiredPeriods?.some(
    (period) => period?.id === buhData?.latestPeriod
  )
    ? buhData?.latestPeriod
    : config?.requiredPeriods[0]?.id;

  const isF5 = config?.reportType === "F5";

  const [dynamicCustomRows, setDynamicCustomRows] = useState(customRows);
  const [addModalConfig, setAddModalConfig] = useState({ isShow: false });
  const [hideContent, setHideContent] = useState(false);
  const [recognition, setRecognition] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [state, setState] = useState({});
  const [latestPeriod, setLatestPeriod] = useState(initialLatestPeriod);

  const initialPeriodsSource = (
    config.requiredPeriods.find((period) => period.id === latestPeriod) || config.requiredPeriods[0]
  )?.periods;

  const [periods, setPeriods] = useState(initialPeriodsSource);

  const { control: fileControl } = useForm();

  const onChangeTaxationType = (value) => {
    onChange({
      ...buhData,
      taxationInfo: {
        taxationType: value,
      },
    });
  };

  const onChangeLatestPeriod = (periodName) => {
    const selectedPeriods = config?.requiredPeriods?.find(
      (period) => period?.id === periodName
    )?.periods;
    setPeriods(selectedPeriods);
    setLatestPeriod(periodName);
    onChange({
      ...buhData,
      latestPeriod: periodName,
    });
  };

  useEffect(() => {
    if (dependentLatestPeriod) {
      onChangeLatestPeriod(dependentLatestPeriod);
    }
  }, [dependentLatestPeriod]);

  const onSave = ({ period, value, key }) => {
    const periodValue = calcPeriod(
      {
        ...(buhData?.reports?.[period] || {}),
        [key]: value,
      },
      dynamicCustomRows,
      config
    );
    onChange({
      ...buhData,
      reports: {
        ...(buhData?.reports || {}),
        [period]: periodValue,
      },
    });
  };

  const updateForm = () => {
    if (buhData) {
      setState({
        gridData: buildGridState(
          periods,
          buhData?.reports,
          dynamicCustomRows,
          config,
          setDynamicCustomRows
        ),
        taxationType: buhData?.taxationInfo?.taxationType || "usn",
        columns: getColumns({ config, periods, onSave }),
      });
    } else if (!config?.readonly) {
      onChange({ reports: {}, latestPeriod });
    }
  };

  useEffect(() => {
    updateForm();
  }, [buhData, dynamicCustomRows]);

  const recognizeBuhReport = (data) => {
    setShowError(false);
    setSuccessful(false);
    setRecognition(true);

    CompanyInfoController.recognizeBuhReport(data)
      .then(({ data: responseData }) => {
        setHideContent(true);
        setRecognition(false);

        // Преобразование ответа api
        const ocrReports =
          responseData.data?.companyBuhs?.reduce(
            (acca, { dateMonth, buhRecords }) => ({
              ...acca,
              ...{
                [dateMonth]: buhRecords?.reduce(
                  (accb, { code, value }) => ({
                    ...accb,
                    ...{ [`b${code}`]: parseInt(value, 10) },
                  }),
                  {}
                ),
              },
            }),
            {}
          ) || {};

        // Обновление модели
        const updatedReports = Object.entries(buhData?.reports || {}).reduce(
          (acca, [reportName, reportValue]) => ({
            ...acca,
            ...{
              [reportName]: {
                ...reportValue,
                ...(Object.entries(reportValue).reduce((accb, [propName, propValue]) => {
                  const ocrReport = ocrReports[reportName] || {};
                  return {
                    ...accb,
                    ...{ [propName]: ocrReport[propName] ?? propValue },
                  };
                }, {}) || {}),
              },
            },
          }),
          {}
        );
        setValue(prefix, {
          ...buhData,
          reports: updatedReports,
        });

        updateForm();
        setSuccessful(true);
        setShowError(false);
        setHideContent(false);
      })
      .catch(() => {
        setSuccessful(false);
        setShowError(true);
        setHideContent(false);
        setRecognition(false);
      });
  };

  const render = () => (
    <div className="buh-report-block">
      <div className={classes.row}>
        {!config?.hideTaxationType && (
          <div>
            <Form.Item label="Форма налогообложения" className={classes.formItem}>
              {config?.readonly ? (
                <div className={classes.readonly}>
                  {TAX_OPTIONS?.find((item) => item?.value === state.taxationType)?.name}
                </div>
              ) : (
                <Select
                  className={classes.select}
                  size="large"
                  value={state.taxationType}
                  suffixIcon={<DiffOutlined style={{ pointerEvents: "none" }} />}
                  onChange={onChangeTaxationType}
                >
                  {TAX_OPTIONS?.map(({ value, title }) => (
                    <Select.Option key={value} value={value} title={title}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
        )}
        <div>
          {config.buhRecognition && !config.readonly && (
            <>
              <Row gutter={20}>
                <Document
                  control={fileControl}
                  prefix="orcDoc"
                  config={{
                    load: true,
                    sign: false,
                    title: "Распознать отчет",
                    remove: true,
                    docType: "buhReport",
                    multiple: false,
                    required: false,
                    entityType: "orders",
                  }}
                  massSignHandler={() => {}}
                  uploadMethod={recognizeBuhReport}
                />
              </Row>
              {showError && <span className={classes.errorMessage}>Возникла ошибка</span>}
              {successful && <span className={classes.successfulMessage}>Успешно</span>}
              {recognition && (
                <span className={classes.recognition}>
                  <Spinner />
                  Обработка документа...
                </span>
              )}
            </>
          )}
        </div>
        <div>
          {/*
          Отображается только в режиме для редактирования
          https://confluence.moos.solutions/pages/viewpage.action?pageId=7326522
        */}
          {!config?.hideChoiceQuarter &&
            config?.requiredPeriods?.length > 0 &&
            !config?.readonly && (
              <Form.Item
                className={classes.formItem}
                label={isF5 ? "Последний отчетный месяц" : "Последний квартал"}
              >
                <Select
                  size="large"
                  className={classes.select}
                  value={latestPeriod}
                  onChange={onChangeLatestPeriod}
                  disabled={(config?.requiredPeriods?.length || null) < 2}
                  suffixIcon={<CalendarOutlined style={{ pointerEvents: "none" }} />}
                  style={{ width: 180 }}
                >
                  {[...config.requiredPeriods].reverse().map((period) => (
                    <Select.Option key={period.id} value={period.id}>
                      {isF5 ? period.id : formatPeriod(period.id)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
        </div>
      </div>
      {isError && <Alert>{error?.message}</Alert>}
      <form>
        {HeaderComponent && <HeaderComponent />}
        <Table
          className={classnames(classes.table, {
            error: isError,
            [ERROR_CLASSNAME]: isError,
          })}
          scroll={{ y: tableScroll, x: "1500" }}
          components={{
            body: {
              row: ({ ...rest }) => <EditableRow control={control} {...rest} />,
              cell: ({ ...rest }) => (
                <EditableCell control={control} getValues={getValues} prefix={prefix} {...rest} />
              ),
            },
          }}
          rowClassName={classes.editableRow}
          bordered
          dataSource={state.gridData}
          columns={state.columns}
          pagination={false}
          size="middle"
        />
        {config?.canChangeCount && (
          <Button text="Добавить статью" onClick={() => setAddModalConfig({ isShow: true })} />
        )}
        {addModalConfig?.isShow && (
          <AddModal
            onClose={() => setAddModalConfig({ isShow: false })}
            onSubmit={(data) => {
              const title = Object.entries(config?.EXPENDITURES_REF).find(
                (refI) => refI[0] === data.key
              )[1];
              setDynamicCustomRows((prev) => [
                ...prev,
                {
                  title,
                  dataKey: data.key,
                },
              ]);
              setAddModalConfig({ isShow: false });
            }}
            mode="add"
            expendituresRef={config?.EXPENDITURES_REF}
          />
        )}
      </form>
    </div>
  );

  const Content = () => (
    <div>
      {noCollapse ? (
        render()
      ) : (
        <CollapsedWrappedBlock config={config}>{render()}</CollapsedWrappedBlock>
      )}
    </div>
  );

  return hideContent ? <Spinner /> : Content();
};

BuhReportBlock.propTypes = {
  control: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  noCollapse: PropTypes.bool,
  dependentLatestPeriod: PropTypes.string,
};
