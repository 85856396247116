import { ERROR_MESSAGES, FormInput, PATTERNS, PLACEHOLDERS } from "@farzoom/common-ui-components";
import { BaseSyntheticEvent } from "react";
import { Control, FieldValues, Path, PathValue, UseFormSetValue } from "react-hook-form";

type Props<T extends FieldValues> = {
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  control: Control<T>;
  className?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
};

function extractEmail<T extends FieldValues>(value: string): PathValue<T, Path<T>> {
  const emailRegex = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}\b/g;
  const emails = value.match(emailRegex);
  return (emails ? emails[0] : "") as PathValue<T, Path<T>>;
}

export const EmailInput = <T extends FieldValues>({
  className,
  control,
  setValue,
  name,
  label,
  required,
  placeholder,
}: Props<T>) => {
  const onBlur = (evt: BaseSyntheticEvent) => {
    const {
      target: { value },
    } = evt;

    setValue(name, extractEmail(value));
  };

  return (
    <FormInput
      className={className}
      control={control}
      setValue={setValue}
      name={name}
      label={label || "Email"}
      placeholder={placeholder || PLACEHOLDERS.email}
      required={required}
      pattern={{
        value: PATTERNS.email,
        message: ERROR_MESSAGES.email,
      }}
      onBlur={onBlur}
    />
  );
};
