import React from "react";
import { colors } from "@farzoom/common-ui-components";

export const SmallCollapseIcon = ({ color = colors.coal4, styleable = false, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M6 9L12 15L18 9"
      stroke={styleable ? "" : color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
