import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { getCustomerSections } from "./getCustomerSections";
import { InfoForm } from "@/_custom/_components/InfoForm";
import { Button, notification } from "antd";
import { createUseStyles } from "react-jss";
import { CustomerComponentModel, CustomerFormModel } from "@/types/Customer";
import { updateCustomer } from "@/services/api/bankGuarantee/customers";
import { hasNonReadonlyComponent } from "@utils/hasReadonlyComponent";
import { FormConfig } from "@/types/FormConfig";

type CustomerInfoWrapperType = {
  config: FormConfig;
  model: CustomerFormModel;
  onSaveCallback: () => void;
  title: string;
  customerId: string;
  handleModalClose?: () => void;
  [key: string]: unknown;
};

type NewSectionType = {
  name: string;
  getData: () => void;
};

const useStyles = createUseStyles({
  actions: {
    position: "sticky",
    bottom: 0,
    paddingTop: 16,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
    zIndex: 100,
  },
});

export const CustomerInfoWrapper = ({
  title,
  model,
  config,
  customerId,
  CustomerController,
  userInfo,
  onSaveCallback,
  handleModalClose,
}: CustomerInfoWrapperType) => {
  const classes = useStyles();
  const [sections, setSections] = useState<NewSectionType[]>(() => []);
  const [isFetching, setIsFetching] = useState(true);
  const [isReadonly, setIsReadonly] = useState(true);

  const {
    control,
    watch,
    resetField,
    getValues,
    setValue,
    register,
    handleSubmit,
    reset,
    setFocus,
  } = useForm<CustomerComponentModel>({
    defaultValues: model?.components,
  });

  const isSubmitted = useRef<boolean>(false);

  useEffect(() => {
    if (isSubmitted.current) {
      reset(model?.components);
      isSubmitted.current = false;
    }
  }, [isSubmitted.current]);

  useEffect(() => {
    if (CustomerController) {
      const newSections = getCustomerSections({
        control,
        watch,
        resetField,
        getValues,
        setValue,
        config,
        models: model?.components,
        orderId: customerId,
        userInfo,
        register,
        setFocus,
      });
      setSections(newSections);
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    for (const [_, { components }] of Object.entries(config.tabs)) {
      if (hasNonReadonlyComponent(Object.values(components))) {
        setIsReadonly(false);
        return;
      }
    }
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsFetching(true);
      await updateCustomer({ orderId: customerId, components: data });
      if (onSaveCallback) {
        await onSaveCallback();
      }

      notification.success({
        message: "Анкета клиента успешно обновлена!",
      });
      isSubmitted.current = true;
    } catch (err) {
      console.error("Customer info form update failed:", err);

      notification.error({
        message: "Что-то пошло не так...",
        description: "Попробуйте ещё раз",
      });
    } finally {
      setIsFetching(false);
    }
  });

  const formActions = (
    <div className={classes.actions}>
      {isReadonly ? (
        <Button type="primary" onClick={handleModalClose}>
          ОК
        </Button>
      ) : (
        <Button type="primary" onClick={onSubmit}>
          Сохранить
        </Button>
      )}
    </div>
  );

  return (
    <InfoForm isFetching={isFetching} title={title} sections={sections} actions={formActions} />
  );
};
