import { GuaranteeInfo } from "@/lib/modals/BankGuaranteeRequest/guaranteeInfo";
import { PurchaseInfo } from "@/lib/modals/BankGuaranteeRequest/purchaseInfo";
import { Lots } from "@/lib/modals/BankGuaranteeRequest/lots";

export const GuaranteeForm = () => {
  return (
    <>
      <GuaranteeInfo />
      <PurchaseInfo />
      <Lots />
    </>
  );
};
