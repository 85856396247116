import { Typography } from "antd";
import { FarzoomLogo, RTLogo } from "../../../layout/Icons";

const { Title } = Typography;

export const Header = () => {
  return (
    <>
      <header className="header">
        <div className="container header-container bg-white">
          <div className="logo-wrapper">
            <FarzoomLogo />
            <RTLogo />
          </div>
          <Title level={2} className="title">
            Биржа банковских продуктов
          </Title>
        </div>
      </header>
    </>
  );
};
