import { useLocation } from "react-router-dom";
import { useExternalDataTask } from "./useExternalDataTask";
import { useExternalDataTaskConfig } from "./useExternalDataTaskConfig";
import { TaskFormWrapper } from "@/lib/modals/TaskFormWrapper";
import { useSelector } from "react-redux";
import { useState } from "react";

import "./style.scss";
import { Header } from "./components/Header";
import { TaskComplete } from "./components/TaskComplete";
import { TaskError } from "./components/TaskError";
import { useTestSign } from "@hooks/useTestSign";
import { useExternalJivoSite } from "@hooks/useJivoSite";

const ExternalTask = () => {
  const [taskState, setTaskState] = useState({
    type: "",
    text: "",
    status: "",
    complete: false,
  });

  useTestSign();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const userInfo = useSelector((state) => state.User);
  const { data, error } = useExternalDataTask(token);
  const { configData } = useExternalDataTaskConfig(token);

  useExternalJivoSite(data);

  const onComplete = (success = true) => {
    const text = success ? "Задача успешно завершена" : "Упс.. Что-то пошло не так :(";
    setTaskState({
      type: "complete",
      complete: true,
      status: success ? "success" : "error",
      text,
    });
  };

  if (error) return <TaskError />;

  if (taskState.complete) return <TaskComplete title={taskState.text} status={taskState.status} />;

  return (
    <>
      <Header />
      <div className="container bg-white">
        <div className="external-task-form modal">
          {configData?.id && (
            <TaskFormWrapper
              id={configData?.id}
              isError={error}
              token={token}
              userInfo={userInfo}
              config={configData}
              model={data}
              onCompleteCallback={onComplete}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalTask;
