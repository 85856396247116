import React from "react";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { Collapse } from "react-collapse";

const useStyles = createUseStyles({
  collapsed: {
    "& .ReactCollapse--collapse": {
      height: (props) => props.isOpened && ["auto", "!important"],
      transition: ["height", "300ms", "ease"],
    },
  },
});

export const Collapsed = ({ className, isOpened, children }) => {
  const classes = useStyles({ isOpened });
  return (
    <div className={classnames(classes.collapsed, className)}>
      <Collapse isOpened={isOpened} style={{ color: "red" }}>
        {children}
      </Collapse>
    </div>
  );
};
