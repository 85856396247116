import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  taskDescriptionBlock: {
    position: "relative",
  },
  title: {
    fontWeight: 600,
  },
});

export const CustomTaskDescriptionBlock = ({ model, config }) => {
  const classes = useStyles();
  return (
    <div className={classes.taskDescriptionBlock}>
      <h3 className={classes.title} style={{ color: config.color, fontWeight: "bold" }}>
        {config?.title}
      </h3>
      <span style={{ color: config.color }}>{model?.text || config?.text}</span>
    </div>
  );
};
