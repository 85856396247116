import { Sign } from "@/_custom/components/Common/Sign";
import { checkSigned } from "@/_custom/utils/fileSignUtils";
import { Builder } from "@components/builder";
import {
  Button,
  Buttons,
  colors,
  ConsentsBlock,
  FormTextArea,
  getIsMobile,
  H2,
  LoadingOverlay,
  MEDIA_QUERY,
  onErrorSubmitForm,
  PageLoader,
  parseExpression,
  useQueryStringParser,
} from "@farzoom/common-ui-components";
import { isTestDownload, isTestSign } from "@store/signSlice";
import { Form, Row, Tabs } from "antd";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

const TASK_FORM_WIDTH = 1300;
const LEFT_BLOCK_WIDTH = 280;
const MARGIN = 24;
const RIGHT_BLOCK_WIDTH = 800;

const useStyles = createUseStyles({
  taskForm: {
    width: "auto",
    maxWidth: TASK_FORM_WIDTH,
    minHeight: "inherit",
    margin: 0,
    display: "flex",
    flexDirection: "row",
    [MEDIA_QUERY.mobile]: {
      width: "100%",
    },
    "&.wider": {
      width: 1880,
      [MEDIA_QUERY.mobile]: {
        width: "100%",
      },
    },
    "&.infoAlignRight": {
      flexDirection: "row-reverse",
    },

    "@media (max-width: 1430px)": {
      maxWidth: "1200px",
    },
    "@media (max-width: 1330px)": {
      maxWidth: "1100px",
    },
    "@media (max-width: 1230px)": {
      maxWidth: "1000px",
    },
    "@media (max-width: 1200px)": {
      maxWidth: "900px",
    },
    "@media (max-width: 1100px)": {
      maxWidth: "800px",
    },
    "@media (max-width: 1030px)": {
      maxWidth: "750px",
    },
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      maxWidth: "100% !important",
    },
  },
  leftBlock: {
    width: LEFT_BLOCK_WIDTH,
    margin: [0, MARGIN, 0, 0],
    padding: 0,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    position: "sticky",
    top: 0,
    "& .common-ui-components-form-select": {
      margin: 0,
    },
    "@media (max-width: 1023px)": {
      width: "100%",
    },
    "@media (max-width: 992px)": {
      width: "auto",
      position: "static",
      margin: 0,
      top: "auto",
      background: "#f8f8f8",
    },
  },
  leftBlockItem: {
    marginTop: [0, "!important"],
    "& + &": {
      marginTop: [24, "!important"],
    },
  },
  rightBlock: {
    width: `calc(100% - ${LEFT_BLOCK_WIDTH + MARGIN}px)`,
    display: "flex",
    [MEDIA_QUERY.mobile]: {
      width: "100%",
    },
    "&:only-child": {
      width: TASK_FORM_WIDTH,
      [MEDIA_QUERY.mobile]: {
        width: "100%",
      },
    },
  },
  rightBlockContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    marginTop: 24,
    display: "flex",
    gap: 10,
    [MEDIA_QUERY.mobile]: {
      marginTop: 16,
    },
  },
  footer: {
    background: "#fff",
    paddingTop: 24,
    position: "sticky",
    bottom: 0,
    [MEDIA_QUERY.mobile]: {
      marginLeft: -16,
      marginRight: -16,
      padding: [24, 16, 16],
      background: colors.bodyBackground,
    },
  },
  footerRow: {
    [MEDIA_QUERY.mobile]: {
      flexDirection: "column-reverse",
    },
    "& $buttons": {
      width: "100%",
      justifyContent: "flex-end",
      [MEDIA_QUERY.mobile]: {
        marginBottom: 16,
        maxWidth: 320,
        width: "100%",
        alignItems: "stretch",
        alignSelf: "center",
      },
    },
  },
  helper: {
    flexGrow: 1,
  },
  tabs: {
    width: "100%",
    "& > .ant-tabs-nav": {
      margin: [0, "!important"],
    },
  },
  consentsBlock: {
    width: 400,
    marginRight: 16,
    [MEDIA_QUERY.mobile]: {
      marginRight: 0,
      width: "100%",
    },
  },
  buttons: {},
  button: {
    "&:nth-child(3)": {
      [MEDIA_QUERY.mobile]: {
        flexGrow: 1,
      },
    },
  },
  comment: {
    order: 25,
  },
  massSignBtn: {
    marginRight: "auto",
  },
});

const NAMES = {
  decisionId: "decision.id",
  decisionComment: "decision.comment",
};

export const TaskForm = ({
  taskId,
  isExternalTask,
  openedTaskConfig,
  openedTaskModel,
  openedTaskStatus,
  userInfo,
  isCompanyLinkable,
  onCompleteTask,
  // onSaveDraftTask,
  onClose,
  onGoToOrder,
  onGoToCompany,
  isLoading,
}) => {
  const classes = useStyles();
  const { isCompleted } = useQueryStringParser();

  const formMethods = useForm();
  const { getValues, watch, reset, trigger, clearErrors, control, setValue, handleSubmit } =
    formMethods;

  // Необходимо, чтобы компоненты инициализировались после того, как данные загружены в форму
  // И первый их рендер был уже с данными
  const [showRightBlock, setShowRightBlock] = useState(false);
  const [decisionConfig, setDecisionConfig] = useState({});

  const [documentsCanBeSigned, setDocumentsCanBeSigned] = useState(() => []);
  const [documentsToSignMap, setDocumentsToSignMap] = useState({});

  const isTest = useSelector(isTestSign);
  const isDownload = useSelector(isTestDownload);

  const decisionId = decisionConfig?.id;
  const decisionTitle = decisionConfig?.title;
  const decisionValidate = decisionConfig?.validate;
  const decisionCommentVisible = decisionConfig?.comment?.visible;
  const decisionCommentRequire = decisionConfig?.comment?.require;

  const isMobile = getIsMobile();

  useEffect(() => {
    const firstContainer = document?.querySelector(".scrollView");
    const secondContainer = firstContainer?.nextElementSibling;
    if (secondContainer) {
      secondContainer.style.position = "relative";
    }
  }, []);

  const tabsConfig = Object.values(openedTaskConfig?.tabs || {}).sort(
    (prev, next) => prev?.pos - next?.pos
  );

  const tabsVisible = tabsConfig?.length > 0;

  const companyId = openedTaskModel?.properties?.find((item) => item?.key === "companyId")?.value;
  const { decision, components: componentsModel } = openedTaskModel?.model || {};
  const taskName = openedTaskModel?.name;
  const { decisions } = openedTaskConfig || {};

  const { orderId } = componentsModel?.orderInfo || {};

  const currentDecisionId = watch(NAMES.decisionId);
  const currentDecisionComment = watch(NAMES.decisionComment);

  const showLeftBlock =
    Object.keys(openedTaskConfig?.info?.components || {}).filter(
      (confKey) =>
        openedTaskConfig?.info?.components &&
        openedTaskConfig.info.components[confKey] &&
        componentsModel &&
        componentsModel[confKey]
    )?.length > 0;

  const infoAlignRight = openedTaskConfig?.info?.style?.align === "right";
  const infoWidth = openedTaskConfig?.info?.style?.width || `${LEFT_BLOCK_WIDTH}px`;

  const getDocumentsCanBeSigned = (initialArr) => {
    let docsArr = structuredClone(initialArr);
    docsArr.forEach((d) => {
      d.files = d.files.filter((f) => !checkSigned(f, d.needCoSign, userInfo?.company?.inn));
    });

    return docsArr.filter((d) => d?.sign).filter((d) => d?.files?.length);
  };

  const getTaskDocumentsToSign = () => {
    const docsCanBeSignedByKeys = {};
    let docsCanBeSigned = [];

    for (const tab of Object.values(openedTaskConfig?.tabs)) {
      for (const key of Object.keys(tab?.components)) {
        const component = tab.components[key];

        if (component.type === "documents") {
          let docsMap = component.types.map((type) => {
            return {
              docType: type.docType,
              sign: type.sign,
              needCoSign: type.needCoSign,
              files:
                getValues(key)
                  ?.filter(Boolean)
                  .find((el) => el.docType === type.docType)?.files || [],
            };
          });

          Object.assign(docsCanBeSignedByKeys, { [key]: docsMap });

          docsCanBeSigned.push(...getDocumentsCanBeSigned(docsMap));
        }
      }
    }

    setDocumentsCanBeSigned(docsCanBeSigned);
    setDocumentsToSignMap(docsCanBeSignedByKeys);
  };

  // Загрузка модели в форму
  useEffect(() => {
    if (!componentsModel) return;

    reset(componentsModel);
    setShowRightBlock(true);
    getTaskDocumentsToSign();
  }, [openedTaskModel]);

  useEffect(() => {
    const decisionData = decisions?.find((item) => item?.id === currentDecisionId);
    setDecisionConfig(decisionData);
  }, [decisions, currentDecisionId, setDecisionConfig]);

  useEffect(() => {
    if (!currentDecisionComment && decisionCommentRequire) {
      trigger(NAMES.decisionComment);
    }
    if (currentDecisionComment || !decisionCommentRequire) {
      clearErrors(NAMES.decisionComment);
    }
  }, [NAMES.decisionComment, currentDecisionComment, decisionCommentRequire, trigger, clearErrors]);

  const prepareModelBeforeSubmit = (model) => {
    const CONFIGS = tabsConfig
      ?.map((tab) => tab?.components)
      ?.reduce((result, item) => ({ ...result, ...item }), {});

    for (const prefix in CONFIGS) {
      const config = CONFIGS[prefix];
      const isVisibleByExpression = parseExpression({
        model,
        expression: config?.visibleExp,
        defaultValue: true,
      });
      const visible = config?.visibleExp ? isVisibleByExpression : config?.visible;
      if (!visible) {
        delete model[prefix];
      }
    }

    return model;
  };

  const onSubmit = ({ id, title, model, decisionModel }) => {
    const payload = {
      model: prepareModelBeforeSubmit(model),
      title,
      taskId,
      decision: {
        id,
        ...decisionModel,
      },
    };

    return onCompleteTask ? onCompleteTask(payload) : console.log(payload);
  };

  const onValidComplete = (model) => {
    onSubmit({
      model,
      id: decisionId,
      title: decisionTitle,
      decisionModel:
        decisionCommentVisible || decisionCommentRequire
          ? {
              genparams: null,
              comment: currentDecisionComment,
            }
          : {},
    });
  };

  const onComplete = () => {
    const onSuccess = (model) => onValidComplete(model);
    if (!decisionId || decisionValidate) {
      return handleSubmit(onSuccess, onErrorSubmitForm)();
    } else {
      if (decisionCommentRequire && !currentDecisionComment) {
        trigger(NAMES.decisionComment, { shouldFocus: true });
        onErrorSubmitForm();
      } else {
        return handleSubmit(onValidComplete(componentsModel));
      }
    }
  };

  /*const onDraft = () => {
    onSaveDraftTask({
      taskId,
      model: getValues(),
    });
  };*/

  const consentsProps = {
    prefix: "consents",
    control,
    setValue,
    model: componentsModel?.consents,
    config: (
      openedTaskConfig?.tabs?.task ||
      openedTaskConfig?.tabs?.task_company ||
      openedTaskConfig?.tabs?.task_individual
    )?.components?.consents,
    className: classes.consentsBlock,
  };
  const showConsentsBlock = !!consentsProps?.model && !!consentsProps?.config;

  const onAddDocument = () => {
    setTimeout(() => {
      getTaskDocumentsToSign();
    }, 16);
  };

  const update = (docType) => (value) => {
    const key = Object.keys(documentsToSignMap).find((key) =>
      documentsToSignMap[key].some((doc) => doc.docType === docType)
    );

    if (key) {
      const docIndex = documentsToSignMap[key].findIndex((doc) => doc.docType === docType);
      const doc = documentsToSignMap[key][docIndex];
      let newFile;

      for (let i = 0; i < doc.files.length; i++) {
        if (doc.needCoSign) {
          newFile = value.files.find((f) => f.fileName === doc.files[i].fileName);
        }

        if (!doc.files[i].fileName.includes(".sig")) {
          newFile = value.files.find((f) => f.fileName.slice(0, -4) === doc.files[i].fileName);
        }

        if (newFile) {
          doc.files.splice(i, 1, newFile);
          newFile = undefined;
        }
      }

      setValue(key, documentsToSignMap[key], { shouldDirty: true });

      let newDocsCanBeSigned = [];
      Object.values(documentsToSignMap).forEach((docs) => {
        newDocsCanBeSigned.push(...getDocumentsCanBeSigned(docs));
      });
      setDocumentsCanBeSigned(newDocsCanBeSigned);
    }
  };

  const taskFormSignUpdate = (docType, files) => {
    update(docType)(files);
  };

  const builderProps = {
    ...formMethods,
    model: componentsModel,
    orderId,
    companyId,
    currentDecisionId,
    isCompanyLinkable,
    onGoToOrder,
    onGoToCompany,
    onAddDocument,
    taskFormSignUpdate,
    // isSingleSign: false // TODO: пока оставили кнопки подписи на доках. Раскоментить, чтобы убрать
  };

  const render = () => (
    <div
      className={classnames(classes.taskForm, {
        infoAlignRight,
        wider: infoWidth === "50%",
      })}
    >
      {showLeftBlock && (
        <div className={classes.leftBlock} style={{ minWidth: infoWidth }}>
          <Builder
            className={classes.leftBlockItem}
            config={openedTaskConfig?.info?.components}
            decision={decision}
            decisions={decisions}
            userInfo={userInfo}
            {...builderProps}
          />
          <div className={classes.comment}>
            <FormTextArea
              label="Комментарий к решению"
              control={control}
              name={NAMES.decisionComment}
              required={decisionCommentRequire}
            />
          </div>
        </div>
      )}
      {showRightBlock ? (
        <div className={classes.rightBlock} style={{ minWidth: `${RIGHT_BLOCK_WIDTH}px` }}>
          <Form layout="vertical" className={classes.rightBlockContent}>
            <H2>Задача: {taskName}</H2>
            <div className={classes.body}>
              {tabsVisible && (
                <Tabs className={classes.tabs}>
                  {tabsConfig?.map((tab) => (
                    <Tabs.TabPane key={tab?.pos} tab={tab?.title} forceRender>
                      <Builder config={tab?.components} userInfo={userInfo} {...builderProps} />
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              )}
            </div>
            <div className={classes.helper} />
            {!isCompleted && (
              <div className={classes.footer}>
                <Row
                  className={classes.footerRow}
                  justify={showConsentsBlock ? "space-between" : "end"}
                >
                  {showConsentsBlock && <ConsentsBlock {...consentsProps} />}
                  <Buttons className={classes.buttons}>
                    {Boolean(documentsCanBeSigned.length) && (
                      <div className={classes.massSignBtn}>
                        <Sign
                          value={documentsCanBeSigned}
                          config={{ sign: true }}
                          update={update}
                          isTest={isTest}
                          isDownload={isDownload}
                          isMass
                          btnText="Подписать все"
                        />
                      </div>
                    )}

                    {!isExternalTask && (
                      <Button
                        className={classes.button}
                        type={isMobile ? "onlyIcon" : "outlined"}
                        iconName={isMobile && "cancel"}
                        text="Отмена"
                        onClick={onClose}
                      />
                    )}
                    {/*TODO: до лучших времён*/}
                    {/*<Button
                      className={classes.button}
                      type={isMobile ? "onlyIcon" : "primary"}
                      iconName={isMobile && "save"}
                      text="Сохранить черновик"
                      onClick={onDraft}
                    />*/}
                    <Button
                      className={classes.button}
                      type="primary"
                      text="Завершить"
                      onClick={onComplete}
                      disabled={isLoading}
                    />
                  </Buttons>
                </Row>
              </div>
            )}
          </Form>
        </div>
      ) : (
        <LoadingOverlay />
      )}
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <PageLoader render={render} status={openedTaskStatus} />
    </FormProvider>
  );
};

TaskForm.propTypes = {
  taskId: PropTypes.string.isRequired,
  userInfo: PropTypes.object,
  openedTaskConfig: PropTypes.object,
  openedTaskModel: PropTypes.object,
  openedTaskStatus: PropTypes.string,
  onCompleteTask: PropTypes.func,
  onSaveDraftTask: PropTypes.func,
};
