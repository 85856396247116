import { useLocation } from "react-router-dom";
import { APP_PAGE_PATH_NAMES } from "@/routes";
import { onOpenBgTasks } from "@store/BankGuarantee/bgSlice";
import { useAppDispatch } from "@store/store";
import { onOpenTasks } from "@store/tasksSlice";

const isPath = (path: string, name: string) => path.includes(name);

export const useUpdateBgTasks = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const updateBgTasks = () => {
    if (isPath(location.pathname, APP_PAGE_PATH_NAMES.guarantees)) dispatch(onOpenBgTasks());
    if (isPath(location.pathname, APP_PAGE_PATH_NAMES.tasks)) dispatch(onOpenTasks());
  };

  return {
    updateBgTasks,
  };
};
