import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import { Space } from "antd";
import { FormInputMoney, FormInputNumber } from "@farzoom/common-ui-components";
import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";

type Props = CustomerFormBaseProps;

export const CustomerStaff = ({
  prefix,
  config,
  control,
  setValue,
  register,
  getValues,
}: Props) => {
  const classes = useStyles();
  const initialValues = getValues("customerStaff.staff");

  return (
    <fieldset disabled={config.readonly}>
      <div className={classes.title}>{config.title}</div>
      <div className={classes.wrapper}>
        <Space>
          <FormInputNumber
            label="Штат сотрудников, чел"
            name={`${prefix}.staff.staffCount`}
            control={control}
            setValue={setValue}
            register={register}
            defaultValue={initialValues?.staffCount}
            controls={false}
            min={0}
            disabled={config.readonly}
          />
          <FormInputMoney
            label="Фонд оплаты в год, руб"
            name={`${prefix}.staff.staffWageFund`}
            control={control}
            setValue={setValue}
            register={register}
            defaultValue={initialValues?.staffWageFund}
            controls={false}
            min={0}
            maxLength={25}
            disabled={config.readonly}
          />
          <FormInputMoney
            label="Задолженность по ЗП, руб"
            name={`${prefix}.staff.staffWageDebt`}
            control={control}
            setValue={setValue}
            register={register}
            defaultValue={initialValues?.staffWageDebt}
            controls={false}
            min={0}
            disabled={config.readonly}
          />
        </Space>
      </div>
    </fieldset>
  );
};
