import { getRdnInfo } from "./RdnParser";

export const checkFileSignedByInn = (file, inn) => {
  const { signInfo } = file || {};
  return (
    signInfo?.signs?.some((sign) => {
      const signatureInn = getRdnInfo(sign?.subjectName).companyInn;
      return !!inn && signatureInn === inn;
    }) || false
  );
};

export const checkSigned = (file, needCoSign, currentUserCompanyInn) => {
  return (
    file?.isSignedOnThisTask || // Уже подписан на текущей открытой форме задачи
    checkFileSignedByInn(file, currentUserCompanyInn) || // Уже есть подпись, сделанная той же компанией
    (!!file?.fileSigned && !needCoSign) ||
    (!!file?.fileName.toLowerCase().endsWith(".sig") && !needCoSign) // Уже есть какая-то подпись (файл формата .sig)
  ); // Уже есть (какая-то) подпись и не требуется двухстороннее подписание
};
