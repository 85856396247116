import PropTypes from "prop-types";
import { TimePicker } from "antd";

const format = "HH:mm";

export const TimeInput = ({
  placeholder,
  disabled,
  disabledTime,
  className,
  onChange,
  value,
  allowClear,
  minuteStep,
}) => {
  const onChangeTime = (newValue, newFormat) => onChange(newValue?.format(newFormat) || null);
  return (
    <TimePicker
      allowClear={allowClear}
      showTime={{ format }}
      format={format}
      className={className}
      size="large"
      onChange={onChangeTime}
      disabled={disabled}
      disabledTime={disabledTime}
      placeholder={placeholder}
      value={value}
      minuteStep={minuteStep}
    />
  );
};

TimeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  /* label: PropTypes.string,
  prefix: PropTypes.string, */
};
