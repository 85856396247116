import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";
import { Col, Divider, Empty, Row, Space, Typography } from "antd";
import { Button, FormInputMoney, FormInputNumber } from "@farzoom/common-ui-components";
import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import { useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { CompanySuggestion } from "@/_custom/components/Common/CompanySuggestion";
import useScreenSize from "@hooks/useScreenSize";

type FoundersOfgsType = CustomerFormBaseProps;
const { Text } = Typography;

const SPACE_SIZE = 6;

const COMPANY_MODEL = {
  companyId: "",
  inn: "",
  kpp: "",
  ogrn: "",
  name: "",
  sharePercent: 0,
  shareAmount: 0,
};

export const CustomerFoundersOrgs = ({
  prefix,
  config,
  setValue,
  control,
  watch,
  getValues,
}: FoundersOfgsType) => {
  const classes = useStyles();
  const {
    fields: founders,
    prepend,
    remove,
  } = useFieldArray({ control, name: `${prefix}.founderOrgs` });
  const screenSize = useScreenSize();

  const onAddCompany = () => {
    prepend(COMPANY_MODEL);
  };

  const initialValues = getValues(`${prefix}.founderOrgs`);
  const founderCompanies = watch(`${prefix}.founderOrgs`);

  return (
    <fieldset disabled={config.readonly}>
      <div className={classNames(classes.title, classes.titleWithAction)}>
        <span>{config.title}</span>
        {!config.readonly && (
          <Button type="outlined" iconName="add" title="Добавить счет" onClick={onAddCompany} />
        )}
      </div>
      <div className={classes.wrapper}>
        {founders.length === 0 && (
          <Empty description="Акционеры/Учредители-ЮЛ отсутствуют">
            <Button className={classes.emptyAddBtn} text="Добавить" onClick={onAddCompany} />
          </Empty>
        )}
        {founders.map((company, idx) => (
          <>
            <Row key={company.id + idx} align="top" gutter={8}>
              <Col span={screenSize.l ? 13 : 14}>
                <VerticalSpace size={SPACE_SIZE}>
                  <CompanySuggestion
                    className={classes.formItem}
                    setValue={setValue}
                    control={control}
                    getVisibleFieldName={(prfx) => `${prfx}.name`}
                    hiddenFieldNames={["inn", "kpp", "ogrn"]}
                    prefix={`${prefix}.founderOrgs.${idx}`}
                    label="Компания"
                    required
                    controlled
                  />
                  <Space>
                    <Text type="secondary" className={classes.formItemInfo}>
                      ИНН: {founderCompanies[idx].inn}
                    </Text>
                    <Text type="secondary" className={classes.formItemInfo}>
                      КПП: {founderCompanies[idx].kpp}
                    </Text>
                    <Text type="secondary" className={classes.formItemInfo}>
                      ОГРН: {founderCompanies[idx].ogrn}
                    </Text>
                  </Space>
                </VerticalSpace>
              </Col>
              <Col span={5}>
                <FormInputNumber
                  name={`${prefix}.founderOrgs.${idx}.sharePercent`}
                  label="Доля, %"
                  className={classes.formItem}
                  setValue={setValue}
                  control={control}
                  defaultValue={initialValues[idx]?.sharePercent}
                  controls={false}
                  required
                  disabled={config.readonly}
                />
              </Col>
              <Col span={5}>
                <FormInputMoney
                  name={`${prefix}.founderOrgs.${idx}.shareAmount`}
                  label="Доля, руб"
                  className={classes.formItem}
                  setValue={setValue}
                  control={control}
                  defaultValue={initialValues[idx]?.shareAmount}
                  controls={false}
                  maxLength={25}
                  disabled={config.readonly}
                />
              </Col>
              {screenSize.l && (
                <Col span={1} className={classes.flexCenterCol}>
                  {!config.readonly && (
                    <Button
                      type="outlined"
                      iconName="remove"
                      onClick={() => remove(idx)}
                      title="Удалить компанию"
                    />
                  )}
                </Col>
              )}
            </Row>
            {!screenSize.l && (
              <Button
                className={classes.removeBtn}
                type="primary"
                onClick={() => remove(idx)}
                text="Удалить компанию"
              />
            )}
            {!screenSize.l && founders.length > 1 && <Divider />}
          </>
        ))}
      </div>
    </fieldset>
  );
};
