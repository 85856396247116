import { CustomerController } from "@/services/api/controllers/bankGuarantee/CustomerController";
import { CustomerComponentModel } from "@/types/Customer";

export type UpdateCustomer = {
  orderId: string;
  components: CustomerComponentModel;
};

export const getCustomers = async () => {
  try {
    const { data } = await CustomerController.getCustomers();
    return data;
  } catch (err) {
    throw err;
  }
};

export const updateCustomer = async ({ orderId, components }: UpdateCustomer) => {
  try {
    return await CustomerController.updateCustomer({ orderId: orderId, components });
  } catch (err) {
    throw err;
  }
};
