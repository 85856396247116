import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { AuditOutlined, CloudDownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useController } from "react-hook-form";
import { Empty, Tooltip } from "antd";
import { DocumentController } from "@/services/api/controllers";
import {
  borderRadius,
  borderRadiusHalf,
  Button,
  colors,
  getFile,
  LoadingOverlay,
  MEDIA_QUERY,
  onValidateFiles,
  showNotification,
} from "@farzoom/common-ui-components";
import { Sign } from "../../Common/Sign";
import { SignInfo } from "./SignInfo";
import { checkSigned } from "../../../utils/fileSignUtils";
import { COLOR_VALUES } from "@/config";
import { useExternalTask } from "@hooks/useExternalTask";
import { getShortFileName } from "@utils/getShortFileName";

const ERROR_CLASSNAME = "ant-form-item-has-error";

const useStyles = createUseStyles({
  document: {
    background: colors.cardBackground,
    padding: 16,
    borderRadius,
    position: "relative",
    gap: 10,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    [MEDIA_QUERY.mobile]: {
      padding: 12,
      borderRadius: borderRadiusHalf,
      background: colors.grayLight,
    },
    "& + &": {
      marginTop: 10,
      [MEDIA_QUERY.mobile]: {
        marginTop: 16,
      },
    },
    "&.isError": {
      border: [1, "solid", colors.error],
    },
    "&.isDragged": {
      filter: "drop-shadow(1px 3px 1px #b5b5b5)",
    },
    "& $buttonAdd": {
      padding: "0 !important",
    },
  },
  wrapper: {
    flexGrow: 1,
    [MEDIA_QUERY.mobile]: {
      width: "calc(100% - 50px)",
    },
  },
  title: {
    fontSize: 15,
    lineHeight: 20 / 15,
    [MEDIA_QUERY.mobile]: {
      fontSize: 12,
      lineHeight: 18 / 12,
    },
  },
  buttonAdd: {},
  fileInput: {
    display: ["none", "!important"],
  },
  files: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    "& a": {
      marginRight: 4,
      [MEDIA_QUERY.mobile]: {
        marginLeft: 4,
      },
    },
  },
  file: {
    fontSize: 18,
    fontWeight: 500,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 6,
    [MEDIA_QUERY.mobile]: {
      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 400,
      wordBreak: "break-word",
    },
    "& .anticon": {
      fontSize: 22,
    },
  },
  error: {
    marginBottom: 16,
    color: colors.error,
  },
  link: {
    color: colors.primaryColor,
    cursor: "pointer",
    lineHeight: 1.1,
  },
  isFileSignedStyle: {
    color: colors.green,
    cursor: "pointer",
    lineHeight: 1.1,
  },
  isFileNotSignedStyle: {
    color: COLOR_VALUES.red,
    cursor: "pointer",
    lineHeight: 1.1,
  },
});

export const Document = ({
  control,
  prefix,
  config,
  value,
  update,
  readonly,
  isTest,
  isDownload,
  allowedTypes,
  uploadMethod,
  userInfo,
  isSingleSign,
  taskFormSignUpdate,
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const dropHolderRef = useRef();
  const { isExternalTask, token } = useExternalTask();
  const currentUserCompanyInn = userInfo?.company?.inn;
  const {
    fieldState: { invalid, error },
  } = useController({
    control,
    name: prefix,
    rules: {
      validate: () => {
        if (window?.skipDocsValidation || readonly || config?.readonly) {
          return true;
        }

        const hasFiles = value?.files?.length > 0;
        // Проверяем подписан ли каждый файл, если файлы есть
        const isSigned =
          hasFiles &&
          value?.files?.every((file) =>
            checkSigned(file, config.needCoSign, currentUserCompanyInn)
          );

        // Если файлы требуются, но не предоставлены, возвращаем ошибку
        if (config.required && !hasFiles) {
          return "Необходимо вложить документ";
        }

        // Если требуется подпись, но файлы не подписаны (или требуется повторная подпись), возвращаем ошибку
        if (config.sign && !isSigned && hasFiles) {
          return "Необходимо подписание.";
        }

        // Во всех остальных случаях возвращаем true
        return true;
      },
    },
  });

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [immutableFiles, setImmutableFiles] = useState({});
  const [isBusy, setIsBusy] = useState(false);

  const isSigned = value?.files.length
    ? value?.files.every((file) => checkSigned(file, config.needCoSign, currentUserCompanyInn))
    : false;

  const isError = !!invalid && (!value?.files?.length || (config?.sign && !isSigned));
  const [isDragged, setIsDragged] = useState(false);
  const apiUrlPrefix = "front-agent";
  const apiExternalTaskPrefix = "api/ext-task";

  const handleFiles = function () {
    const files = onValidateFiles({
      files: this.files,
      allowedTypes,
      onError: (message) => {
        showNotification({
          message,
          type: "error",
          duration: 20,
        });
      },
    });
    setFilesToUpload(files);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("change", handleFiles, false);
    }

    setImmutableFiles(
      Object.assign({}, ...(value?.files || []).map((f) => ({ [f.fileId]: true })))
    );
  }, []);

  const uploadFile = (file) => {
    const data = new FormData();
    data.append("file", file);
    return uploadMethod ? uploadMethod(data) : DocumentController.upload(data, token);
  };

  useEffect(() => {
    const onError = (data) => {
      showNotification({
        type: "error",
        message:
          data?.response?.status === 413
            ? "Файл превышает максимально допустимый размер"
            : "Ошибка загрузки файла",
      });
      const files = value?.files || [];
      update({
        ...value,
        files: [...files],
        docType: config.docType,
      });
      inputRef.current.value = null;
      setFilesToUpload([]);
      setIsBusy(false);
    };
    if (filesToUpload.length > 0 && !isBusy) {
      setIsBusy(true);
      const uploadList = [];
      for (let i = 0; i < filesToUpload.length; i++) {
        uploadList.push(uploadFile(filesToUpload[i]));
      }

      Promise.all(uploadList)
        .then((response) => {
          const files = value?.files || [];
          const uploadedList = response.reduce((result, item) => {
            if (item?.request?.status === 200) {
              result.push(item.data);
            }
            return result;
          }, []);
          if (uploadedList?.length > 0) {
            update({
              ...value,
              files: config?.multiple ? [...files, ...uploadedList] : [...uploadedList],
              docType: config.docType,
            });
            inputRef.current.value = null;
            setFilesToUpload([]);
            setIsBusy(false);
          } else if (uploadMethod) {
            setIsBusy(false);
          } else {
            onError(response[0]);
          }
        })
        .catch((err) => {
          if (uploadMethod) {
            setIsBusy(false);
          }
          if (!uploadMethod) {
            onError(err);
          }
        });
    }
  }, [filesToUpload]);

  const onUpload = () => inputRef.current.click();

  const onRemove = (fileId) => () => {
    const files = value?.files || [];
    update({
      ...value,
      files: files.filter((file) => file.fileId !== fileId),
      docType: config.docType,
    });
  };

  const [isSignInfoOpen, setIsSignInfoOpen] = useState({});

  const toggleSignInfo = (fileId) => () => {
    setIsSignInfoOpen((isSignInfoOpen) => ({
      ...isSignInfoOpen,
      [fileId]: !isSignInfoOpen[fileId],
    }));
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (!config?.load) return;

    if (!config.multiple) {
      const dt = new DataTransfer();
      dt.items.add(e.dataTransfer.files[0]);

      handleFiles.apply({ files: dt.files });
      setIsDragged(false);
      return;
    }

    handleFiles.apply({ files: e.dataTransfer.files });
    setIsDragged(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    if (!config?.load) return;

    setIsDragged(true);
  };

  return (
    <>
      {(((!readonly || !config?.readonly) && config?.load) || value?.files?.length) && (
        <>
          <div
            ref={dropHolderRef}
            className={classnames(classes.document, { isError, isDragged })}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={() => setIsDragged(false)}
          >
            {isBusy && <LoadingOverlay text="Обработка" />}
            {!readonly && config?.load && (
              <>
                <Button iconName="add" className={classes.buttonAdd} onClick={onUpload} />
              </>
            )}
            <input
              ref={inputRef}
              className={classes.fileInput}
              type="file"
              multiple={config?.multiple}
            />
            <div className={classes.wrapper}>
              <div className={classes.title}>{config?.title}</div>
              {value?.files?.length > 0 && (
                <div className={classes.files}>
                  {/*TODO: куча говна*/}
                  {value?.files?.map((file) => {
                    const { fileName, fileId, signInfo } = file || {};
                    const url = `/${apiUrlPrefix}/document/file/${fileId}`;
                    const externalTaskUrl = `/${apiExternalTaskPrefix}/public/file/${fileId}`;
                    const externalTaskUrlToken = `/${apiExternalTaskPrefix}/public/file/${fileId}?token=${token}`;
                    const signedUrl = isExternalTask
                      ? `${externalTaskUrl}/sign?token=${token}`
                      : `${url}/sign`;
                    const originalUrl = isExternalTask
                      ? `${externalTaskUrl}/original?token=${token}`
                      : `${url}/original`;
                    const stampedUrl = isExternalTask
                      ? `${externalTaskUrl}/stamped?token=${token}`
                      : `${url}/stamped`;
                    const canRemoveFile =
                      !readonly && ((config?.load && !immutableFiles[fileId]) || config?.remove);
                    const originalFileName = fileName?.split(".sig")?.join("");
                    const isFileSigned = fileName?.toLowerCase()?.endsWith(".sig");
                    const signIconClasses =
                      typeof file.signInfo === "undefined" || !file.signInfo
                        ? classes.link
                        : signInfo?.isValid
                        ? classes.isFileSignedStyle
                        : classes.isFileNotSignedStyle;

                    return (
                      <>
                        <div className={classes.file} key={fileId}>
                          {isFileSigned ? (
                            <>
                              <Tooltip title="Скачать оригинал">
                                <span
                                  className={classes.link}
                                  title={originalUrl}
                                  onClick={getFile(originalUrl, originalFileName)}
                                >
                                  {getShortFileName(originalFileName)}
                                </span>
                              </Tooltip>
                              <Tooltip title="Скачать подписанный файл" arrowPointAtCenter>
                                <span
                                  className={classes.link}
                                  title={signedUrl}
                                  onClick={getFile(signedUrl)}
                                >
                                  <CloudDownloadOutlined />
                                </span>
                              </Tooltip>
                              {signInfo?.isValid && (
                                <Tooltip title="Скачать pdf-образ со штампом" arrowPointAtCenter>
                                  <span
                                    className={classes.link}
                                    title={stampedUrl}
                                    onClick={getFile(stampedUrl)}
                                  >
                                    <AuditOutlined />
                                  </span>
                                </Tooltip>
                              )}
                              <Tooltip title="Информация о подписи" arrowPointAtCenter>
                                <span className={signIconClasses} onClick={toggleSignInfo(fileId)}>
                                  <InfoCircleOutlined />
                                </span>
                              </Tooltip>
                            </>
                          ) : (
                            <span
                              className={classes.link}
                              title={url}
                              onClick={getFile(
                                isExternalTask ? externalTaskUrlToken : url,
                                fileName
                              )}
                            >
                              {getShortFileName(fileName)}
                            </span>
                          )}
                          {canRemoveFile && (
                            <a onClick={onRemove(fileId)} title={`Удалить ${fileName}`}>
                              ❌
                            </a>
                          )}
                        </div>
                        {isSignInfoOpen[fileId] && <SignInfo signInfo={signInfo} />}
                      </>
                    );
                  })}
                  {!!readonly && !value?.files?.length && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Загруженные файлы отсутствуют"
                    />
                  )}
                </div>
              )}
            </div>
            {config?.sign && (
              <Sign
                config={config}
                value={value}
                update={update}
                isTest={isTest}
                isDownload={isDownload}
                isSuccess={isSigned}
                needCoSign={config.needCoSign}
                userInfo={userInfo}
                showSignBtn={isSingleSign}
                taskFormSignUpdate={taskFormSignUpdate}
              />
            )}
          </div>
          {isError && (
            <div className={classnames(classes.error, ERROR_CLASSNAME)}>{error?.message}</div>
          )}
        </>
      )}
    </>
  );
};

Document.propTypes = {
  control: PropTypes.object,
  prefix: PropTypes.string,
  config: PropTypes.object,
  value: PropTypes.object,
  update: PropTypes.func,
  isTest: PropTypes.bool,
  isDownload: PropTypes.bool,
  readonly: PropTypes.bool,
  isSingleSign: PropTypes.bool,
};
