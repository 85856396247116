import { useEffect, useState } from "react";

export const useDebtorsList = (config) => {
  const [debtorsList, setDebtorsList] = useState([]);

  useEffect(() => {
    const debtors = config.debtors?.map((debtor) => ({
      key: debtor.inn,
      value: debtor.name,
    }));

    setDebtorsList(debtors);
  }, []);

  return {
    debtorsList: debtorsList || [],
  };
};
