import { createContext } from "react";
import { Form } from "antd";
import { colors } from "@farzoom/common-ui-components";

export const EditableContext = createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const { record } = props.children[0]?.props || {};
  const background = record?.summary ? colors.disabled : "transparent";

  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr key={index} style={{ background }} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
