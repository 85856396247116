import { Col, Row, Typography } from "antd";
import { Button, colors, MEDIA_QUERY, Modal } from "@farzoom/common-ui-components";
import React from "react";
import { createUseStyles } from "react-jss";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { useForm } from "react-hook-form";
import { PersonsType } from "@/types/Customer";
import { PersonInfoBlock } from "@components/BankGuarantee/personInfo/personInfoBlock";
import { IdentityDocumentBlock } from "@components/BankGuarantee/personInfo/identityDocumentBlock";
import { PDLBlock } from "@components/BankGuarantee/personInfo/PDLBlock";
import { PersonRoles } from "@components/BankGuarantee/personInfo/personRoles";

const { Title } = Typography;

type PersonInfoModalProps = {
  onClose: () => void;
  onUpdate: (values: PersonsType) => void;
  defaultValues: PersonsType;
  readonly: boolean;
};

export const useStyles = createUseStyles({
  container: {
    width: "clamp(600px, 80vw, 1100px)",
    position: "relative",
    [MEDIA_QUERY.mobile]: {
      width: "100%",
    },
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& [class*=formSelect] + [class*=formSelect]": {
      marginTop: 0,
    },
  },
  block: {
    backgroundColor: colors.cardBackground,
    padding: 16,
    borderRadius: 8,
    height: "100%",
  },
  rolesWrapper: {
    marginTop: 24,
  },
  btn: {
    marginLeft: "auto",
  },
  btnWrapper: {
    paddingTop: 16,
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  gender: {
    marginTop: 32,
    padding: 4,
    border: "1px solid transparent",
    transition: "border-color .2s ease",
    [MEDIA_QUERY.mobile]: {
      marginTop: 16,
    },
  },
  genderError: {
    borderColor: "red",
    borderRadius: 8,
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    color: "#8E909B",
    fontSize: 14,
  },
});

export const disabledDate = (current) => {
  const today = new Date();
  return current && current > today;
};

export const PersonInfoModal = ({
  onClose,
  onUpdate,
  defaultValues,
  readonly,
}: PersonInfoModalProps) => {
  const classes = useStyles();
  const {
    setValue,
    control,
    watch,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonsType>({
    defaultValues,
  });

  const personW = watch();

  const onOk = handleSubmit(() => onUpdate(getValues()));

  const onReadonlyOk = () => onClose();

  return (
    <Modal open={true} color={colors.ruby1} onClose={onClose} closePath={false}>
      <fieldset disabled={readonly}>
        <div className={classes.container}>
          <Title level={4}>Сведения о физ.лице</Title>
          <VerticalSpace>
            <Row gutter={[16, 16]} align="stretch">
              <Col span={24} xl={12}>
                <PersonInfoBlock
                  control={control}
                  setValue={setValue}
                  personW={personW}
                  errors={errors}
                  getValues={getValues}
                  readonly={readonly}
                />
              </Col>
              <Col span={24} xl={12}>
                <IdentityDocumentBlock
                  control={control}
                  setValue={setValue}
                  personW={personW}
                  readonly={readonly}
                  getValues={getValues}
                />
              </Col>
            </Row>
            <PDLBlock register={register} setValue={setValue} getValues={getValues} />
          </VerticalSpace>

          <PersonRoles
            control={control}
            setValue={setValue}
            readonly={readonly}
            register={register}
            personW={personW}
          />
        </div>
      </fieldset>
      <div className={classes.btnWrapper}>
        <Button
          className={classes.btn}
          type="primary"
          text="ОК"
          onClick={readonly ? onReadonlyOk : onOk}
        />
      </div>
    </Modal>
  );
};
