import { Checkbox, Space } from "antd";
import React from "react";

export const PDLBlock = ({ register, setValue, getValues }) => {
  return (
    <Space>
      <Checkbox
        {...register("person.ipdl.isPdl")}
        onChange={(e) => setValue("person.ipdl.isPdl", e.target.checked)}
        checked={getValues("person.ipdl.isPdl")}
      >
        ИПДЛ
      </Checkbox>
      <Checkbox
        {...register("person.mpdl.isPdl")}
        onChange={(e) => setValue("person.mpdl.isPdl", e.target.checked)}
        checked={getValues("person.mpdl.isPdl")}
      >
        МПДЛ
      </Checkbox>
      <Checkbox
        {...register("person.rpdl.isPdl")}
        onChange={(e) => setValue("person.rpdl.isPdl", e.target.checked)}
        checked={getValues("person.rpdl.isPdl")}
      >
        РПДЛ
      </Checkbox>
    </Space>
  );
};
