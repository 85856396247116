const config = {
  theme: {
    // primaryColor: colors.ruby1,
    primaryColor: "#f36223",
  },
};

/* eslint-disable prettier/prettier */
export const colors = {
  white: "#fff",
  white50: "rgba(255, 255, 255, 0.5)",
  black: "#000",
  black2: "#0C0C0D",
  black10: "rgba(0, 0, 0, 0.1)",
  black85: "rgba(0, 0, 0, 0.85)",
  black70: "rgba(0, 0, 0, 0.70)",
  black50: "rgba(0, 0, 0, 0.50)",
  blue: "#0A84FF",
  coal1: "#323E48",
  coal2: "#5B656D",
  coal3: "#757F86",
  coal4: "#8E909B",
  coal5: "#D6D8DA",
  ruby1: "#ff0078",
  ruby2: "#E92379",
  lavender: "#A798C5",
  gray: "#F0F2F5",
  gray2: "#F3F4F4",
  gray3: "#ADB2B6",
  grayLight: "#F0F0F0",
  grayDark: "#9B9B9B",
  disabled: "#F5F5F5",
  bodyBackground: "#f8f8f8",
  cardBackground: "#fafafa",
  secondaryBackground: "#EAECF5",
  borderColor: "#d9d9d9",
  borderHoverColor: "#303E49",
  primaryColor: "var(--ant-primary-color)",
  primaryColorHover: "var(--ant-primary-color-hover)",
  primaryColorActive: "var(--ant-primary-color-active)",
  primaryColorOutline: "var(--ant-primary-color-outline)",
  primaryColor1: "var(--ant-primary-1)",
  primaryColor2: "var(--ant-primary-2)",
  primaryColor3: "var(--ant-primary-3)",
  primaryColor4: "var(--ant-primary-4)",
  primaryColor5: "var(--ant-primary-5)",
  primaryColor6: "var(--ant-primary-6)",
  primaryColor7: "var(--ant-primary-7)",
  primaryColor8: "var(--ant-primary-8)",
  primaryColor9: "var(--ant-primary-9)",
  primaryColor10: "var(--ant-primary-10)",
  error: "var(--ant-error-color)",
  red: "#EB5757",
  redLight: "#F5D0CE",
  orange: "#F5A631",
  orange2: "#EB9A42",
  orangeLight: "#F5E5CE",
  green: "#27AE60",
  greenLight: "#D3F5CE",
  alertBackground: "var(--ant-error-color-deprecated-bg)",
  alertBorderColor: "var(--ant-error-color-deprecated-border)",
  factoringColor: "#ff5000",
  transparent: "transparent",
  carambola: "#dd0a34",
};

export const fonts = {
  mont: "Mont",
  montserrat: '"Montserrat", sans-serif',
  roboto: '"Roboto", sans-serif',
  uralsib: '"Uralsib*"',
  gratimoGrotesk: "GratimoGrotesk",
};

export const centered = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const justified = {
  ...centered,
  justifyContent: "space-between",
};

export const lefted = {
  ...centered,
  justifyContent: "left",
};

export const ended = {
  ...centered,
  justifyContent: "end",
};

export const grid = {
  ...centered,
  flexWrap: "wrap",
  gap: 20,
};

export const overflowEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const ellipsis = {
  whiteSpace: "nowrap",
  ...overflowEllipsis,
};

export const boxShadow = {
  boxShadow: [0, 4, 12, "rgb(0 0 0 / 8%)"],
};

export const borderRadius = 16;
export const borderRadiusHalf = 8;

export const locked = {
  backgroundImage:
    "url(\"data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 7.83331H3.33333C2.59695 7.83331 2 8.43027 2 9.16665V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V9.16665C14 8.43027 13.403 7.83331 12.6667 7.83331Z' stroke='%23D6D8DA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.66663 7.83331V5.16665C4.66663 4.28259 5.01782 3.43475 5.64294 2.80962C6.26806 2.1845 7.1159 1.83331 7.99996 1.83331C8.88401 1.83331 9.73186 2.1845 10.357 2.80962C10.9821 3.43475 11.3333 4.28259 11.3333 5.16665V7.83331' stroke='%23D6D8DA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E\")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: ["calc(100% - 16px)", "center"],
};

export const CalendarBackgroundImage = {
  backgroundImage:
    "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z' stroke='%238E909B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 2V6' stroke='%238E909B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2V6' stroke='%238E909B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 10H21' stroke='%238E909B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E\")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

export default config;
