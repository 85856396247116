import { axiosExternalTask, axiosFrontAgent } from "../axiosInstance";

const getCompleteTaskBodyPayload = (model, decision) => ({
  taskModel: {
    decision,
    components: model,
  },
  type: "COMPLETE",
});

export const TaskController = Object.freeze({
  getTasks: (params) => axiosFrontAgent.get(`/api/task/list`, { params }),
  getTaskById: (taskId) => axiosFrontAgent.get(`/task/${taskId}`),
  getTaskConfigById: (taskId) => axiosFrontAgent.get(`/task/${taskId}/config`),
  getScoringById: () => axiosFrontAgent.get(`/scoring/TEST-4a19d/bg-drd-demo-scoring`),
  assignTask: (taskId, user) => axiosFrontAgent.post(`/task/${taskId}/assign?user=${user}`),

  /**
   * @param {string} taskId
   * @param {object} model
   * @param {string} decision
   * @param {string} [token] - authorization token for the request
   */
  completeTask: (taskId, model, decision, token) => {
    const data = getCompleteTaskBodyPayload(model, decision);
    if (token) {
      return axiosExternalTask.post(`/task/complete?token=${token}`, data);
    }

    return axiosFrontAgent.post(`/task/${taskId}/complete`, data);
  },

  /**
   * @param {string} taskId
   * @param {object} model
   * @param {string} [token] - authorization token for the request
   */
  saveDraftTask: (taskId, model, token) => {
    const data = { components: model };

    if (token) {
      return axiosExternalTask.post(`/task/draft?token=${token}`, data);
    }

    return axiosFrontAgent.post(`/task/${taskId}/draft`, data);
  },
});
