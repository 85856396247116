import { StartFormWrapper } from "@/lib/modals/StartFormWrapper";
import { FormProvider, useForm } from "react-hook-form";
import { useStartForm } from "@/lib/modals/hooks/useStartForm";
import { completeStartForm } from "@store/formsSlice";
import { useAppDispatch } from "@store/store";
import { BgFormType } from "@/types/BgForm";
import { GuaranteeForm } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm";
import { PrincipalInfo } from "@/lib/modals/BankGuaranteeRequest/principalInfo";
import { notification } from "antd";
import { useEffect } from "react";
import { LOT_MODEL } from "@/lib/modals/BankGuaranteeRequest/utils/purchaseLotModels";
import { useUpdateBgTasks } from "@/lib/modals/BankGuaranteeRequest/hooks/useUpdateBgTasks";

export const GUARANTEE_TYPE_NAMES = {
  participation: "На участие в торгах",
  execution: "На исполнение обязательств по контракту",
  returnAdvance: "На возврат аванса",
  period: "На обеспечение гарантийных обязательств",
};

export const GUARANTEE_OPTIONS_AGENT = [
  { key: "participation", value: GUARANTEE_TYPE_NAMES["participation"] },
  {
    key: "execution",
    value: GUARANTEE_TYPE_NAMES["execution"],
  },
  { key: "returnAdvance", value: GUARANTEE_TYPE_NAMES["returnAdvance"] },
  {
    key: "period",
    value: GUARANTEE_TYPE_NAMES["period"],
  },
];

export const BankGuaranteeRequestAgent = ({ id }) => {
  const dispatch = useAppDispatch();
  const methods = useForm<BgFormType>({
    defaultValues: {
      purchase: {
        lots: [LOT_MODEL],
      },
    },
  });

  const {
    formState: { errors, isSubmitted },
    handleSubmit,
  } = methods;

  const { isFetching, setIsFetching, onClose, onError, onSuccess } = useStartForm({
    dispatch,
    successTitle: "Заявка отправлена",
  });

  const { updateBgTasks } = useUpdateBgTasks();

  const onSubmitSuccess = () => {
    onSuccess();
    updateBgTasks();
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      notification.error({
        message: "Ошибка отправки формы.",
        description: "Проверьте заполненные поля",
      });
    }
  }, [isSubmitted]);

  const isSubmitDisabled = !!Object.keys(errors).length;

  const onSubmit = handleSubmit((data) => {
    if (isSubmitDisabled) return;
    setIsFetching(true);

    dispatch(
      completeStartForm({
        id,
        payload: data,
        onSuccess: onSubmitSuccess,
        onError,
      })
    );
  });

  return (
    <StartFormWrapper
      title="Банковская гарантия"
      formName="bankGuaranteeRequest"
      isFetching={isFetching}
      onSubmit={onSubmit}
      onClose={onClose}
      className="bg-request"
      isSubmitDisabled={isSubmitDisabled}
    >
      <FormProvider {...methods}>
        <PrincipalInfo />
        <GuaranteeForm />
      </FormProvider>
    </StartFormWrapper>
  );
};
