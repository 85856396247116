import React, { useLayoutEffect, useState } from "react";
import { Routes } from "./routes";
import "moment/locale/ru";
import ExternalTask from "./pages/ExternalTask";
import "./static/scss/style.scss";
import { WithKeycloak } from "./providers";
import { AppProvider } from "./providers/appProvider";

export const App = () => {
  const [isUnregisteredRoute, setIsUnregisteredRoute] = useState<boolean>(true);

  useLayoutEffect(() => {
    setIsUnregisteredRoute(window.location.href.includes("external-task"));
  }, []);

  return (
    <AppProvider>
      {isUnregisteredRoute ? (
        <ExternalTask />
      ) : (
        <WithKeycloak>
          <Routes />
        </WithKeycloak>
      )}
    </AppProvider>
  );
};
