import { FormAutoComplete } from "@/_custom/components/Common/FormAutoComplete";
import React, { ReactNode } from "react";
import { Control, FieldValues, UseFormSetValue } from "react-hook-form";

type PersonSearchProps = {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  hiddenFieldNames?: unknown[];
  getVisibleFieldName?: (prefix: string) => string;
  prefix: string;
  setValue: UseFormSetValue<FieldValues>;
  control: Control;

  showHint?: boolean;
  forceFetch?: boolean;
  controlled?: boolean;
  placeholder?: string;
  getHiddenFieldNames?: (prefix: string) => string;
  className?: string;
  fullAddress?: boolean;
  notFoundContent?: ReactNode;
  extraOption?: { label: ReactNode; value: string; key: string };
};

export const PersonSearch = ({
  label,
  prefix,
  control,
  showHint,
  disabled,
  required,
  setValue,
  forceFetch,
  controlled,
  placeholder,
  hiddenFieldNames = [],
  getVisibleFieldName,
  getHiddenFieldNames,
  className,
  notFoundContent,
  extraOption,
}: PersonSearchProps) => {
  return (
    <FormAutoComplete
      type="person"
      label={label}
      prefix={prefix}
      className={className}
      // classes={classes}
      control={control}
      setValue={setValue}
      required={required}
      disabled={disabled}
      showHint={showHint}
      controlled={controlled}
      forceFetch={forceFetch}
      placeholder={placeholder}
      hiddenFieldNames={hiddenFieldNames}
      getVisibleFieldName={getVisibleFieldName}
      getHiddenFieldNames={getHiddenFieldNames}
      notFoundContent={notFoundContent}
      extraOption={extraOption}
    />
  );
};
