import React from "react";
import Icons from "./img.svg?url";

import FarzoomLogo from "./farzoom-logo.svg";
import RTLogo from "./rt-logo.svg";

const Icon = ({ className = "", iconName = "", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      style={style}
    >
      <use xlinkHref={`${Icons}#${iconName}`} />
    </svg>
  );
};

export { Icon as default, Icon, FarzoomLogo, RTLogo };
