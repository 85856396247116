import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { RootState } from "@store/store";

const initialState = {
  isTest: false,
  isDownload: false,
};

const signSlice = createSlice({
  name: "signSlice",
  initialState,
  reducers: {
    setTestSign(state) {
      notification.success({
        message: `${!state.isTest ? "Включено" : "Выключено"} тестовое подписание`,
      });
      state.isTest = !state.isTest;
    },
    setDownload(state) {
      notification.success({
        message: `${
          !state.isDownload ? "Включено" : "Выключено"
        } тестовое скачивание документов при подписании`,
      });
      state.isDownload = !state.isDownload;
    },
  },
});

export const { setTestSign, setDownload } = signSlice.actions;
export const isTestSign = (state: RootState) => state.carrot.sign.isTest;
export const isTestDownload = (state: RootState) => state.carrot.sign.isDownload;

export default signSlice.reducer;
