import { Redirect, Route, RouteProps } from "react-router-dom";
import { Sidebar } from "@components/Sidebar";
import { ModalContainer } from "@/lib/ModalContainer";
import React, { ComponentType } from "react";
import { CreateCustomerButton } from "@components/BankGuarantee/createCustomerButton";
import { useAccessRoles } from "@hooks/useAccessRoles";

interface LayoutProps extends RouteProps {
  component: ComponentType;
  role: string;
}

export const BGLayout = ({ component: Component, role, ...rest }: LayoutProps) => {
  const { hasRealmRole } = useAccessRoles();

  return (
    <Route {...rest}>
      <div className="fr-app">
        <Sidebar />
        <div className="fr-container">
          {/*<Header />*/} {/*TODO: это только мобильный хедер*/}
          {hasRealmRole(role) ? <Component /> : <Redirect to="/404" />}
        </div>
        <CreateCustomerButton />
        <ModalContainer />
      </div>
    </Route>
  );
};
