export const PURCHASE_MAP_ERROR_TYPE = {
  multilot: "multilot",
  cooperative: "cooperative",
  purchaseMapError: "purchaseMapError",
};

export const PURCHASE_MAP_ERRORS = {
  [PURCHASE_MAP_ERROR_TYPE.multilot]: "Мультилотные закупки не поддерживаются",
  [PURCHASE_MAP_ERROR_TYPE.cooperative]: "Кооперативные закупки не поддерживаются",
};
