export const PERSON_ROLES_MAP = {
  isHead: { name: "Руководитель компании", color: "magenta" },
  isSigner: { name: "Подписант", color: "red" },
  isFounder: { name: "Учредитель", color: "volcano" },
  isBeneficiaryOwner: { name: "Бенефициарный владелец", color: "blue" },
  isSupervisoryBoardMember: { name: "Член наблюдательного совета", color: "geekblue" },
  isCollegialExecutiveBodyMember: {
    name: "Член коллегиального исполнительного органа",
    color: "purple",
  },
};

export const restrictedRolesToShow = ["employee", "beneficiaryOwner", "founder"];
