import { createUseStyles } from "react-jss";
import { borderRadius, colors, justified } from "@farzoom/common-ui-components";

export const useStyles = createUseStyles({
  row: {
    // marginBottom: 45,
    ...justified,
    "& .ant-form-item": {
      marginBottom: 0,
    },
  },
  editableRow: {},
  formItem: {
    "&.ant-form-item": {
      width: 190,
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: 0,
      "& .ant-select": {
        width: 180,
      },
    },
  },
  readonly: {
    background: "#eee",
    width: 190,
    padding: [3, 14],
    borderRadius,
  },
  select: {
    "& .ant-select-selector": {
      borderRadius: [[borderRadius], "!important"],
    },
  },
  table: {
    "&.error": {
      marginTop: 6,
      borderRadius: [[borderRadius], "!important"],
      outline: [1, "solid", colors.error],
    },
    // '& .ant-table-cell': {
    //   '&:first-child': {
    //     borderTopLeftRadius: [borderRadius, '!important'],
    //   },
    //   '&:last-child': {
    //     borderTopRightRadius: [borderRadius, '!important'],
    //   },
    // },
  },
  errorMessage: {
    color: colors.error,
    paddingLeft: 40,
  },
  successfulMessage: {
    color: colors.green,
    paddingLeft: 40,
  },
  recognition: {
    paddingLeft: 5,
  },
});
