import { Button } from "@farzoom/common-ui-components";
import { useState } from "react";
import CommissionChangeModal from "@components/BankGuarantee/commissionChangeRequest/commissionChangeModal";

export const CommissionChangeRequest = ({ orderId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChangeModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <Button type="primary" text="Запросить изменение комиссии" onClick={onChangeModal} />
      {isModalOpen && (
        <CommissionChangeModal
          id="carrot-cpa-bg-agent-commission"
          orderId={orderId!}
          onClose={onChangeModal}
        />
      )}
    </>
  );
};
