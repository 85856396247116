import React from "react";
import { cn } from "../../../../../utils/bemConfig";
import "./Actions.scss";

const bem = cn("action");

export const Action = ({ title, children, className, onClick }) => (
  <div className={bem(null, [className])} title={title} onClick={onClick}>
    {children}
  </div>
);
