import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DatePicker, Input } from "antd";
import { createUseStyles } from "react-jss";
import {
  BaseInput,
  BE_FORM_DATE_FORMAT,
  borderRadiusHalf,
  colors,
  dateDots,
  FE_FORM_DATE_FORMAT,
} from "@farzoom/common-ui-components";

const useStyles = createUseStyles({
  input: {
    color: colors.coal1,
    height: 54,
    padding: 14,
    fontSize: 14,
    lineHeight: 22 / 14,
    letterSpacing: -0.5,
    border: [1, "solid", colors.coal5],
    borderRadius: borderRadiusHalf,
    outline: "none",
    transition: ["border", "300ms"],
    "&:hover, &:focus": {
      borderColor: colors.coal1,
    },
    "&.disabled": {
      color: colors.coal5,
      backgroundColor: colors.white,
      cursor: "not-allowed",
      "&:hover, &:focus": {
        borderColor: colors.coal5,
      },
    },
  },
});

export const DateInput = ({
  placeholder,
  disabled,
  disabledDate,
  className,
  onChange,
  value,
  readonly,
  allowClear,
  type,
  format,
}) => {
  const ref = useRef(null);

  const BE_FORMAT = format || BE_FORM_DATE_FORMAT;
  const FE_FORMAT = format || FE_FORM_DATE_FORMAT;

  const onChangeDate = (date) => onChange(date?.format(BE_FORMAT) || null);

  const dateValue = value ? moment(value, BE_FORMAT) : null;

  useEffect(() => {
    const container = ref?.current?.children && ref?.current?.children[0];
    const dateInput = container?.children && container?.children[0]?.children[0];

    if (dateInput) {
      dateInput.addEventListener("keyup", dateDots);
      dateInput.maxLength = 10;
    }

    return () => {
      dateInput?.removeEventListener("keyup", dateDots);
    };
  }, []);

  const preparedPlaceholder = disabled ? "" : placeholder;

  const isMonthPicker = format === "YYYY-MM";

  const onInputChange = (value) => {
    if (disabledDate) {
      if (
        value.length === 10 &&
        moment(value, FE_FORMAT).isValid() &&
        !disabledDate(moment(value, FE_FORMAT))
      ) {
        onChange(moment(value, FE_FORMAT)?.format(BE_FORMAT));
      }

      return;
    }

    if (value.length === 10 && moment(value, FE_FORMAT).isValid()) {
      onChange(moment(value, FE_FORMAT)?.format(BE_FORMAT));
    }
  };

  return (
    <BaseInput
      value={value}
      disabled={disabled}
      readonly={readonly}
      type={type}
      withoutFormatter={isMonthPicker}
    >
      <div ref={ref}>
        <DatePicker
          className={className}
          size="large"
          format={FE_FORMAT}
          placeholder={preparedPlaceholder}
          value={dateValue}
          onChange={onChangeDate}
          disabled={disabled}
          disabledDate={disabledDate}
          allowClear={allowClear}
          picker={isMonthPicker ? "month" : "date"}
          inputRender={(inputProps) => (
            <Input
              {...inputProps}
              onInput={() => onInputChange(inputProps.ref.current.input.value)}
            />
          )}
        />
      </div>
    </BaseInput>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  readonly: PropTypes.bool,
  type: PropTypes.string,
};
