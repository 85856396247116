import { BgCardHeader } from "@components/BankGuarantee/BgInfoCard/bgCardHeader";
import { useStyles } from "./styles";
import { useState } from "react";
import { BgModal } from "@components/BankGuarantee/BgInfoCard/bgModal";
import { GUARANTEE_TYPE_NAMES } from "@/lib/modals/BankGuaranteeRequest/bankGuaranteeRequestAgent";
import { Space, Typography } from "antd";

const { Text } = Typography;

type GuaranteeType = keyof typeof GUARANTEE_TYPE_NAMES;

export type ShortInfoType = {
  purchaseNumber: string;
  guaranteeType: GuaranteeType;
};

export const BgInfoCard = (props) => {
  const classes = useStyles();
  const { config, getValues, prefix } = props;

  const bgInfo = getValues(prefix);

  const [isModal, setIsModal] = useState(false);

  const [cardInfo, setCardInfo] = useState<ShortInfoType>({
    purchaseNumber: bgInfo.purchaseNumber,
    guaranteeType: bgInfo.guaranteeType,
  });

  const onChangeModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const onSaveBg = (data: ShortInfoType) => {
    setCardInfo(data);
    onChangeModal();
  };

  return (
    <>
      <div className={classes.title}>{config.title}</div>
      <div className={classes.wrapper}>
        <BgCardHeader onClick={onChangeModal} purchaseNumber={cardInfo.purchaseNumber} />

        <Space size="large">
          <span>
            <Text type="secondary" className={classes.label}>
              Тип гарантии
            </Text>
            <Text>{GUARANTEE_TYPE_NAMES[cardInfo.guaranteeType]}</Text>
          </span>
        </Space>
      </div>

      {isModal && <BgModal {...props} onClose={onChangeModal} onSave={onSaveBg} />}
    </>
  );
};
