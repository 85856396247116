export const COUNTRIES = [
  {
    key: "RU",
    value: "Россия",
  },
  {
    key: "KZ",
    value: "Казахстан",
  },
  {
    key: "BY",
    value: "Беларусь",
  },
];

export const BENEFICIARY_REASONS = [
  { key: "controlOfActions", value: "Возможность контролировать действия Клиента" },
  { key: "directCapitalParticipation", value: "Прямое участие в капитале Клиента" },
  { key: "indirectCapitalParticipation", value: "Косвенное участие в капитале Клиента" },
  { key: "companyHead", value: "Единоличный исполнительный орган юридического лица" },
];

export const IDENTITY_DOCUMENTS = [
  {
    key: "passportRf",
    value: "Паспорт РФ",
  },
];
