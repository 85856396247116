import React from "react";
import PropTypes from "prop-types";
import { FormAutoComplete } from "./FormAutoComplete";

export const CompanySuggestion = ({
  label,
  prefix,
  control,
  required,
  setValue,
  register,
  controlled,
  allowClear,
  placeholder,
  errorMessage,
  hiddenFieldNames,
  getHiddenFieldNames,
  getVisibleFieldName,
  className,
  parentOnChange,
  rules,
}) => (
  <FormAutoComplete
    className={className}
    type="company"
    label={label}
    prefix={prefix}
    control={control}
    required={required}
    register={register}
    setValue={setValue}
    controlled={controlled}
    allowClear={allowClear}
    placeholder={placeholder}
    errorMessage={errorMessage}
    hiddenFieldNames={hiddenFieldNames}
    getHiddenFieldNames={getHiddenFieldNames}
    getVisibleFieldName={getVisibleFieldName}
    parentOnChange={parentOnChange}
    rules={rules}
  />
);

CompanySuggestion.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  register: PropTypes.func,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
  hiddenFieldNames: PropTypes.array,
  getVisibleFieldName: PropTypes.func,
  prefix: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,

  controlled: PropTypes.bool,
  getHiddenFieldNames: PropTypes.array,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  parentOnChange: PropTypes.func,
  rules: PropTypes.object,
};
