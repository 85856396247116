import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { AutoComplete as AntdAutoComplete, Form, Input, Skeleton } from "antd";
import {
  borderRadius,
  borderRadiusHalf,
  colors,
  ERROR_MESSAGES,
  MEDIA_QUERY,
  useDebouncedEffect,
} from "@farzoom/common-ui-components";

const emptyHiddenFields = (hiddenFieldNames) => {
  const options = {};
  hiddenFieldNames.forEach((hiddenFieldName) => {
    options[hiddenFieldName] = undefined;
  });
  return options;
};

const useStyles = createUseStyles({
  autoComplete: {
    marginBottom: 10,
    display: "block",
    "& .ant-input": {
      borderRadius,
      [MEDIA_QUERY.mobile]: {
        borderRadius: borderRadiusHalf,
      },
    },
    "& .ant-select-dropdown": {
      borderRadius,
      [MEDIA_QUERY.mobile]: {
        borderRadius: borderRadiusHalf,
      },
    },
    "& .ant-btn": {
      borderRadius: [0, borderRadius, borderRadius, 0],
      [MEDIA_QUERY.mobile]: {
        borderRadius: [0, borderRadiusHalf, borderRadiusHalf, 0],
      },
    },
    "& .ant-select": {
      borderRadius,
      [MEDIA_QUERY.mobile]: {
        borderRadius: borderRadiusHalf,
      },
    },
    "& .ant-input-group-addon": {
      background: colors.white,
      [MEDIA_QUERY.mobile]: {
        background: colors.transparent,
      },
    },
    "& .ant-skeleton-button-round": {
      borderRadius,
      [MEDIA_QUERY.mobile]: {
        borderRadius: borderRadiusHalf,
      },
    },
  },
});

export const AutoComplete = ({
  classes: propsClasses,
  value,
  onChange,
  setHiddenFields,
  placeholder,
  label,
  hiddenFieldNames,
  fetchOptions,
  forceFetch = false,
  getAutoCompleteOption,
  allowClear,
  invalid,
  error,
  required,
  disabled,
  controlled,
  errorMessage = ERROR_MESSAGES.required,
  notFoundContent,
  extraOption,
}) => {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(forceFetch);
  const [options, setOptions] = useState(() => []);
  const [internalDisabled, setInternalDisabled] = useState(!!forceFetch || !!disabled);
  const [isSelected, setIsSelected] = useState(false);
  // TODO: свойство костыль, чтобы наблюре не удалять данные из компонента
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setInternalDisabled(!!forceFetch || !!disabled);
  }, [forceFetch, disabled]);

  useEffect(() => {
    if (forceFetch && searchQuery !== value) {
      setSearchQuery(value);
    }
  }, [forceFetch, value]);

  const onSearch = (search) => {
    setIsSelected(false);
    setIsDirty(true);
    if (search) {
      setSearchQuery(search);
    } else {
      setOptions([]);
      setHiddenFields(emptyHiddenFields(hiddenFieldNames));
      onChange(null);
      setIsDirty(false);
    }
  };

  const onSelect = (newValue, option) => {
    if (option.key === "custom") {
      setIsDirty(false);
      return;
    }

    onChange(option?.shortname || newValue, option);
    setHiddenFields(option);
    setIsSelected(true);
    setIsDirty(false);
  };

  const onBlur = () => {
    if (!isSelected && isDirty) {
      onChange(null);
      setHiddenFields(emptyHiddenFields(hiddenFieldNames));
      setOptions([]);
      setSearchQuery("");
      setIsDirty(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (!forceFetch) {
        if ((!forceFetch && loading) || searchQuery) {
          fetchOptions(searchQuery).then((data) => {
            const newOptions = (data || []).map((option) =>
              getAutoCompleteOption(option, searchQuery, propsClasses)
            );
            setOptions(extraOption ? [...newOptions, extraOption] : newOptions);
          });
        }
      }
    },
    [searchQuery],
    300
  );

  useEffect(() => {
    // если требуется предзаполнение поля с бэкенда
    if (forceFetch && value === searchQuery) {
      fetchOptions(searchQuery)
        .then((data) => {
          const newOptions = (data || []).map((option) =>
            getAutoCompleteOption(option, searchQuery, propsClasses)
          );
          setOptions(newOptions);
          if (newOptions.length) {
            onChange(newOptions[0]?.value || "");
            setHiddenFields(newOptions[0]);
          }
          if (!newOptions?.length) {
            setInternalDisabled(false);
          }
        })
        .catch(() => {})
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 50);
        });
    }
  }, [forceFetch, searchQuery]);

  return (
    <>
      <Form.Item
        className={classes.autoComplete}
        label={label}
        validateStatus={invalid ? "error" : undefined}
        required={required}
        help={invalid && !error?.message ? errorMessage : error?.message}
      >
        {loading ? (
          <Skeleton.Button size="large" shape="round" active block />
        ) : (
          <AntdAutoComplete
            getPopupContainer={(trigger) =>
              document.querySelector("[class*=app]") || trigger?.parentElement || document.body
            }
            popupClassName="certain-category-search-dropdown"
            onSearch={onSearch}
            options={options}
            defaultValue={value}
            onSelect={onSelect}
            disabled={internalDisabled}
            onChange={controlled || forceFetch ? onChange : undefined}
            value={controlled || forceFetch ? value : undefined}
            notFoundContent={notFoundContent}
            onBlur={onBlur}
          >
            <Input size="large" placeholder={placeholder} allowClear={allowClear} />
          </AntdAutoComplete>
        )}
      </Form.Item>
      {hiddenFieldNames.map((name, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Form.Item key={`${name}-${index}`} name={name} hidden />
      ))}
    </>
  );
};

AutoComplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setHiddenFields: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  hiddenFieldNames: PropTypes.array,
  getAutoCompleteOption: PropTypes.func,
  fetchOptions: PropTypes.func,
  invalid: PropTypes.bool,
  forceFetch: PropTypes.bool,
  error: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  controlled: PropTypes.bool,
  notFoundContent: PropTypes.node,
  extraOption: PropTypes.object,
};
