/* eslint-disable max-len */
import { borderRadiusHalf, colors, fonts } from "./theme";

export const carrotColors = {
  main: "#F36223",
  inputBg: "#F0F2F5",
  primary: "#242729",
  blue800: "#3557A6",
  green100: "#F4FBF7",
  green400: "#D8F8E7",
  green600: "#75CC9E",
  green800: "#1D7C4D",
  orange100: "#FEE9D8",
  orange200: "#fbe1cc",
  orange400: "#FA9368",
  orange600: "#F84C23",
  orange800: "#CE2C0B",
  yellow100: "#FFF2CC",
  yellow400: "#FFE499",
  yellow600: "#FFBE08",
  yellow800: "#CA9500",
  grey100: "#F7F7F7",
  grey400: "#E2E2E2",
  grey800: "#8E909B",
  grey1100: "#2D2D2D",
};

const cssVariables = {
  "--ant-primary-color": carrotColors.main,
  "--ant-primary-color-hover": "rgba(243, 98, 35, 0.96)",
  "--ant-primary-color-outline": "rgba(243, 98, 35, 0.2)",
};

export const carrot = {
  "@global": {
    ":root": {
      "--ant-primary-color-outline": "rgba(243, 98, 35, 0.2)",
    },
  },
  "&.carrot": {
    color: carrotColors.primary,
    ...cssVariables,
    "& .ant-tabs, & .ant-form, & h1, & h2, & h3, & h4, & h5, & h6": {
      color: carrotColors.primary,
    },
    "& [class*=leftBlock]": {
      "& > div[class*=block]": {
        marginTop: 25,
      },
      "& > div:first-of-type": {
        marginTop: 6,
      },
      "& div[class*=formSelect], & div[class*=textarea]": {
        marginBottom: 8,
      },
    },
    "& [class*=rightBlockContent] > div[class*=h2]": {
      lineHeight: 1,
      fontFamily: fonts.roboto,
    },
    "& .modal": {
      "& .ant-form-item-label": {
        width: "100%",
        "& > label": {
          color: carrotColors.grey800,
          width: "100%",
        },
      },
      "& [class*=formInputMoney] .ant-input-number, & [class*=formSelect] .ant-select-selector, & .ant-input, & .ant-picker, & textarea.ant-input":
        {
          borderRadius: "8px !important",
          backgroundColor: carrotColors.inputBg,
          "&:not(:hover):not(.ant-input-status-error):not(.ant-picker-status-error.ant-picker)": {
            borderColor: carrotColors.inputBg,
          },
        },
      "& .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover":
        { backgroundColor: carrotColors.inputBg },
      "& [class*=wrapper] > .ant-row > .ant-col": {
        alignItems: "start",
        "& + .ant-col": {
          margin: 0,
        },
      },
      "& [class*=wrapper] > .ant-row": {
        rowGap: "16px !important",
      },
    },
    "& .common-ui-components-sign__edit_isMass:hover svg": {
      fill: "white",
    },
    "& [class*=autoComplete] .ant-select, & [class*=autoComplete] .ant-input": {
      borderRadius: borderRadiusHalf,
    },
    "& [class*=baseInput] > div .ant-input-affix-wrapper": {
      borderRadius: borderRadiusHalf,
    },
    "& div.ant-picker": {
      backgroundColor: "white",
    },
    "& .ant-input-focused, & .ant-input:focus": {
      boxShadow: `0 0 0 2px ${colors.redLight}`,
    },
  },
};
