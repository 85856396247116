import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getOrderSections } from "./getOrderSections";
import { InfoForm } from "../../../_components/InfoForm";
import { Button, notification } from "antd";
import { hasNonReadonlyComponent } from "@utils/hasReadonlyComponent";
import { OrderController } from "@/services/api/controllers";
import { createUseStyles } from "react-jss";
import { updateOrder } from "@/services/api/tasks";

const useStyles = createUseStyles({
  actions: {
    position: "sticky",
    bottom: 0,
    paddingTop: 16,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
    zIndex: 100,
  },
});

export const OrderInfoWrapper = ({ title, model, config, orderId, userInfo, onSaveCallback }) => {
  const classes = useStyles();
  const [sections, setSections] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isReadonly, setIsReadonly] = useState(true);
  const isSubmitted = useRef(false);

  const formMethods = useForm({
    defaultValues: {
      ...model?.components,
      guarantee: model?.components?.productBg?.guarantee,
      purchase: model?.components?.productBg?.purchase,
    },
  });

  useEffect(() => {
    if (isSubmitted.current) {
      formMethods.reset(model?.components);
      isSubmitted.current = false;
    }
  }, [isSubmitted.current]);

  useEffect(async () => {
    const { data: orderDetails } = await OrderController.getOrder(orderId);
    const newSections = getOrderSections({
      ...formMethods,
      models: model?.components,
      order: orderDetails?.order,
      config,
      userInfo,
    });
    setSections(newSections);
    setIsFetching(false);
  }, []);

  useEffect(() => {
    for (const [_, { components }] of Object.entries(config.tabs)) {
      if (hasNonReadonlyComponent(Object.values(components))) {
        setIsReadonly(false);
        return;
      }
    }
  }, []);

  const onSubmit = formMethods.handleSubmit(async (data) => {
    try {
      setIsFetching(true);
      await updateOrder({ orderId, components: data });
      if (onSaveCallback) {
        await onSaveCallback();
      }

      notification.success({
        message: "Заявка обновлена!",
      });
      isSubmitted.current = true;
    } catch (err) {
      console.error("Order info form update failed:", err);

      notification.error({
        message: "Что-то пошло не так...",
        description: "Попробуйте ещё раз",
      });
    } finally {
      setIsFetching(false);
    }
  });

  const formActions = !isReadonly ? (
    <div className={classes.actions}>
      <Button type="primary" onClick={onSubmit}>
        Сохранить
      </Button>
    </div>
  ) : null;

  return (
    <FormProvider {...formMethods}>
      <InfoForm isFetching={isFetching} title={title} sections={sections} actions={formActions} />
    </FormProvider>
  );
};
