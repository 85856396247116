import React from "react";
import classnames from "classnames";
import { Form } from "antd";
import { createUseStyles } from "react-jss";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseFormSetValue,
  Validate,
} from "react-hook-form";
import { DateInput } from "./components/DateInput";
import { TimeInput } from "./components/TimeInput";
import { DateTimeInput } from "./components/DateTimeInput";
import { ERROR_MESSAGES } from "@farzoom/common-ui-components";
import { Moment } from "moment";

const useStyles = createUseStyles({
  formInputDateTime: {
    display: "block",
    "& .ant-form-item-row": {
      display: "block",
    },
  },
  input: {
    width: "100%",
  },
});

type InputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;

  visibleFieldName?: string;
  disabled?: boolean;
  allowClear?: boolean;
  type?: string;
  setValue?: UseFormSetValue<T>;
  placeholder?: string;
  label?: string;
  readonly?: boolean;
  required?: boolean;
  className?: string;
  format?: string;

  disabledDate?: (current: Moment) => boolean;
  disabledTime?: (current: Moment) => boolean;
  validate?:
    | Validate<PathValue<T, Path<T>>, T>
    | Record<string, Validate<PathValue<T, Path<T>>, T>>;
  parentOnChange?: (value: string) => void;
  minuteStep?: number;
  [key: string]: unknown;
};

export const FormInputDateTime = <T extends FieldValues>({
  name,
  label,
  placeholder,
  type,
  disabled,
  disabledDate,
  disabledTime,
  control,
  readonly,
  className,
  required = false,
  validate,
  allowClear,
  parentOnChange,
  minuteStep,
  format,
  ...rest
}: InputProps<T>) => {
  const classes = useStyles();

  const calcRequired = !readonly && required;

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: calcRequired,
        message: ERROR_MESSAGES.required,
      },
      validate,
    },
  });

  let CurrentDateTimeInput;

  switch (type) {
    case "datetime":
      CurrentDateTimeInput = DateTimeInput;
      break;
    case "time":
      CurrentDateTimeInput = TimeInput;
      break;
    case "date":
    default:
      CurrentDateTimeInput = DateInput;
  }

  const validationMessage = error?.message;

  const handleChange = parentOnChange
    ? (val) => {
        onChange(val);
        parentOnChange(val);
      }
    : onChange;

  return (
    <Form.Item
      className={classnames(classes.formInputDateTime, className)}
      label={label}
      required={calcRequired}
      validateStatus={invalid ? "error" : undefined}
      help={validationMessage}
    >
      <CurrentDateTimeInput
        className={classes.input}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        readonly={readonly}
        allowClear={allowClear}
        minuteStep={minuteStep}
        format={format}
        {...rest}
      />
    </Form.Item>
  );
};
