import { axiosExternalTask, axiosInstance } from "../axiosInstance";

const fileRequestHeaders = {
  "content-type": "multipart/form-data",
};

const documentControllerUrl = "/document";

export const DocumentController = Object.freeze({
  /**
   * Отправляем на сервер файл.
   *
   * @param {object} data - данные файла
   * @param {string} [token] - токен авторизации (optional, если external-task)
   */
  upload: (data, token) => {
    const config = { headers: fileRequestHeaders };

    if (token) {
      return axiosExternalTask.post(`/file?token=${token}`, data, config);
    }

    return axiosInstance.post(`${documentControllerUrl}/file`, data, config);
  },
  download: (id) => axiosInstance.get(`${documentControllerUrl}/file/${id}`),
  downloadOriginal: (id) => axiosInstance.get(`${documentControllerUrl}/file/${id}/original`),
  downloadSig: (id) => axiosInstance.get(`${documentControllerUrl}/file/${id}/sign`),
  downloadBuffer: (id, token) => {
    if (token) {
      return axiosExternalTask.get(`/file/${id}?token=${token}`, {
        responseType: "arraybuffer",
      });
    }

    return axiosInstance.get(`${documentControllerUrl}/file/${id}`, {
      responseType: "arraybuffer",
    });
  },
});
