import { axiosFrontAgent } from "../axiosInstance";

const companyControllerUrl = "/company";
const genParamsControllerUrl = "/genparams";
const documentControllerUrl = "/document";
const liabilitiesControllerUrl = "/liability";

export const CompanyInfoController = Object.freeze({
  getCompanyRequisites: (companyId) =>
    axiosFrontAgent.get(`${companyControllerUrl}/${companyId || "vCag4l9uMX6YsOa3QEKBi"}/detail`),
  getCompanyBuhReports: (companyId) =>
    axiosFrontAgent.get(`${companyControllerUrl}Buh/${companyId || "vCag4l9uMX6YsOa3QEKBi"}/list`),
  getCompanyPersons: (companyId) =>
    axiosFrontAgent.get(`${companyControllerUrl}/${companyId || "vCag4l9uMX6YsOa3QEKBi"}/person`),
  getCompanyPrincipalContact: (companyId) =>
    axiosFrontAgent.get(
      `${genParamsControllerUrl}/principalContact/${companyId || "vCag4l9uMX6YsOa3QEKBi"}`
    ),
  getCompanyDocuments: (companyId) =>
    axiosFrontAgent.get(`${documentControllerUrl}/${companyId || "vCag4l9uMX6YsOa3QEKBi"}/list`),
  getCompanyLiabilities: (companyId) =>
    axiosFrontAgent.get(`${liabilitiesControllerUrl}/${companyId || "vCag4l9uMX6YsOa3QEKBi"}/list`),
  recognizeBuhReport: (data) => axiosFrontBank.post(`/api/buh-facade/parse/osn `, data),
});
