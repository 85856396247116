import React from "react";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { H2, colors, fonts, lefted } from "@farzoom/common-ui-components";
import { LinkOutlined } from "@ant-design/icons";

const useStyles = createUseStyles({
  orderInfoHeader: {
    background: colors.white,
    paddingBottom: 16,
    borderBottom: [1, "solid", colors.black10],
    position: "sticky",
    top: 0,
    zIndex: 3,
  },
  title: {
    whiteSpace: "nowrap",
    position: "relative",
  },
  wrapper: {
    ...lefted,
    gap: 20,
  },
  status: {
    padding: [4, 12],
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 500,
    fontFamily: fonts.roboto,
    border: [1, "solid", colors.borderColor],
    borderRadius: 100,
    whiteSpace: "nowrap",
    "&.lost": {
      background: colors.redLight,
      color: colors.red,
      borderColor: colors.redLight,
    },
    "&.inprogress": {
      background: colors.orangeLight,
      color: colors.orange,
      borderColor: colors.orangeLight,
    },
    "&.executed": {
      background: colors.greenLight,
      color: colors.green,
      borderColor: colors.greenLight,
    },
  },
});

export const OrderInfoHeader = ({ title = "Отсутствует номер заявки", orderStatus = {}, processInstanceUrl }) => {
  const classes = useStyles();
  const { color, title: statusTitle } = orderStatus || {};
  return (
    <div className={classes.orderInfoHeader}>
      <div className={classes.wrapper}>
        <H2 className={classes.title}>{title} {processInstanceUrl && <a href={processInstanceUrl} target="_blank"><LinkOutlined /></a>}</H2>
        <div className={classnames(classes.status, { [color]: color })}>{statusTitle}</div>
      </div>
    </div>
  );
};
