import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../services/api";

const initialState = {
  isFetching: false,
  username: "",
  fullname: "",
  processDefinitionKeys: [],
  availableProcesses: [],
  availableOrderTypes: [],
  keycloak: {},
};

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async () => await User.getUserInfo()
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.availableOrderTypes = action.payload.availableOrderTypes;
        state.availableProcesses = action.payload.avaliableProcesses;
        state.username = action.payload.login;
        state.fullname = `${action.payload.firstName} ${action.payload.lastName}`;
        state.lastName = action.payload.lastName;
        state.company = action.payload.company;
        state.nextTask = action.payload.nextTask;
        state.isFetching = false;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const userState = (state) => state?.carrot?.user;
export const fullName = (state) => state?.carrot?.user.fullname;
export const userName = (state) => state?.carrot?.user.username;
export const processes = (state) => state?.carrot?.user.availableProcesses;

export default userSlice.reducer;
