import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DatePicker } from "antd";
import { BaseInput, dateDots, formattedDateTime } from "@farzoom/common-ui-components";

export const DateTimeInput = ({
  placeholder,
  disabled,
  disabledDate,
  className,
  onChange,
  value,
  readonly,
  allowClear,
  minuteStep,
  type,
}) => {
  const ref = useRef(null);

  const onChangeDate = (date) => onChange(date?.toISOString() || null);

  useEffect(() => {
    const container = ref?.current?.children && ref?.current?.children[0];
    const dateInput = container?.children && container?.children[0]?.children[0];

    if (dateInput) {
      dateInput.addEventListener("keyup", dateDots);
      dateInput.maxLength = 16;
    }

    return () => {
      dateInput?.removeEventListener("keyup", dateDots);
    };
  }, []);

  return (
    <BaseInput value={formattedDateTime(value)} disabled={disabled} readonly={readonly} type={type}>
      <div ref={ref}>
        <DatePicker
          className={className}
          getPopupContainer={() =>
            ref?.current?.parentElement?.parentElement?.parentElement?.parentElement ||
            document.body
          }
          placeholder={placeholder}
          value={value ? moment(value) : null}
          size="large"
          format="DD.MM.YYYY HH:mm"
          showTime={{ format: "HH:mm" }}
          onChange={onChangeDate}
          disabled={disabled}
          disabledDate={disabledDate}
          allowClear={allowClear}
          minuteStep={minuteStep}
        />
      </div>
    </BaseInput>
  );
};

DateTimeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  readonly: PropTypes.bool,
  type: PropTypes.string,
  /* label: PropTypes.string,
  prefix: PropTypes.string, */
};
