import { MEDIA_QUERY } from "@farzoom/common-ui-components";
import { borderRadius, borderRadiusHalf, colors, ellipsis, fonts, locked } from "./theme";
import { carrotColors } from "@/carrotTheme";

const cssVariables = {
  "--ant-primary-color": carrotColors.main,
};

export const persimmon = {
  "&.persimmon": {
    ...cssVariables,

    "& .modal": {
      "& .ant-typography h5, div.ant-typography-h5, div.ant-typography-h5 > textarea, h5.ant-typography":
        {
          color: colors.coal1,
          fontSize: 16,
          minHeight: 24,
          fontFamily: fonts.roboto,
          fontWeight: 500,
          lineHeight: 24 / 16,
          textTransform: "uppercase",
        },
      "& .ant-form-item-control": {
        "& > .ant-form-item-control-input": {
          "& > .ant-form-item-control-input-content": {
            "& > .common-ui-components-base-input > div > input.ant-input, .common-ui-components-base-input > div > .ant-input-affix-wrapper, .common-ui-components-base-input > .ant-input-affix-wrapper":
              {
                borderRadius,
              },
          },
        },
      },
      "& .ant-picker-input > input, .ant-input-search .ant-input-lg": {
        lineHeight: 24 / 16,
      },
      "& .ant-btn-icon-only.ant-btn-lg": {
        background: colors.gray,
        width: 56,
        height: 56,
      },
      "& .ant-input-group-addon": {
        background: "transparent !important",
      },
      "& .ant-input-number-handler": {
        borderColor: colors.coal1,
      },
      "& .ant-input-number-handler-wrap": {
        background: "none",
        height: "auto",
        top: 1,
        bottom: 1,
      },
      "& .ant-input-number-handler-down-inner, .ant-input-number-handler-up-inner": {
        color: colors.coal1,
      },
      "& .ant-input-lg, .ant-picker-large, .ant-input-number-lg .ant-input-number-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input":
        {
          background: colors.gray,
          width: "100%",
          height: 56,
          padding: 16,
          color: colors.coal1,
          fontSize: 16,
          lineHeight: 24 / 16,
          fontWeight: 400,
          border: [1, "solid", colors.gray],
          borderRadius,
          fontFamily: fonts.roboto,
          outline: "none",
          cursor: "text",
          boxSizing: "border-box",
          ...ellipsis,
          "&.ant-picker-status-error": {
            borderColor: colors.error,
          },
          [MEDIA_QUERY.mobile]: {
            height: 40,
            padding: 8,
            fontSize: 14,
            lineHeight: 1.2,
            borderRadius: borderRadiusHalf,
          },
        },
      "& textarea.ant-input-lg": {
        whiteSpace: "normal",
      },
      "& span.ant-input-group-wrapper > span.ant-input-wrapper > span:first-child, span.ant-input-group-wrapper > span.ant-input-wrapper > input.ant-input:first-child":
        {
          borderRadius: [borderRadius, 0, 0, borderRadius],
        },
      "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
        background: "none",
        minHeight: 56,
        padding: 0,
        border: "none",
        [MEDIA_QUERY.mobile]: {
          minHeight: 40,
        },
      },
      "& .ant-select-single.ant-select-show-arrow .ant-select-selection-search": {
        right: 0,
        left: 0,
      },
      "& .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder":
        {
          background: colors.gray,
          width: "100%",
          height: 56,
          padding: 16,
          color: colors.coal1,
          fontSize: 16,
          lineHeight: 24 / 16,
          fontWeight: 500,
          border: [1, "solid", colors.borderColor],
          borderRadius,
          [MEDIA_QUERY.mobile]: {
            height: 40,
            padding: 8,
            fontSize: 14,
            lineHeight: 1.2,
            borderRadius: borderRadiusHalf,
          },
        },
      "& .ant-select-selection-overflow": {
        background: colors.gray,
        width: "100%",
        minHeight: 56,
        padding: 16,
        border: [1, "solid", colors.gray],
        borderRadius,
      },
      "& .ant-select-multiple.ant-select-lg .ant-select-selection-search": {
        height: 22 / 14,
        lineHeight: 22 / 14,
        "& .ant-select-selection-search-input": {
          padding: 0,
          height: 22 / 14,
          lineHeight: 22 / 14,
        },
      },
      "& .ant-select-multiple .ant-select-selection-item": {
        height: "auto",
        padding: 8,
        color: colors.coal1,
        fontSize: 14,
        lineHeight: 22 / 14,
        fontWeight: 500,
        borderRadius,
        border: [1, "solid", colors.coal4],
        fontFamily: fonts.roboto,
      },
      "& .ant-select-multiple .ant-select-selection-item-remove svg": {
        width: 16,
        height: 16,
      },
      "& .ant-select-dropdown": {
        border: "none",
        borderRadius: [[borderRadius], "!important"],
      },
      "& .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input":
        {
          height: 56,
          padding: 16,
          boxSizing: "border-box",
        },
      "& .ant-select, .ant-input-number-input, .ant-picker-input > input, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after":
        {
          color: colors.coal1,
          fontSize: 16,
          lineHeight: 24 / 16,
          fontWeight: 400,
          fontFamily: fonts.roboto,
        },
      "& .ant-select-item": {
        width: "100%",
        height: 56,
        padding: 16,
        color: colors.coal1,
        fontSize: 16,
        lineHeight: 24 / 16,
        fontWeight: 400,
        fontFamily: fonts.roboto,
        ...ellipsis,
      },
      "& .ant-select-item-empty": {
        // background: colors.gray,
        width: "100%",
        padding: 16,
        color: colors.coal1,
        fontSize: 16,
        lineHeight: 24 / 16,
        fontWeight: 500,
        fontFamily: fonts.roboto,
        ...ellipsis,
      },
      "& .ant-select-multiple .ant-select-selection-item-content": {
        whiteSpace: "normal",
      },
      "& .ant-input-number": {
        border: "none",
      },
      "& .ant-select-item-option": {
        height: "auto",
        padding: [8, 16],
      },
      "& .ant-select-item-option-active:not(.ant-select-item-option-disabled)": {
        background: colors.disabled,
      },
      "& .ant-input-lg:hover, .ant-picker-large:hover, .ant-btn:hover, .ant-input-search .ant-input:hover, .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary)":
        {
          borderColor: colors.gray,
        },
      "& .ant-input-lg:focus, .ant-picker-large:focus, .ant-input-lg:active, .ant-picker-large:active, .ant-btn:focus, .ant-input-search .ant-input:focus, .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-picker-focused, .ant-picker-focused:hover, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number-focused, .ant-input-number:focus, .ant-input-number-focused .ant-input-number-input":
        {
          borderColor: colors.borderHoverColor,
          boxShadow: "none",
          outline: "none",
        },
      "& .ant-input-lg:disabled, .ant-picker.ant-picker-disabled, .ant-select-single.ant-select-show-arrow.ant-select-disabled .ant-select-selection-item, .ant-select-single.ant-select-show-arrow.ant-select-disabled .ant-select-selection-placeholder, .ant-input-number.ant-input-number-disabled .ant-input-number-input":
        {
          backgroundColor: colors.disabled,
          paddingRight: 40,
          borderColor: colors.disabled,
          cursor: "default",
          ...locked,
          "&:hover": {
            borderColor: colors.disabled,
          },
        },
      "& .ant-input[disabled], .ant-picker-input > input[disabled]": {
        cursor: "default",
      },
      "& .ant-select-single.ant-select-show-arrow.ant-select-disabled .ant-select-arrow": {
        right: 40,
      },
      "& .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover":
        {
          background: colors.gray,
        },
      "& .ant-form-item-has-error .ant-input, .ant-input-group-wrapper-status-error .ant-btn.ant-input-search-button, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selection-search-input, .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selection-placeholder":
        {
          background: colors.gray,
          borderColor: colors.error,
        },
      "& .common-ui-components-form-block-multiple-container__invalid, .common-ui-components-form-block-multiple-container__invalid .ant-alert, .ant-alert":
        {
          borderRadius,
        },
      "& .common-ui-components-readonly-individual-item__error": {
        margin: 0,
      },
      "& .ant-select-arrow": {
        width: 24,
        height: 24,
        marginTop: -12,
      },
      "& .ant-select-suffix": {
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23949BA0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E\")",
        backgroundPosition: "center center",
        width: "inherit",
        height: "inherit",
        padding: 0,
        "& svg": {
          display: "none",
        },
      },
      "& .ant-picker.ant-picker-disabled .ant-picker-suffix": {
        display: "none",
      },
      "& .ant-form-item-label": {
        width: "100%",
        whiteSpace: "normal",
        "& > label": {
          height: "auto",
          marginBottom: 8,
          color: colors.coal4,
          fontFamily: fonts.roboto,
          fontSize: 14,
          lineHeight: 1.2,
          fontWeight: 400,
          textAlign: "left",
          display: "block",
          [MEDIA_QUERY.mobile]: {
            fontSize: 12,
          },
        },
      },
      "& .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label, .ant-form-vertical .ant-form-item-label":
        {
          padding: 0,
        },
      "& .ant-btn": {
        background: colors.primaryColor,
        height: 56,
        padding: [11, 32],
        color: colors.white,
        fontSize: 16,
        lineHeight: 24 / 16,
        borderRadius,
        border: [1, "solid", colors.primaryColor],
        [MEDIA_QUERY.mobile]: {
          height: 40,
          padding: [8, 16],
          borderRadius: borderRadiusHalf,
          lineHeight: 1,
        },
        "&:hover": {
          borderColor: colors.coal1,
        },
        "&.ant-btn-default": {
          background: colors.white,
          color: colors.coal1,
          borderColor: colors.coal1,
        },
        "&.ant-btn-primary": {
          background: colors.primaryColor,
          color: colors.white,
          border: [1, "solid", colors.primaryColor],
          "&:hover": {
            borderColor: colors.primaryColor,
          },
        },
        "&.ant-btn-dangerous": {
          background: colors.white,
          color: colors.primaryColor,
          borderColor: colors.primaryColor,
        },
        "&.ant-btn-icon-only": {
          width: 32,
          height: 32,
          padding: [2.4, 0],
        },
        "&.ant-input-search-button": {
          background: colors.gray,
          height: 56,
          borderColor: colors.gray,
        },
      },
      "& .ant-input-disabled + .ant-input-group-addon > .ant-input-search-button": {
        background: colors.disabled,
      },
      "& .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button":
        {
          borderRadius: [0, borderRadius, borderRadius, 0],
        },
      "& .common-ui-components-form-block-multiple-container__append-button, .common-ui-components-form-dynamic-array__bottom-add-button":
        {
          height: 56,
          margin: 0,
          padding: [[0, 32], "!important"],
          fontFamily: fonts.roboto,
          borderStyle: "solid !important",
        },
      "& .common-ui-components-management-block__management-group .ant-row:not(.ant-form-item-hidden)":
        {
          display: "block",
        },
      "& .ant-tabs-tab": {
        color: colors.coal4,
        padding: 8,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: fonts.roboto,
        [MEDIA_QUERY.mobile]: {
          fontSize: 14,
        },
        "& + .ant-tabs-tab": {
          margin: [0, 0, 0, 16],
        },
      },
      "& .common-ui-components-edit-individual__roles-item .common-ui-components-text-input": {
        height: "auto !important",
      },
      "& button.common-ui-components-edit-member__switch-mt, button.common-ui-components-edit-individual__switch-mt":
        {
          marginTop: [[0], "!important"],
        },
      "& .common-ui-components-edit-member__roles-title_fixed, .common-ui-components-edit-individual__roles-title_fixed":
        {
          maxWidth: [480, "!important"],
          minWidth: [480, "!important"],
        },
      "& .common-ui-components-edit-member__roles-title, .common-ui-components-edit-individual__roles-title":
        {
          color: colors.coal4,
          fontFamily: fonts.roboto,
          fontSize: 14,
          lineHeight: 22 / 14,
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
        },
      "& .common-ui-components-edit-address__address-radio, .common-ui-components-form-radio-multiple":
        {
          flex: "auto",
        },
      "& .common-ui-components-form-radio-single, .common-ui-components-form-radio-multiple": {
        background: colors.gray,
        padding: [[16], "!important"],
        borderRadius: 16,
      },
      "& .common-ui-components-form-radio-single__option, .common-ui-components-form-radio-multiple__option":
        {
          display: "flex",
          color: colors.coal1,
          fontSize: 16,
          lineHeight: 24 / 16,
          fontFamily: fonts.roboto,
        },
      "& .common-ui-components-readonly-address-item__address": {
        color: colors.coal1,
        fontSize: 16,
        lineHeight: 24 / 16,
        fontFamily: fonts.roboto,
        display: "block",
      },
      "& .common-ui-components-readonly-address-item__types": {
        marginTop: 16,
      },
      "& .common-ui-components-form-block-multiple-container__readonly-item": {
        marginBottom: 0,
      },
      "& .common-ui-components-scoring-tab .ant-select": {
        width: 250,
      },
      "& .rc-virtual-list-scrollbar-thumb": {
        background: ["rgba(0, 0, 0, 0.2)", "!important"],
        width: [6, "!important"],
        right: [2, "!important"],
        borderRadius: [[3], "!important"],
      },
      "& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)": {
        background: colors.white,
        fontWeight: 600,
        "&:hover": {
          background: colors.disabled,
        },
      },
    },
    "& button": {
      [MEDIA_QUERY.mobile]: {
        height: 40,
        padding: [8, 16],
      },
      "&.withIcon": {
        [MEDIA_QUERY.mobile]: {
          height: "auto",
          padding: 8,
          aspectRatio: 1,
        },
      },
    },
    "& .ant-tag": {
      borderRadius: 6,
      lineHeight: 1.5715,
    },
  },
};
