import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, showNotification } from "@farzoom/common-ui-components";
import { axiosInstance } from "@/services/api/axiosInstance";
import { closeModal } from "@/store/modalSlice";
import { useHistory } from "react-router-dom";
import { cn } from "@/utils/bemConfig";
import "./Wizzard.scss";

const bem = cn("wizzard");

const getNext = ({ taskId, orderId }) =>
  axiosInstance
    .get(`/task/getNext?${taskId ? `taskId=${taskId}` : `orderId=${orderId}`}`)
    .catch(console.log);

export const Wizzard = ({
  orderId,
  taskId,
  waitingText = "Заявка отправлена в банк. Пожалуйста, подождите...",
  noTaskText = "Заявка выполняется на стороне банка",
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => state.User);
  const attemptsRef = useRef(1);
  const attemptsHandlerRef = useRef(null);
  const percentsHandlerRef = useRef(null);
  const { poolingInterval, poolingMaxTime } = userInfo?.nextTask || {
    poolingInterval: 5,
    poolingMaxTime: 60,
  };
  const [attempts, setAttempts] = useState(() => ({
    current: 1,
    max: poolingMaxTime / poolingInterval,
  }));
  const [percents, setPercents] = useState((attempts.current / attempts.max) * 10);
  const [loadFailed, setLoadFailed] = useState(() => false);

  const clearIntervals = () => {
    if (percentsHandlerRef.current) {
      clearInterval(percentsHandlerRef.current);
    }
    if (attemptsHandlerRef.current) {
      clearInterval(attemptsHandlerRef.current);
    }
  };

  const onClose = () => dispatch(closeModal());

  const checkNext = async () => {
    const { data: result, status } = (await getNext(orderId ? { orderId } : { taskId })) || {};
    // TODO убрать проверку загружаемости конфига, когда пофиксят бэкенд
    if (
      status === 200 &&
      result?.nextTask?.id &&
      (await axiosInstance.get(`/task/${result.nextTask.id}/config`))?.data
    ) {
      onClose();
      setTimeout(() => {
        history.push(`/tasks/${result.nextTask.id}`);
      }, 100);
      return clearIntervals();
    }
    if (status === 200 && result?.nextTask === null) {
      setLoadFailed(true);
      return clearIntervals();
    }
    if (attemptsRef.current >= attempts.max) {
      setLoadFailed(true);
      showNotification({
        type: "info",
        message: "Время ожидания вышло",
      });
      clearIntervals();
    }
    setAttempts((prev) => ({
      ...prev,
      current: prev.current + 1,
    }));
  };

  const calcPercents = useCallback(() => {
    setPercents((prev) => prev + 10 / (poolingMaxTime / poolingInterval));
  }, []);

  useEffect(() => {
    if (orderId || taskId) {
      attemptsHandlerRef.current = setInterval(checkNext, poolingInterval * 1000);
      percentsHandlerRef.current = setInterval(calcPercents, poolingInterval * 100);
    }
    return () => clearIntervals();
  }, []);

  useEffect(() => {
    attemptsRef.current = attempts.current;
  }, [attempts]);

  return (
    <div className={bem()}>
      {loadFailed ? (
        <>
          <div className={bem("failed")}>{noTaskText}</div>
          <Button type="primary" onClick={onClose}>
            Закрыть
          </Button>
        </>
      ) : (
        <>
          <LoadingOverlay text={waitingText} />
          <Progress
            strokeColor={{
              from: "var(--ant-primary-4)",
              to: "var(--ant-primary-6)",
            }}
            percent={percents}
            status="active"
            showInfo={false}
          />
        </>
      )}
    </div>
  );
};

Wizzard.propTypes = {
  orderId: PropTypes.string,
  taskId: PropTypes.string,
  waitingText: PropTypes.string,
  noTaskText: PropTypes.string,
};
