import { axiosInstanceSpaCarrot } from "@/services/api/axiosInstance";

export const getWidgets = async () => {
  try {
    const { data } = await axiosInstanceSpaCarrot.get("/widget");
    return data;
  } catch (err) {
    return err;
  }
};
