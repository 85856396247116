import { Typography } from "antd";
import { useStyles } from "./styles";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useExternalTask } from "@hooks/useExternalTask";

const { Title, Link, Text } = Typography;

type HeaderProps = {
  onClick: () => void;
  purchaseNumber: string;
};

export const BgCardHeader = ({ onClick, purchaseNumber }: HeaderProps) => {
  const classes = useStyles();
  const { isExternalTask } = useExternalTask();

  return (
    <div className={classes.title}>
      <Title level={4}>
        <Text type="secondary" className={classes.label}>
          Реестровый № торгов
        </Text>
        <Text>{purchaseNumber ?? "Отсутствует"}</Text>
      </Title>

      {!isExternalTask && (
        <Link className={classes.titleLink} onClick={onClick}>
          <span>Открыть</span>
          <span className={classes.linkIcon}>
            <ArrowUpOutlined />
          </span>
        </Link>
      )}
    </div>
  );
};
