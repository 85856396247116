import { useFieldArray, useFormContext } from "react-hook-form";
import { Col, Row, Typography } from "antd";
import { Button, FormInput } from "@farzoom/common-ui-components";
import { LotsSubComponent } from "@/lib/modals/BankGuaranteeRequest/lots";
import { useStyles } from "@/lib/modals/BankGuaranteeRequest/index";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { useContext } from "react";
import { GuaranteeFormContext } from "@/lib/modals/BankGuaranteeRequest/GuaranteeForm/guaranteeFormWrapper";
import { BgFormType } from "@/types/BgForm";
import { PRODUCT_MODEL } from "@/lib/modals/BankGuaranteeRequest/utils/purchaseLotModels";

const { Text } = Typography;

export const ProductsList = ({ lotIndex }: LotsSubComponent) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext<BgFormType>();
  const { formDisabled, prefix } = useContext(GuaranteeFormContext);

  const currentPrefix = prefix ? `${prefix}.purchase.lots` : "purchase.lots";

  const {
    fields: products,
    prepend,
    remove,
  } = useFieldArray({
    control,
    name: `${currentPrefix}.${lotIndex}.products` as `purchase.lots.${number}.products`,
  });

  const onAddProduct = () => {
    prepend(PRODUCT_MODEL);
  };
  const onRemove = (idx) => {
    remove(idx);
  };

  return (
    <VerticalSpace>
      <Text strong>
        <div className={classes.actionTitle}>
          <span>Товары</span>
          {!formDisabled && (
            <Button type="outlined" iconName="add" title="Добавить товар" onClick={onAddProduct} />
          )}
        </div>
      </Text>
      <VerticalSpace size="small">
        {products.map((product, idx) => (
          <Row key={product.id} gutter={16} align="middle">
            <Col span={8}>
              <FormInput
                name={`${currentPrefix}.${lotIndex}.products.${idx}.okpdCode`}
                control={control}
                setValue={setValue}
                label={idx > 0 ? "" : "Код по ОКПД"}
              />
            </Col>
            <Col flex="auto">
              <FormInput
                name={`${currentPrefix}.${lotIndex}.products.${idx}.okpdName`}
                control={control}
                setValue={setValue}
                label={idx > 0 ? "" : "Название по ОКПД"}
              />
            </Col>
            {products.length > 1 && !formDisabled && (
              <Col flex="32px">
                <Button
                  type="outlined"
                  iconName="remove"
                  title="Удалить товар"
                  onClick={() => onRemove(idx)}
                />
              </Col>
            )}
          </Row>
        ))}
      </VerticalSpace>
    </VerticalSpace>
  );
};
