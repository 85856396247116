import { colors, Modal } from "@farzoom/common-ui-components";
import { CustomerInfoForm } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/CustomerInfoForm";
import React from "react";
import { createPortal } from "react-dom";

type ModalProps = {
  customerId: string;
  onClose: () => void;
};

export const CustomerInfoModal = ({ customerId, onClose }: ModalProps) => {
  return createPortal(
    <Modal open={true} color={colors.ruby1} onClose={onClose} closePath={false}>
      <CustomerInfoForm customerId={customerId} handleModalClose={onClose} />
    </Modal>,
    document.querySelector("[class*=app]") as HTMLElement
  );
};
