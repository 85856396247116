import { Spin } from "antd";
import { createUseStyles } from "react-jss";

type PreloaderProps = {
  fullScreen?: boolean;
};

type PreloaderStyles = Pick<PreloaderProps, "fullScreen">;

const useStyles = createUseStyles({
  preloader: {
    position: (props: PreloaderStyles) => (props.fullScreen ? "fixed" : "absolute"),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    zIndex: "calc(infinity)",
  },
});

const Overlay = ({ fullScreen = true }: PreloaderProps) => {
  const styles = useStyles({ fullScreen });

  return (
    <div className={styles.preloader}>
      <Spin spinning delay={200} size="large" />
    </div>
  );
};
export default Overlay;
