import { useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Nullish } from "@/types/helpers";
import { notNullish } from "@utils/notNullish";

export const useAddresses = ({ addressesValue, setValue, prefix, legalAddress }) => {
  const [actualAsLegal, setActualAsLegal] = useState(
    addressesValue?.legal?.address === addressesValue?.actual?.address
  );
  const [postalAsLegal, setPostalAsLegal] = useState(
    addressesValue?.legal?.address === addressesValue?.postal?.address
  );

  const onChangeLegalAddress = (value: Nullish<string>) => {
    if (!notNullish(value)) {
      setValue(`${prefix}.addresses.legal.ownType`, null);
    }

    if (actualAsLegal) {
      setValue(`${prefix}.addresses.actual.address`, value);
      setValue(`${prefix}.addresses.actual.ownType`, legalAddress?.ownType);
    }
    if (postalAsLegal) {
      setValue(`${prefix}.addresses.postal.address`, value);
      setValue(`${prefix}.addresses.postal.ownType`, legalAddress?.ownType);
    }
  };

  const onChangeLegalOwnership = (value: Nullish<string>) => {
    if (actualAsLegal) setValue(`${prefix}.addresses.actual.ownType`, value);
    if (postalAsLegal) setValue(`${prefix}.addresses.postal.ownType`, value);
  };

  const onChangeActualAsLegal = (e: CheckboxChangeEvent) => {
    setActualAsLegal(e.target.checked);

    if (e.target.checked) {
      setValue(`${prefix}.addresses.actual`, {
        address: legalAddress?.address,
        ownType: legalAddress?.ownType,
      });
    }
  };

  const onChangePostalAsLegal = (e: CheckboxChangeEvent) => {
    setPostalAsLegal(e.target.checked);

    if (e.target.checked) {
      setValue(`${prefix}.addresses.postal`, {
        address: legalAddress?.address,
        ownType: legalAddress?.ownType,
      });
    }
  };

  const onChangeSubAddress = (type) => (value: Nullish<string>) => {
    if (!notNullish(value)) {
      if (type === "actual") setValue(`${prefix}.addresses.actual.ownType`, null);
      if (type === "postal") setValue(`${prefix}.addresses.postal.ownType`, null);
    }
  };

  return {
    actualAsLegal,
    postalAsLegal,
    onChangeLegalOwnership,
    onChangeActualAsLegal,
    onChangePostalAsLegal,
    onChangeLegalAddress,
    onChangeSubAddress,
  };
};
