import React, { useRef } from "react";
import { Tabs } from "antd";
import { LoadingOverlay } from "@farzoom/common-ui-components";
import useScreenSize from "@hooks/useScreenSize";

export const InfoForm = ({ isFetching, title, sections, actions }) => {
  const ref = useRef();
  const screenSize = useScreenSize();

  return (
    <div className="infoForm" ref={ref}>
      {title && <div className="title">{title}</div>}
      {isFetching ? (
        <LoadingOverlay />
      ) : (
        <div className="tabs">
          <Tabs
            tabPosition={!screenSize["1366"] ? "top" : "left"}
            size="large"
            tabBarGutter={8}
            onTabClick={() => ref?.current?.scrollIntoView({ behavior: "smooth" })}
          >
            {sections?.map((section, index) => (
              <Tabs.TabPane key={index} size="large" tab={section.name}>
                {section.getData() || section.name}
              </Tabs.TabPane>
            ))}
          </Tabs>
          {actions}
        </div>
      )}
    </div>
  );
};
