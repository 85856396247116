import { Button, colors, Modal } from "@farzoom/common-ui-components";
import React from "react";
import { PersonSearch } from "@/_custom/components/Common/PersonSearch";
import { useForm } from "react-hook-form";
import { Space, Typography } from "antd";

import { createUseStyles } from "react-jss";
import {
  PERSON_INFO_MODEL,
  PERSON_ROLE_MODEL,
} from "@components/BankGuarantee/customerInfo/customerPersons/utils/personsModels";
import { PersonsType } from "@/types/Customer";

const { Text } = Typography;

type PersonAddModalProps = {
  onClose: () => void;
  onAdd: (person: PersonsType) => void;
};

const useStyles = createUseStyles({
  container: {
    width: 600,
  },
  addBtn: {
    marginRight: 0,
    marginLeft: "auto",
  },
  notFoundBtn: {
    height: "auto !important",
  },
});

export const FindPersonModal = ({ onClose, onAdd }: PersonAddModalProps) => {
  const classes = useStyles();
  const { control, setValue, getValues, watch } = useForm();

  const personW = watch("person");

  const notFound = {
    value: "",
    key: "custom",
    label: (
      <Space align="center">
        <Text strong>Нет нужного физ.лица?</Text>
        <Button
          type="small"
          text="Добавить вручную"
          className={classes.notFoundBtn}
          onClick={() => onAdd({ person: PERSON_INFO_MODEL, personRole: PERSON_ROLE_MODEL })}
        />
      </Space>
    ),
  };

  const onAddPerson = () => {
    onAdd({ person: getValues("person"), personRole: PERSON_ROLE_MODEL });
  };

  return (
    <Modal open={true} color={colors.ruby1} onClose={onClose} closePath={false}>
      <div className={classes.container}>
        <PersonSearch
          prefix="person"
          control={control}
          setValue={setValue}
          label="Выберите физ.лицо"
          getHiddenFieldNames={(prefix) => prefix}
          hiddenFieldNames={[{ target: "person", source: (opt) => opt?.props }]}
          extraOption={notFound}
          controlled
        />
        <Button
          type="primary"
          text="Добавить"
          className={classes.addBtn}
          onClick={onAddPerson}
          disabled={!personW?.inn}
        />
      </div>
    </Modal>
  );
};
