import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./static/scss/antd.scss";
import "antd/dist/antd.variable.min.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
