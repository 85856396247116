import React from "react";
import { cn } from "../../../../../utils/bemConfig";
import { Loader } from "..";
import "./Status.scss";

const STATUSES = {
  PREPARING: "Подготовка",
  DOWNLOADING: "Загрузка",
  SIGNING: "Подписание",
  UPLOADING: "Загрузка",
};

const bem = cn("status");

export const Status = ({ status }) => {
  const currentStatus = STATUSES[status];
  const isSuccess = status === "success";
  const isError = status === "error";
  const isLarge = status === "tooLarge";
  const isLoader = !!status && !isSuccess && !isError && !isLarge;
  return (
    <div className={bem()}>
      <div>{currentStatus}</div>
      {isLoader && <Loader />}
    </div>
  );
};
