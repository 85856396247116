import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { useController } from "react-hook-form";
import { justified } from "@farzoom/common-ui-components";
import { FormAutoCompleteItem } from "./FormAutoCompleteItem";
import { SearchController } from "@/services/api/controllers";
import { FormAutoCompleteInfo } from "@/_custom/components/Common/FormAutoComplete/FormAutoCompleteInfo";
import { AutoComplete } from "@/_custom/components/Common/AutoComplete";
import { CustomerController } from "@/services/api/controllers/bankGuarantee/CustomerController";

export const AUTOCOMPLETE_TYPES = {
  address: "address",
  bank: "bank",
  company: "company",
  holding: "holding",
  person: "person",
  fms: "fms",
};

const useStyles = createUseStyles({
  formAutoComplete: {
    width: "100%",
    "& .type-address": {
      flexGrow: 1,
    },
    "& .type-bank": {
      flexGrow: 1,
    },
  },
  addressSuggestion: {
    minWidth: 300,
    ...justified,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  infoIcon: {
    cursor: "pointer",
  },
});

const getHiddenFieldNamesDefault = (prefix, hiddenName) => `${prefix}.${hiddenName}`;

const {
  address: ADDRESS,
  bank: BANK,
  company: COMPANY,
  holding: HOLDING,
  person: PERSON,
  fms: FMS,
} = AUTOCOMPLETE_TYPES || {};

export const FormAutoComplete = ({
  type,
  label,
  title,
  prefix,
  control,
  showHint = true,
  required = false,
  disabled = false,
  setValue,
  className,
  forceFetch = false, // флаг -> отправлять пустой запрос и выбирать первый вариант из списка
  allowClear,
  controlled,
  placeholder,
  fetchOptions,
  errorMessage,
  addressDetails,
  classes: classesFromProps,
  hiddenFieldNames: hiddenFieldNamesFromProps,
  getVisibleFieldName,
  getHiddenFieldNames = getHiddenFieldNamesDefault,
  fullAddress = false,
  notFoundContent,
  extraOption,
  parentOnChange,
  rules: rulesFromProps,
}) => {
  const classes = useStyles();

  const isAddress = type === ADDRESS;

  const FETCH_OPTIONS = {
    [ADDRESS]: (query) => SearchController?.suggestion?.address(query).then((data) => data.suggest),
    [BANK]: (query) => SearchController.suggestion.bank(query).then((data) => data.suggest),
    [COMPANY]: (query) => SearchController?.suggestion?.company(query),
    [HOLDING]: (name) => SearchController.suggestion.holding({ name }),
    [PERSON]: (query) => CustomerController.searchPerson(query).then((data) => data.data),
    [FMS]: (query) => SearchController?.suggestion?.fms(query),
  };

  const PLACEHOLDERS = {
    [ADDRESS]: "Введите адрес",
    [BANK]: "Введите наименование банка",
    [COMPANY]: "Введите ИНН или наименование компании",
    [HOLDING]: "Введите ИНН или наименование холдинга",
    [PERSON]: "Введите ИНН или ФИО",
    [FMS]: "Введите код подразделения",
  };

  const NAMES = {
    [ADDRESS]: prefix,
    [BANK]: prefix,
    [COMPANY]: `${prefix}.shortName`,
    [HOLDING]: `${prefix}.name`,
    [PERSON]: `${prefix}.displayName`,
    [FMS]: prefix,
  };

  const HIDDEN_NAMES = {
    [ADDRESS]: ["unrestricted_value", "data"],
    [BANK]: ["bic", "corrNumber"],
    [COMPANY]: ["inn", "kpp", "ogrn"],
    [HOLDING]: ["typeName", "absId", "siebelId"],
    [PERSON]: ["displayName"],
    [FMS]: ["name", "code"],
  };

  const hiddenFieldNames = hiddenFieldNamesFromProps || HIDDEN_NAMES?.[type];

  const AUTOCOMPLETE_OPTIONS = {
    [ADDRESS]: ({ value, unrestricted_value, data }, search) => ({
      data,
      value: fullAddress ? unrestricted_value : value,
      key: unrestricted_value,
      unrestricted_value,
      label: (
        <FormAutoCompleteItem className={classes.addressSuggestion} value={value} search={search} />
      ),
    }),
    [BANK]: ({ value, unrestricted_value, data }, search) => ({
      data,
      unrestricted_value,
      key: data.bic,
      value: value,
      name: value,
      bic: data.bic,
      corrNumber: data.correspondent_account,
      label: <FormAutoCompleteItem value={value} search={search} />,
    }),
    [COMPANY]: ({ shortName, inn, ogrn, kpp, address, fullAddress }, search) => ({
      value: `${shortName} (ИНН ${inn})`,
      shortname: shortName,
      displayName: shortName,
      inn,
      ogrn,
      fullAddress,
      kpp: kpp ?? null,
      key: inn,
      label: (
        <FormAutoCompleteItem
          value={shortName}
          search={search}
          subValues={{ inn, ogrn, kpp, address }}
        />
      ),
    }),
    [PERSON]: (props, search) => {
      const { displayName, inn } = props;
      return {
        value: displayName,
        key: inn,
        ...props,
        label: (
          <FormAutoCompleteItem value={displayName} search={search} subValues={{ inn }} isPerson />
        ),
      };
    },
    [FMS]: (props, search) => {
      const {
        data: { code, name },
      } = props;

      return {
        value: code + name, // тут нужно передавать code + name, а не code, т.к. код не уникален (есть подсказки с разным наименованием, но одинаковым кодом)
        key: code + name,
        code,
        name,
        label: <FormAutoCompleteItem value={code} search={search} subValues={{ name }} />,
      };
    },
  };

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name: (getVisibleFieldName && getVisibleFieldName(prefix)) || NAMES?.[type],
    control,
    rules: {
      required,
      ...rulesFromProps,
    },
  });

  const handleChange = (val, option) => {
    onChange(val);
    parentOnChange && parentOnChange(val, option);
  };

  const setHiddenFields = (option) => {
    hiddenFieldNames.forEach((name) => {
      // если нужен маппинг:
      // name - объект,
      // source - поле у опции (откуда читаем)
      // target -> payloadFieldName (куда пишем)
      if (name?.source && name?.target) {
        if (typeof name.source === "function") {
          setValue(getHiddenFieldNames(prefix, name.target), name.source(option));
        } else {
          setValue(getHiddenFieldNames(prefix, name.target), option[name.source]);
        }
      } else {
        setValue(getHiddenFieldNames(prefix, name), option[name]);
      }
    });
  };

  return (
    <div
      className={classnames(classes.formAutoComplete, className, {
        [`type-${type}`]: !!type,
      })}
    >
      <AutoComplete
        classes={classesFromProps}
        value={value}
        onChange={handleChange}
        setHiddenFields={setHiddenFields}
        placeholder={placeholder || PLACEHOLDERS?.[type]}
        title={title}
        hiddenFieldNames={hiddenFieldNames}
        getAutoCompleteOption={AUTOCOMPLETE_OPTIONS?.[type]}
        fetchOptions={FETCH_OPTIONS?.[type] || fetchOptions}
        forceFetch={forceFetch}
        label={label}
        allowClear={allowClear}
        invalid={invalid}
        error={error}
        errorMessage={errorMessage}
        required={required}
        disabled={disabled}
        controlled={controlled}
        notFoundContent={notFoundContent}
        extraOption={extraOption}
      />
      {isAddress && (
        <FormAutoCompleteInfo type={type} showHint={showHint} addressDetails={addressDetails} />
      )}
    </div>
  );
};

FormAutoComplete.propTypes = {
  setValue: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,

  type: PropTypes.oneOf([ADDRESS, BANK, COMPANY, HOLDING, PERSON]),
  title: PropTypes.string,
  label: PropTypes.string,
  showHint: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  forceFetch: PropTypes.bool,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  addressDetails: PropTypes.object,
  payloadFieldName: PropTypes.string,
  fetchOptions: PropTypes.func,
  hiddenFieldNames: PropTypes.array,
  getHiddenFieldNames: PropTypes.func,
  getVisibleFieldName: PropTypes.func,
  fullAddress: PropTypes.bool,
  notFoundContent: PropTypes.node,
  extraOption: PropTypes.object,
};
