import React from "react";
import { Space } from "antd";
import {
  ERROR_MESSAGES,
  FormInput,
  FormSelect,
  MASKS,
  PATTERNS,
} from "@farzoom/common-ui-components";
import {
  COUNTRIES,
  IDENTITY_DOCUMENTS,
} from "@components/BankGuarantee/personInfo/utils/constants";
import { FormInputDateTime } from "@/_custom/components/Common/FormInputDateTime";
import { FmsUnitSearch } from "@/_custom/components/Common/FmsUnitSearch";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { disabledDate, useStyles } from "./personInfoModal";

export const IdentityDocumentBlock = ({ control, setValue, personW, readonly, getValues }) => {
  const classes = useStyles();

  const onIssueByCodeChange = (_, opt) => {
    setValue("person.identityDocument.issuedBy", (opt?.name as string) || null);

    // HACK: чтобы значение поля изменялось по мере ввода с клавиатуры
    opt?.code && setValue("person.identityDocument.issuedByCode", opt?.code as string);
  };

  return (
    <VerticalSpace className={classes.block}>
      <FormSelect
        name="person.identityDocument.type"
        label="Тип документа"
        setValue={setValue}
        control={control}
        defaultValue={personW?.person?.identityDocument?.type}
        options={IDENTITY_DOCUMENTS}
        disabled={readonly}
      />
      <Space>
        <FormInput
          name="person.identityDocument.series"
          control={control}
          setValue={setValue}
          label="Серия"
          mask={MASKS.passportSeria}
          pattern={{
            value: PATTERNS.passportSeria,
            message: ERROR_MESSAGES.passportSeria,
          }}
        />
        <FormInput
          name="person.identityDocument.number"
          control={control}
          setValue={setValue}
          label="Номер"
          mask={MASKS.passportNumber}
          pattern={{
            value: PATTERNS.passportNumber,
            message: ERROR_MESSAGES.passportNumber,
          }}
        />
      </Space>
      <Space>
        <FormInputDateTime
          name="person.identityDocument.issuedDate"
          control={control}
          setValue={setValue}
          label="Дата выдачи"
          controls={false}
          disabledDate={disabledDate}
          disabled={readonly}
        />
        <FmsUnitSearch
          prefix="person.identityDocument.issuedByCode"
          control={control}
          setValue={setValue}
          label="Код подразделения"
          parentOnChange={onIssueByCodeChange}
          controlled
        />
      </Space>
      <FormInput
        name="person.identityDocument.issuedBy"
        control={control}
        setValue={setValue}
        label="Кем выдан"
        title={getValues("person.identityDocument.issuedBy")}
        disabled={readonly}
      />
      <FormSelect
        name="person.citizenship"
        label="Гражданство"
        setValue={setValue}
        control={control}
        defaultValue={personW?.person?.citizenship}
        options={COUNTRIES}
        disabled={readonly}
      />
      <FormSelect
        name="person.countryOfResidence"
        label="Страна проживания"
        setValue={setValue}
        control={control}
        defaultValue={personW?.person?.countryOfResidence}
        options={COUNTRIES}
        disabled={readonly}
      />
    </VerticalSpace>
  );
};
