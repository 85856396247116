import React from "react";
import { InfoFormBlock } from "../../../../_custom/_components/InfoForm";
import { getDynamicBlock } from "@utils/getDynamicBlock";

export const getCustomerSections = ({
  config,
  watch,
  resetField,
  getValues,
  setValue,
  models,
  control,
  orderId,
  userInfo,
  register,
  setFocus,
}) => {
  const { tabs } = config || {};

  const sections = [];

  for (const tab in tabs) {
    const { title, components } = tabs[tab] || {};
    sections.push({
      name: title,
      getData: () => {
        const blocks = [];
        for (const prefix in components) {
          const { type } = components[prefix] || {};
          if (type) {
            blocks.push(
              getDynamicBlock({
                model: models[prefix],
                config: config?.tabs[tab]?.components[prefix],
                control,
                watch,
                resetField,
                getValues,
                setValue,
                type,
                prefix,
                userInfo,
                orderId,
                register,
                setFocus,
              })
            );
          }
        }
        return (
          <>
            {blocks?.map((block, index) => (
              <InfoFormBlock key={index}>{block}</InfoFormBlock>
            ))}
          </>
        );
      },
    });
  }

  return sections;
};
