import { createUseStyles } from "react-jss";
import { colors, MEDIA_QUERY } from "@farzoom/common-ui-components";

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: colors.cardBackground,
    padding: 16,
    borderRadius: 8,
    "& [class*=formRadioSingle]": {
      padding: "0 !important",
      background: "none",
      gap: 8,
    },
    "& .ant-row:not(:last-of-type)": {
      marginBottom: 22,
      [MEDIA_QUERY.mobile]: {
        marginBottom: 0,
      },
    },
  },
  title: {
    marginBottom: 16,
    fontSize: 16,
    lineHeight: 1.2,
  },
  titleWithAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 13,
  },
  formItem: {
    marginBottom: 0,
    "& [class*=autoComplete],&[class*=autoComplete]": {
      marginBottom: 0,
    },
  },
  formItemInfo: {
    fontSize: 13,
    [MEDIA_QUERY.mobile]: {
      fontSize: 11,
    },
  },
  flexCenterCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  emptyAddBtn: {
    margin: "0 auto",
  },
  removeBtn: {
    margin: [0, 0, 0, "auto"],
  },
});
