export const parseRdnString = (str) => {
  if (!str) return [];
  const parts = [];
  let tokenStart = 0;
  let tokenEnd = 0;
  while (tokenEnd < str.length) {
    const part = { key: null, value: null };

    // Find key end
    for (; str[tokenEnd] !== "=" && tokenEnd < str.length; ++tokenEnd);
    if (str[tokenEnd] !== "=") throw new Error("Invalid format");
    part.key = str.substring(tokenStart, tokenEnd);
    tokenStart = tokenEnd + 1;
    tokenEnd = tokenStart;

    // Find value end
    if (str[tokenStart] !== '"') {
      for (; str[tokenEnd] !== "," && tokenEnd < str.length; ++tokenEnd);
      part.value = str.substring(tokenStart, tokenEnd);
    } else {
      tokenStart += 1; // Skip starting quote
      tokenEnd = tokenStart;
      while (tokenEnd < str.length) {
        // Searching for ending quote
        if (str[tokenEnd] === '"') {
          if (tokenEnd === str.length - 1 || str[tokenEnd + 1] !== '"') {
            break;
          } else {
            tokenEnd += 1; // Skip escaped inner quotes
          }
        }
        tokenEnd += 1;
      }
      part.value = str.substring(tokenStart, tokenEnd).replaceAll('""', '"');
      tokenEnd += 1; // Skip ending quote
      if (str[tokenEnd] === ",") {
        tokenEnd += 1;
      }
    }

    tokenStart = tokenEnd + 1;
    tokenEnd = tokenStart;

    parts.push(part);

    // Find next key start
    for (; str[tokenEnd] === " " && tokenEnd < str.length; ++tokenEnd);
    tokenStart = tokenEnd;
    tokenEnd = tokenStart;
  }
  return parts;
};

function getCompanyInn(rdnObject) {
  const rawInn =
    rdnObject["ИНН ЮЛ"] ||
    rdnObject["INNLE"] ||
    rdnObject["1.2.643.100.4"] || // ИНН ЮЛ
    rdnObject["ИНН"] ||
    rdnObject["INN"] ||
    rdnObject["1.2.643.3.131.1.1"]; // ИНН
  return rawInn?.startsWith("00") && rawInn.length === 12 ? rawInn.substring(2) : rawInn;
}

function getCompanyName(rdnObject) {
  return (
    rdnObject["O"] ||
    rdnObject["2.5.4.10"] || // organizationName - Название организации
    rdnObject["CN"] ||
    rdnObject["2.5.4.3"]
  ); // commonName - Основное имя
}

function getPersonName(rdnObject) {
  const surname = rdnObject["SN"] || rdnObject["2.5.4.4"]; // surname - Фамилия
  const givenName = rdnObject["G"] || rdnObject["2.5.4.42"]; // givenName - Имя и отчество
  return [surname, givenName].filter((s) => !!s).join(" ");
}

export const getRdnInfo = (str) => {
  if (!str) return {};
  const rdnList = parseRdnString(str);
  const rdnObject = rdnList.reduce((obj, part) => {
    obj[part.key] = part.value;
    return obj;
  }, {});
  const rdnInfo = {
    personName: getPersonName(rdnObject),
    companyName: getCompanyName(rdnObject),
    companyInn: getCompanyInn(rdnObject),
  };

  return rdnInfo;
};
