import { Route, RouteProps } from "react-router-dom";
import React, { ComponentType } from "react";

interface LayoutProps extends RouteProps {
  component: ComponentType;
}

export const SimpleLayout = ({ component: Component, ...rest }: LayoutProps) => {
  return (
    <div className="fr-app simple-layout">
      <div className="fr-container">
        <Route {...rest}>
          <Component />
        </Route>
      </div>
    </div>
  );
};
