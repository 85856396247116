import { keycloak } from "../keycloak";
import React, { ReactNode } from "react";
import { type KeycloakEvent, KeycloakProvider } from "react-keycloak";
import Keycloak from "keycloak-js";
import { isValidToken, onLogin, onLogout, setToken } from "@farzoom/common-ui-components";

type WithKeycloakProps = {
  children: ReactNode;
};
type ExtendedInitConfig = Keycloak.KeycloakInitOptions & {
  silentCheckSsoRedirectUri?: string;
};

export const WithKeycloak = ({ children }: WithKeycloakProps) => {
  const config: ExtendedInitConfig = {
    // При локальной сборке приходится отключать проверку iframe логина, т.к. куки теряются при передаче на другой url
    // https://stackoverflow.com/questions/60622192/keycloak-session-cookies-are-missing-within-the-token-request-with-the-new-chro
    checkLoginIframe: false,
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  };

  const onToken = (token: string) => {
    setToken(token);
  };

  const onEvent = (event: KeycloakEvent) => {
    const loginEvents = ["onReady", "onAuthError", "onAuthRefreshError"];
    const logoutEvents = ["onAuthLogout"];

    if (loginEvents.includes(event) && !isValidToken()) {
      onLogin(keycloak);
    }

    if (logoutEvents.includes(event)) {
      onLogout(keycloak);
    }
  };

  return (
    <KeycloakProvider keycloak={keycloak} initConfig={config} onToken={onToken} onEvent={onEvent}>
      {children}
    </KeycloakProvider>
  );
};
