import { createAsyncThunk } from "@reduxjs/toolkit";
import { Supplies } from "@/services/api";
import { RootState } from "@store/store";
import { currentPageLinkClickHandler } from "@utils/links";
import { setIsOfferLoading } from "@store/supplies/suppliesSlice";
import { notification } from "antd";

export const onOpenSupplies = createAsyncThunk(
  "supplies/onOpenSupplies",
  async (_, { dispatch, getState }) => {
    const state = getState();
    currentPageLinkClickHandler({
      pathname: "/supplies",
      onSuccess: () => {
        dispatch(getSupplies({ params: (state as RootState).carrot.supplies.suppliesFilters }));
      },
    });
  }
);

export const getSupplies = createAsyncThunk(
  "supplies/getSupplies",
  async ({ params }: Record<string, Record<string, string | number>>) => {
    return await Supplies.getSupplies(params);
  }
);

export const getSupplyConfig = createAsyncThunk(
  "supplies/getSupplyConfig",
  async (_, { getState }) => {
    const state = getState();

    const config = (state as RootState).carrot.supplies.supplyConfig;
    // @ts-expect-error - factors not in initial state yet
    if (config.factors) return config;

    return await Supplies.getSupplyConfig();
  }
);

type SupplyGetPostFinancingProps = {
  data: Record<string, string | number>[];
  onSuccessCallback?: () => void;
};
type RequestFinancingProps = {
  data: string[];
  onSuccessCallback?: () => void;
};
type RevokeSyppliesProps = {
  data: string[];
  onSuccessCallback?: () => void;
};

export const postSupplyOffer = createAsyncThunk(
  "supplies/postSupplyOffer",
  async ({ data, onSuccessCallback }: SupplyGetPostFinancingProps, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      await Supplies.postSupplyOffer(data);
      dispatch(getSupplies({ params: state.carrot.supplies.suppliesFilters }));
      dispatch(setIsOfferLoading(true));
      onSuccessCallback && onSuccessCallback();
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsOfferLoading(false));
    }
  }
);

export const postRequestFinancing = createAsyncThunk(
  "supplies/postRequestFinancing",
  async ({ data, onSuccessCallback }: RequestFinancingProps, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      await Supplies.postRequestFinancing(data);
      dispatch(getSupplies({ params: state.carrot.supplies.suppliesFilters }));
      dispatch(setIsOfferLoading(true));
      onSuccessCallback && onSuccessCallback();
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsOfferLoading(false));
    }
  }
);

export const postSupplyDiscountOffer = createAsyncThunk(
  "supplies/postSupplyDiscountOffer",
  async ({ data, onSuccessCallback }: SupplyGetPostFinancingProps, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      await Supplies.postSupplyDiscountOffer(data);
      dispatch(getSupplies({ params: state.carrot.supplies.suppliesFilters }));
      dispatch(setIsOfferLoading(true));
      onSuccessCallback && onSuccessCallback();
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsOfferLoading(false));
    }
  }
);

export const getSearchCompaniesDebtor = createAsyncThunk(
  "supplies/getSearchCompaniesDebtor",
  async (data) => await Supplies.getSearchCompaniesDebtor({ params: { q: data } })
);

export const getSearchCompaniesSupplier = createAsyncThunk(
  "supplies/getSearchCompaniesSupplier",
  async (data) => await Supplies.getSearchCompaniesSupplier({ params: { q: data } })
);

export const postRevokeSupplies = createAsyncThunk(
  "supplies/postRevokeSupplies",
  async ({ data, onSuccessCallback }: RevokeSyppliesProps, { dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      await Supplies.postRevokeSupplies(data);
      dispatch(getSupplies({ params: state.carrot.supplies.suppliesFilters }));
      dispatch(setIsOfferLoading(true));
      onSuccessCallback && onSuccessCallback();

      notification.success({
        message: "Поставки успешно отозваны",
      });
    } catch (e) {
      throw e;
    } finally {
      dispatch(setIsOfferLoading(false));
    }
  }
);
