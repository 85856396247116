import { CustomerFormBaseProps } from "@components/BankGuarantee/customerInfo/CustomerInfoForm/Types";
import { useFieldArray } from "react-hook-form";
import { useStyles } from "@components/BankGuarantee/customerShortCard/styles";
import classNames from "classnames";
import { Button } from "@farzoom/common-ui-components";
import { Button as AntButton, Col, Row, Space, Tag, Typography } from "antd";
import { ExportOutlined, MinusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { VerticalSpace } from "@components/UI/VerticalSpace";
import { formatDate } from "@utils/formatDate";
import { useState } from "react";
import { PersonInfoModal } from "@components/BankGuarantee/personInfo/personInfoModal";
import { FindPersonModal } from "@/lib/modals/BankGuaranteeRequest/findPersonModal";
import { notNullish } from "@utils/notNullish";
import {
  PERSON_ROLES_MAP,
  restrictedRolesToShow,
} from "@components/BankGuarantee/customerInfo/customerPersons/utils/constants";
import { PersonsType } from "@/types/Customer";
import { PERSON_MODEL } from "@components/BankGuarantee/customerInfo/customerPersons/utils/personsModels";

type CustomerPersonsType = CustomerFormBaseProps;

const { Text } = Typography;

export const CustomerPersons = ({ prefix, config, control, watch }: CustomerPersonsType) => {
  const classes = useStyles();
  const {
    fields: persons,
    remove,
    prepend,
    update,
  } = useFieldArray({ control, name: `${prefix}.persons` as "customerPersons.persons" });

  const personsW = watch(`${prefix}.persons` as "customerPersons.persons");

  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [tempPerson, setTempPerson] = useState<PersonsType>(PERSON_MODEL);

  const onFindPerson = () => {
    setIsAdd(true);
  };
  const onClosePerson = () => {
    setIsAdd(false);
  };

  const onAddPerson = (person: PersonsType) => {
    const index = persons.findIndex((p) => p.person.inn === person.person.inn);

    if (index === -1) {
      setTempPerson(person);
      setIsEdit(true);
      setIsAdd(false);
      return;
    }

    onEdit(index);
    setIsAdd(false);
  };

  const onPersonModalClose = () => {
    setIsEdit(false);
    setEditIndex(null);
  };
  const onEdit = (idx: number) => {
    setIsEdit(true);
    setEditIndex(idx);
  };
  const onUpdatePerson = (values: PersonsType) => {
    if (notNullish(editIndex)) {
      update(editIndex as number, values);
      onPersonModalClose();
      return;
    }

    prepend(values);
    setTempPerson(PERSON_MODEL);
    onPersonModalClose();
  };

  return (
    <div>
      <div className={classNames(classes.title, classes.titleWithAction)}>
        <span>{config.title}</span>
        {!config.readonly && (
          <Button type="outlined" iconName="add" title="Добавить физ.лицо" onClick={onFindPerson} />
        )}
      </div>
      <div className={classes.wrapper}>
        {persons.map(({ person, personRole, id }, idx) => (
          <Row key={id} gutter={8} align="top">
            <Col span={9}>
              <Space align="start">
                <UserOutlined style={{ fontSize: "24px" }} />
                <VerticalSpace size={0}>
                  <Text strong>
                    {person?.lastName} {person?.firstName} {person?.secondName}
                  </Text>
                  <VerticalSpace size={0}>
                    {person?.inn && <Text type="secondary">ИНН: {person?.inn}</Text>}
                    {person?.birthDate && (
                      <Text type="secondary">д.р.: {formatDate(person?.birthDate)}</Text>
                    )}
                    {person?.identityDocument?.series && (
                      <Text type="secondary">
                        паспорт: {person?.identityDocument?.series}{" "}
                        {person?.identityDocument?.number}
                      </Text>
                    )}
                  </VerticalSpace>
                </VerticalSpace>
              </Space>
            </Col>
            <Col xs={13} lg={14}>
              <Space size={[0, 4]} wrap>
                {personRole &&
                  Object.entries(personRole)
                    .filter(([k, value]) => !restrictedRolesToShow.includes(k) && !!value)
                    .map(([key, value]) => (
                      <Tag
                        key={key}
                        color={value ? PERSON_ROLES_MAP[key]?.color : "default"}
                        icon={!value ? <MinusCircleOutlined /> : undefined}
                      >
                        {PERSON_ROLES_MAP[key]?.name}
                      </Tag>
                    ))}
              </Space>
            </Col>
            <Col xs={2} lg={1} style={{ padding: 0, display: "flex", justifyContent: "flex-end" }}>
              {!config.readonly && (
                <VerticalSpace size={4}>
                  <Button
                    type="outlined"
                    iconName="edit"
                    title="Редактировать физ.лицо"
                    onClick={() => onEdit(idx)}
                  />
                  <Button
                    type="outlined"
                    iconName="remove"
                    title="Удалить физ.лицо"
                    onClick={() => remove(idx)}
                  />
                </VerticalSpace>
              )}
              {config.readonly && (
                <AntButton
                  title="Просмотреть карточку ФЛ"
                  icon={<ExportOutlined />}
                  onClick={() => onEdit(idx)}
                ></AntButton>
              )}
            </Col>
          </Row>
        ))}
      </div>

      {isEdit && (
        <PersonInfoModal
          defaultValues={notNullish(editIndex) ? personsW[editIndex as number] : tempPerson}
          onClose={onPersonModalClose}
          onUpdate={onUpdatePerson}
          readonly={config.readonly}
        />
      )}
      {isAdd && <FindPersonModal onClose={onClosePerson} onAdd={onAddPerson} />}
    </div>
  );
};
