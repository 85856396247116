import classnames from "classnames";
import { Icon } from "../../layout";
import { useEffect, useRef, useState } from "react";
import { keycloak } from "../../keycloak";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { fullName } from "@/store/userSlice";
import { clearFilters } from "@store/orderFilterSlice";

export const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const name = useAppSelector(fullName);

  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      if (menuRef.current && menuRef.current.contains(evt.target as Node)) return;
      setIsOpen(false);
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleToggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(clearFilters());
    keycloak?.logout();
    localStorage.removeItem("JWT");
  };

  return (
    <div className={classnames("fr-user-menu", { open: isOpen })} ref={menuRef}>
      <div className="fr-user-menu__dropdown">
        <div className="fr-user-menu__list">
          <span className="fr-user-menu__item" onClick={handleLogout}>
            <i className="icon icon-exit" />
            Выход
          </span>
        </div>
      </div>
      <div className="fr-user-menu__main" onClick={handleToggleMenu}>
        <div className="fr-user-menu__name" title={name}>
          <Icon iconName="user2" />
          {name}
        </div>
      </div>
    </div>
  );
};
