import React from "react";
import { cn } from "../../../../../utils/bemConfig";
import "./Loader.scss";

const bem = cn("loader");

export const Loader = () => (
  <div className={bem()}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
